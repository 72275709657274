/* eslint-disable no-unused-vars */
import Formatter from '../../../helper/formatter'
import DateTime from '../../../helper/datetime'
import translate from '../../../helper/translate'
import { columnResource } from '../../../helper/translatePage/languageResource'
// eslint-disable-next-line import/no-unresolved
import dataType from '../../../helper/constant/dataType'

const BalanceDetailColumn = (actionFun, language) => ({
  key: 'id_mutasi',
  columns: [
    {
      name: 'ID Trx',
      selector: 'id_transaksi',
      sortable: true,
      type: dataType.INTEGER,
      showFilter: true,
    },
    {
      name: translate('time', language, columnResource),
      selector: 'tanggal_mutasi',
      sortable: true,
      cell: (row) => DateTime.format(row.tanggal_mutasi, DateTime.FMT_DATE_TIME_YMDHMS3),
      type: dataType.DATERANGE,
      default: true,
      showFilter: true,
    },
    {
      name: 'Partner Reff',
      selector: 'invoicenumber',
      sortable: false,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('name', language, columnResource),
      selector: 'nama_pemilik',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('type', language, columnResource),
      selector: 'tipe_mutasi',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('debt', language, columnResource),
      selector: 'debet',
      sortable: true,
      cell: (row) => Formatter.price(row.debet),
      type: dataType.INTEGER,
      showFilter: true,
    },
    {
      name: translate('credit', language, columnResource),
      selector: 'kredit',
      sortable: true,
      cell: (row) => Formatter.price(row.kredit),
      type: dataType.INTEGER,
      showFilter: true,
    },
    {
      name: translate('remark', language, columnResource),
      selector: 'keterangan',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('note', language, columnResource),
      selector: 'keterangan_detail',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('lastBalance', language, columnResource),
      selector: 'last_balance',
      sortable: true,
      cell: (row) => Formatter.price(Formatter.format2(row.last_balance)),
      type: dataType.INTEGER,
      showFilter: true,
    },
    {
      name: translate('currentBalance', language, columnResource),
      selector: 'current_balance',
      sortable: true,
      cell: (row) => Formatter.price(Formatter.format2(row.current_balance)),
      type: dataType.INTEGER,
      showFilter: true,
    },
  ],
})

export default BalanceDetailColumn
