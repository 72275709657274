import MessageSender from '../sender'
import BaseMessage from '../message'

export default () => async () => {
  const msg = new BaseMessage()
  msg.setPath('data/bank_list')

  const response = await new MessageSender().doGet(msg)

  return response
}
