import MessageSender from '../sender'
import BaseMessage from '../message'

// eslint-disable-next-line no-unused-vars
export default (idPayment, limit, page) => async () => {
  const msg = new BaseMessage()
  msg.setPath('data/list_payment')
  msg.setItem('idPayment', idPayment)
  msg.setItem('limit', limit)
  msg.setItem('page', page)

  const response = await new MessageSender().doGet(msg)

  return response
}
