/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React from 'react'
import { connect } from 'react-redux'
// eslint-disable-next-line
import { Container, Row, Col, Spinner, Badge, Button, Image } from 'react-bootstrap'
import PropTypes from 'prop-types'
import './transfer.scss'
import BaseScreen from '../../base'
import globalConstant from '../../../store/constant/global'
import HeaderComponent from '../../component/header'
import AlertDialog from '../../dialog/alert'
// eslint-disable-next-line
import Formatter from '../../../helper/formatter'
import report from '../../../api/action/report'
import DateTime from '../../../helper/datetime'
import translate from '../../../helper/translate'
import {
  buttonResource,
  columnResource,
  headerPageResource,
  placeholderResource,
  titleSentence,
} from '../../../helper/translatePage/languageResource'
import building from '../../../assets/imgs/logo/Gubuk.png'
import Browser from '../../../helper/browser'
import { confirmOrder } from '../../../api/action/transaction'
import icons from '../../../helper/icons'
import iconName from '../../../helper/constant/iconName'

class TransferDetailScreen extends BaseScreen {
  constructor(props) {
    super(props, ['page--transfer'])
    this.state = {
      isOpen: false,
      alertError: '',
      alertConfirm: '',
      balanceFresh: false,
      loadingData: false,
      dataTransfers: [],
      idOrder: this.props.match.params.id,
      confirmTransferDescription: '',
    }

    this.handleChange = this.handleChange.bind(this)
  }

  toggleSidebar = () => {
    const { isOpen } = this.state

    this.setState({ isOpen: !isOpen })
  }

  getData = async () => {
    const { dispatch, match } = this.props
    const response = await dispatch(report('order_report_detail', '', match.params.id))

    if (response.rc === '00') {
      this.setState({ dataTransfers: response.data })
    }
    this.setState({ balanceFresh: true })
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  showError = (alertError) => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ alertError })
  }

  closeError = () => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ alertError: '' })
  }

  componentDidMount() {
    super.componentDidMount()

    this.getData()
  }

  print = (row) => {
    Browser.openInNewTab(`${Browser.baseURL()}invoice/${row.id_order}/null`)
  }

  dateFormat = (timestamp) => {
    const d = new Date(timestamp)
    // eslint-disable-next-line
    return d.getFullYear() + '-'+ (d.getMonth() + 1) + '-' + d.getDate()
  }

  setStatus = (status, expired = null) => {
    const { language } = this.props
    let word
    let color
    const d = new Date()
    switch (`${status}`) {
      case 'NEW':
        if (expired && expired < d) {
          word = translate('expired', language, placeholderResource)
          color = 'danger'
        } else {
          word = translate('waitingPay', language, placeholderResource)
          color = 'info'
        }
        break
      case 'PAID':
        word = translate('donePay', language, placeholderResource)
        color = 'info'
        break
      case 'PROCESS_COMPLETED':
        word = translate('processTf', language, placeholderResource)
        color = 'info'
        break
      case 'COMPLETED':
        word = translate('success', language, placeholderResource)
        color = 'success'
        break
      default:
        word = translate('fail', language, placeholderResource)
        color = 'danger'
        break
    }
    return <div className={color}>{word}</div>
  }

  copyText = (copyText) => {
    navigator.clipboard.writeText(copyText)

    /* Alert the copied text */
    // eslint-disable-next-line no-alert
    alert(`Copied the text: ${copyText}`)
  }

  orderConfirm = async (idOrder) => {
    const { dispatch, usernameAgen } = this.props

    const res = await dispatch(confirmOrder(usernameAgen, idOrder))
    if (res.data.rc === '00') {
      this.setState({ confirmTransferDescription: res.data.rd })
      this.getData()
    }
    this.setState({ alertError: res.data.rd })
  }

  render() {
    const { appIsLoading, language } = this.props
    const { alertError, balanceFresh, dataTransfers } = this.state
    return (
      <>
        {/* eslint-disable-next-line */}
        <HeaderComponent title="Home" balanceFresh={balanceFresh} onToggle={this.toggleSidebar} changeLang={this.handleLang} />
        <AlertDialog
          show={!!alertError}
          onHide={this.closeError}
          onConfirm={this.closeError}
          title="Information"
        >
          {alertError}
        </AlertDialog>
        <Container fluid className="p-3">
          <div className="mb-3" onClick={() => this.props.history.goBack()}>
            <button type="button" className="btn btn-outline-primary back-button">
              <Image src={icons(iconName.backButton)} className="" />
            </button>
            <span className="ml-2 fontSize-20-Bold">
              {translate('detailTransferMoney', language, headerPageResource)}
            </span>
          </div>
          {appIsLoading ? (
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <Spinner size="xs" className="mr-1" animation="border" variant="primary" />
              <b className="text-primary">Loading</b>
            </div>
          ) : (
            <Row>
              <Col sm={12}>
                {dataTransfers.length > 0 &&
                  // eslint-disable-next-line array-callback-return
                  dataTransfers.map((data) => {
                    let date = null
                    if (data.status === 'COMPLETED') {
                      date = data.time_payment
                        ? DateTime.format(data.time_payment, DateTime.FMT_DATE_TIME_YMDHMS3)
                        : null
                    } else {
                      date = data.expired_str
                        ? DateTime.format(data.expired_str, DateTime.FMT_DATE_TIME_YMDHMS3)
                        : null
                    }

                    return (
                      <div key={data.id_order} className="mb-3">
                        <Row>
                          <Col md={data.status === 'COMPLETED' ? 12 : 8} sm={12}>
                            <div className="card-confirm card mb-3">
                              <div className="card-header">
                                {data.status !== 'COMPLETED' ? (
                                  <Row>
                                    <Col className="fontSize-20-Bold">Bank Transfer</Col>
                                    <Col className="text-right">
                                      <Button
                                        type="submit"
                                        size="md"
                                        variant="primary"
                                        disabled={appIsLoading}
                                        onClick={() => this.print(data)}
                                        className="card-subpicker"
                                      >
                                        <Image src={icons(iconName.print)} className="" />
                                        <div className="color-red">
                                          {translate('print', this.props.languange, columnResource)}
                                        </div>
                                      </Button>
                                    </Col>
                                  </Row>
                                ) : (
                                  <>
                                    <Row>
                                      <Col className="fontSize-20-Bold">Bank Transfer</Col>
                                    </Row>
                                  </>
                                )}
                              </div>
                              <div className="card-body body-detail-transfer">
                                <div className="bank-logo">
                                  {/* eslint-disable-next-line */}
                                  <img className="image-bank" src={data?.channel?.url_image == "" || data.channel.url_image === undefined ? building : data.channel.url_image} alt={"LOGO CHANNEL BANK"} />
                                  <div className="item fontSize-19-Bold">
                                    <div>{data.channel.channel_name}</div>
                                    <div className="color-grey">{data.channel.account_name}</div>
                                  </div>
                                  <div className="item">
                                    <div className="status-trx">
                                      {this.setStatus(data.status, data.expired)}
                                    </div>
                                  </div>
                                </div>
                                <div className="container-item">
                                  {/* eslint-disable-next-line */}
                                  <div  className="fontSize-16-Bold color-red">{translate('transferType', language, titleSentence)}</div>
                                  {/* eslint-disable-next-line */}
                                  <div className="fontSize-16-Bold">{data.channel.channel_type === 'VA' ? 'Virtual Account' : 'Manual Transfer'}</div>
                                </div>
                                <div className="container-copy-data mb-3">
                                  {/* eslint-disable-next-line */}
                                  <div  className="fontSize-16-SemiBold">{translate('accountNumber', language, titleSentence)}</div>
                                  <div className="fontSize-19-Bold copy-data">
                                    <div className="text">
                                      {data.channel.channel_type === 'VA'
                                        ? data.channel.virtual_account_number
                                        : data.channel.account_number}
                                    </div>
                                    <div
                                      className="button-copy"
                                      onClick={() =>
                                        this.copyText(
                                          data.channel.channel_type === 'VA'
                                            ? data.channel.virtual_account_number
                                            : data.channel.account_number
                                        )
                                      }
                                    >
                                      <Image src={icons(iconName.copy)} className="" />
                                      <div className="ml-2 button-copy-text fontSize-16-Medium color-white">
                                        {translate('copy', this.props.language, buttonResource)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="container-copy-data mb-3">
                                  {/* eslint-disable-next-line */}
                                  <div  className="fontSize-16-SemiBold">{translate('accountNumber', language, titleSentence)}</div>
                                  <div className="fontSize-19-Bold copy-data">
                                    <div className="text">
                                      Rp{' '}
                                      {data.channel.channel_type === 'VA'
                                        ? Formatter.price(data.channel.total_amount)
                                        : Formatter.uniquePrice(
                                            Formatter.price(
                                              data.channel.total_amount + data.channel.unique_amount
                                            )
                                          )}
                                    </div>
                                    <div
                                      className="button-copy"
                                      onClick={() =>
                                        this.copyText(
                                          data.channel.channel_type === 'VA'
                                            ? data.channel.total_amount
                                            : data.channel.total_amount + data.channel.unique_amount
                                        )
                                      }
                                    >
                                      <Image src={icons(iconName.copy)} className="" />
                                      <div className="ml-2 button-copy-text fontSize-16-Medium color-white">
                                        {translate('copy', this.props.language, buttonResource)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="container-item">
                                  {/* eslint-disable-next-line */}
                                  <div  className="fontSize-16-SemiBold">{data.status == 'COMPLETED' ? translate('successTime', language, titleSentence) : translate('expTime', language, titleSentence)}</div>
                                  {/* eslint-disable-next-line */}
                                  <div className="fontSize-16-Bold">{date}</div>
                                </div>
                                <div style={{ marginBottom: 50 }}>
                                  <Image src={icons(iconName.alert)} className="" />
                                  <div className="color-pink-dark fontSize-16 d-inline ml-4">
                                    {translate('topupInfo2', this.props.language, titleSentence)}
                                  </div>
                                </div>
                                <hr />
                                <div className="card-header">
                                  <h5 className="m-0">Detail Order</h5>
                                </div>
                                <div className="card-body">
                                  {data.list_order.map((order) => (
                                    <Row key={order.id_inquiry}>
                                      <Col>
                                        <div className="bank-logo">
                                          {/* eslint-disable-next-line */}
                                          <img src={order?.url_image == "" || order.url_image === undefined ? building : order.url_image} alt={"LOGO BANK TUJUAN"} />
                                        </div>
                                        <Row>
                                          <Col>
                                            <div className="">
                                              {/* eslint-disable-next-line */}
                                              <label className="fontSize-16-Bold color-red">{translate('accName', language, titleSentence)}</label>
                                              <h5 className="fontSize-16-SemiBold">
                                                {order.account_name}
                                              </h5>
                                            </div>
                                          </Col>
                                          <Col>
                                            <div className="">
                                              {/* eslint-disable-next-line */}
                                              <label className="fontSize-16-Bold color-red">{order.account_name === 'INDOMARET' ? 'Nomor Pelanggan' : translate('accountNumber', language, titleSentence)}</label>
                                              <h5 className="fontSize-16-SemiBold">
                                                {order.account_number}
                                              </h5>
                                            </div>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col>
                                            <div className="">
                                              {/* eslint-disable-next-line */}
                                              <label className="fontSize-16-Bold color-red">{translate('amount', language, titleSentence)}</label>
                                              <h5 className="fontSize-16-SemiBold">
                                                Rp {Formatter.price(order.amount)}
                                              </h5>
                                            </div>
                                          </Col>
                                          <Col>
                                            <div className="">
                                              {/* eslint-disable-next-line */}
                                              <label className="fontSize-16-Bold color-red">{translate('adminFee', language, titleSentence)}</label>
                                              <h5 className="fontSize-16-SemiBold">
                                                Rp {Formatter.price(order.fee_amount)}
                                              </h5>
                                            </div>
                                          </Col>
                                        </Row>
                                        {data.channel.channel_type === 'VA' && (
                                          <Row>
                                            <Col>
                                              <div className="">
                                                {/* eslint-disable-next-line */}
                                                <label className="fontSize-16-Bold color-red">{translate('serviceFee', language, titleSentence)}</label>
                                                <h5 className="fontSize-16-SemiBold">
                                                  Rp {Formatter.price(order.service_fee)}
                                                </h5>
                                              </div>
                                            </Col>
                                          </Row>
                                        )}
                                        {order.token_code !== '-' && (
                                          <Row>
                                            <Col>
                                              <div className="footer-title">
                                                <label className="fontSize-16-Bold color-red">
                                                  KODE PENGAMBILAN
                                                </label>
                                                <h5 className="fontSize-16">{order.token_code}</h5>
                                                <div className="fontSize-16">{order.note}</div>
                                              </div>
                                            </Col>
                                          </Row>
                                        )}
                                      </Col>
                                    </Row>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </Col>
                          {data.status !== 'COMPLETED' && (
                            <Col md={4} sm={12}>
                              <div className="card card-confirm">
                                <div className="card-header fontSize-16-Medium">
                                  <h5 className="m-0">Konfirmasi Transfer</h5>
                                </div>
                                <hr />
                                {data.is_confirm_transfer ? (
                                  <div className="card-body fontSize-16-Medium color-grey">
                                    <p>
                                      Anda sudah melakukan konfirmasi transfer, silakan menunggu
                                      hingga proses telah berhasil
                                    </p>
                                  </div>
                                ) : (
                                  <div className="card-body fontSize-16-Medium color-grey">
                                    <p>
                                      Jika Anda sudah transfer, silahkan langsung klik tombol{' '}
                                      {/* eslint-disable-next-line react/no-unescaped-entities */}
                                      "Konfirmasi Transfer" dibawah ini.
                                    </p>
                                    <Button
                                      className="mt-3 fontSize-17 btn-custom"
                                      type="submit"
                                      size="lg"
                                      block
                                      variant="primary"
                                      disabled={appIsLoading}
                                      onClick={() => this.orderConfirm(data.id_order)}
                                    >
                                      {translate('orderConfirm', language, titleSentence)}
                                    </Button>
                                  </div>
                                )}
                              </div>
                            </Col>
                          )}
                        </Row>
                      </div>
                    )
                  })}
              </Col>
            </Row>
          )}
        </Container>
      </>
    )
  }
}

TransferDetailScreen.defaultProps = {
  appIsLoading: false,
}

TransferDetailScreen.propTypes = {
  appIsLoading: PropTypes.bool,
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
    language: state.Global[globalConstant.LANGUAGE],
  }),
  (dispatch) => ({ dispatch })
)(TransferDetailScreen)
