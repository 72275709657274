/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable radix */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable eqeqeq */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/prop-types */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
// eslint-disable-next-line
import { Container, Row, Col, Spinner, Table, Button, Alert, Image, Form, Modal } from 'react-bootstrap'
import authConstant from '../../../../store/constant/auth'
// eslint-disable-next-line
import { postOrderCreate } from '../../../../api/action/transaction'
import AlertDialog from '../../../dialog/alert'
import Formatter from '../../../../helper/formatter'
import translate from '../../../../helper/translate'
import global from '../../../../store/constant/global'
import {
  buttonResource,
  headerPageResource,
  placeholderResource,
  titleSentence,
} from '../../../../helper/translatePage/languageResource'
import iconName from '../../../../helper/constant/iconName'
import icons from '../../../../helper/icons'
import getCsrf from '../../../../api/action/getCsrf'
import logout from '../../../../api/action/logout'

class ChoosePayment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      alertError: '',
      selectedVoucher: null,
      selectedChannel: null,
      voucherAmount: null,
      agree: false,
      showVoucherList: false,
      showModalFAQ: false,
      cardOptionChannelPicker: [
        {
          key: 'transfer',
          title: 'Manual Input',
          active: true,
        },
        {
          key: 'va',
          title: translate('transferVa', this.props.languange, buttonResource),
          active: false,
        },
      ],
      listChannelActive: 'transfer',
    }
  }

  componentDidMount() {
    const { channel } = this.props

    if (channel === null || channel === undefined || channel.length === 0) {
      this.setState({ alertError: 'Terjadi Kesahalan Saat Mengambil Data Channel Payment' })
    }
  }

  handleSelected = async (bank) => {
    const { selectedChannel } = this.state

    if (selectedChannel === null) {
      this.setState({ selectedChannel: bank })
    } else if (selectedChannel.id == bank.id) {
      this.setState({ selectedChannel: null })
    } else {
      this.setState({ selectedChannel: null })
      this.setState({ selectedChannel: bank })
    }
  }

  handleSubmit = async () => {
    this.setState({ loading: true })
    const bank = this.state.selectedChannel
    // eslint-disable-next-line
    const { tabledata, uniqcode, biayaadmin, dispatch, appUserData, rekeningTujuan, bankChoosen } = this.props
    // eslint-disable-next-line
    const amount = parseInt(uniqcode) + parseInt(tabledata.nominal) + parseInt(biayaadmin)

    const rekening = rekeningTujuan.split('-').join('')
    const data = {
      // eslint-disable-next-line
      id_channel: parseInt(bank.id),
      description: tabledata.catatan,
      username: appUserData.username_agen,
      kode_voucher:
        this.state.selectedVoucher !== null ? this.state.selectedVoucher.voucher_kode : '',
      list_order: [
        {
          // eslint-disable-next-line
          account_number: rekening,
          // bank_code: bank.codeChannel,
          bank_code: bankChoosen === 'IDMTARIKTUNAI' ? 'INDOMARET' : bankChoosen,
          // eslint-disable-next-line
          amount: parseInt(amount),
          remark: bank.text_help,
          // eslint-disable-next-line
          id_inquiry: parseInt(tabledata.id_transaksi),
        },
      ],
    }

    if (bank === null) {
      this.setState({ loading: false })
      this.setState({ alertError: 'Please Select Channel Payment First' })
    } else {
      let response = await dispatch(getCsrf())
      if (response.isOK()) {
        // eslint-disable-next-line
        response = await dispatch(postOrderCreate(data))
        this.setState({ loading: false })
        if (response.isOK()) {
          this.props.onSelect(bank, response.data.data)
        } else {
          // eslint-disable-next-line
          // this.setState({ alertError: 'Order gagal, coba beberapa saat lagi. Jika masih gagal, silakan mengubungi customer service LinkQu. Terima kasih.' })
          this.setState({ alertError: response.getRD() })
        }
      } else {
        dispatch(logout())
      }
    }
  }

  handleBack = () => {
    this.props.onBack('result-inquiry')
  }

  handleVoucher = (voucher) => {
    if (voucher) {
      const { tabledata } = this.props
      const amount = parseInt(tabledata.nominal)

      this.setState({
        selectedVoucher: voucher,
        voucherAmount: amount - voucher.voucher_nominal,
        showVoucherList: false,
      })
    } else {
      this.setState({
        showVoucherList: false,
      })
    }
  }

  setListChannelPayment(item, dataPicker) {
    const datas = []
    let listChannelActive
    for (const data of dataPicker) {
      if (data.key == item.key) {
        data.active = true
        listChannelActive = item.key
      } else {
        data.active = false
      }
      datas.push(data)
    }
    this.setState({ cardOptionChannelPicker: datas, listChannelActive: listChannelActive })
  }

  checkboxHandler = (evt) => {
    this.setState({ agree: evt.target.checked })
  }

  labelCheckbox = () => {
    return (
      <>
        <div className="fontSize-16">
          {translate('choosePaymentTransferInfoVoucher4', this.props.language, titleSentence)}
        </div>
      </>
    )
  }

  checkActiveChannel = (selectedChannel, data) => {
    if (selectedChannel !== null && selectedChannel.id == data.id) {
      return `list-wrapper-active`
    }

    return ``
  }

  render() {
    // eslint-disable-next-line
    const { channel, voucherList, languange, appUserType, biayaadmin } = this.props
    // eslint-disable-next-line
    const { cardOptionChannelPicker, loading, alertError, selectedVoucher, selectedChannel, agree, showVoucherList, listChannelActive, showModalFAQ } = this.state
    let { voucherAmount } = this.state

    let total = 0
    if (this.state.selectedChannel) {
      total =
        parseInt(this.props.tabledata.nominal) +
        this.state.selectedChannel.admin_fee +
        this.state.selectedChannel.service_fee
      if (this.state.selectedVoucher) {
        total -= this.state.selectedVoucher.voucher_nominal
      }
      voucherAmount = Formatter.price(total)
      // eslint-disable-next-line no-unused-vars
      total = Formatter.price(total)
    }

    return (
      <div>
        <div className="mb-3 mt-3 ml-3 d-flex align-items-center" onClick={this.handleBack}>
          <button type="button" className="btn btn-outline-primary back-button">
            <Image src={icons(iconName.backButton)} className="" />
          </button>
          <span className="ml-3">
            <div className="fontSize-16">
              {translate('choosePaymentTransferInfo1', languange, titleSentence)}
            </div>
          </span>
        </div>
        <div className="container-choose-payment mt-5 ml-3">
          <Row>
            <Col md={8} sm={12}>
              {loading ? (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                  <Spinner size="xs" className="mr-1 text-active" animation="border" />
                  <b className="text-active">Memproses data</b>
                </div>
              ) : (
                <>
                  {appUserType !== 'Personal END USER' ? (
                    <Row>
                      {cardOptionChannelPicker.map((item) => (
                        <Col
                          key={item.component}
                          sm="6"
                          xs="12"
                          className="pl-0 mb-2"
                          onClick={() => this.setListChannelPayment(item, cardOptionChannelPicker)}
                        >
                          <div
                            className={
                              item.active
                                ? 'card-subpicker card-subpicker-active'
                                : 'card-subpicker'
                            }
                          >
                            <div className="d-inline-block">
                              <div
                                className={
                                  item.active
                                    ? 'color-white fontSize-17'
                                    : 'text-active fontSize-17'
                                }
                              >
                                {item.title}
                              </div>
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  ) : (
                    <></>
                  )}
                  {voucherList !== null && (
                    <div className="container-voucher mr-3">
                      <div className="d-flex">
                        <Image
                          src={icons(iconName.voucher, process.env.REACT_APP_BRAND_NAME)}
                          className="mr-3"
                        />
                        <div className="fontSize-16-Bold">Voucher</div>
                      </div>
                      <div className="fontSize-16 color-grey">
                        {translate('choosePaymentTransferInfoVoucher1', languange, titleSentence)}
                      </div>
                      {voucherAmount !== null ? (
                        <div>
                          <div
                            className="button-popup-modal-voucher d-flex fontSize-16-SemiBold mr-3"
                            onClick={() => this.setState({ showVoucherList: true })}
                          >
                            {selectedVoucher.voucher_keterangan} (Rp.{' '}
                            {Formatter.price(selectedVoucher.voucher_nominal)})
                            <Image src={icons(iconName.checkRounded)} className="ml-3" />
                          </div>
                          <div className="color-green fontSize-14-Bold mt-1">
                            Total Nominal saat Voucher ini digunakan adalah Rp.{' '}
                            {Formatter.price(voucherAmount)}
                          </div>
                        </div>
                      ) : (
                        <div
                          className="button-popup-modal-voucher d-flex justify-content-between mb-5 fontSize-16-SemiBold"
                          onClick={() => this.setState({ showVoucherList: true })}
                        >
                          {translate('choosePaymentTransferInfoVoucher2', languange, titleSentence)}
                          <Image src={icons(iconName.arrowRightBlack)} className="" />
                        </div>
                      )}
                    </div>
                  )}
                  <div className="container-channel-payment mt-5">
                    {listChannelActive == 'transfer' ? (
                      <div className="fontSize-20-Bold mb-4">
                        {translate('manualTf', languange, headerPageResource)}
                      </div>
                    ) : (
                      <div className="fontSize-20-Bold mb-4">
                        {translate('virtualAccount', languange, headerPageResource)}
                      </div>
                    )}
                    <div className="method-list mb-4">
                      {channel[listChannelActive == 'transfer' ? 0 : 1].data.map((bank) => (
                        <button
                          key={`ma-${bank.codeChannel}`}
                          type="button"
                          className={`list-wrapper mr-3 ${this.checkActiveChannel(
                            selectedChannel,
                            bank
                          )}`}
                          name={bank.namaChannel}
                          onClick={() => this.handleSelected(bank)}
                        >
                          <Image
                            src={bank.url_image == '' ? icons(iconName.gubuk) : bank.url_image}
                            alt="logo bank"
                            className="logo"
                          />
                          <span className="fontSize-16-SemiBold ml-4">{bank.namaChannel}</span>
                        </button>
                      ))}
                    </div>
                  </div>
                </>
              )}
              <AlertDialog
                show={!!alertError}
                onHide={() => this.setState({ alertError: '' })}
                onConfirm={() => this.setState({ alertError: '' })}
                title="Perhatian"
              >
                {alertError}
              </AlertDialog>
            </Col>
            <Col md={4} sm={12}>
              <div className="container-card-detail-payment mb-3">
                <div className="header-detail-payment">
                  <div className="fontSize-16-SemiBold">
                    {translate('choosePaymentTransferInfoVoucher5', languange, titleSentence)}
                  </div>
                </div>
                <hr />
                <div className="body-detail-payment">
                  <Row className="mb-3">
                    <Col>
                      <div className="fontSize-16-SemiBold color-grey">
                        {translate('choosePaymentTransferInfoVoucher6', languange, titleSentence)}
                      </div>
                    </Col>
                    <Col>
                      <div className="fontSize-16-SemiBold">
                        Rp. {Formatter.price(parseInt(this.props.tabledata.nominal))}
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <div className="fontSize-16-SemiBold color-grey">
                        {translate('choosePaymentTransferInfoVoucher9', languange, titleSentence)}
                      </div>
                    </Col>
                    <Col>
                      <div className="fontSize-16-SemiBold">
                        Rp.{' '}
                        {this.state.selectedVoucher
                          ? Formatter.price(this.state.selectedVoucher.voucher_nominal)
                          : 0}
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <div className="fontSize-16-SemiBold color-grey">
                        {translate('choosePaymentTransferInfoVoucher10', languange, titleSentence)}
                      </div>
                    </Col>
                    <Col>
                      <div className="fontSize-16-SemiBold">
                        Rp
                        {
                          // eslint-disable-next-line
                          this.state.selectedChannel
                            ? Formatter.price(
                                // eslint-disable-next-line
                                this.state.selectedChannel.admin_fee +
                                  // eslint-disable-next-line
                                this.state.selectedChannel.service_fee
                              )
                            : 0
                        }
                      </div>
                    </Col>
                  </Row>
                  <div className="card-total">
                    <Row>
                      <Col>
                        <div className="fontSize-16-SemiBold ">
                          {translate('choosePaymentTransferInfoVoucher7', languange, titleSentence)}
                        </div>
                      </Col>
                      <Col>
                        <div className="fontSize-16-SemiBold">Rp. {total}</div>
                      </Col>
                    </Row>
                  </div>
                  <div className="alert-info-custom">
                    {/* <Image src={icons(iconName.alert)} className="" />
                    <div className="color-pink-dark fontSize-16 d-inline ml-5">
                      {translate(
                        'choosePaymentTransferInfoVoucher8',
                        this.props.language,
                        titleSentence
                      )}
                    </div> */}
                  </div>
                </div>
              </div>
              <Form.Check
                inline
                className="mt-5"
                name="term"
                label={this.labelCheckbox()}
                type="checkbox"
                defaultChecked={false}
                onChange={(e) => this.checkboxHandler(e)}
              />
              <Button
                type="submit"
                size="md"
                variant="primary"
                block
                onClick={this.handleSubmit}
                disabled={selectedChannel == null || !agree || loading}
                className="btn-custom btn-custom-reset-password fontSize-17 clear-margin"
              >
                {translate('next', languange, buttonResource)}
                <Image src={icons(iconName.arrowWhite)} className="ml-3" />
              </Button>
            </Col>
          </Row>
        </div>
        <Modal className="custom-modal" show={showVoucherList} onHide={() => this.handleVoucher()}>
          <Modal.Header closeButton className="header-custom header-custom-voucher">
            <Modal.Title className="fontSize-16-Bold">
              <Image
                src={icons(iconName.voucher, process.env.REACT_APP_BRAND_NAME)}
                className="mr-3"
              />
              {translate('choosePaymentTransferInfoVoucher3', this.props.language, titleSentence)}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {voucherList != null &&
              voucherList.map((voucher) => (
                <>
                  <div className="card-voucher">
                    <div className="fontSize-16-SemiBold">
                      {voucher.voucher_keterangan} (Rp. {Formatter.price(voucher.voucher_nominal)})
                    </div>
                    <Button
                      type="submit"
                      size="md"
                      variant="primary"
                      block
                      onClick={() => this.handleVoucher(voucher)}
                      className="clear-margin btn-custom btn-custom-reset-password fontSize-17"
                    >
                      {translate('choose', this.props.language, placeholderResource)}
                    </Button>
                  </div>
                </>
              ))}
            {voucherList == null && (
              <div>
                <div className="fontSize-16-SemiBold">
                  {translate('noData', this.props.language, placeholderResource)}
                </div>
              </div>
            )}
          </Modal.Body>
        </Modal>
        <Modal show={showModalFAQ} onHide={() => this.setState({ showModalFAQ: false })}>
          <Modal.Header closeButton>
            <Modal.Title>FAQ</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <iframe title="FAQ" src="https://www.linkqu.id/faq-produk-dan-layanan/" />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" className="d-none">
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

ChoosePayment.defaultProps = {
  onSelect: '',
  onBack: '',
  channel: '',
  tabledata: '',
  uniqcode: '',
  biayaadmin: '',
  appUserData: '',
  rekeningTujuan: '',
  bankChoosen: '',
  appUserType: '',
}

ChoosePayment.propTypes = {
  onSelect: PropTypes.string,
  onBack: PropTypes.string,
  channel: PropTypes.string,
  tabledata: PropTypes.string,
  uniqcode: PropTypes.string,
  biayaadmin: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  appUserData: PropTypes.string,
  rekeningTujuan: PropTypes.string,
  bankChoosen: PropTypes.string,
  appUserType: PropTypes.string,
}

export default connect(
  (state) => ({
    appUserData: state.Auth[authConstant.USER_DATA],
    languange: state.Global[global.LANGUAGE],
    appUserType: state.Auth[authConstant.USER_TYPE],
  }),
  (dispatch) => ({ dispatch })
)(ChoosePayment)
