/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { Table, Form, Button, Row, Col, InputGroup, Image } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Formatter from '../../../helper/formatter'
import AlertDialog from '../../dialog/alert'
import translate from '../../../helper/translate'
import { postChecksum, bulkWithdrawInquiry } from '../../../api/action/transaction'
import {
  buttonResource,
  columnResource,
  placeholderResource,
} from '../../../helper/translatePage/languageResource'
import icons from '../../../helper/icons'
import iconName from '../../../helper/constant/iconName'

class DisbursementTable extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      ...props.data.reduce((acc, cur, index) => {
        return {
          ...acc,
          ...{
            [`check_${index}`]: true,
            [`bankCode_${index}`]: cur.bankCode || '',
            [`accountNumber_${index}`]: Formatter.serial(cur.accountNumber, 64),
            [`phone_${index}`]: cur.phone || '',
            [`email_${index}`]: cur.email || '',
            [`nominal_${index}`]: cur.nominal || '',
            [`note_${index}`]: cur.note || '',
            [`reffId_${index}`]: 0,
            [`name_${index}`]: '-',
            [`error_${index}`]: '',
          },
        }
      }, {}),
      allChecked: true,
      hideCheckButton: false,
      showPass: false,
    }
  }

  showError = (alertError) => {
    this.setState({ alertError })
  }

  closeError = () => {
    this.setState({ alertError: '' })
  }

  handleShowPass = (type) => {
    switch (type) {
      case 'show':
        this.setState({ showPass: true })
        break
      case 'hidden':
        this.setState({ showPass: false })
        break

      default:
        this.setState({ showPass: false })
        break
    }
  }

  handleChange = (e) => {
    const {
      target: { name, checked, value },
    } = e

    if (name === 'checkAll') {
      const { data } = this.props

      this.setState(() => {
        const x = {
          allChecked: checked,
        }

        for (let i = 0; i < data.length; i += 1) {
          x[`check_${i}`] = checked
        }

        return x
      })
    } else if (name.indexOf('check') === 0) {
      this.setState((state) => ({
        [name]: checked,
        allChecked:
          Object.keys(state).filter(
            (k) =>
              k.indexOf('check') === 0 &&
              ((k !== name && state[k] === false) || (k === name && !checked))
          ).length === 0,
      }))
    } else if (name.indexOf('accountNumber') === 0 || name.indexOf('phone') === 0) {
      this.setState({ [name]: Formatter.serial(value, 64) })
    } else if (name.indexOf('nominal') === 0) {
      this.setState({ [name]: Formatter.price(value) })
    } else {
      this.setState({ [name]: value })
    }
  }

  doSubmit = async () => {
    const { password } = this.state

    if (password) {
      const { dispatch, data, onPaymentFinished } = this.props
      const response = await dispatch(postChecksum(password))
      if (response.isOK()) {
        const { state } = this
        const { uuid_user: uuid, checksum } = response.getResultAsObject()

        const promises = []
        data.forEach((datum, index) => {
          if (state[`check_${index}`]) {
            promises.push(
              dispatch(
                bulkWithdrawInquiry(
                  index,
                  state[`bankCode_${index}`],
                  state[`accountNumber_${index}`],
                  state[`nominal_${index}`],
                  checksum,
                  uuid
                )
              )
            )
          }
        })

        const results = await Promise.all(promises)
        const oks = results.filter((res) => res.isOK())

        if (oks.length > 0) {
          onPaymentFinished(
            uuid,
            checksum,
            oks.map((res, index) => ({
              ...res.getResultAsObject(),
              ...{
                phone: state[`phone_${index}`],
                email: state[`email_${index}`],
                note: state[`note_${index}`],
              },
            }))
          )
        }

        this.setState(() => {
          const x = { uuid, checksum, hideCheckButton: oks.length === data.length }
          results.forEach((res) => {
            const index = res.getItem('index')
            if (res.isOK()) {
              const {
                idtransaksi,
                anrekeningtujuan,
                nominal,
                nominaladmin,
              } = res.getResultAsObject()

              x[`reffId_${index}`] = idtransaksi
              x[`name_${index}`] = anrekeningtujuan
              x[`subtotal_${index}`] = nominal
              x[`adminFee_${index}`] = nominaladmin
            } else {
              x[`error_${index}`] = res.getRD()
            }
          })

          return x
        })
      } else {
        this.showError(response.getRD())
      }
    } else {
      this.showError('Input account password to continue')
    }
  }

  render() {
    const { allChecked, password, alertError, checksum, hideCheckButton, showPass } = this.state
    const { data, listBank, onReset, language } = this.props

    const bankMap = listBank.reduce((acc, cur) => {
      acc[cur.kodebank] = cur.namabank
      return acc
    }, {})

    return (
      <>
        <Table bordered hover responsive className="disbursement-table">
          <thead>
            <tr>
              <th>
                <Form.Check
                  type="checkbox"
                  name="checkAll"
                  value="on"
                  checked={allChecked}
                  onChange={this.handleChange}
                />
              </th>
              {checksum && <th>&nbsp;</th>}
              <th>{translate('bankName', language, columnResource)}</th>
              <th>{translate('accNumber', language, columnResource)}</th>
              <th>{translate('name', language, columnResource)}</th>
              <th>{translate('phone', language, columnResource)}</th>
              <th>Email</th>
              <th>{translate('amount', language, columnResource)}</th>
              <th>{translate('note', language, columnResource)}</th>
              {checksum && <th>{translate('adminFee', language, columnResource)}</th>}
              {checksum && <th>Subtotal</th>}
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {data.map((datum, index) => {
              const { state } = this
              if (state[`reffId_${index}`]) {
                return (
                  <tr>
                    <td className="fontSize-16">&nbsp;</td>
                    {checksum && <td>&nbsp;</td>}
                    <td className="fontSize-16">{`(${state[`bankCode_${index}`]}) ${
                      bankMap[state[`bankCode_${index}`]]
                    }`}</td>
                    <td className="fontSize-16">{state[`accountNumber_${index}`]}</td>
                    <td className="fontSize-16">{state[`name_${index}`]}</td>
                    <td className="fontSize-16">{state[`phone_${index}`]}</td>
                    <td className="fontSize-16">{state[`email_${index}`]}</td>
                    <td className="fontSize-16">{Formatter.price(state[`nominal_${index}`])}</td>
                    <td className="fontSize-16">{state[`note_${index}`]}</td>
                    {checksum && (
                      <td className="fontSize-16">{Formatter.price(state[`adminFee_${index}`])}</td>
                    )}
                    {checksum && (
                      <td className="fontSize-16">{Formatter.price(state[`subtotal_${index}`])}</td>
                    )}
                    <th className="fontSize-16">&nbsp;</th>
                  </tr>
                )
              }
              return (
                <tr className={state[`error_${index}`] ? 'error' : ''}>
                  <td>
                    <Form.Check
                      type="checkbox"
                      name={`check_${index}`}
                      value="on"
                      checked={state[`check_${index}`]}
                      onChange={this.handleChange}
                    />
                  </td>
                  {checksum && <td>{state[`error_${index}`] ? state[`error_${index}`] : ' '}</td>}
                  <td>
                    <Form.Control
                      as="select"
                      custom
                      name={`bankCode_${index}`}
                      value={state[`bankCode_${index}`]}
                      onChange={this.handleChange}
                      className="fontSize-16"
                    >
                      {listBank.map((b) => (
                        <option key={b.kodebank} value={b.kodebank}>
                          {b.namabank}
                        </option>
                      ))}
                    </Form.Control>
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      name={`accountNumber_${index}`}
                      maxLength="64"
                      value={state[`accountNumber_${index}`]}
                      autoComplete="off"
                      onChange={this.handleChange}
                      className="fontSize-16"
                    />
                  </td>
                  <td>{state[`name_${index}`]}</td>
                  <td>
                    <Form.Control
                      type="text"
                      name={`phone_${index}`}
                      maxLength="64"
                      value={state[`phone_${index}`]}
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="email"
                      name={`email_${index}`}
                      maxLength="64"
                      value={state[`email_${index}`]}
                      autoComplete="off"
                      onChange={this.handleChange}
                      className="fontSize-16"
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      name={`nominal_${index}`}
                      maxLength="20"
                      value={state[`nominal_${index}`]}
                      autoComplete="off"
                      onChange={this.handleChange}
                      className="fontSize-16"
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      name={`note_${index}`}
                      maxLength="64"
                      value={state[`note_${index}`]}
                      autoComplete="off"
                      onChange={this.handleChange}
                      className="fontSize-16"
                    />
                  </td>
                  {checksum && <td>&nbsp;</td>}
                  {checksum && <td>&nbsp;</td>}
                  <td>
                    <FontAwesomeIcon fixedWidth icon="trash-alt" className="text-active" />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
        {!hideCheckButton &&
          // eslint-disable-next-line react/destructuring-assignment
          Object.keys(this.state).filter((k) => k.indexOf('check') === 0 && this.state[k] === true)
            .length > 0 && (
            <div className="table-input mt-3">
              <Row aria-label="Basic example">
                <Col sm="6" xs="12" className="mt-2">
                  <Form.Group className="form-password mb-3">
                    <Form.Label className="fontSize-16-Medium color-grey">
                      {translate('inputPass', language, placeholderResource)}
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        type={showPass ? 'text' : 'password'}
                        name="password"
                        maxLength="64"
                        value={password}
                        onChange={this.handleChange}
                        className="custom-input"
                      />
                      {showPass ? (
                        <InputGroup.Text onClick={() => this.handleShowPass('hidden')}>
                          <Image src={icons(iconName.showPass)} />
                        </InputGroup.Text>
                      ) : (
                        <InputGroup.Text onClick={() => this.handleShowPass('show')}>
                          <Image src={icons(iconName.hidePass)} />
                        </InputGroup.Text>
                      )}
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col sm="3" xs="12">
                  <Button
                    className="btn-custom-second btn-custom color-white"
                    type="button"
                    size="md"
                    block
                    onClick={onReset}
                  >
                    {translate('cancel', language, buttonResource)}
                  </Button>
                </Col>
                <Col sm="3" xs="12">
                  <Button
                    className="btn-custom btn-custom-first"
                    type="submit"
                    size="md"
                    variant="danger"
                    block
                    onClick={this.doSubmit}
                  >
                    {translate('smCheck', language, buttonResource)}
                  </Button>
                </Col>
              </Row>
            </div>
          )}
        {hideCheckButton && (
          <Button
            className="btn-custom-second btn-custom color-white"
            type="button"
            size="md"
            block
            onClick={onReset}
          >
            {translate('cancel', language, buttonResource)}
          </Button>
        )}
        <AlertDialog
          show={!!alertError}
          onHide={this.closeError}
          onConfirm={this.closeError}
          title="Perhatian"
        >
          {alertError}
        </AlertDialog>
      </>
    )
  }
}

DisbursementTable.defaultProps = {
  data: [],
  listBank: [],
  dispatch: null,
  onReset: null,
  onPaymentFinished: null,
  language: 'in',
}

DisbursementTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  listBank: PropTypes.arrayOf(PropTypes.object),
  dispatch: PropTypes.func,
  onReset: PropTypes.func,
  onPaymentFinished: PropTypes.func,
  language: PropTypes.string,
}

export default DisbursementTable
