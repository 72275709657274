/* eslint-disable react/react-in-jsx-scope */
import { connect } from 'react-redux'
// eslint-disable-next-line
import { Container, Row, Col } from 'react-bootstrap'
// eslint-disable-next-line
import PropTypes from 'prop-types'

import './landing.scss'
import BaseScreen from '../base'
import globalConstant from '../../store/constant/global'
import authConstant from '../../store/constant/auth'
import icBussiness from '../../assets/imgs/icons/ic-bussiness.png'
import icons from '../../helper/icons'
import iconName from '../../helper/constant/iconName'

class DashboardScreen extends BaseScreen {
  constructor(props) {
    super(props, ['page--dashboard-landing'])
    this.state = {
      isOpen: false,
      balanceFresh: false,
    }
  }

  toggleSidebar = () => {
    const { isOpen } = this.state

    this.setState({ isOpen: !isOpen })
  }

  redirect = (role) => {
    // eslint-disable-next-line
    const { token } = this.props
    localStorage.clear()
    if (role === 'personal') {
      // eslint-disable-next-line
      window.location.href=process.env.REACT_APP_URL_LINKQU_REG+'/activationuser/'+token.auth_token.personal
    } else {
      // eslint-disable-next-line
      window.location.href=process.env.REACT_APP_URL_LINKQU_REG+'/activationuser/'+token.auth_token.bisnis
    }
  }

  render() {
    return (
      <div className="w-100">
        <Container fluid className="p-3">
          <Container>
            <center>
              <h3 className="text-active">
                Selamat bergabung di {process.env.REACT_APP_BRAND_NAME}
              </h3>
              <br />
              Sebelum kamu dapat melakukan transaksi. Kami membutuhkan sedikit
              <br />
              informasi demi keamanan sesuai peraturan dari Bank Indonesia
              <br />
              <br />
              <h4>Jenis layanan apa yang kamu butuhkan?</h4>
              <br />
            </center>
            <Row>
              <Col>
                <div className="card-option personal">
                  <img src={icons(iconName.icPersonal, process.env.REACT_APP_BRAND_NAME)} alt="" />
                  <div className="title">PERSONAL</div>
                  {/* eslint-disable-next-line */}
                  <button type="button" onClick={() => this.redirect('personal')}>Pilih</button>
                </div>
              </Col>
              <Col>
                <div className="card-option bisnis">
                  <img src={icBussiness} alt="" />
                  <div className="title">BISNIS</div>
                  {/* eslint-disable-next-line */}
                  <button type="button" onClick={() => this.redirect('bisnis')}>Pilih</button>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>
    )
  }
}

DashboardScreen.defaultProps = {
  appIsLoading: false,
  appUserId: '',
  appUserName: '',
  appAgentName: '',
  appUserGroup: '',
  token: '',
}

DashboardScreen.propTypes = {
  appIsLoading: PropTypes.bool,
  appUserId: PropTypes.string,
  appUserName: PropTypes.string,
  appAgentName: PropTypes.string,
  appUserGroup: PropTypes.string,
  token: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
    appUserId: state.Auth[authConstant.USER_ID],
    appUserName: state.Auth[authConstant.USER_NAME],
    appUserGroup: state.Auth[authConstant.USER_GROUP],
    appAgentName: state.Auth[authConstant.AGENT_NAME],
  }),
  (dispatch) => ({ dispatch })
)(DashboardScreen)
