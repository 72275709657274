/* eslint-disable eqeqeq */
/* eslint-disable react/react-in-jsx-scope */
const Formatter = {
  price: (mny) => {
    let money = mny
    if (!money) {
      money = ''
    }
    if (typeof money === 'number') {
      money = money.toString()
    }
    const v = money.replace(/[^0-9]/gi, '')
    let f = parseInt(v) // eslint-disable-line radix
    if (Number.isNaN(f) || f < 1) {
      f = 0
    }
    return f.toFixed(0).replace(/./g, (c, i, a) => {
      let x
      if (i && c !== '.' && !((a.length - i) % 3)) {
        x = `.${c}`
      } else if (c === '.') {
        x = `,${c.substring(1)}`
      } else {
        x = c
      }
      return x
    })
  },

  priceDecimal: (mny) => {
    let money = mny
    if (!money) {
      money = ''
    }
    if (typeof money === 'number') {
      money = money.toString()
    }
    // const v = money.replace(/[^0-9]/gi, '')
    // let f = parseInt(v) // eslint-disable-line radix
    let f = parseFloat(money) // eslint-disable-line radix
    if (Number.isNaN(f)) {
      f = 0
    }
    return f.toFixed(2).replace(/./g, (c, i, a) => {
      let x
      if (i && c !== '.' && !((a.length - i) % 3)) {
        x = `.${c}`
      } else if (c === '.') {
        x = `,${c.substring(1)}`
      } else {
        x = c
      }
      return x
    })
  },

  uniquePrice: (price) => {
    const splitPrice = price.split('.')
    const commonPrice = []

    const uniqueCode = splitPrice[splitPrice.length - 1]

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < splitPrice.length - 1; i++) {
      commonPrice.push(splitPrice[i])
    }

    return (
      <span>
        {commonPrice.join('.')}.<span style={{ color: 'red' }}>{uniqueCode}</span>
      </span>
    )
  },

  serial: (vlue, lngth, asis) => {
    let value = vlue
    let length = lngth
    if (!value) {
      value = ''
    }
    if (!length) {
      length = 16
    }
    let v = value.replace(/-+/g, '')
    let rgx
    if (!asis) {
      v = v.replace(/[^0-9]/gi, '')
      rgx = `\\d{4,${length}}`
    } else {
      rgx = `.{4,${length}}`
    }
    const matches = v.match(new RegExp(rgx, 'g'))
    const match = (matches && matches[0]) || ''
    const parts = []

    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4))
    }

    if (parts.length) {
      return parts.join('-')
    }
    return value
  },

  slug: (value) => {
    let slg = value
    slg = slg.toLowerCase()
    // eslint-disable-next-line
    slg = slg.replace('/\s/g','-')

    return slg
  },

  // erase format 1000.00
  format2: (value) => {
    let money = value
    if (!money) {
      money = ''
    }
    if (typeof money === 'number') {
      money = money.toString()
    }

    const splitted = money.split('.')
    const numb = splitted[0]

    return numb
  },
}

export default Formatter
