/* eslint-disable camelcase */
import MessageSender from '../sender'
import BaseMessage from '../message'

export default (ipAddress, appName, idOtp, otp, captcha) => async () => {
  const msg = new BaseMessage()

  msg.setPath('/control/update_credential')
  msg.setItem('app_name', appName)
  msg.setItem('ip_address', ipAddress)
  msg.setItem('id_otp', idOtp)
  msg.setItem('otp', otp)
  msg.setItem('captcha', captcha)

  const response = await new MessageSender().doPost(msg)

  return response
}
