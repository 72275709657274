/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/react-in-jsx-scope */
import { connect } from 'react-redux'
import { Container, Row, Col, Card, Spinner, Image, Button } from 'react-bootstrap'
import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import moment from 'moment'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import { Chart, Axis, Tooltip, Interval, Interaction, Coordinate, Legend } from 'bizcharts'
import './rightpanel.css'
import './dashboard.scss'
import BaseScreen from './base'
import globalConstant from '../store/constant/global'
import authConstant from '../store/constant/auth'
import HeaderComponent from './component/header'
import resume from '../api/action/resume'

import Linkqu from '../helper/linkqu'
import BANNER from '../assets/imgs/mobile-link.webp'
import foto from '../assets/imgs/up.png'
import iconName from '../helper/constant/iconName'
import icons from '../helper/icons'
import translate from '../helper/translate'
import { titleSentence } from '../helper/translatePage/languageResource'
import { getBerita } from '../api/action/berita'

class DashboardScreen extends BaseScreen {
  constructor(props) {
    super(props, ['page--dashboard'])
    this.state = {
      resumeData: Linkqu.getResumeObject(props.appUserGroup),
      selectedDate: moment(new Date()).format('DD MMM YYYY'),
      selectedDateTemp: moment(new Date()).format('YYYY-MM-DD'),
      balanceFresh: false,
      loading: false,
      resumeProductTransactionPerDate: [],
      resumePendingTransactionPerDate: undefined,
      resumeTransactionPerDate: [
        { key: 'TRANSFER', value: 'Transfer', data: undefined },
        { key: 'IPG', value: 'Virtual Account', data: undefined },
        { key: 'EMONEY', value: 'E-money', data: undefined },
      ],
      resumeBerita: [],
      monthNames: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
    }
    this.props.dispatch({ type: globalConstant.HEADER_TITLE, payload: 'Dashboard' })
  }

  componentDidMount() {
    super.componentDidMount()

    this.dataBerita()
    this.doChangeDate()
  }

  dataBerita = async () => {
    const { dispatch, appAgentGroup } = this.props
    const hasil = await dispatch(getBerita(6, appAgentGroup))
    this.setState({ resumeBerita: hasil.data.data })
    this.setState({ loading: true })
  }

  doChangeDate = async () => {
    const { appUserGroup, language } = this.props
    const DATA = Linkqu.getResumeObject(appUserGroup)
    this.setState({ balanceFresh: true })
    Object.keys(DATA).forEach(async (key) => {
      const { dispatch } = this.props
      const { resumeData, selectedDateTemp, resumeTransactionPerDate } = this.state
      // eslint-disable-next-line prefer-const
      let temp = resumeTransactionPerDate
      const datum = DATA[key]
      const result = await dispatch(resume(datum.path, key, selectedDateTemp, datum.args))

      if (datum.path === 'product_transaction_per_date') {
        this.setState({ resumeProductTransactionPerDate: result })
      } else if (datum.path === 'pending_transaction_per_date') {
        this.setState({ resumePendingTransactionPerDate: result.value })
      } else if (datum.path === 'grup_product_transaction_per_date') {
        if (result && result.length > 0) {
          // eslint-disable-next-line no-restricted-syntax
          for (const item of result) {
            // eslint-disable-next-line no-restricted-syntax
            for (const data of temp) {
              if (data.key === item.value) {
                data.data = item.data ? item.data : 0
              }
              if (data.key === 'TRANSFER') {
                data.data = data.data ? data.data : 0
              } else if (data.key === 'IPG') {
                data.data = data.data ? data.data : 0
              } else if (data.key === 'EMONEY') {
                data.data = data.data ? data.data : 0
              }
            }
          }
        } else {
          // eslint-disable-next-line no-restricted-syntax
          for (const data of temp) {
            data.data = 0
          }
        }
      } else {
        resumeData[key].value = result.value
      }

      this.setState({ resumeData, resumeTransactionPerDate: temp })
    })
  }

  setDate = (e, picker) => {
    const { resumeData, resumeProductTransactionPerDate } = this.state

    this.setState({
      selectedDate: picker.startDate.format('DD MMM YYYY'),
      selectedDateTemp: picker.startDate.format('YYYY-MM-DD'),
      resumeData: Linkqu.getResumeObject(this.props.appUserGroup),
      resumeProductTransactionPerDate: [],
      resumePendingTransactionPerDate: undefined,
      resumeTransactionPerDate: [
        { key: 'TRANSFER', value: 'Transfer', data: undefined },
        { key: 'IPG', value: 'Virtual Account', data: undefined },
        { key: 'EMONEY', value: 'E-money', data: undefined },
      ],
    })

    this.doChangeDate()
  }

  render() {
    let total
    let vaValue
    let emoneyValue
    let transferValue
    const data = []
    const {
      resumeData,
      resumeProductTransactionPerDate,
      resumePendingTransactionPerDate,
      resumeTransactionPerDate,
    } = this.state
    // eslint-disable-next-line no-restricted-syntax
    for (const item of resumeTransactionPerDate) {
      if (item.key === 'TRANSFER') {
        transferValue = item.data
      } else if (item.key === 'IPG') {
        vaValue = item.data
      } else if (item.key === 'EMONEY') {
        emoneyValue = item.data
      }
      data.push({ value: item.value, data: item.data ? item.data : 0 })
    }
    // eslint-disable-next-line prefer-const, radix
    if (transferValue === undefined && vaValue === undefined && emoneyValue === undefined) {
      total = undefined
    } else {
      total = transferValue + vaValue + emoneyValue
    }

    const { isOpen, appUserGroup, appAgentGroup } = this.props
    let cashoutHasDataResumeProductTransactionPerDate = false
    let cashinHasDataResumeProductTransactionPerDate = false
    let hasDataResumeProductTransactionPerDate = false
    if (
      resumeProductTransactionPerDate.cashin &&
      resumeProductTransactionPerDate.cashin.length > 0
    ) {
      cashinHasDataResumeProductTransactionPerDate = true
    }

    if (
      resumeProductTransactionPerDate.cashout &&
      resumeProductTransactionPerDate.cashout.length > 0
    ) {
      cashoutHasDataResumeProductTransactionPerDate = true
    }

    if (
      cashoutHasDataResumeProductTransactionPerDate ||
      cashinHasDataResumeProductTransactionPerDate
    ) {
      hasDataResumeProductTransactionPerDate = true
    }

    return (
      <>
        <Container
          fluid
          className={
            isOpen
              ? 'p-3 open-sidebar background-dashboard'
              : 'p-3 close-sidebar background-dashboard'
          }
        >
          {this.props.appUserType === 'Personal END USER' && appUserGroup === '6' ? (
            <>
              <div
                style={{ height: '90vh' }}
                className="wrapper w-100 d-flex flex-column justify-content-center align-items-center"
              >
                <a
                  href="https://bit.ly/linkqumobile"
                  className="w-100 d-flex justify-content-center"
                  target="_blank"
                >
                  <Image className="img-banner" src={BANNER} width="65%" />
                </a>
                <a
                  href="https://bit.ly/linkqumobile"
                  className="mt-3 btn btn-primary background-primary"
                  target="_blank"
                >
                  Download Application
                </a>
              </div>
            </>
          ) : (
            <>
              <Row>
                <Col lg={9} sm={6}>
                  <div className="ml-4 mr-3">
                    <div className="button-datepicker">
                      <div
                        className="fontSize-16-lexend color-grey"
                        style={{ marginRight: '10px', marginTop: '5px' }}
                      >
                        Overview Transaksi
                      </div>
                      <DateRangePicker
                        initialSettings={{
                          singleDatePicker: true,
                          showDropdowns: true,
                          startDate: this.selectedDate,
                        }}
                        onApply={this.setDate}
                      >
                        <Button
                          className="input-date fontSize-14-lexend-l color-grey"
                          variant="light"
                        >
                          {` ${this.state.selectedDate}`}
                          <Image
                            src={icons(iconName.arrowDownGrey)}
                            style={{ height: '5px', width: '10px' }}
                          />
                        </Button>
                      </DateRangePicker>
                    </div>
                    {appUserGroup === '6' && hasDataResumeProductTransactionPerDate ? (
                      <Card style={{ width: '100%', borderRadius: 18, top: 10 }}>
                        <Card.Body>
                          <Row>
                            {cashoutHasDataResumeProductTransactionPerDate ? (
                              <Col
                                xs={12}
                                md={
                                  cashoutHasDataResumeProductTransactionPerDate &&
                                  cashinHasDataResumeProductTransactionPerDate
                                    ? 6
                                    : 12
                                }
                                className={
                                  cashinHasDataResumeProductTransactionPerDate
                                    ? 'doughnut-chart-first'
                                    : ''
                                }
                              >
                                <Chart
                                  data={resumeProductTransactionPerDate.cashout}
                                  height={170}
                                  autoFit
                                >
                                  <Coordinate type="theta" radius={0.8} innerRadius={0.7} />
                                  <Axis visible={false} />
                                  <Tooltip showTitle={false} />
                                  <Interval
                                    adjust="stack"
                                    position="value"
                                    color="type"
                                    shape="sliceShape"
                                  />
                                  <Interaction type="element-single-selected" />
                                  <Legend
                                    position="left"
                                    title={{
                                      text: 'Cash-Out',
                                      style: {
                                        fontFamily: 'PoppinsMedium',
                                        fontStyle: 'normal',
                                        fontWeight: 500,
                                        fontSize: 16,
                                        lineHeight: 24,
                                        color: '#000000',
                                        marginTop: -50,
                                      },
                                    }}
                                  />
                                </Chart>
                              </Col>
                            ) : (
                              <></>
                            )}
                            {cashinHasDataResumeProductTransactionPerDate ? (
                              <Col
                                xs={12}
                                md={
                                  cashoutHasDataResumeProductTransactionPerDate &&
                                  cashinHasDataResumeProductTransactionPerDate
                                    ? 6
                                    : 12
                                }
                              >
                                <Chart
                                  data={resumeProductTransactionPerDate.cashin}
                                  height={170}
                                  autoFit
                                >
                                  <Coordinate type="theta" radius={0.8} innerRadius={0.7} />
                                  <Axis visible={false} />
                                  <Tooltip showTitle={false} />
                                  <Interval
                                    adjust="stack"
                                    position="value"
                                    color="type"
                                    shape="sliceShape"
                                  />
                                  <Interaction type="element-single-selected" />
                                  <Legend
                                    position="left"
                                    title={{
                                      text: 'Cash-In',
                                      style: {
                                        fontFamily: 'PoppinsMedium',
                                        fontStyle: 'normal',
                                        fontWeight: 500,
                                        fontSize: 16,
                                        lineHeight: 24,
                                        color: '#000000',
                                        marginTop: -50,
                                      },
                                    }}
                                  />
                                </Chart>
                              </Col>
                            ) : (
                              <></>
                            )}
                          </Row>
                        </Card.Body>
                      </Card>
                    ) : (
                      <></>
                    )}
                    {appUserGroup === '6' ? (
                      <div style={{ top: 10 }}>
                        <Row className="mt-4 ">
                          <Col xs={12} sm={12} md={12} lg={12} xl={6} className="mb-3 chart-bar">
                            <Card
                              style={{
                                width: '100%',
                                borderRadius: 18,
                                height: '100%',
                                paddingBottom: 8,
                              }}
                            >
                              <Card.Body>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                  }}
                                >
                                  <div className="card-title fontSize-12-lexend-k color-grey">
                                    Transaksi Sukses
                                  </div>
                                  <div
                                    className="fontSize-12-lexend"
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      color: '#696969',
                                    }}
                                  >
                                    Total :
                                    {total !== undefined && (
                                      <Card.Text
                                        className="fontSize-20-lexend-m color-green"
                                        style={{ marginLeft: '5px' }}
                                      >
                                        {total}
                                      </Card.Text>
                                    )}
                                    {total === undefined && (
                                      <Spinner
                                        size="xs"
                                        className="mr-1"
                                        animation="border"
                                        variant="primary"
                                        style={{ marginLeft: '5px' }}
                                      />
                                    )}
                                  </div>
                                </div>
                                <Chart height={150} autoFit data={data}>
                                  <Interval position="value*data" color="#C7FFC2" />
                                  <Axis
                                    name="x"
                                    visible={false}
                                    title={null}
                                    grid={null}
                                    line={null}
                                  />
                                  <Tooltip shared />
                                </Chart>
                              </Card.Body>
                            </Card>
                          </Col>
                          <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={6}
                            className="mb-3 clear-padding padding-on-md"
                          >
                            <Row>
                              <Col xs={12} md={6}>
                                <Card style={{ width: '100%', borderRadius: 18, height: 120 }}>
                                  <Card.Body>
                                    <div className="card-title-wo-chart fontSize-12-lexend-k color-grey">
                                      Transaksi Cash Out Sukses
                                    </div>
                                    <div className="fontSize-12-lexend total">
                                      Total :
                                      {transferValue !== undefined && (
                                        <Card.Text className="fontSize-20-lexend-m color-green nilai">
                                          {transferValue}
                                        </Card.Text>
                                      )}
                                      {transferValue === undefined && (
                                        <Spinner
                                          size="xs"
                                          className="mr-1"
                                          animation="border"
                                          variant="primary"
                                        />
                                      )}
                                    </div>
                                  </Card.Body>
                                </Card>
                              </Col>
                              <Col xs={12} md={6}>
                                <Card style={{ width: '100%', borderRadius: 18, height: 120 }}>
                                  <Card.Body>
                                    <div className="card-title-wo-chart fontSize-12-lexend-k color-grey">
                                      Transaksi e-money Sukses
                                    </div>
                                    <div className="fontSize-12-lexend total">
                                      Total :
                                      {emoneyValue !== undefined && (
                                        <Card.Text className="fontSize-20-lexend-m color-green nilai">
                                          {emoneyValue}
                                        </Card.Text>
                                      )}
                                      {emoneyValue === undefined && (
                                        <Spinner
                                          size="xs"
                                          className="mr-1"
                                          animation="border"
                                          variant="primary"
                                        />
                                      )}
                                    </div>
                                  </Card.Body>
                                </Card>
                              </Col>
                              <Col xs={12} md={6}>
                                <Card
                                  style={{ width: '100%', borderRadius: 18, height: 120 }}
                                  className="second-column"
                                >
                                  <Card.Body>
                                    <div className="card-title-wo-chart fontSize-12-lexend-k color-grey">
                                      Transaksi Cash In Sukses
                                    </div>
                                    <div className="fontSize-12-lexend total">
                                      Total :
                                      {vaValue !== undefined && (
                                        <Card.Text className="fontSize-20-lexend-m color-green nilai">
                                          {vaValue}
                                        </Card.Text>
                                      )}
                                      {vaValue === undefined && (
                                        <Spinner
                                          size="xs"
                                          className="mr-1"
                                          animation="border"
                                          variant="primary"
                                        />
                                      )}
                                    </div>
                                  </Card.Body>
                                </Card>
                              </Col>
                              <Col xs={12} md={6}>
                                <Card
                                  style={{ width: '100%', borderRadius: 18, height: 120 }}
                                  className="second-column"
                                >
                                  <Card.Body>
                                    <div className="card-title-wo-chart fontSize-12-lexend-k color-grey">
                                      Transaksi Pending
                                    </div>
                                    <div className="fontSize-12-lexend total">
                                      Total :
                                      {resumePendingTransactionPerDate !== undefined && (
                                        <Card.Text className="fontSize-20-lexend-m nilai color-red">
                                          {resumePendingTransactionPerDate}
                                        </Card.Text>
                                      )}
                                      {resumePendingTransactionPerDate === undefined && (
                                        <Spinner
                                          size="xs"
                                          className="mr-1"
                                          animation="border"
                                          variant="primary"
                                        />
                                      )}
                                    </div>
                                  </Card.Body>
                                </Card>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <Row className="container-chart-price">
                    {Object.keys(resumeData).map((key) => {
                      const { path, pathTo, title, value, hasChart, hasData } = resumeData[key]

                      if (
                        path !== 'product_transaction_per_date' &&
                        path !== 'grup_product_transaction_per_date' &&
                        path !== 'pending_transaction_per_date'
                      ) {
                        let click = null
                        if (hasChart) {
                          click = this.redirectToReport
                        } else if (hasData) {
                          click = this.redirectToData
                        }

                        return (
                          <Col md={4} key={key}>
                            <Card
                              className="mb-3"
                              data-path={path}
                              data-pathto={pathTo}
                              onClick={click}
                              style={{
                                borderRadius: 18,
                                height: 180,
                              }}
                            >
                              <Card.Body data-path={path} data-pathto={pathTo} className="p-0">
                                <Row>
                                  <Col xs="12">
                                    <div className="p-3">
                                      <Card.Title
                                        as="h6"
                                        className="card-title-price-chart fontSize-12-lexend-k color-grey"
                                      >
                                        {translate(title, this.props.language, titleSentence)}
                                      </Card.Title>
                                      {value !== undefined && (
                                        <Card.Text
                                          className="fontSize-20-lexend-m"
                                          style={{ color: '#696969' }}
                                        >
                                          {value !== 'n/a' ? value : 0}
                                        </Card.Text>
                                      )}
                                      {value === undefined && (
                                        <Spinner
                                          size="xs"
                                          className="mr-1"
                                          animation="border"
                                          variant="primary"
                                        />
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Col>
                        )
                      }
                      return (
                        <Container key={key}>
                          <div />
                        </Container>
                      )
                    })}
                  </Row>
                </Col>
                <Col lg={3} sm={6}>
                  <Card style={{ width: '100%', borderRadius: 18, height: '100%' }}>
                    <Card.Body>
                      <div
                        className="card-title-wo-chart fontSize-16-lexend color-black"
                        style={{ color: '#696969' }}
                      >
                        Berita Terkini
                      </div>
                      {this.state.loading ? (
                        <>
                          {this.state.resumeBerita ? (
                            this.state.resumeBerita.map((item) => (
                              <a href={`/news/${item.id}`}>
                                <div className="berita border-bottom">
                                  <Row>
                                    <div className="col-6">
                                      <img src={item.foto} alt="" />
                                    </div>
                                    <div className="col-6">
                                      <p className="color-black fontSize-12-Bold">{item.title}</p>
                                      <p className="fontSize-12 color-grey">
                                        Artikel | {new Date(item.date).getDate()}{' '}
                                        {this.state.monthNames[new Date(item.date).getMonth() + 1]}{' '}
                                        {new Date(item.date).getFullYear() % 100}
                                      </p>
                                    </div>
                                  </Row>
                                </div>
                              </a>
                            ))
                          ) : (
                            <h6 className="kosong border-bottom">Tidak Ada Berita Baru</h6>
                          )}
                        </>
                      ) : (
                        [...Array(5).keys()].map((i) => (
                          <div>
                            <Row>
                              <div className="col-6" key={i}>
                                <h6 className="color-black">
                                  <Skeleton height={120} />
                                </h6>
                              </div>
                              <div className="col-6" key={i}>
                                <h6 className="color-black">
                                  <Skeleton count={4} />
                                </h6>
                              </div>
                            </Row>
                          </div>
                        ))
                      )}
                      <a
                        target="_blank"
                        href={
                          appAgentGroup !== 1
                            ? process.env.REACT_APP_URL_LINKQU_ID_HIGHRISK
                            : process.env.REACT_APP_URL_LINKQU_ID
                        }
                      >
                        <h6 className="color-red text-right-md fontSize-12">Lihat Selengkapnya</h6>
                      </a>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </>
    )
  }
}

DashboardScreen.defaultProps = {
  appIsLoading: false,
  appUserId: '',
  appUserName: '',
  appAgentName: '',
  appUserGroup: '',
  appStatusReg: '',
  isOpen: false,
}

DashboardScreen.propTypes = {
  appIsLoading: PropTypes.bool,
  appUserId: PropTypes.string,
  appUserName: PropTypes.string,
  appAgentName: PropTypes.string,
  appUserGroup: PropTypes.string,
  appStatusReg: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
    language: state.Global[globalConstant.LANGUAGE],
    appUserId: state.Auth[authConstant.USER_ID],
    appUserName: state.Auth[authConstant.USER_NAME],
    appUserGroup: state.Auth[authConstant.USER_GROUP],
    appAgentName: state.Auth[authConstant.AGENT_NAME],
    appStatusReg: state.Auth[authConstant.STATUS_REG],
    appUserType: state.Auth[authConstant.USER_TYPE],
    isOpen: state.Global[globalConstant.SIDEBAR_OPEN],
    appAgentGroup: state.Auth[authConstant.AGENT_GROUP],
  }),
  (dispatch) => ({ dispatch })
)(DashboardScreen)
