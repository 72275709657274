/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable prettier/prettier */
/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import auth from "../../store/constant/auth";
import roleAccess from "../../store/constant/roleAccess";
import global from "../../store/constant/global";
import CreateRequestPayment from "./CreateRequestPayment";
import RequestPaymentList from "./GetList";
import DetailRequestPayment from "./DetailRequestPayment";
import "./requestPayment.scss";
import translate from "../../helper/translate";
import { sidebarLangResource } from "../../helper/translatePage/languageResource";

function RequestPaymentScreen({
  appAgentId,
  appIsLoading,
  appUserData,
  appUserAccess,
  languange
}) {
  const [content, contentChange] = useState({
    listRequestPayment: true,
    createRequestPayment: false,
    detailRequestPayment: false,
    detailDataRequestPayment: null,
  });

  const dispatch = useDispatch();

  const addBodyClass = (className) => document.body.classList.add(className);
  const removeBodyClass = (className) =>
    document.body.classList.remove(className);

  useEffect(() => {
    addBodyClass("page--requestPayment");
    
    async function fetchData() {
      await dispatch({
        type: global.HEADER_TITLE,
        payload: translate('requestPayment', languange, sidebarLangResource),
      })
    }
    fetchData();
    return () => {
      removeBodyClass("page--requestPayment");
    };
  }, []);

  const handleChangeContent = (contentSelected, dataRow) => {
    if (contentSelected === "list_request_payment") {
      contentChange({
        listRequestPayment: true,
        createRequestPayment: false,
        detailRequestPayment: false,
        detailDataRequestPayment: null,
      });
    } else if (contentSelected === "create_request_payment") {
      contentChange({
        listRequestPayment: false,
        createRequestPayment: true,
        detailRequestPayment: false,
        detailDataRequestPayment: null,
      });
    } else if (contentSelected === "detail_request_payment") {
      contentChange({
        listRequestPayment: false,
        createRequestPayment: false,
        detailRequestPayment: true,
        detailDataRequestPayment: dataRow,
      });
    }
  };

  return (
    <>
      <Container fluid className="p-3">
        {content.createRequestPayment && (
          <CreateRequestPayment
            dispatch={dispatch}
            username={appUserData.username_agen}
            contentChange={handleChangeContent}
            loading={appIsLoading}
            appUserAccess={appUserAccess}
            languange={languange}
          />
        )}
        {content.listRequestPayment && (
          <RequestPaymentList
            contentChange={handleChangeContent}
            agentId={appAgentId}
            dispatch={dispatch}
            loading={appIsLoading}
            appUserAccess={appUserAccess}
            languange={languange}
          />
        )}
        {content.detailRequestPayment && (
          <DetailRequestPayment
            contentChange={handleChangeContent}
            dataPayment={content.detailDataRequestPayment}
            dispatch={dispatch}
            loading={appIsLoading}
            language={languange}
          />
        )}
      </Container>
    </>
  );
}

export default connect((state) => ({
  appIsLoading: state.Global[global.IS_LOADING],
  appAgentId: state.Auth[auth.AGENT_ID],
  appUserData: state.Auth[auth.USER_DATA],
  appUserAccess: state.RoleAccess[roleAccess.ACCESS],
  languange: state.Global[global.LANGUAGE]
}))(RequestPaymentScreen);
