/* eslint-disable no-unused-vars */
import React from 'react'
import { Badge, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import translate from '../../../helper/translate'
import Formatter from '../../../helper/formatter'
import { columnResource } from '../../../helper/translatePage/languageResource'
import dataType from '../../../helper/constant/dataType'

const SettlementMerchantProcess = (action, languange) => ({
  key: 'id',
  columns: [
    {
      name: translate('tglTrx', languange, columnResource),
      selector: 'tgltrx',
      sortable: true,
      type: dataType.DATERANGE,
      showFilter: true,
      default: true,
    },
    {
      name: translate('tglSettle', languange, columnResource),
      selector: 'tglsettle',
      sortable: true,
      type: dataType.DATERANGE,
      showFilter: true,
    },
    {
      name: translate('username', languange, columnResource),
      selector: 'username',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('namaPemilik', languange, columnResource),
      selector: 'nama_pemilik',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('namaProduk', languange, columnResource),
      selector: 'nama_produk',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('rekonnumhold', languange, columnResource),
      selector: 'rekonnum',
      sortable: true,
      type: dataType.INTEGER,
      showFilter: true,
    },
  ],
})

export default SettlementMerchantProcess
