import MessageSender from '../sender'
import BaseMessage from '../message'

export const getSettingCallback = () => async () => {
  const msg = new BaseMessage()
  msg.setPath(`control/setting_callback_url`)

  const response = await new MessageSender().doGet(msg)

  if (response.isOK()) {
    return response.getResultAsList()
  }

  return []
}

export const saveSettingCallback = (id, url, idOtp, otp, captcha) => async () => {
  const msg = new BaseMessage()
  msg.setPath(`control/setting_callback_url`)
  msg.setItem('id', id)
  msg.setItem('url', url)
  msg.setItem('id_otp', idOtp)
  msg.setItem('otp', otp)
  msg.setItem('captcha', captcha)

  const response = await new MessageSender().doPost(msg)

  return response
}
