/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable eqeqeq */
import React from 'react'
import { Container, Image } from 'react-bootstrap'
import { connect } from 'react-redux'

import './fab.scss'
import global from '../../store/constant/global'
import translate from '../../helper/translate'
import { titleSentence } from '../../helper/translatePage/languageResource'
import icons from '../../helper/icons'
import iconName from '../../helper/constant/iconName'

class FabComponent extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      url: process.env.REACT_APP_URL_LINKQU_SUPPORT,
    }
  }

  render() {
    const { language } = this.props
    const { url } = this.state
    return (
      <Container className="container-fab">
        <a href={url} target="_blank" rel="noopener noreferrer">
          <Image src={icons(iconName.sendFeedback)} className="mr-3" />
          {translate('sendfeedback', language, titleSentence)}
        </a>
      </Container>
    )
  }
}

export default connect(
  (state) => ({
    language: state.Global[global.LANGUAGE],
  }),
  (dispatch) => ({ dispatch })
)(FabComponent)
