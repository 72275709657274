export default {
  INTEGER: [
    {
      name: 'Lebih dari',
      id: '>',
    },
    {
      name: 'Kurang dari',
      id: '<',
    },
    {
      name: 'Sama dengan',
      id: '=',
    },
    {
      name: 'Tidak sama dengan',
      id: '!=',
    },
  ],
  STRING: [
    {
      name: 'Seperti',
      id: 'like',
    },
    {
      name: 'Diawali dengan',
      id: 'startwith',
    },
    {
      name: 'Diakhiri dengan',
      id: 'endwith',
    },
    {
      name: 'Sama dengan',
      id: '=',
    },
    {
      name: 'Tidak sama dengan',
      id: '!=',
    },
  ],
  ENUM: [
    {
      name: 'Sama dengan',
      id: '=',
    },
    {
      name: 'Tidak sama dengan',
      id: '!=',
    },
  ],
  DATE: [
    {
      name: 'Lebih dari',
      id: '>',
    },
    {
      name: 'Kurang dari',
      id: '<',
    },
    {
      name: 'Sama dengan',
      id: '=',
    },
    {
      name: 'Tidak sama dengan',
      id: '!=',
    },
  ],
  DATERANGE: [
    {
      name: 'Diantara',
      id: 'between',
    },
  ],
  NULL: [
    {
      name: 'PILIH',
      id: 'null',
    },
  ],
}
