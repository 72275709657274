import mandiri from '../assets/imgs/logo/logo-bank-mandiri.png'
import bri from '../assets/imgs/logo/logo-bank-bri.png'
import bni from '../assets/imgs/logo/logo-bank-bni.png'
import permata from '../assets/imgs/logo/logo-bank-permata.png'
import bca from '../assets/imgs/logo/logo-bank-bca.png'
import building from '../assets/imgs/logo/Gubuk.png'

export default (slug) => {
  let logo = ''
  let slg = slug
  slg = slg.toLowerCase()
  // eslint-disable-next-line
  slg = slg.replace(' ','-')
  switch (slg) {
    case 'mandiri':
      logo = mandiri
      break
    case 'bank-bri':
      logo = bri
      break
    case 'bank-bni':
      logo = bni
      break
    case 'permata-bank':
      logo = permata
      break
    case 'bank-bca':
      logo = bca
      break
    default:
      logo = building
  }
  return logo
}
