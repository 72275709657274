/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import classNames from 'classnames'
import {
  ProgressBar,
  Row,
  Col,
  Form,
  Button,
  Spinner,
  Card,
  InputGroup,
  Image,
} from 'react-bootstrap'
import PropTypes from 'prop-types'
import * as XLSX from 'xlsx'

import './bulk.scss'
import BaseScreen from '../../base'
import authConstant from '../../../store/constant/auth'
import globalConstant from '../../../store/constant/global'
import AlertDialog from '../../dialog/alert'
import BankDialog from '../../dialog/bank'
import DropFileComponent from '../../component/dropfile'
import DisbursementTable from '../../component/table/disbursement'
import { getBank, bulkWithdrawSave } from '../../../api/action/transaction'
import Formatter from '../../../helper/formatter'
import roleAccess from '../../../store/constant/roleAccess'
import checkAccess from '../../../helper/access'
import AccessDenied from '../../component/AccessDenied'
import translate from '../../../helper/translate'
import { buttonResource, titleSentence } from '../../../helper/translatePage/languageResource'
import icons from '../../../helper/icons'
import iconName from '../../../helper/constant/iconName'

function withParams(Component) {
  return (props) => <Component {...props} navigate={useHistory()} />
}

class bulkScreen extends BaseScreen {
  constructor(props) {
    super(props, ['page--disbursement'])

    this.state = {
      isOpen: false,
      csvData: [],
      savedData: [],
      listBank: [],
      bankModalVisible: false,
      totalNominal: 0,
      totalAdmin: 0,
      totalPayment: 0,
      affixed: true,
      balanceFresh: false,
      showPass: false,
    }
  }

  toggleSidebar = () => {
    const { isOpen } = this.state

    this.setState({ isOpen: !isOpen })
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleShowedPass = (action) => {
    switch (action) {
      case 'show':
        this.setState({ showPass: true })
        break
      case 'hidden':
        this.setState({ showPass: false })
        break

      default:
        this.setState({ showPass: false })
        break
    }
  }

  onDrop = (files) => {
    const [file] = files

    const reader = new FileReader()
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target.result
      const wb = XLSX.read(bstr.replace(/""/g, '"').replace(/""/g, '"'), {
        type: 'binary',
        raw: true,
      })
      /* Get first worksheet */
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      /* Convert array of arrays */
      const csvData = XLSX.utils.sheet_to_json(ws, { raw: true })

      this.setState({ csvData })
    }

    reader.readAsBinaryString(file)
  }

  openBankModal = () => this.setState({ bankModalVisible: true })

  closeBankModal = () => this.setState({ bankModalVisible: false })

  resetTable = () => this.setState({ csvData: [], savedData: [] })

  onPaymentFinished = (uuid, checksum, result) => {
    let totalNominal = 0
    let totalAdmin = 0
    let totalPayment = 0

    const savedData = result.map((res) => {
      const {
        nominal,
        nominaladmin,
        nominalorigin,
        kodebanktujuan,
        norekeningtujuan,
        customername,
        phone,
        email,
        note,
        idtransaksi,
      } = res

      totalNominal += nominal
      totalAdmin += nominaladmin
      totalPayment += nominalorigin

      return {
        nominal,
        nominaladmin,
        nominalorigin,
        kodebanktujuan,
        norekeningtujuan,
        customername,
        phone,
        email,
        note,
        idtransaksi,
      }
    })

    this.setState({
      totalNominal,
      totalAdmin,
      totalPayment,
      savedData,
      uuid,
      checksum,
    })
  }

  handleScroll = () => {
    const { savedData } = this.state
    if (savedData.length === 0) {
      return
    }

    const offset = this.pricePanelRef ? this.pricePanelRef.scrollHeight : 60

    this.setState({
      affixed: window.scrollY + document.body.offsetHeight + offset < document.body.scrollHeight,
    })
  }

  pricePanelRefHandle = (ref) => {
    this.pricePanelRef = ref
  }

  doSubmit = async () => {
    const { groupName, groupDesc, pin, savedData, checksum, uuid } = this.state

    if (!groupName) {
      this.showError('Please fill group name')
    } else if (!groupDesc) {
      this.showError('Please fill group description')
    } else if (!pin) {
      this.showError('Please input your transaction PIN')
    } else {
      const { dispatch } = this.props

      const response = await dispatch(
        bulkWithdrawSave(savedData, checksum, uuid, pin, groupName, groupDesc)
      )

      if (response.isOK()) {
        this.props.navigate.push('/data/disbursement')
      } else {
        this.showError(response.getRD())
      }
    }
  }

  componentDidMount() {
    super.componentDidMount()

    const { dispatch } = this.props

    dispatch(getBank()).then((listBank) => {
      this.setState({
        listBank,
      })
    })

    window.addEventListener('scroll', this.handleScroll, true)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, true)
  }

  createAccess() {
    return checkAccess(this.props.appUserAccess, 'disbursement', 'ADD')
  }

  render() {
    const {
      alertError,
      groupName,
      groupDesc,
      csvData,
      bankModalVisible,
      listBank,
      totalNominal,
      totalAdmin,
      totalPayment,
      affixed,
      pin,
      savedData,
    } = this.state
    const { appIsLoading, dispatch, language, appUserData } = this.props

    const isParent = appUserData?.id_user_parent == null

    return (
      <>
        <div
          className={classNames('main-form container-bulk mt-5', { affixed })}
          onScroll={this.handleScroll}
        >
          {!this.createAccess() && !isParent ? (
            <AccessDenied />
          ) : (
            <>
              {appIsLoading && (
                <ProgressBar
                  style={{ width: '100%' }}
                  className="mt-3 mb-3"
                  variant="info"
                  label="Please Wait..."
                  animated
                  now={100}
                />
              )}
              {csvData.length === 0 && (
                <>
                  <div className="container-bulk-before-upload">
                    <div className="fontSize-16-Medium">
                      {translate('bulkInfo1', language, titleSentence)}
                    </div>
                    <div className="fontSize-16 color-grey">
                      {translate('bulkInfo2', language, titleSentence)}
                    </div>
                    <Button
                      className="info-id text-active fontSize-17-Medium"
                      href={`${process.env.PUBLIC_URL}/sample/disbursement.csv`}
                    >
                      <Image
                        src={icons(iconName.downloadFile, process.env.REACT_APP_BRAND_NAME)}
                        className="mr-3"
                      />
                      {translate('bulkInfo3', language, titleSentence)}
                    </Button>
                    {listBank.length !== 0 && (
                      <div>
                        <div className="fontSize-16 color-grey d-inline">
                          {translate('bulkInfo4', language, titleSentence)}
                        </div>
                        <div
                          className="fontSize-16 text-active d-inline"
                          onClick={this.openBankModal}
                        >
                          {translate('bulkInfo4Add', language, titleSentence)}
                        </div>
                      </div>
                    )}
                    <div className="fontSize-16 color-grey mt-3">
                      {translate('bulkInfo5', language, titleSentence)}
                    </div>
                    <DropFileComponent onDrop={this.onDrop} />
                  </div>
                </>
              )}
              {csvData.length > 0 && (
                <div className={appIsLoading ? 'loading active' : 'loading'}>
                  <DisbursementTable
                    listBank={listBank}
                    data={csvData}
                    dispatch={dispatch}
                    onPaymentFinished={this.onPaymentFinished}
                    onReset={this.resetTable}
                    language={language}
                  />
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              )}
              <Row>
                <Col sm="6" xs="12">
                  <Form.Group className="mb-3">
                    <Form.Label className="fontSize-16-Medium color-grey">
                      {csvData.length === 0
                        ? translate('bulkInfo9', language, titleSentence)
                        : translate('bulkInfo11', language, titleSentence)}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="groupName"
                      placeholder={translate('groupNameEx', language, titleSentence)}
                      maxLength="64"
                      autoComplete="off"
                      value={groupName}
                      className="custom-input fontSize-16"
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col sm="6" xs="12">
                  <Form.Group className="mb-3">
                    <Form.Label className="fontSize-16-Medium color-grey">
                      {csvData.length === 0
                        ? translate('bulkInfo10', language, titleSentence)
                        : translate('bulkInfo12', language, titleSentence)}
                    </Form.Label>
                    <Form.Control
                      placeholder={translate('groupDescEx', language, titleSentence)}
                      rows={5}
                      type="text"
                      maxLength={255}
                      autoComplete="off"
                      name="groupDesc"
                      value={groupDesc}
                      className="custom-input fontSize-16"
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Card
                className={classNames('pricePanel', { hidden: savedData.length === 0 })}
                ref={this.pricePanelRefHandle}
              >
                <Card.Body>
                  <Row>
                    <Col>
                      <p className="fontSize-16-SemiBold text-active">Subtotal</p>
                      <p className="fontSize-16-Medium">{Formatter.price(totalNominal)}</p>
                    </Col>
                    <Col>
                      <p className="fontSize-16-SemiBold text-active">
                        {translate('adminFee', language, titleSentence)}
                      </p>
                      <p className="fontSize-16-Medium">{Formatter.price(totalAdmin)}</p>
                    </Col>
                    <Col>
                      <p className="fontSize-16-SemiBold text-active">
                        {translate('totalPay', language, titleSentence)}
                      </p>
                      <p className="fontSize-16-Medium">{Formatter.price(totalPayment)}</p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <div className="">
                <Row>
                  <Col>
                    <Form.Group
                      className={classNames('form-password mt-3 mb-3', {
                        hidden: savedData.length === 0,
                      })}
                    >
                      <Form.Label className="fontSize-16-Medium color-grey">
                        {translate('pinTrx', language, titleSentence)}
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          type={this.state.showPass ? 'text' : 'password'}
                          name="pin"
                          maxLength="64"
                          value={pin}
                          onChange={this.handleChange}
                          className="custom-input fontSize-16"
                        />
                        {this.state.showPass ? (
                          <InputGroup.Text onClick={() => this.handleShowedPass('hidden')}>
                            <Image src={icons(iconName.showPass)} />
                          </InputGroup.Text>
                        ) : (
                          <InputGroup.Text onClick={() => this.handleShowedPass('show')}>
                            <Image src={icons(iconName.hidePass)} />
                          </InputGroup.Text>
                        )}
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col align="right">
                    <Button
                      type="submit"
                      size="md"
                      variant="danger"
                      block
                      onClick={this.doSubmit}
                      disabled={appIsLoading}
                      className="button-custom-submit fontSize-17"
                    >
                      <Image src={icons(iconName.iconSaveWhite)} className="mr-3" />
                      {translate('save', language, buttonResource)}
                    </Button>
                  </Col>
                </Row>
              </div>
            </>
          )}
        </div>
        <AlertDialog
          show={!!alertError}
          onHide={this.closeError}
          onConfirm={this.closeError}
          title="Perhatian"
          type="warning"
          messageTitle={alertError}
        />
        <BankDialog
          onConfirm={this.closeBankModal}
          onHide={this.closeBankModal}
          show={bankModalVisible}
          listBank={listBank}
        />
      </>
    )
  }
}

bulkScreen.defaultProps = {
  appIsLoading: false,
}

bulkScreen.propTypes = {
  appIsLoading: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
    appUserPass: state.Auth[authConstant.USER_PASS],
    appUserData: state.Auth[authConstant.USER_DATA],
    appUserAccess: state.RoleAccess[roleAccess.ACCESS],
    language: state.Global[globalConstant.LANGUAGE],
  }),
  (dispatch) => ({ dispatch })
)(withParams(bulkScreen))
