/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable eqeqeq */
/* eslint-disable react/no-danger */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable react/react-in-jsx-scope */

import { useEffect, useState } from 'react'
import { Button, Col, Image, ProgressBar, Row } from 'react-bootstrap'
import DataTable from 'react-data-table-component'

import classNames from 'classnames'
import Formatter from '../../helper/formatter'
import Browser from '../../helper/browser'
import dataListPayment from '../../api/action/dataListPayment'
import listPaymentCol from '../component/column/list_payment'
import DateTime from '../../helper/datetime'
import translate from '../../helper/translate'
import {
  columnResource,
  headerPageResource,
  placeholderResource,
  titleSentence,
} from '../../helper/translatePage/languageResource'
import icons from '../../helper/icons'
import iconName from '../../helper/constant/iconName'
import AlertDialog from '../dialog/alert'

const DetailRequestPayment = ({ contentChange, dataPayment, dispatch, loading, language }) => {
  const [initialData, changeDataState] = useState({
    data: [],
    limit: 5,
    page: 1,
    total: 0,
    duce: []
  })

  const customStyles = {
    rows: {
      style: {
        minHeight: '72px', // override the row height
        cursor: 'pointer',
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
      },
    },
  }

  const getData = async () => {
    const response = await dispatch(
      dataListPayment(dataPayment.id, initialData.limit, initialData.page)
    )

    if (response.data.data === undefined) {
      changeDataState({
        ...initialData,
        data: [],
        total: 0,
      })
    } else {
      changeDataState({
        ...initialData,
        data: response.data.data.data,
        total: response.data.data.total,
      })
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const setPage = (page) => {
    changeDataState({ ...initialData, page })
    getData(dataPayment.id, initialData.limit, page)
  }

  const setLimit = (limit) => {
    changeDataState({ ...initialData, limit })
    getData(dataPayment.id, limit, 1)
  }

  const actionFunction = (row) => {
    const {column} = listPaymentCol(language)
    const duce = Object.keys(row).reduce((acc, key) => {
      const [y] = column.filter((x) => x.selector === key)
      if (y) {
        acc.push({
          label: y.name,
          value: y.cell !== undefined ? y.cell(row) : row[key],
          preFormatted: y.preFormatted,
        })
      }

      return acc
    }, [])

    changeDataState({ ...initialData, duce })
  }

  const closeDuce = () => {
    const duce = []
    changeDataState({ ...initialData, duce })
  }

  return (
    <>
      <div className="w-100 d-flex align-items-center">
        <div className="container-flex-center" onClick={() => contentChange('list_request_payment', null)}>
          <button type="button" className="btn btn-outline-primary back-button">
            <Image src={icons(iconName.backButton)} className="" />
          </button>
        </div>
        <span className="ml-2 fontSize-20-Bold">
          {translate('detailRequestPayment', language, headerPageResource)}
        </span>
      </div>
      <div className="mt-5 container-detail-request-payment">
        <Row className="mb-5">
          <Col xs="12" sm="6" >
            <div className="text-active fontSize-16-Bold">
              {translate('transferRequirements', language, placeholderResource)}
            </div>
            <div className="mb-4 fontSize-16-Bold">
              {!!dataPayment.namePayment ? dataPayment.namePayment : '-'}
            </div>
            <div className="text-active fontSize-16-Bold">
              {translate('trxCount', language, columnResource)}
            </div>
            <div className="mb-4 fontSize-16-Bold">
              {Formatter.price(dataPayment.amount)}
            </div>
            <div className="text-active fontSize-16-Bold">
              {translate('opMaxUse', language, placeholderResource)}
            </div>
            <div className="mb-4 fontSize-16-Bold">
              {dataPayment.number}
            </div>
            <a href={`${Browser.baseURL()}pay/${dataPayment.paymentSecret}`} target="_blank">
              <Button
                variant="primary"
                type="submit"
                disabled={loading}
                className={`button-custom-submit fontSize-17 `}
              >
                {translate('payLink', language, titleSentence)}
                <Image src={icons(iconName.clipPath)} className="ml-3" />
              </Button>
            </a>
          </Col>
          <Col xs="12" sm="6">
            <div className="text-active fontSize-16-Bold">
              {translate('opDesc', language, placeholderResource)}
            </div>
            <div className="mb-4 fontSize-16-Bold">
              {dataPayment.descPayment}
            </div>
            <div className="container-total">
              <div className="color-grey fontSize-16-Bold">
                {translate('createDate', language, columnResource)}
              </div>
              <div className="mb-4 fontSize-16-Bold">
                {DateTime.format(dataPayment.timeRequest, DateTime.FMT_DATE_TIME_YMDHMS3)}
              </div>
              <div className="color-grey fontSize-16-Bold">
                {translate('opExpDate', language, placeholderResource)}
              </div>
              <div className="mb-4 fontSize-16-Bold">
                {DateTime.format(dataPayment.timeExpired, DateTime.FMT_DATE_TIME_YMDHMS3)}
              </div>
            </div>
          </Col>
        </Row>

        <DataTable
          data={initialData.data}
          columns={listPaymentCol(language).column}
          highlightOnHover
          fixedHeader
          fixedHeaderScrollHeight="80vh"
          persistTableHead
          pagination
          paginationServer
          paginationTotalRows={initialData.total}
          paginationPerPage={initialData.limit}
          paginationRowsPerPageOptions={[5, 10, 100, 200]}
          onChangePage={setPage}
          onChangeRowsPerPage={setLimit}
          progressPending={loading}
          onRowClicked={actionFunction}
          progressComponent={
            <ProgressBar
              style={{ width: '100%' }}
              className="mt-3 mb-3"
              variant="info"
              label="Please Wait..."
              animated
              now={100}
            />
          }
          customStyles={customStyles}
        />
      </div>
      <AlertDialog
          show={initialData.duce.length > 0}
          onHide={closeDuce}
          onConfirm={closeDuce}
          title="Perhatian"
        >
          {initialData.duce.map((d) => (
            <Row key={d.label}>
              <Col xs="3" className="text-left fontSize-16">
                {d.label}
              </Col>
              <Col xs="1" className="fontSize-16">
                :
              </Col>
              <Col
                xs="8"
                className={classNames(
                  'detail',
                  d.preFormatted ? 'pre text-left fontSize-16' : 'text-left fontSize-16'
                )}
              >
                {d.value || '-'}
              </Col>
            </Row>
          ))}
        </AlertDialog>
    </>
  )
}

export default DetailRequestPayment
