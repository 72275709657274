import MessageSender from '../sender'
import BaseMessage from '../message'

export default (captcha = '') => async () => {
  const msg = new BaseMessage()

  msg.setPath('/control/create_otp')
  msg.setItem('captcha', captcha)
  msg.setItem('brand', process.env.REACT_APP_BRAND_NAME.toLowerCase())

  const response = await new MessageSender().doPost(msg)

  return response
}
