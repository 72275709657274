/* eslint-disable no-else-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-danger */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/no-unknown-property */
import translate from '../../../helper/translate'
import { columnResource } from '../../../helper/translatePage/languageResource'

const listPaymentCol = (lang) => ({
  column: [
    {
      name: translate('bankName', lang, columnResource),
      cell: (row) => {
        if (row.dataBank.namaBank === 'QRIS DANAMON') {
          const newName = row.dataBank.namaBank.split(' ')
          return newName[0]
        } else {
          return row.dataBank.namaBank
        }
      },
      sortable: true,
      selector: 'dataBank',
    },
    {
      name: 'Reff Pelanggan',
      // selector: (row) => row.customerReff,
      selector: 'customerReff',
      sortable: false,
    },
    {
      name: 'Nama Pelanggan',
      // selector: (row) => row.customerName,
      selector: 'customerName',
      sortable: true,
    },
    {
      name: 'Status Bayar',
      cell: (row) => {
        if (row.isPaid) {
          return <b variant="success">{translate('alreadyPaid', lang, columnResource)}</b>
        }
        if (!row.isPaid) {
          return <b variant="danger">{translate('notYetPaid', lang, columnResource)}</b>
        }
        return <></>
      },
      sortable: true,
      selector: 'isPaid',
    },
    {
      name: 'Nomor VA',
      selector: 'vaNumber',
      cell: (row) => {
        if (row.dataBank.namaBank === 'QRIS DANAMON') {
          return '-'
        }
        return row.vaNumber
      },
      sortable: false,
    },
    {
      name: 'Link Payment',
      selector: 'linkPayment',
      cell: (row) => {
        return row.linkPayment
      },
      sortable: false,
    },
  ],
})

export default listPaymentCol
