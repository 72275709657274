/* eslint-disable no-unused-vars */
import React from 'react'
import { Badge } from 'react-bootstrap'
import Formatter from '../../../helper/formatter'
import dataType from '../../../helper/constant/dataType'

const DepositColumn = (actionFunc, language) => ({
  key: 'id_deposit',
  columns: [
    {
      name: 'Deposit ID',
      selector: 'id_deposit',
      sortable: true,
      type: dataType.INTEGER,
      showFilter: true,
    },
    {
      name: 'Trx Date',
      selector: 'tanggal_transaksi',
      sortable: true,
      type: dataType.DATERANGE,
      default: true,
      showFilter: true,
    },
    {
      name: 'Username',
      selector: 'username',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: 'Bank Account Name',
      selector: 'atasnama',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: 'Bank Account Number',
      selector: 'norekening',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: 'Bank Name',
      selector: 'namabank',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: 'Nominal',
      selector: 'nominal',
      sortable: true,
      cell: (row) => Formatter.price(row.nominal),
      type: dataType.INTEGER,
      showFilter: true,
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      cell: (row) => {
        let word
        let color
        switch (row.status) {
          case 0:
            word = 'New'
            color = 'primary'
            break
          case 1:
            word = 'Success'
            color = 'success'
            break
          case 3:
            word = 'Expired'
            color = 'Warning'
            break
          case 2:
          default:
            word = 'Failed'
            color = 'danger'
            break
        }
        return <Badge variant={color}>{word}</Badge>
      },
      showFilter: true,
      type: dataType.ENUM,
      default_value: [
        {
          id: '1',
          name: 'Success',
        },
        {
          id: '0',
          name: 'New',
        },
        {
          id: '2',
          name: 'Failed',
        },
        {
          id: '3',
          name: 'Expired',
        },
      ],
    },
    {
      name: 'Transfer Date',
      selector: 'tanggal_transfer',
      sortable: true,
      type: dataType.DATERANGE,
      showFilter: true,
    },
  ],
})

export default DepositColumn
