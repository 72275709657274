/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable eqeqeq */
import React from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Container,
  Image,
  ProgressBar,
  Button,
  Form,
  FormControl,
  InputGroup,
  Row,
  Col,
  Modal,
} from 'react-bootstrap'
import PropTypes from 'prop-types'
import ReCAPTCHA from 'react-google-recaptcha'

import './login.scss'
import BaseScreen from '../base'
import AlertDialog from '../dialog/alert'
import globalConstant from '../../store/constant/global'
import login from '../../api/action/login'

import BANNER from '../../assets/imgs/line_shape.png'
import BIN from '../../assets/imgs/icons/In.png'
import BEN from '../../assets/imgs/icons/En.png'
import resetPassword from '../../api/action/resetPassword'
import icons from '../../helper/icons'
import iconName from '../../helper/constant/iconName'
import translate from '../../helper/translate'
import { loginResource } from '../../helper/translatePage/languageResource'

class LoginScreen extends BaseScreen {
  constructor(props) {
    super(props, ['page--login'])

    this.state = {
      username: '',
      password: '',
      captcha: '',
      captchaReset: '',
      emailReset: '',
      showPass: false,
      show: false,
    }
    this.captchaRef = React.createRef().current

    if (this.props.appIsLoading) {
      this.props.dispatch({ type: globalConstant.IS_LOADING, payload: false })
    }
  }

  handleLang = (evt) => {
    const { dispatch } = this.props

    dispatch({ type: globalConstant.LANGUAGE, payload: evt.target.value })

    if (this.props.changeLang) {
      this.props.changeLang(evt.target.value)
    }
  }

  componentDidMount() {
    super.componentDidMount()
    localStorage.removeItem('currentBalance')

    const params = new URLSearchParams(window.location.search)
    if (params.get('test') === process.env.REACT_APP_RECAPTCHA_TES) {
      // this.setState({ isTestUser: true })
      this.setState({ captcha: process.env.REACT_APP_RECAPTCHA_TES })
      // localStorage.setItem('isTestUser', params.get('test'))
      this.props.dispatch({ type: globalConstant.IS_TEST_USER, payload: true })
    } else {
      this.props.dispatch({ type: globalConstant.IS_TEST_USER, payload: false })
    }
  }

  onHide = () => {
    this.setState({ show: false })
  }

  doLogin = async () => {
    const { username, password, captcha } = this.state

    let error
    if (!username) {
      error = 'Enter your username'
    } else if (!password) {
      error = 'Enter your password'
    } else if (!captcha) {
      if (!this.props.isTestUser) {
        error = 'Please submit captcha verification'
      }
    }

    if (error) {
      this.showError(error)
    } else {
      const { dispatch } = this.props

      const response = await dispatch(login(username, password, captcha, this.captchaRef))
      if (!response.isOK()) {
        if (!this.props.isTestUser) {
          this.captchaRef.reset()
        }
        this.setState({ username: '', password: '', captcha: '' })
        this.showError(response.getRD())
      } else {
        window.location.href = '/'
      }
    }
  }

  handleShowPass = (type) => {
    switch (type) {
      case 'show':
        this.setState({ showPass: true })
        break
      case 'hidden':
        this.setState({ showPass: false })
        break

      default:
        this.setState({ showPass: false })
        break
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleCaptcha = (value) => {
    this.setState({ captcha: value })
  }

  handleCaptchaReset = (value) => {
    this.setState({ captchaReset: value })
  }

  contentResetPassword = () => {
    return (
      <>
        <FormControl
          name="emailReset"
          placeholder="Input Your Email"
          value={this.state.emailReset}
          onChange={this.handleChange}
        />
        <ReCAPTCHA
          ref={(ref) => {
            this.captchaRef = ref
          }}
          className="captcha mt-3 w-100 centered"
          sitekey={process.env.REACT_APP_GRECAPTCHA_KEY}
          onChange={this.handleCaptchaReset}
        />
      </>
    )
  }

  resetPassword = () => {
    this.setState({ show: true })
  }

  doResetPassword = async () => {
    const { emailReset, captchaReset } = this.state
    const { dispatch } = this.props

    if (emailReset === '') {
      this.showError('Enter your email for reset password')
    } else if (captchaReset === '') {
      this.showError('Please submit captcha verification')
    } else {
      const res = await dispatch(resetPassword(emailReset, captchaReset))
      if (res.data.rc == '00') {
        this.captchaRef.reset()
        this.onHide()
        this.setState({ emailReset: '' })
        this.showError(res.data.rd)
      } else {
        this.onHide()
        this.captchaRef.reset()
        this.showError(res.data.rd)
      }
    }
  }

  render() {
    const { language, appIsLoading } = this.props
    const { alertError, username, password } = this.state
    let logo = `${process.env.PUBLIC_URL}/logo512.png`
    if (process.env.REACT_APP_BRAND === 'FUSINDO') {
      logo = `${process.env.PUBLIC_URL}/logo512-fusindo.png`
    }
    return (
      <>
        <header className="fixed-header">
          <Image className="img-logo" src={logo} />
          {/* <div
            className="language-switcher"
            tabIndex={0}
            onClick={this.toggleLanguage}
            onKeyDown={this.handleKeyDown}
            role="button"
          >
            {this.state.language === 'en' ? 'Bahasa Indonesia' : 'English'}
          </div> */}
          <div className="lang-list button-change-server fontSize-12-lexend color-black ">
            {/* <Image src={BIN} className="d-inline icon-lang" /> */}
            <select
              className="form-control color-black text-uppercase"
              onChange={this.handleLang}
              value={language}
              style={{ marginLeft: '10px' }}
            >
              <option value="id" className="color-black text-uppercase">
                IN
              </option>
              <option value="en" className="color-black">
                EN
              </option>
            </select>
            {language && (
              <>
                {language === 'id' ? (
                  <Image src={BIN} className="d-inline icon-lang" />
                ) : (
                  <Image src={BEN} className="d-inline icon-lang" />
                )}
              </>
            )}
          </div>
        </header>
        <Container fluid className="clear-padding h-100-p">
          <Row className="h-100-p">
            {/* <Col lg="6" className="clear-padding">
              <div className="container-image">
                <h3>Simple, Swift, Secure</h3>
                <Image className="img-banner" src={BANNER} />
              </div>
            </Col> */}
            <Col lg="12" className="clear-padding">
              <div
                className="container-form"
                style={{
                  backgroundImage: `url(${BANNER})`,
                  backgroundSize: '391.31px 387.63px',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'left bottom',
                  backgroundAttachment: 'fixed',
                }}
              >
                {/* <Image className="img-logo" src={logo} /> */}

                <Form.Group className="login-form">
                  <div className="fontSize-22-lexend" style={{ paddingBottom: '5px' }}>
                    {translate('welcome', this.props.language, loginResource)}
                  </div>
                  <div className="fontSize-14-lexend mb-5">
                    {translate('akun', this.props.language, loginResource)} ? {` `}
                    <a
                      href={process.env.REACT_APP_URL_LINKQU_REG}
                      className="link-active fontSize-14-Bold-lexend"
                      style={{ color: '#FF0000' }}
                    >
                      {translate('daftar', this.props.language, loginResource)}
                    </a>
                  </div>
                  <Form.Label className="fontSize-14-Bold-lexend">Email</Form.Label>
                  <InputGroup>
                    <FormControl
                      aria-label="Username"
                      aria-describedby="username-addon"
                      name="username"
                      value={username}
                      onChange={this.handleChange}
                      className="custom-input-login fontSize-12-lexend custom-placeholder"
                      placeholder={translate('email', this.props.language, loginResource)}
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group className="form-password login-form">
                  <Form.Label className="fontSize-14-Bold-lexend">
                    {translate('password', this.props.language, loginResource)}
                  </Form.Label>
                  <InputGroup>
                    <FormControl
                      aria-label="Password"
                      aria-describedby="password-addon"
                      name="password"
                      type={this.state.showPass ? 'text' : 'password'}
                      value={password}
                      onChange={this.handleChange}
                      className="custom-input-login fontSize-12-lexend custom-placeholder"
                      placeholder={translate('password2', this.props.language, loginResource)}
                    />
                    {this.state.showPass ? (
                      <InputGroup.Text onClick={() => this.handleShowPass('hidden')}>
                        {/* <Image src={icons(iconName.showPass)} /> */}
                        <span className="fontSize-12-lexend-l" style={{ color: '#858585' }}>
                          {translate('hide', this.props.language, loginResource)}
                        </span>
                      </InputGroup.Text>
                    ) : (
                      <InputGroup.Text onClick={() => this.handleShowPass('show')}>
                        {/* <Image src={icons(iconName.hidePass)} /> */}
                        <span className="fontSize-12-lexend-l" style={{ color: '#858585' }}>
                          {translate('show', this.props.language, loginResource)}
                        </span>
                      </InputGroup.Text>
                    )}
                  </InputGroup>
                </Form.Group>
                {!this.props.isTestUser && (
                  <ReCAPTCHA
                    ref={(ref) => {
                      this.captchaRef = ref
                    }}
                    className="captcha mb-3"
                    sitekey={process.env.REACT_APP_GRECAPTCHA_KEY}
                    onChange={this.handleCaptcha}
                  />
                )}
                {appIsLoading && (
                  <Form.Group>
                    <ProgressBar
                      className="fontSize-16"
                      animated
                      variant="info"
                      now={100}
                      label="mohon tunggu..."
                    />
                  </Form.Group>
                )}
                <Form.Group className="login-form">
                  <Button
                    type="submit"
                    variant="danger"
                    block
                    disabled={appIsLoading}
                    className="btn-custom clear-margin fontSize-14-Bold-lexend"
                    onClick={this.doLogin}
                    style={{ height: '50px', borderRadius: '12px' }}
                  >
                    {translate('login', this.props.language, loginResource)}
                  </Button>
                </Form.Group>
                <a
                  href="#"
                  onClick={this.resetPassword}
                  className="fontSize-16-Bold-lexend link-active"
                  style={{ color: '#FF6B6B', paddingTop: '20px' }}
                >
                  {translate('forgot', this.props.language, loginResource)}
                </a>
                <div
                  className="fontSize-14-lexend"
                  style={{ paddingTop: '80px', color: '#CFCFCF' }}
                >
                  LinkQu {new Date().getFullYear()}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <AlertDialog
          show={!!alertError}
          onHide={this.closeError}
          onConfirm={this.closeError}
          addedClass="mini-height"
          type="warning"
          messageTitle={alertError}
        />

        <Modal
          show={this.state.show}
          onHide={this.onHide}
          backdrop="static"
          keyboard={false}
          centered
          contentClassName="mini-height"
        >
          <Modal.Header closeButton>
            <Modal.Title>Reset Password</Modal.Title>
          </Modal.Header>

          <Modal.Body>{this.contentResetPassword()}</Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={this.onHide}>
              Batal
            </Button>
            <Button
              variant="primary"
              className="custom-button-primary"
              onClick={this.doResetPassword}
            >
              Reset
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

LoginScreen.defaultProps = {
  appIsLoading: false,
  language: '',
}

LoginScreen.propTypes = {
  appIsLoading: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  language: PropTypes.string,
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
    language: state.Global[globalConstant.LANGUAGE],
    isTestUser: state.Global[globalConstant.IS_TEST_USER],
  }),
  (dispatch) => ({ dispatch })
)(LoginScreen)
