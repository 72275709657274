/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable eqeqeq */
import React from 'react'
import { connect } from 'react-redux'
import { Container, Row } from 'react-bootstrap'
import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import './konten.css'
import ReactHtmlParser from 'react-html-parser'
import BaseScreen from '../base'
import globalConstant from '../../store/constant/global'
import auth from '../../store/constant/auth'

import roleAccess from '../../store/constant/roleAccess'
import checkAccess from '../../helper/access'
import translate from '../../helper/translate'
import { getBerita, getKonten } from '../../api/action/berita'

class BeritaScreen extends BaseScreen {
  constructor(props) {
    super(props, ['page--berita'])
    const { id } = props.match.params

    this.state = {
      loading: false,
      resumeBerita: [],
      resumeArtikel: [],
      id,
      monthNames: [
        'Januari',
        'Februari',
        'Maret',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Agustus',
        'September',
        'October',
        'November',
        'Desember',
      ],
    }
  }

  componentDidMount() {
    super.componentDidMount()
    this.dataBerita()
  }

  readAccess = () => {
    return checkAccess(this.props.appUserAccess, 'domestic', 'READ')
  }

  dataBerita = async () => {
    const { dispatch } = this.props
    const hasil = await dispatch(getKonten(this.state.id))
    const artikel = await dispatch(getBerita(4))
    this.setState({ resumeArtikel: artikel.data.data })
    // console.log(this.state.id,'tessss');
    this.setState({ resumeBerita: hasil })
    this.setState({ loading: true })
  }

  translation = (key, resource) => {
    return translate(key, this.props.languange, resource)
  }

  render() {
    return (
      <>
        <Container fluid className="p-3">
          <Row className="container-content mt-4">
            {this.state.loading ? (
              <>
                {this.state.resumeBerita && this.state.resumeArtikel ? (
                  this.state.resumeBerita.map((item) => (
                    <div className="konten" style={{ width: '726px', height: '100%' }}>
                      {/* <h6 className="color-grey fontSize-12">{`Berita  >  ${item.title}`}</h6> */}
                      <h1 className="fontK">{item.title}</h1>
                      <h6 className="border-bottom tgl color-grey fontSize-12">
                        Diperbarui {new Date(item.date).getDate()}{' '}
                        {this.state.monthNames[new Date(item.date).getMonth() + 1]}
                      </h6>
                      <div className="isi border-bottom">{ReactHtmlParser(item.konten)}</div>
                      <h1 className="fontK">Artikel Terkait</h1>
                      <Row>
                        {this.state.resumeArtikel.map((x) => (
                          <div className="berita col-lg-3 col-md-6 col-sm-12">
                            <a href={`/news/${x.id}`}>
                              <img src={x.foto} alt="" />
                              <h6 className="color-black fontSize-14-Bold">{x.title}</h6>
                              <p className="fontSize-12 color-grey">
                                Artikel | {new Date(x.date).getDate()}{' '}
                                {this.state.monthNames[new Date(x.date).getMonth() + 1]}{' '}
                                {new Date(x.date).getFullYear() % 100}
                              </p>
                            </a>
                          </div>
                        ))}
                      </Row>
                    </div>
                  ))
                ) : (
                  <h5>Gagal menampilkan data ...</h5>
                )}
              </>
            ) : (
              [...Array(1).keys()].map((i) => (
                <div className="konten" style={{ width: '726px', height: '100%' }} key={i}>
                  <h6 className="color-grey">
                    <Skeleton />
                  </h6>
                  <h1>
                    <Skeleton height={30} />
                  </h1>
                  <h6 className="border-bottom color-grey">
                    <Skeleton />
                  </h6>
                  <div className="isi">
                    <Skeleton height={400} />
                  </div>
                </div>
              ))
            )}
          </Row>
        </Container>
      </>
    )
  }
}

BeritaScreen.defaultProps = {
  appIsLoading: false,
}

BeritaScreen.propTypes = {
  appIsLoading: PropTypes.bool,
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
    appUserAccess: state.RoleAccess[roleAccess.ACCESS],
    languange: state.Global[globalConstant.LANGUAGE],
    usernameAgen: state.Auth[auth.AGENT_USER],
    appEnableAPI: state.Auth[auth.ENABLE_API],
    appUserData: state.Auth[auth.USER_DATA],
  }),
  (dispatch) => ({ dispatch })
)(BeritaScreen)
