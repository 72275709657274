/* eslint-disable no-unused-vars */
import React from 'react'
import { Badge, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import translate from '../../../helper/translate'
import Formatter from '../../../helper/formatter'
import { columnResource } from '../../../helper/translatePage/languageResource'
import dataType from '../../../helper/constant/dataType'

const MonitorHoldBalanceColumn = (action, languange) => ({
  key: 'id',
  columns: [
    {
      name: translate('idTrx', languange, columnResource),
      selector: 'id_transaksi',
      sortable: true,
      type: dataType.INTEGER,
      showFilter: true,
    },
    {
      name: translate('dateTrx', languange, columnResource),
      selector: 'date_created',
      sortable: true,
      type: dataType.DATERANGE,
      showFilter: true,
      default: true,
    },
    {
      name: translate('dateSettlement', languange, columnResource),
      selector: 'date_settlement',
      sortable: true,
      type: dataType.DATERANGE,
      showFilter: true,
    },
    {
      name: translate('dateMutation', languange, columnResource),
      selector: 'date_flag',
      sortable: true,
      type: dataType.DATERANGE,
      showFilter: true,
    },
    {
      name: translate('totalDay', languange, columnResource),
      selector: 'days_settlement',
      sortable: true,
      type: dataType.INTEGER,
      showFilter: true,
    },
    {
      name: translate('agentName', languange, columnResource),
      selector: 'nama_pemilik',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('username', languange, columnResource),
      selector: 'username',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: 'ID Produk',
      selector: 'id_produk',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('partnerReff', languange, columnResource),
      selector: 'addinfo1',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('holdAmount', languange, columnResource),
      selector: 'hold_amount',
      sortable: true,
      cell: (row) => Formatter.price(row.hold_amount),
      type: dataType.INTEGER,
      showFilter: true,
    },
    {
      name: translate('isSettle', languange, columnResource),
      selector: 'is_sent',
      sortable: true,
      cell: (row) => {
        let word
        let color
        switch (row.is_sent) {
          case 0:
            word = translate('waitingSettlement', languange, columnResource)
            color = 'warning'
            break
          case 1:
            word = translate('completedSettlement', languange, columnResource)
            color = 'success'
            break
          case 2:
            word = translate('failed', languange, columnResource)
            color = 'danger'
            break
          case 3:
            word = translate('proccess', languange, columnResource)
            color = 'secondary'
            break
          default:
            word = 'Unknown'
            color = 'secondary'
            break
        }
        return <Badge variant={color}>{word}</Badge>
      },
      type: dataType.ENUM,
      showFilter: true,
      default_value: [
        {
          id: 0,
          name: translate('waitingSettlement', languange, columnResource),
        },
        {
          id: 1,
          name: translate('completedSettlement', languange, columnResource),
        },
      ],
    },
  ],
})

export default MonitorHoldBalanceColumn
