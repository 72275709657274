const checkModuleAccess = (moduls, modulName) => {
  if (moduls === null || moduls === undefined) {
    return false
  }

  const selectedModule = moduls.filter((modul) => modul.nama_modul === modulName)

  if (selectedModule.length > 0) {
    return true
  }

  return false
}

export default checkModuleAccess
