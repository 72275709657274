export default {
  IS_LOADING: 'GLOBAL_IS_LOADING',
  IS_LOGIN: 'GLOBAL_IS_LOGIN',
  SIDEBAR_OPEN: 'GLOBAL_OPEN_SIDEBAR',
  LANGUAGE: 'CHOOSEN_LANGUAGE',
  ENVIRONMENT: 'CHOOSEN_ENVIRONMENT',
  MENU_CHOOSEN: 'MENU_CHOOSEN',
  HEADER_TITLE: 'HEADER_TITLE',
  ANTI_CSRF_TOKEN: 'ANTI_CSRF_TOKEN',
  RULE_SHOW_BALANCE: 'RULE_SHOW_BALANCE',
  GLOBAL: 'GLOBAL',
  IS_TEST_USER: 'IS_TEST_USER',
}
