import MessageSender from '../sender'
import BaseMessage from '../message'

// eslint-disable-next-line no-unused-vars
export default (username, orderName, date, limit, nominal, desc, recaptcha) => async () => {
  const msg = new BaseMessage()
  msg.setPath('data/request_payment')
  msg.setItem('username', username)
  msg.setItem('paymentName', orderName)
  msg.setItem('expiredDate', date)
  msg.setItem('paymentLimit', limit)
  msg.setItem('amount', nominal)
  msg.setItem('paymentDesc', desc)
  msg.setItem('recaptcha', recaptcha)

  const response = await new MessageSender().doPost(msg)

  return response
}
