/* eslint-disable camelcase */
import MessageSender from '../sender'
import BaseMessage from '../message'

export default (idGrup) => async () => {
  const msg = new BaseMessage()

  msg.setPath('/data/modul_access')
  msg.setItem('id_grup', idGrup)

  const response = await new MessageSender().doGet(msg)

  return response
}
