/* eslint-disable react/prop-types */
import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { connect } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import PropTypes from 'prop-types'
import history from './navigator/history'
import Routes from './navigator/routes'
import { persistor } from './store'
import './App.scss'
import './helper/icon'

import global from './store/constant/global'

import SidebarComponent from './screen/component/sidebar'
import HeaderComponent from './screen/component/header'

class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidMount() {
    const language = localStorage.getItem('language')
    this.setState({ language: language })
  }

  toggleSidebar = () => {
    const { isOpen, dispatch } = this.props

    dispatch({ type: global.SIDEBAR_OPEN, payload: !isOpen })
  }

  handleLang = (language) => {
    this.setState({ language })
  }

  render() {
    const { language } = this.state
    const { isOpen, isLogin } = this.props
    const firstPath = window.location.pathname.split('/')[1]
    let wrapper = ''
    let content = ''
    if (isLogin) {
      if (firstPath === 'pay') {
        content = 'content-public'
        wrapper = 'bs-wrapper out'
      } else {
        content = isOpen ? 'content is-open open-sidebar' : 'content close-sidebar'
        wrapper = 'bs-wrapper in'
      }
    } else {
      content = 'content-public'
      wrapper = 'bs-wrapper out'
    }

    return (
      <Router history={history}>
        <div className={wrapper}>
          <SidebarComponent onToggle={this.toggleSidebar} isOpen={isOpen} language={language} />
          <HeaderComponent
            title=""
            balanceFresh={false}
            onToggle={this.toggleSidebar}
            changeLang={this.handleLang}
          />
          <div className={content}>
            <PersistGate loading={null} persistor={persistor}>
              <Routes />
            </PersistGate>
          </div>
        </div>
      </Router>
    )
  }
}

App.defaultProps = { isOpen: false }

App.propTypes = { isOpen: PropTypes.bool, dispatch: PropTypes.func.isRequired }

export default connect(
  (state) => ({
    isOpen: state.Global[global.SIDEBAR_OPEN],
    isLogin: state.Global[global.IS_LOGIN],
  }),
  (dispatch) => ({ dispatch })
)(App)
