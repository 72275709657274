const Linkqu = {
  getChartMenu(group, translate, access, appEnableAPI) {
    if (group === '11') {
      return [
        {
          path: '/report/report_transaction_payment_success_by_agen',
          title: translate('reportTransactionPaymentSuccessByAgen'),
          key: 'report_transaction_payment_success_by_agen',
          hide: true,
          showOnMenu: true,
        },
        {
          path: '/report/report_transaction_payment_success_by_product',
          title: translate('reportTransactionPaymentSuccessByProduct'),
          key: 'report_transaction_payment_success_by_product',
          hide: true,
          showOnMenu: false,
        },
      ]
    }
    return [
      {
        path: '/report/daily_success_trx',
        title: 'dailySuccessTransaction',
        key: 'daily_success_trx',
        hide: access('daily_success_trx', 'READ'),
        showOnMenu: true,
      },
      {
        path: '/report/daily_omzet',
        title: 'dailyOmzet',
        key: 'daily_omzet',
        hide: access('daily_omzet', 'READ'),
        showOnMenu: false,
      },
      {
        path: '/report/daily_callback',
        title: 'dailyCallback',
        key: 'daily_callback',
        hide: access('daily_callback', 'READ') && appEnableAPI,
        showOnMenu: false,
      },
    ]
  },
  getReportSidebar(group, translate, access, appEnableAPI) {
    if (group === '11') {
      return [
        {
          path: '/report/report_transaction_payment_success_by_agen',
          title: translate('daily'),
          key: 'report',
          hide: access('report', 'READ'),
        },
        {
          path: '/data/aggregator_fee_daily',
          title: translate('monitoring'),
          key: 'monitor',
          hide: access('data_aggregator_fee_daily', 'READ'),
        },
      ]
    }
    return [
      {
        path: '/report/daily_success_trx',
        title: translate('daily'),
        key: 'report',
        hide: access('report', 'READ'),
      },
      {
        path: '/data/monitor_hold_balance',
        title: translate('monitoring'),
        key: 'monitor_hold_balance',
        hide: access('data_monitor_hold_balance', 'READ') && appEnableAPI,
      },
      {
        path: '/data/balance',
        title: 'Rekening',
        key: 'rekening',
        hide: access('data_balance', 'READ') && appEnableAPI,
      },
    ]
  },
  getResumeObject(group) {
    if (group === '11') {
      return {
        cnt_fee_total_agg: {
          path: 'monthly_aggregator',
          pathTo: 'aggregator_fee_monthly',
          title: 'aggMonthFee',
          args: { type: 'fee' },
          hasData: true,
        },
        cnt_fee_va_agg: {
          path: 'monthly_aggregator',
          pathTo: 'aggregator_fee_monthly',
          title: 'aggMonthFeeVa',
          args: { type: 'fee', group: 1 },
          hasData: true,
        },
        cnt_fee_td_agg: {
          path: 'monthly_aggregator',
          pathTo: 'aggregator_fee_monthly',
          title: 'aggMonthFeeTf',
          args: { type: 'fee', group: 2 },
          hasData: true,
        },
        cnt_fee_total_agg_daily: {
          path: 'daily_aggregator',
          pathTo: 'aggregator_fee_daily',
          title: 'aggTodayFee',
          args: { type: 'fee' },
          hasData: true,
        },
        cnt_fee_va_agg_daily: {
          path: 'daily_aggregator',
          pathTo: 'aggregator_fee_daily',
          title: 'aggTodayFeeVa',
          args: { type: 'fee', group: 1 },
          hasData: true,
        },
        cnt_fee_td_agg_daily: {
          path: 'daily_aggregator',
          pathTo: 'aggregator_fee_daily',
          title: 'aggTodayFeeTf',
          args: { type: 'fee', group: 2 },
          hasData: true,
        },
        cnt_trx_total_agg: {
          path: 'monthly_aggregator',
          pathTo: 'aggregator_fee_monthly',
          title: 'aggMonthTrx',
          args: { type: 'trx' },
          hasData: true,
        },
        cnt_trx_va_agg: {
          path: 'monthly_aggregator',
          pathTo: 'aggregator_fee_monthly',
          title: 'aggMonthTrxVa',
          args: { type: 'trx', group: 1 },
          hasData: true,
        },
        cnt_trx_td_agg: {
          path: 'monthly_aggregator',
          pathTo: 'aggregator_fee_monthly',
          title: 'aggMonthTrxTf',
          args: { type: 'trx', group: 2 },
          hasData: true,
        },
        cnt_trx_total_agg_daily: {
          path: 'daily_aggregator',
          pathTo: 'aggregator_fee_daily',
          title: 'aggTodayTrx',
          args: { type: 'trx' },
          hasData: true,
        },
        cnt_trx_va_agg_daily: {
          path: 'daily_aggregator',
          pathTo: 'aggregator_fee_daily',
          title: 'aggTodayTrxVa',
          args: { type: 'trx', group: 1 },
          hasData: true,
        },
        cnt_trx_td_agg_daily: {
          path: 'daily_aggregator',
          pathTo: 'aggregator_fee_daily',
          title: 'aggTodayTrxTf',
          args: { type: 'trx', group: 2 },
          hasData: true,
        },
      }
    }
    return {
      product_transaction_per_date: {
        path: 'product_transaction_per_date',
        title: 'mitraTrx',
        hasChart: false,
      },
      grup_product_transaction_per_date: {
        path: 'grup_product_transaction_per_date',
        title: 'mitraTrx',
        hasChart: false,
      },
      pending_transaction_per_date: {
        path: 'pending_transaction_per_date',
        title: 'mitraTrx',
        hasChart: false,
      },
      daily_total_omzet: {
        path: 'daily_total_omzet',
        title: 'mitraAllTrxOmzet',
        args: { group: 4 },
        hasChart: false,
      },
      daily_omzet_va: {
        path: 'daily_omzet_va',
        title: 'mitraVAOmzet',
        args: { group: 1 },
        hasChart: false,
      },
      daily_omzet_tf: {
        path: 'daily_omzet_tf',
        title: 'mitraTFOmzet',
        args: { group: 2 },
        hasChart: false,
      },
      omzet_va_this_month: {
        path: 'omzet_va_this_month',
        title: 'mitraVAOmzetThisMonth',
        args: { group: 7 },
        hasChart: false,
      },
      omzet_tf_this_month: {
        path: 'omzet_tf_this_month',
        title: 'mitraTFOmzetThisMonth',
        args: { group: 6 },
        hasChart: false,
      },
    }
  },
}

export default Linkqu
