import Var from '../helper/var'

export default class BaseMessage {
  constructor(data) {
    this.data = {
      version: process.env.REACT_APP_VERSION,
      uuid: navigator.userAgent,
    }
    if (data instanceof BaseMessage) {
      this.data = { ...this.data, ...data.getData() }
    } else if (typeof data === 'object' && data !== null) {
      this.data = { ...this.data, ...data }
    }
    this.path = ''
    this.header = {}
  }

  setRC(rc) {
    this.data.rc = rc
  }

  getRC() {
    return Var.toString(this.data.rc)
  }

  getCsrf() {
    return Var.toString(this.data.data.csrf)
  }

  isOK() {
    return this.getRC() === '00'
  }

  setRD(rd) {
    this.data.rd = rd
  }

  getRD() {
    return Var.toString(this.data.rd)
  }

  getResultAsObject() {
    return Var.toObject(this.data.data)
  }

  getResultAsList() {
    return Var.toList(this.data.data)
  }

  getData() {
    return this.data
  }

  getPath() {
    return this.path
  }

  setPath(path) {
    this.path = path
  }

  getHeader() {
    return this.header
  }

  setHeader(header) {
    this.header = header
  }

  setItem(key, value) {
    this.data[key] = value
  }

  getItem(key) {
    return this.data[key]
  }
}
