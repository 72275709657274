/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/react-in-jsx-scope */

import { useEffect, useState, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Button, Col, Container, Image, Row, Spinner } from "react-bootstrap";
import axios from "axios";
import DOMPurify from "dompurify";
import swal from "sweetalert";
// eslint-disable-next-line import/no-extraneous-dependencies
import Swal from "sweetalert2";

import "./paymentPublic.scss";
import AlertDialog from "../dialog/alert";
import Formatter from "../../helper/formatter";
import DateTime from "../../helper/datetime";
import LinkquLogo from "../../assets/imgs/icons/linkqu.png";
import QrisLogo from "../../assets/imgs/icons/qris.png"
import icons from "../../helper/icons";
import iconName from "../../helper/constant/iconName";

const PaymentRequestPublic = () => {
  const { idPayment } = useParams();

  const [initial, changeInitial] = useState({
    loading: false,
    alertError: "",
    data: null,
    dataBank: [],
    pageTitle: "Payment Confirmation",
    successGeneratedVAData: null,
    email_user: '',
    totalAmount: '',
    type_user: '',
  });


  const [mainContent, changeMainContent] = useState({
    detailContent: true,
    paymentContent: false,
    createdVa: false,
  });


  const [emailUser, setEmailUser] = useState('')
  const [nameUser, setNameUser] = useState('')
  const [notelpUser, setNumberPhoneUser] = useState('')

  const bankListContent = () => {
    changeMainContent({
      detailContent: true,
      createdVa: false,
    });
  };

  const createdVAContent = (email) => {
    changeMainContent({
      detailContent: false,
      createdVa: true,
    });
  };

  const generateVAPersonal = async (bankCode, email) => {
    changeInitial({ ...initial });
    const url = `${process.env.REACT_APP_API_URL}api/publics/generated_va`;

    try {
      const response = await axios
        .get(url, {
          params: { id_payment: initial.data.id, code_bank: bankCode, email_user: email, brand: process.env.REACT_APP_BRAND_NAME.toLowerCase() },
        })
        .then((res) => res)

      if (response.data.rc == "00") {
        changeInitial({
          ...initial,
          successGeneratedVAData: response.data.data.data,
          totalAmount: response.data.data.data.amount
        });
        createdVAContent();
      } else {
        changeInitial({
          ...initial,
          alertError: response.data.rd,
        });
      }
    } catch (error) {
      console.log(error);
      changeInitial({ ...initial, alertError: error.rd });
      bankListContent();
    }
  };

  const generateVABisnis = async (bankCode, email, name, notelp) => {
    changeInitial({ ...initial });
    const url = `${process.env.REACT_APP_API_URL}api/publics/generated_va`;

    try {
      const response = await axios
        .post(url, {
          params: { id_payment: initial.data.id, code_bank: bankCode, email: email, name: name, notelp: notelp },
        })
        .then((res) => res)

      if (response.data.rc == "00") {
        changeInitial({
          ...initial,
          successGeneratedVAData: response.data.data.data,
          totalAmount: response.data.data.data.amount
        });
        createdVAContent();
      } else {
        changeInitial({
          ...initial,
          alertError: response.data.rd,
        });
      }
    } catch (error) {
      console.log(error);
      changeInitial({ ...initial, alertError: error.rd });
      bankListContent();
    }
  };

  const addBodyClass = (className) => document.body.classList.add(className);
  const removeBodyClass = (className) =>
    document.body.classList.remove(className);

  const getData = async () => {
    changeInitial({ ...initial, loading: true });
    let url = `${process.env.REACT_APP_API_URL}api/publics/request_payment_detail`;

    try {
      let response = await axios
        .get(url, { params: { paymentSecret: idPayment } })
        .then((res) => res.data)

      if (response.rc == "00") {
        const responseData = response.data.data["payment-request"]
        const typeUser = response.data.data["type-user"]
        url = `${process.env.REACT_APP_API_URL}api/publics/list_va`
        response = await axios.get(url).then((res) => res.data);
        
        if (response.rc == "00") {
          const bankExist = [];
          const bankNotUsed = [];

          for (let i = 0; i < response.data.data.length; i++) {
            if (response.data.data[i].kodeBank == 523 || response.data.data[i].kodeBank == 'QRISB' || response.data.data[i].kodeBank == 'PAYGOPAY' || response.data.data[i].kodeBank == 'PAYOVO') {
              bankNotUsed.push(response.data.data[i])
            } else {
              bankExist.push(response.data.data[i])
            }
          }

          changeInitial({
            ...initial,
            data: responseData,
            dataBank: bankExist,
            type_user: typeUser,
            loading: false
          });
          bankListContent();
        } else {
          changeInitial({ ...initial, loading: false, alertError: response.rd, dataBank: [] });
        }
      } else {
        changeInitial({ ...initial, loading: false, alertError: response.rd, data: null });
      }
    } catch (error) {
      console.log(error);
      changeInitial({ ...initial, loading: false, alertError: error.rd, data: null });
    }
  };

  const [selectedBank, selectedBankState] = useState({
    bank: null,
  });

  const [disableButtonPay, disableButtonPayState] = useState({
    disabled: true,
  });

  const closeError = () => {
    changeInitial({ ...initial, alertError: "" });
  };

  useEffect(() => {
    addBodyClass("page--requestPaymentPublic");
    getData(); 
    return () => {
      removeBodyClass("page--requestPaymentPublic");
    };
  }, []);

  const isExpired = (dateExp) => {
    return dateExp < DateTime.today(DateTime.FMT_DATE_TIME_YMDHMS)
  }

  const inputEmailUser = async () => {
    swal({
      title: "Input your Email",
      icon: icons(iconName.warningDialog),
      content: "input",
      buttons: {
        cancel: {
          text: 'Cancel',
          value: 'cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'PAY',
          closeModal: true,
          className: 'swal-red-btn'
        }
      }
    }).then((email) => {

      if (email === '') {
        swal("Alert", "Input Your Email First!", "error").then(() => swal.close())
      } else if (email === 'cancel') {
        swal.close()
      } else {
        setEmailUser(email)
        swal.close();
        generateVAPersonal(selectedBank.bank.kodeBank, email);
      }
    })
  }

  const inputDataUser = async () => {
    // const { value: formValues } = await Swal.fire({
    await Swal.fire({
      title: "Input Your Data",
      animation: false,
      html:
        '<label for="name" class="fontSize-16-Medium form-label">Name</label><br>' +
        '<input id="swal-input-name" class="custom-input fontSize-16 " placeholder="Input Your Name"><br>' +
        '<label for="email" class="fontSize-16-Medium form-label mt-3">Email</label><br>' +
        '<input id="swal-input-email" class="custom-input fontSize-16 " placeholder="Input Your Email Address"><br>'+
        '<label for="number_phone" class="fontSize-16-Medium form-label mt-3">Number Phone</label><br>' +
        '<input id="swal-input-notelp" class="custom-input fontSize-16 " placeholder="Input Your Number Phone">',
      focusConfirm: false,
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText:
        '<i class="fa fa-thumbs-up"></i> OK',
      confirmButtonAriaLabel: 'Thumbs up, great!',
      cancelButtonText:
        '<i class="fa fa-thumbs-down">Batal</i>',
      cancelButtonAriaLabel: 'Thumbs down',
      preConfirm: () => {
        const email = document.getElementById('swal-input-email').value
        const name = document.getElementById('swal-input-name').value
        const notelp = document.getElementById('swal-input-notelp').value
        if (name == "" ) {
          Swal.showValidationMessage('Input Your Name First!')
        } else if (email == "" ) {
          Swal.showValidationMessage('Input Your Email First!')
        } else if (notelp == "" ) {
          Swal.showValidationMessage('Input Your Phone First!')
        } else {
          generateVABisnis(selectedBank.bank.kodeBank, email, name, notelp);
        }
      }
    })
  }

  const alertConfirm = () => {
    swal({
      title: "Are you sure to proceed?",
      icon: icons(iconName.warningDialog),
      text:
        "After you press the confirmation button, you cannot change the payment method again",
      buttons: {
        cancel: true,
        confirm: {
          value: "confirm",
          text: "Confirm",
          className: "swal-red-btn",
        },
      },
    }).then((onConfirm) => {
      if (onConfirm === "confirm") {
        if(initial.type_user == "BISNIS") {
          inputDataUser()
        } else {
          inputEmailUser() 
        }
      } else {
        swal.close();
      }
    });
  };

  return (
    <>
      <div
        className="cust-header"
        style={{
          background: "white",
          height: 80,
          width: "100%",
        }}
      >
        <Container className="container-header-payment-public">
          {process.env.REACT_APP_BRAND === 'FUSINDO' ? (<>
            <img alt="LinkQu Logo" src={`${process.env.PUBLIC_URL}/logo192-fusindo.png`} className="logo-brand"/>
          </>) : (<>
            <img alt="LinkQu Logo" src={LinkquLogo} className="logo-brand"/>
          </>)}
          <div className="fontSize-26-Bold text-right">
            Payment Confirmation
          </div>
        </Container>
      </div>
      {initial.loading ? (
        <Container
          className="loading-wrapper d-flex justify-content-center align-items-center w-100"
          style={{ height: "90vh" }}
        >
          <div className="loading-state d-flex">
            <Spinner
              size="xl"
              className="mx-2 text-active"
              animation="grow"
            />
            <Spinner
              size="xl"
              className="mx-2 text-active"
              animation="grow"
            />
            <Spinner
              size="xl"
              className="mx-2 text-active"
              animation="grow"
            />
          </div>
        </Container>
      ) : initial.data == null ? (
        <Container
          className="container-payment-public"
        >
          <div
            className="content-wrapper"
          >
            <div className="d-flex flex-column align-items-center p-5">
              <img src={icons(iconName.limitLogo, process.env.REACT_APP_BRAND_NAME)} alt="Limit Logo" style={{ width: "50%", marginBottom: 20 }} />
              <h5 style={{ textAlign: "center" }}>Data not found</h5>
            </div>
          </div>
        </Container>
      ) : (
        <Container
          className="container-payment-public"
        >
          <div
            className="content-wrapper"
          >
            {
              isExpired(initial.data.timeExpired) ? 
              (
                <div className="d-flex flex-column align-items-center p-5">
                  <img src={icons(iconName.limitLogo, process.env.REACT_APP_BRAND_NAME)} alt="Limit Logo" style={{ width: "50%", marginBottom: 20 }} />
                  <h5 style={{ textAlign: "center" }}>This Request Payment Already Expired At <br /> {DateTime.format(initial.data.timeExpired, DateTime.FMT_DATE_TIME_YMDHMS3)} WIB.</h5>
                </div>
              ) : (
                initial.data.count >= initial.data.number ? 
                (
                  <div className="d-flex flex-column align-items-center p-5">
                    <img src={icons(iconName.limitLogo, process.env.REACT_APP_BRAND_NAME)} alt="Limit Logo" style={{ width: "50%", marginBottom: 20 }} />
                    <h5 style={{ textAlign: "center" }}>This Request Payment Already Reached the Maximum Usage Limit.</h5>
                  </div>
                ) : 
                (
                  <>
                    <div className="body-content-wrapper">
                      <div className="main-content">
                        {mainContent.detailContent && (
                          <DetailPayment
                            dataPayment={initial}
                            mainState={initial}
                            changeMainState={changeInitial}
                            selectedBank={selectedBank}
                            disableButtonPay={disableButtonPay}
                            disableButtonPayState={disableButtonPayState}
                            selectedBankState={selectedBankState}
                            action={alertConfirm}
                          />
                        )}
                        {mainContent.createdVa && (
                          <CreatedVAContent selectedBank={selectedBank} dataPayment={initial} dataVA={initial.successGeneratedVAData} emailUser={emailUser} />
                        )}
                      </div>
                    </div>
                  </>
                )
              )
            }
          </div>
        </Container>
      )}
      <AlertDialog
        show={!!initial.alertError}
        onHide={closeError}
        onConfirm={closeError}
        title="Perhatian"
      >
        {initial.alertError}
      </AlertDialog>
    </>
  );
};

const DetailPayment = ({ dataPayment, action, selectedBank, selectedBankState, disableButtonPay, disableButtonPayState }) => {
  const descHTMLContent = (contentHMTL) => {
    return {
      __html: DOMPurify.sanitize(contentHMTL),
    };
  };

  const DetailContent = ({ payment, banks, actions, selectedBankStates, selectedBanks,disableButton, disableButtonPayStates }) => {
    const date = DateTime.parse(
      payment?.timeExpired,
      DateTime.FMT_DATE_TIME_YMDHMS
    ).toDate();
    return (
      <Row>
        <Col md="6" sm="12" xs="12">
          <div className="wrapper-detail mb-5">
            <div className="detail-full">
              <div className="pt-3 pl-3 fontSize-16-Medium">
                Detail Payment
              </div>
              <hr />
              <Row>
                <Col sm="6" xs="12">
                  <div className="pl-3 fontSize-16-Medium color-grey">
                    Payment Name:
                  </div>
                </Col>
                <Col sm="6" xs="12">
                  <div className="pr-3 fontSize-16-Medium text-right">
                    {payment?.namePayment}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm="6" xs="12">
                  <div className="pl-3 fontSize-16-Medium color-grey">
                    Amount:
                  </div>
                </Col>
                <Col sm="6" xs="12">
                  <div className="pr-3 fontSize-16-Medium text-right">
                    {Formatter.price(!!payment.totalAmount ? payment.totalAmount : payment.amount)}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm="6" xs="12">
                  <div className="pl-3 fontSize-16-Medium color-grey">
                    Payment Expired:
                  </div>
                </Col>
                <Col sm="6" xs="12">
                  <div className="pr-3 fontSize-16-Medium text-right">
                    {DateTime.format(date, DateTime.FMT_DATE_TIME_YMDHMS)}
                  </div>
                </Col>
              </Row>
              <div className="pl-3 fontSize-16-Medium color-grey">Payment Description: </div>
              {payment?.descPayment === "" ? (
                  <div className="pb-5" />
                ) : (
                  <div
                    dangerouslySetInnerHTML={descHTMLContent(payment?.descPayment)}
                    className="pb-5 pl-3 fontSize-16-Medium"
                  ></div>
                )}
            </div>
          </div>
          <Button
            type="submit"
            size="md"
            variant="primary"
            block
            onClick={actions}
            className="btn-custom btn-custom-reset-password fontSize-17 button-desktop"
            disabled={disableButton.disabled}
          >
            <div className="fontSize-17 color-white">Pay</div>
            <Image src={icons(iconName.arrowWhite)} className="ml-5" />
          </Button>
        </Col>
        <Col md="6" sm="12" xs="12">
          <div className="wrapper-payment container">
            <div className="fontSize-20 mb-3">
              Select Payment
            </div>
            <div className="list-payment">
              {banks.map((bank, i) => {
                if(bank.isActive) {
                  return (<div key={bank.kodeBank} className={`card-payment ${selectedBanks.bank && selectedBanks.bank.kodeBank == bank.kodeBank ? `card-payment-active` : ``}`} onClick={(e) => { e.preventDefault();disableButtonPayStates({disabled: !selectedBankStates });selectedBankStates({ bank: bank })}}>
                  <Image src={bank.url_image == "" ? icons(iconName.gubuk) : bank.url_image} />
                  <div className="fontSize-16-Medium ml-3">
                    {bank.namaBank}
                  </div>
                </div>)
                } 
                  return(<></>) 
                
              })}
            </div>
          </div>
        </Col>
        <Button
          type="submit"
          size="md"
          variant="primary"
          block
          onClick={actions}
          className="btn-custom btn-custom-reset-password fontSize-17 button-mobile"
        >
          <div className="fontSize-17 color-white">Pay</div>
          <Image src={icons(iconName.arrowWhite)} className="ml-5" />
        </Button>
      </Row>
    );
  };

  return (
    <div>
      <div className="id-order fontSize-17 text-active">
        ORDER ID {dataPayment.data.id}
      </div>
      <DetailContent selectedBanks={selectedBank} disableButton={disableButtonPay} disableButtonPayStates={disableButtonPayState} selectedBankStates={selectedBankState} payment={dataPayment.data} banks={dataPayment.dataBank} actions={action} />
    </div>
  );
};

const CreatedVAContent = ({ selectedBank ,dataPayment, dataVA, emailUser }) => {
  const isQris = dataVA?.kodBank === 'QRIS' || dataVA?.kodBank === 'QRISB'
  const hasLinkPaymentExternal = dataVA?.kodBank === 'CREDITCARD' || dataVA?.kodBank === 'PAYDANA' || dataVA?.kodBank === 'PAYSHOPEE' || dataVA?.kodBank === 'PAYLINKAJA'
  return (
    <>
      <div className="container-generatedva">
        <div className="text-right">
          <div className="id-order fontSize-17 text-active mb-3">
            ORDER ID {dataPayment.data.id}
          </div>
        </div>
        <Row>
          <Col md="6" sm="12" xs="12">
            {!isQris && !hasLinkPaymentExternal && (<div>
              <div className="fontSize-19-Bold">
                Virtual Account Detail
              </div>
              <div className="detail-va">
                <Row>
                  <Col sm="6" xs="12">
                    <div className="pl-3 fontSize-16-Medium color-grey">
                      VA Number:
                    </div>
                  </Col>
                  <Col sm="6" xs="12">
                    <div className="pr-3 fontSize-16-Medium text-right">
                      {dataVA?.vaNumber}
                    </div>
                  </Col>
                  <Col sm="6" xs="12">
                    <div className="pl-3 fontSize-16-Medium color-grey">
                      VA Name:
                    </div>
                  </Col>
                  <Col sm="6" xs="12">
                    <div className="pr-3 fontSize-16-Medium text-right">
                      {dataVA?.customerName}
                    </div>
                  </Col>
                  <Col sm="6" xs="12">
                    <div className="pl-3 fontSize-16-Medium color-grey">
                      Amount:
                    </div>
                  </Col>
                  <Col sm="6" xs="12">
                    <div className="pr-3 fontSize-16-Medium text-right">
                      {Formatter.price(!!dataPayment.totalAmount ? dataPayment.totalAmount : dataPayment.data?.amount)}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>)}
            {!isQris && hasLinkPaymentExternal && (<div>
              <div className="fontSize-19-Bold">
                Credit Card Detail
              </div>
              <div className="detail-va">
                <Row>
                  <Col sm="6" xs="12">
                    <div className="pl-3 fontSize-16-Medium color-grey">
                      Customer Name:
                    </div>
                  </Col>
                  <Col sm="6" xs="12">
                    <div className="pr-3 fontSize-16-Medium text-right">
                      {dataVA?.customerName}
                    </div>
                  </Col>
                  <Col sm="6" xs="12">
                    <div className="pl-3 fontSize-16-Medium color-grey">
                    Customer Email:
                    </div>
                  </Col>
                  <Col sm="6" xs="12">
                    <div className="pr-3 fontSize-16-Medium text-right">
                      {dataVA?.email}
                    </div>
                  </Col>
                  <Col sm="6" xs="12">
                    <div className="pl-3 fontSize-16-Medium color-grey">
                      Amount:
                    </div>
                  </Col>
                  <Col sm="6" xs="12">
                    <div className="pr-3 fontSize-16-Medium text-right">
                      {Formatter.price(!!dataPayment.totalAmount ? dataPayment.totalAmount : dataPayment.data?.amount)}
                    </div>
                  </Col>
                  <Col sm="6" xs="12">
                    <div className="pl-3 fontSize-16-Medium color-grey">
                      Fee Admin:
                    </div>
                  </Col>
                  <Col sm="6" xs="12">
                    <div className="pr-3 fontSize-16-Medium text-right">
                      {Formatter.price(dataVA?.feeadmin)}
                    </div>
                  </Col>
                  <Col xs="12">
                    <a
                      href={dataVA?.linkPayment}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button
                        type="submit"
                        size="md"
                        variant="primary"
                        block
                        className="btn-custom btn-custom-reset-password fontSize-17 button-desktop"
                      >
                        <div className="fontSize-17 color-white">Link Payment</div>
                        <Image src={icons(iconName.arrowWhite)} className="ml-5" />
                      </Button>
                    </a>
                  </Col>
                </Row>
              </div>
            </div>)}
            {isQris && (<div className="d-flex w-100 mb-3 flex-column align-items-center justify-content-center">
              <img src={QrisLogo} alt="QR CODE LOGO" width="200px" height="100px"/>
              <img src={`data:image/png;base64,${ dataVA?.imageQris}`} alt="QR CODE" width="200px" height="200px"/>
            </div>)}
          </Col>
          <Col md="6" sm="12" xs="12">
            <div className="alert-info-custom">
              <Image src={icons(iconName.alert)} className="" />
              <div className="color-pink-dark fontSize-20-Regular d-inline">
                {isQris ? 'QRIS ' : 'Virtual Account '} Information will be send to your email :
              </div>
              <div className="fontSize-20-Regular">
                {emailUser}
              </div>
            </div>
          </Col>
        </Row>
        {selectedBank.bank.urlHelp && (
        <a
          href={selectedBank.bank.urlHelp}
          target="_blank"
          rel="noreferrer"
        >
        <div className="payment-guide">
          <div className="title">
            Payment Guide
          </div>
          <Image src={icons(iconName.arrowRightRed)} className="" />
        </div>
        </a>)}
      </div>
    </>
  );
};

export default PaymentRequestPublic;
