import MessageSender from '../sender'
import BaseMessage from '../message'

export default (id) => async () => {
  const msg = new BaseMessage()
  msg.setPath(`data/set_change_status_account_favourite`)
  msg.setItem('id', id)

  const response = await new MessageSender().doGet(msg)
  return response
}
