/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable eqeqeq */
/* eslint-disable prettier/prettier */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react'
import { Form, Container, Image, Button } from 'react-bootstrap'
import DatePicker from 'react-date-picker'
import { EditorState } from 'draft-js'
import ReCAPTCHA from 'react-google-recaptcha'
import DateTime from '../../helper/datetime'
import Formatter from '../../helper/formatter'
import AlertDialog from '../dialog/alert'
import createRequestPayment from '../../api/action/createRequestPayment'
import checkAccess from '../../helper/access'
import AccessDenied from '../component/AccessDenied'
import translate from '../../helper/translate'
import { buttonResource, columnResource, placeholderResource } from '../../helper/translatePage/languageResource'
import icons from '../../helper/icons'
import iconName from '../../helper/constant/iconName'
import getCsrf from '../../api/action/getCsrf'
import logout from '../../api/action/logout'

// eslint-disable-next-line react/prop-types
function CreateRequestPayment({
  contentChange,
  username,
  dispatch,
  loading,
  appUserAccess,
  languange,
}) {
  // eslint-disable-next-line no-unused-vars
  let captchaRef = React.createRef().current
  const [initial, initialChangeState] = useState({
    balanceFresh: false,
    editor: EditorState.createEmpty(),
    alertError: '',
    alertSuccess: '',
  })

  const [formState, formChangeState] = useState({
    orderName: '',
    nominal: '',
    description: null,
    maxUsed: "1",
    date: new Date(),
    recaptcha: '',
  })


  const onChangeRecaptcha = (value) => {
    formChangeState({
      ...formState,
      recaptcha: value,
    })
  }

  const accessCreate = () => {
    return checkAccess(appUserAccess, 'request_payment', 'ADD')
  }

  const handleForm = (event) => {
    const { name, value } = event.target

    formChangeState({ ...formState, [name]: value })
  }

  const handleDate = (dateValue) => {
    formChangeState({
      ...formState,
      date: DateTime.parse(dateValue, DateTime.FMT_DATE_YMD).toDate(),
    })
  }

  const handleNominal = (e) => {
    formChangeState({ ...formState, nominal: Formatter.price(e.target.value) })
  }

  const handleDescription = (e) => {
    formChangeState({ ...formState, description: e.target.value })
  }

  const onSubmit = async () => {
    const { orderName, nominal, description, maxUsed, recaptcha } = formState
    // eslint-disable-next-line radix
    if (orderName === '' || nominal === '' || description === null || maxUsed === '') {
      initialChangeState({
        ...initial,
        alertError:
          'Data yang anda masukkan tidak valid atau ada yang belum terisi, periksa kembali data yang anda masukkan',
      })
    } else if (!recaptcha) {
      initialChangeState({
        ...initial,
        alertError:
          'Please fill recatpcha',
      })
    } else {
      captchaRef.reset()
      const formatDate = `${DateTime.format(
        Date.parse(formState.date),
        DateTime.FMT_DATE_YMD
      )} 23:59:59`

      const response = await dispatch(getCsrf())
      if (response.isOK()) {
        const createRequestPaymentRes = await dispatch(
          createRequestPayment(username, orderName, formatDate, maxUsed, nominal, description, recaptcha)
        )

        if (createRequestPaymentRes.data.rc == '00') {
          initialChangeState({ ...initial, alertSuccess: 'Create Request Payment Success' })
        } else {
          initialChangeState({ ...initial, alertError: createRequestPaymentRes.getRD() })
        }
      } else {
        dispatch(logout())
        initialChangeState({ ...initial, alertError: response.getRD() })
      }
    }
  }

  const closeError = () => {
    initialChangeState({ ...initial, alertError: '' })
  }

  const onConfirmSuccess = () => {
    initialChangeState({ ...initial, alertSuccess: '' })
    contentChange('list_request_payment', null)
  }

  return (
    <>
      <div className="mb-3 container-flex-center">
        <button type="button" className="btn btn-outline-primary back-button"  onClick={() => contentChange('list_request_payment', null)}>
          <Image src={icons(iconName.backButton)} className="" />
        </button>
      </div>
      {!accessCreate() ? (
        <AccessDenied />
      ) : (
        <>
          <Container fluid className="container-create-request-payment">
            <Form className="form-create-request-payment">
              <div className="container-split-form-topup">
                <Form.Group className="mb-3 form-left">
                  <Form.Label className="color-grey fontSize-16-Medium">
                    {translate('transferRequirements', languange, placeholderResource)} <span style={{ color: 'red' }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="orderName"
                    value={formState.orderName}
                    onChange={(e) => handleForm(e)}
                    required
                    className="custom-input fontSize-16"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="color-grey fontSize-16-Medium">{translate('trxCount', languange, columnResource)} <span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Control
                    onChange={handleNominal}
                    value={formState.nominal}
                    className="custom-input fontSize-16"
                  />
                </Form.Group>
              </div>
              <div className="mt-3 mb-2 text-left">
                <Form.Label className="color-grey fontSize-16-Medium">
                  {translate('opDesc', languange, placeholderResource)}
                  <span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <Form.Control
                  onChange={handleDescription}
                  value={formState.description}
                  className="custom-input fontSize-16 input-description"
                />
              </div>
            </Form>
            <div className="expired container-bttom-request-payment">
              <div className="container-form-bottom-request-payment">
                <div className="pt-2 container-datepicker-request-payment">
                  <Form.Label className="color-grey fontSize-16">
                    {translate('opExpDate', languange, placeholderResource)}<span style={{ color: 'red' }}>*</span>
                  </Form.Label>
                  <DatePicker
                    className="mb-3 custom-input fontSize-16"
                    onChange={handleDate}
                    disableClock
                    format="yyyy-MM-dd"
                    minDate={new Date()}
                    value={formState.date}
                    clearIcon={null}
                    calendarIcon={null}
                  />
                </div>
                <div />
                <Form.Group className="mb-3">
                  <Form.Label className="color-grey fontSize-16">
                    {translate('opMaxUse', languange, placeholderResource)} <span style={{ color: 'red' }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="maxUsed"
                    placeholder="Masukkan maksimal penggunaan"
                    value={formState.maxUsed}
                    onChange={(e) => handleForm(e)}
                    required
                    className="custom-input fontSize-16"
                  />
                </Form.Group>
              </div>
              <ReCAPTCHA
                ref={(ref) => {
                  captchaRef = ref
                }}
                className="mt-5"
                sitekey={process.env.REACT_APP_GRECAPTCHA_KEY}
                onChange={onChangeRecaptcha}
              />
              <Button
                variant="primary"
                type="submit"
                disabled={loading}
                className={`button-custom-submit fontSize-17 ${loading ? 'disabled' : ''}`}
                onClick={onSubmit}
              >
                <Image src={icons(iconName.iconSaveWhite)} className="mr-3" />
                {translate('save', languange, buttonResource)}
              </Button>
            </div>
          </Container>
          <AlertDialog
            show={!!initial.alertError}
            onHide={closeError}
            onConfirm={closeError}
            type="warning"
            messageTitle={initial.alertError}
          />
          <AlertDialog
            show={!!initial.alertSuccess}
            onHide={onConfirmSuccess}
            onConfirm={onConfirmSuccess}
            type="success"
            messageTitle={initial.alertSuccess}
          />
        </>
      )}
    </>
  )
}

export default CreateRequestPayment
