import DateTime from '../../helper/datetime'
import MessageSender from '../sender'
import BaseMessage from '../message'
// eslint-disable-next-line
import authConstant from '../../store/constant/auth'

export const getAccounts = (iduser) => async () => {
  const msg = new BaseMessage()
  msg.setPath('data/account_all')
  msg.setItem('iduser', iduser)

  const response = await new MessageSender().doPost(msg)

  return response.getResultAsObject()
}

export const getAccountData = (iduser) => async () => {
  const msg = new BaseMessage()
  msg.setPath('data/account_data')
  msg.setItem('iduser', iduser)

  const response = await new MessageSender().doPost(msg)

  return response.getResultAsObject()
}

export const postAccount = (idParent, email, password, name, userpw) => async () => {
  const msg = new BaseMessage()
  msg.setPath('data/account_store')
  msg.setItem('id', idParent)
  msg.setItem('email', email)
  msg.setItem('password', password)
  msg.setItem('name', name)
  msg.setItem('userpw', userpw)
  const response = await new MessageSender().doPost(msg)
  return response
}

export const updateAccount = (id, email, name, newAccountpw = '', userpw) => async () => {
  const msg = new BaseMessage()
  msg.setPath('data/account_change_pass')
  msg.setItem('id', id)
  msg.setItem('email', email)
  msg.setItem('username', name)
  msg.setItem('userpw', userpw)
  msg.setItem('newaccountpw', newAccountpw)
  const response = await new MessageSender().doPost(msg)
  return response
}

export const hapusAccount = (id) => async () => {
  const msg = new BaseMessage()
  msg.setPath('data/account_delete')
  msg.setItem('idaccount', id)
  const response = await new MessageSender().doPost(msg)
  return response
}

export const getBalance = () => async () => {
  const msg = new BaseMessage()
  const key = 'current_balance'
  msg.setPath(`resume/current_balance`)
  msg.setItem('key', key)
  msg.setItem('date', DateTime.today(DateTime.FMT_DATE_YMD))
  const response = await new MessageSender().doGet(msg, false)

  if (response.isOK()) {
    return response.getResultAsObject()
  }

  return { key, value: 'n/a' }
}

export const getAppInfo = () => async () => {
  const msg = new BaseMessage()
  msg.setPath(`data/app_info_web`)
  const response = await new MessageSender().doGet(msg, false)

  return response
}

export const getHoldBalance = () => async () => {
  const msg = new BaseMessage()
  const key = 'current_hold_balance'
  msg.setPath(`resume/current_hold_balance`)
  msg.setItem('key', key)
  msg.setItem('date', DateTime.today(DateTime.FMT_DATE_YMD))
  const response = await new MessageSender().doGet(msg, false)

  if (response.isOK()) {
    return response.getResultAsObject()
  }

  return { key, value: 'n/a' }
}

// eslint-disable-next-line
export const getStatusReg = async (email) => {
  const msg = new BaseMessage()
  msg.setPath('data/check_status_reg')
  msg.setItem('email', email)
  const response = await new MessageSender().doPost(msg)
  if (response.isOK()) {
    return response.getResultAsObject()
  }
  return []
}
