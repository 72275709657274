import authConstant from '../../store/constant/auth'
import global from '../../store/constant/global'
import roleAccess from '../../store/constant/roleAccess'

export default () => async (dispatch) => {
  if ('caches' in window) {
    caches.keys().then((names) => {
      // Delete all the cache files
      names.forEach((name) => {
        caches.delete(name)
      })
    })
  }
  localStorage.clear()
  document.cookie = 'token=; Max-Age=-99999999;'
  await dispatch({
    type: authConstant.CLEAR,
    payload: {},
  })
  await dispatch({ type: global.IS_LOGIN, payload: false })
  await dispatch({ type: global.ANTI_CSRF_TOKEN, payload: '' })
  await dispatch({
    type: roleAccess.CLEAR_ROLE_ACCESS,
  })
  localStorage.removeItem('currentBalance')
  localStorage.removeItem('currentHoldBalance')
}
