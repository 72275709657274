/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-alert */
/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-else-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
// eslint-disable-next-line
import { Nav, Button, Image, Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import swal from 'sweetalert'

import './sidebar.scss'
import SubMenuComponent from './submenu'
import authConstant from '../../store/constant/auth'
import global from '../../store/constant/global'
import Browser from '../../helper/browser'
import Linkqu from '../../helper/linkqu'
import { sidebarLangResource } from '../../helper/translatePage/languageResource'
import roleAccess from '../../store/constant/roleAccess'
import { production, sandbox } from '../../api/action/changeEnv'
import checkModuleAccess from '../../helper/modulAccess'
import checkAccess from '../../helper/access'
import icons from '../../helper/icons'
import iconName from '../../helper/constant/iconName'

function SidebarComponent({
  appUserName,
  appUserID,
  appEnableAPI,
  appUserModulAccess,
  appEnvironment,
  appAgentName,
  appAgentGroup,
  appUserGroup,
  appUserData,
  appUserType,
  appStatusReg,
  statusUserParent,
  userId,
  userPass,
  onToggle,
  isOpen,
  language,
  menuChoosen,
  statusKyc,
  balanceFresh,
  isLogin,
  dispatch,
  appAgentUser,
  userBalance,
  appRuleShowBalance,
}) {
  const location = useLocation()
  const [initState, changedState] = useState({ path: Browser.getCurrentPath(), childMenu: false })
  const [balance, changedBalance] = useState(userBalance)
  useEffect(() => {
    if (isLogin) {
      changedBalance(userBalance)
    }
  })

  const translate = (key) => {
    let result = ''
    if (language === 'id') {
      // eslint-disable-next-line
      result = sidebarLangResource.id[key]
    } else {
      // eslint-disable-next-line
      result = sidebarLangResource.en[key]
    }

    return result !== undefined ? result : 'Language not found'
  }

  const moduleAccess = (moduleName) => {
    return checkModuleAccess(appUserModulAccess, moduleName)
  }

  const isUserPersonal = () => {
    if (appUserType === 'Personal END USER') {
      if (appUserGroup === '11') {
        return false
      } else {
        return true
      }
    }

    return false
  }

  const accessCheck = (moduleName, accessName) => {
    return checkAccess(appUserModulAccess, moduleName, accessName)
  }

  const stateActiveMenu = (menu, openChildMenu) => {
    changedState({ ...initState, childMenu: openChildMenu })
    dispatch({
      type: global.MENU_CHOOSEN,
      payload: menu,
    })
  }

  const closeChildMenu = () => {
    changedState({ ...initState, childMenu: !initState.childMenu })
  }

  // eslint-disable-next-line no-unused-vars
  const changeEnvironment = (password) => {
    swal({
      title: 'Informasi',
      text: 'Anda akan diarahkan ke mode Sandbox, apakah Anda yakin?',
      icon: 'info',
      buttons: {
        cancel: true,
        confirm: {
          text: 'Yes',
          value: 'confirmed',
          className: 'swal-red-btn',
          closeModal: false,
        },
      },
    })
      .then((onConfirm) => {
        if (onConfirm !== 'confirmed') {
          swal.close()
        }

        const response = dispatch(sandbox(password))
        return response
      })
      .then((res) => {
        if (res.data.rc != '00' || res.data.rc == 500) {
          swal('Information', res.data.rd, 'info')
        } else {
          swal('Success', 'You are now in Sandbox Mode', 'success')
        }
      })
  }

  const changeSandbox = () => {
    swal({
      title: 'Input your Password',
      icon: 'info',
      content: {
        element: 'input',
        attributes: {
          placeholder: 'Type your password',
          type: 'password',
        },
      },
      buttons: {
        cancel: {
          text: 'Cancel',
          value: 'cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Continue',
          closeModal: false,
          className: 'swal-red-btn',
        },
      },
    }).then((pass) => {
      if (pass === '') {
        return swal('Alert', 'Input Your Password First!', 'error').then(() => swal.close())
      } else if (pass === 'cancel') {
        return swal.close()
      }

      changeEnvironment(pass)
    })
  }

  const changeProduction = () => {
    swal({
      title: 'Informasi',
      text: 'Anda akan diarahkan ke mode Production, apakah Anda yakin?',
      icon: 'info',
      buttons: {
        cancel: true,
        confirm: {
          text: 'Yes',
          value: 'confirmed',
          className: 'swal-red-btn',
          closeModal: false,
        },
      },
    })
      .then((onConfirm) => {
        if (onConfirm !== 'confirmed') {
          swal.close()
        }

        const response = dispatch(production())
        return response
      })
      .then(() => {
        swal('Success', 'You are now in Production Mode', 'success')
      })
  }

  const isParent = appUserData?.id_user_parent == null
  const token = userPass
  const signed = !!userId && !!token

  let saldoContainerShow = false
  if (appUserType === 'Personal END USER' || !appStatusReg?.status || !appEnableAPI) {
    saldoContainerShow = false
  } else {
    saldoContainerShow = true
  }

  let saldoShow = false
  if (appRuleShowBalance === 1) {
    saldoShow = true
  }

  let showBalanceMenu = false
  if (moduleAccess('data_balance') && accessCheck('data_balance', 'READ') && appEnableAPI) {
    showBalanceMenu = true
  }

  let logo = `${process.env.PUBLIC_URL}/logo192.png`
  if (process.env.REACT_APP_BRAND === 'FUSINDO') {
    logo = `${process.env.PUBLIC_URL}/logo192-fusindo.png`
  }

  if (signed && !appStatusReg.status) {
    return null
  } else if (
    Browser.getCurrentPath().includes('pay/') ||
    Browser.getCurrentPath().includes('auth/login')
  ) {
    return null
  } else if (signed && Browser.getCurrentPath().includes('invoice/')) {
    return null
  } else if (signed) {
    return (
      <>
        <div className={isOpen ? 'sidebar open' : 'sidebar'}>
          <div className="sidebar-header">
            <div className="sidebar-profile">
              <div className="d-inline ml-4 logo">
                <Image src={logo} roundedCircle />
              </div>
              {saldoContainerShow ? (
                <div className="container-saldo">
                  {saldoShow ? (
                    <>
                      <Image
                        className="d-inline-block icon ml-4"
                        src={icons(iconName.saldo, process.env.REACT_APP_BRAND_NAME)}
                      />
                      <span className="d-inline-block fontSize-16-lexend ml-2 color-grey">
                        {translate('saldo')}
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                  <div
                    className={`${
                      saldoShow ? '' : 'ml-3'
                    } content-saldo d-flex justify-content-between align-items-center`}
                  >
                    {saldoShow ? (
                      <div className="d-block fontSize-20-lexend ml-4 color-black overflow-auto info-saldo">
                        Rp. {balance && balance !== 'n/a' ? balance : 0}
                      </div>
                    ) : (
                      <></>
                    )}
                    {/* {showBalanceMenu && !isUserPersonal() && (
                      <Link
                        to="/data/balance"
                        className="btn btn-outline-primary mr-3 btn-menu-balance"
                      >
                        <Image
                          className="d-inline-block icon"
                          src={icons(iconName.balance, process.env.REACT_APP_BRAND_NAME)}
                        />
                      </Link>
                    )} */}
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div style={{ textAlign: 'center' }}>
                <div
                  className={`${
                    isOpen ? 'd-inline float-right' : 'd-flex justify-content-center'
                  } toggle-menu`}
                >
                  <Button variant="link" onClick={onToggle} className="mt-2 close-sidebar">
                    <Image
                      className="icon"
                      src={isOpen ? icons(iconName.arrowLeftNew) : icons(iconName.arrowRightNew)}
                    />
                  </Button>
                </div>
                {isOpen ? (
                  <div
                    className="fontSize-14-lexend-l"
                    style={{
                      color: '#DCDCDC',
                      borderBottom: '1px solid #DCDCDC',
                      paddingBottom: '25px',
                      width: '90%',
                      margin: '0 auto',
                    }}
                  >
                    Sembunyikan Menu
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <Nav
            className={`sidebar-content mt-5 flex-column ${isOpen ? '' : 'align-items-center'}`}
            onSelect={(selectedKey) => stateActiveMenu(selectedKey, false)}
          >
            <div className="heading d-none">Menu</div>
            {!moduleAccess('dashboard') || !accessCheck('dashboard', 'READ') ? (
              <></>
            ) : (
              <Nav.Item className="mb-0">
                <Nav.Link
                  as={Link}
                  to="/"
                  active={menuChoosen === 'dashboard'}
                  eventKey="dashboard"
                  className={
                    menuChoosen === 'dashboard' ? 'v-align-middle active' : 'v-align-middle'
                  }
                >
                  <Image
                    className="icon"
                    src={
                      menuChoosen === 'dashboard'
                        ? icons(iconName.homeActive, process.env.REACT_APP_BRAND)
                        : icons(iconName.homeInactive)
                    }
                  />
                  <div
                    className={
                      menuChoosen === 'dashboard'
                        ? 'd-inline-block ml-3 fontSize-14-lexend-l color-red title-menu'
                        : 'd-inline-block ml-3 fontSize-14-lexend-l color-grey title-menu'
                    }
                  >
                    {translate('home')}
                  </div>
                </Nav.Link>
              </Nav.Item>
            )}
            {!moduleAccess('transfer_money') ||
            !accessCheck('transfer_money', 'READ') ||
            !isParent ? (
              <></>
            ) : (
              <SubMenuComponent
                title={translate('disbursement')}
                icon={
                  menuChoosen === 'domestic' || menuChoosen === 'single_disbursement'
                    ? process.env.REACT_APP_BRAND !== 'LINKQU'
                      ? iconName.transferActive + process.env.REACT_APP_BRAND
                      : iconName.transferActive
                    : iconName.transferInactive
                }
                active={location.pathname.includes('/transfer_money/')}
                changeActive={stateActiveMenu}
                collapseState={closeChildMenu}
                menu={menuChoosen}
                items={[
                  {
                    path: '/transfer_money/domestic',
                    title: translate('domestic'),
                    key: 'domestic',
                    hide: accessCheck('domestic', 'READ'),
                  },
                  {
                    path: '/transaction/single_disbursement',
                    title: translate('withdraw'),
                    key: 'single_disbursement',
                    hide: accessCheck('single_disbursement', 'READ') && isUserPersonal(),
                  },
                  {
                    path: '/transfer_money/international',
                    title: translate('international'),
                    key: 'international',
                    // hide: accessCheck('international', 'READ'),
                    hide:
                      accessCheck('international', 'READ') &&
                      (appAgentUser === 'LI5350WBI' || appAgentUser === 'LI8781PBT'),
                  },
                ]}
                isOpen={isOpen}
              />
            )}
            {!moduleAccess('request_payment') ||
            !accessCheck('request_payment', 'READ') ||
            !appEnableAPI ||
            !statusKyc ? (
              <></>
            ) : (
              <Nav.Item className="mb-0">
                <Nav.Link
                  as={Link}
                  to="/request-payment"
                  active={menuChoosen === 'request_payment'}
                  eventKey="request_payment"
                  className={
                    menuChoosen === 'request_payment' ? 'v-align-middle active' : 'v-align-middle'
                  }
                >
                  <Image
                    className="icon"
                    src={
                      menuChoosen === 'request_payment'
                        ? icons(iconName.terimauangActive, process.env.REACT_APP_BRAND)
                        : icons(iconName.terimauangInactive)
                    }
                  />
                  <div
                    className={
                      menuChoosen === 'request_payment'
                        ? 'd-inline-block ml-3 fontSize-14-lexend-l color-red title-menu'
                        : 'd-inline-block ml-3 fontSize-14-lexend-l color-grey title-menu'
                    }
                  >
                    {translate('requestPayment')}
                  </div>
                </Nav.Link>
              </Nav.Item>
            )}
            {!moduleAccess('data_history') || !accessCheck('data_history', 'READ') ? (
              <></>
            ) : (
              <SubMenuComponent
                title={translate('myHistory')}
                icon={
                  menuChoosen === 'data_transaction' ||
                  menuChoosen === 'data_deposit' ||
                  menuChoosen === 'data_transfer'
                    ? process.env.REACT_APP_BRAND !== 'LINKQU'
                      ? iconName.history + process.env.REACT_APP_BRAND
                      : iconName.history
                    : iconName.historyInactive
                }
                active={location.pathname.includes('/data_history/')}
                changeActive={stateActiveMenu}
                collapseState={closeChildMenu}
                menu={menuChoosen}
                items={[
                  {
                    path:
                      appUserType === 'Personal END USER' ? '/data/transfer' : '/data/transaction',
                    title: translate('historyTransaction'),
                    key: 'data_transfer',
                    hide: accessCheck('data_transfer', 'READ'),
                  },
                  {
                    path: '/data/deposit',
                    title: translate('historyTopup'),
                    key: 'data_deposit',
                    hide:
                      accessCheck('data_deposit', 'READ') &&
                      appEnableAPI &&
                      appUserType !== 'Personal END USER',
                  },
                ]}
                isOpen={isOpen}
              />
            )}
            {!moduleAccess('virtual_account') ||
            !accessCheck('virtual_account', 'READ') ||
            isUserPersonal() ? (
              <></>
            ) : (
              <SubMenuComponent
                title={translate('vaTransaction')}
                icon={
                  menuChoosen === 'virtual_account_transaction' ||
                  menuChoosen === 'bulk_virtual_account'
                    ? process.env.REACT_APP_BRAND !== 'LINKQU'
                      ? iconName.vaActive + process.env.REACT_APP_BRAND
                      : iconName.vaActive
                    : iconName.vaInactive
                }
                active={location.pathname.includes('/virtual_account/')}
                changeActive={stateActiveMenu}
                collapseState={closeChildMenu}
                menu={menuChoosen}
                items={[
                  {
                    path: '/data/virtual_account_transaction',
                    title: translate('vaTransactionHistory'),
                    key: 'virtual_account_transaction',
                    hide: accessCheck('data_virtual_account', 'READ'),
                  },
                  {
                    path: '/register_va/bulk',
                    title: translate('vaTransactionBulk'),
                    key: 'bulk_virtual_account',
                    hide: appEnableAPI && accessCheck('bulk_virtual_account', 'CREATE'),
                  },
                ]}
                isOpen={isOpen}
              />
            )}
            {!moduleAccess('merchant_member') ||
            !accessCheck('merchant_member', 'READ') ||
            isUserPersonal() ? (
              <></>
            ) : (
              <Nav.Item className="mb-0">
                <Nav.Link
                  as={Link}
                  to="/data/merchant_member"
                  active={menuChoosen === 'merchant_member'}
                  eventKey="merchant_member"
                  className={
                    menuChoosen === 'merchant_member' ? 'v-align-middle active' : 'v-align-middle'
                  }
                >
                  <Image
                    className="icon"
                    src={
                      menuChoosen === 'merchant_member'
                        ? icons(iconName.merchantActive, process.env.REACT_APP_BRAND)
                        : icons(iconName.merchantInactive)
                    }
                  />
                  <div
                    className={
                      menuChoosen === 'merchant_member'
                        ? 'd-inline-block ml-3 fontSize-14-lexend-l color-red title-menu'
                        : 'd-inline-block ml-3 fontSize-14-lexend-l color-grey title-menu'
                    }
                  >
                    {translate('merchantMember')}
                  </div>
                </Nav.Link>
              </Nav.Item>
            )}
            {!moduleAccess('report') || appUserType === 'Personal END USER' ? (
              <></>
            ) : (
              <SubMenuComponent
                title={translate('Report')}
                icon={
                  menuChoosen === 'report' || menuChoosen === 'monitor_hold_balance'
                    ? process.env.REACT_APP_BRAND !== 'LINKQU'
                      ? iconName.laporanActive + process.env.REACT_APP_BRAND
                      : iconName.laporanActive
                    : iconName.laporanInactive
                }
                active={location.pathname.includes('/report/')}
                changeActive={stateActiveMenu}
                collapseState={closeChildMenu}
                menu={menuChoosen}
                items={Linkqu.getReportSidebar(appUserGroup, translate, accessCheck, appEnableAPI)}
                isOpen={isOpen}
              />
            )}
            {!moduleAccess('data_settlement') ||
            !accessCheck('data_settlement', 'READ') ||
            !appEnableAPI ||
            appUserType === 'Personal END USER' ? (
              <></>
            ) : (
              <Nav.Item className="mb-0">
                <Nav.Link
                  as={Link}
                  to="/data/settlement_merchant_sent"
                  active={menuChoosen === 'settlement_sent'}
                  eventKey="settlement_sent"
                  className={
                    menuChoosen === 'settlement_sent' ? 'v-align-middle active' : 'v-align-middle'
                  }
                >
                  <Image
                    className="icon"
                    src={
                      menuChoosen === 'settlement_sent'
                        ? icons(iconName.settlementActive, process.env.REACT_APP_BRAND)
                        : icons(iconName.settlementInactive)
                    }
                  />
                  <div
                    className={
                      menuChoosen === 'settlement_sent'
                        ? 'd-inline-block ml-3 fontSize-14-lexend-l color-red title-menu'
                        : 'd-inline-block ml-3 fontSize-14-lexend-l color-grey title-menu'
                    }
                  >
                    {translate('settlement')}
                  </div>
                </Nav.Link>
              </Nav.Item>
            )}
            {!moduleAccess('data_dana_talangan') ||
            !accessCheck('data_dana_talangan', 'READ') ||
            !appEnableAPI ||
            appUserType === 'Personal END USER' ? (
              <></>
            ) : (
              <Nav.Item className="mb-0">
                <Nav.Link
                  as={Link}
                  to="/data/dana_talangan"
                  active={menuChoosen === 'data_dana_talangan'}
                  eventKey="data_dana_talangan"
                  className={
                    menuChoosen === 'data_dana_talangan'
                      ? 'v-align-middle active'
                      : 'v-align-middle'
                  }
                >
                  <Image
                    className="icon"
                    src={
                      menuChoosen === 'data_dana_talangan'
                        ? icons(iconName.danatalanganActive, process.env.REACT_APP_BRAND_NAME)
                        : icons(iconName.danatalanganInactive)
                    }
                  />
                  <div
                    className={
                      menuChoosen === 'data_dana_talangan'
                        ? 'd-inline-block ml-3 fontSize-14-lexend-l color-red title-menu'
                        : 'd-inline-block ml-3 fontSize-14-lexend-l color-grey title-menu'
                    }
                  >
                    Dana Talangan
                  </div>
                </Nav.Link>
              </Nav.Item>
            )}
            {moduleAccess('data_export_data') ? (
              <Nav.Item className="mb-0">
                <Nav.Link
                  as={Link}
                  to="/data/export_data"
                  active={menuChoosen === 'data_export_data'}
                  eventKey="data_export_data"
                  className={
                    menuChoosen === 'data_export_data' ? 'v-align-middle active' : 'v-align-middle'
                  }
                >
                  <Image
                    className="icon"
                    src={
                      menuChoosen === 'data_export_data'
                        ? icons(iconName.unduhInactive, process.env.REACT_APP_BRAND_NAME)
                        : icons(iconName.unduhActive)
                    }
                  />
                  <div
                    className={
                      menuChoosen === 'data_export_data'
                        ? 'd-inline-block ml-3 fontSize-14-lexend-l color-red title-menu'
                        : 'd-inline-block ml-3 fontSize-14-lexend-l color-grey title-menu'
                    }
                  >
                    {translate('exportData')}
                  </div>
                </Nav.Link>
              </Nav.Item>
            ) : null}
            {(appUserType === 'Business' || appUserType === 'Personal H2H') &&
            appEnableAPI &&
            moduleAccess('tools') ? (
              <SubMenuComponent
                title={translate('setting')}
                icon={
                  menuChoosen === 'credential_server' ||
                  menuChoosen === 'data_setting_account_favourite'
                    ? process.env.REACT_APP_BRAND !== 'LINKQU'
                      ? iconName.settingActive + process.env.REACT_APP_BRAND
                      : iconName.settingActive
                    : iconName.settingInactive
                }
                active={location.pathname.includes('/data_history/')}
                changeActive={stateActiveMenu}
                collapseState={closeChildMenu}
                menu={menuChoosen}
                items={[
                  {
                    path: '/tools/credential_server',
                    title: translate('settingCredential'),
                    key: 'credential_server',
                    hide:
                      accessCheck('credential_server', 'READ') &&
                      appEnableAPI &&
                      appUserType !== 'Personal END USER',
                  },
                  {
                    path: '/data/setting_account_favourite',
                    title: translate('settingAccountFavourite'),
                    key: 'data_setting_account_favourite',
                    hide:
                      accessCheck('data_setting_account_favourite', 'READ') &&
                      appEnableAPI &&
                      appUserType !== 'Personal END USER',
                  },
                ]}
                isOpen={isOpen}
              />
            ) : null}
          </Nav>
        </div>
      </>
    )
  } else {
    return null
  }
}

SidebarComponent.defaultProps = {
  appUserName: '',
  appUserID: '',
  appAgentName: '',
  appUserGroup: '',
  appAgentUser: '',
  appUserData: {},
  appUserType: '',
  appStatusReg: {},
  userId: '',
  userPass: '',
  onToggle: null,
  isOpen: false,
  language: '',
  balanceFresh: false,
}

SidebarComponent.propTypes = {
  appUserName: PropTypes.string,
  appUserID: PropTypes.string,
  appAgentName: PropTypes.string,
  appUserGroup: PropTypes.string,
  appAgentUser: PropTypes.string,
  appUserData: PropTypes.object,
  appUserType: PropTypes.string,
  appStatusReg: PropTypes.object,
  userId: PropTypes.string,
  userPass: PropTypes.string,
  onToggle: PropTypes.func,
  isOpen: PropTypes.bool,
  language: PropTypes.string,
  balanceFresh: PropTypes.bool,
}

export default connect(
  (state) => ({
    appUserID: state.Auth[authConstant.USER_ID],
    appEnableAPI: state.Auth[authConstant.ENABLE_API],
    appUserName: state.Auth[authConstant.USER_NAME],
    appAgentName: state.Auth[authConstant.AGENT_NAME],
    appUserGroup: state.Auth[authConstant.USER_GROUP],
    appAgentGroup: state.Auth[authConstant.AGENT_GROUP],
    appAgentUser: state.Auth[authConstant.AGENT_USER],
    appUserData: state.Auth[authConstant.USER_DATA],
    appUserType: state.Auth[authConstant.USER_TYPE],
    appStatusReg: state.Auth[authConstant.STATUS_REG],
    menuChoosen: state.Global[global.MENU_CHOOSEN],
    userId: state.Auth[authConstant.USER_ID],
    userPass: state.Auth[authConstant.USER_PASS],
    language: state.Global[global.LANGUAGE],
    appUserModulAccess: state.RoleAccess[roleAccess.ACCESS],
    appEnvironment: state.Global[global.ENVIRONMENT],
    statusUserParent: state.Auth[authConstant.USER_PARENT],
    statusKyc: state.Auth[authConstant.STATUS_KYC],
    userBalance: state.Auth[authConstant.USER_BALANCE],
    isLogin: state.Global[global.IS_LOGIN],
    appRuleShowBalance: state.Global[global.RULE_SHOW_BALANCE],
  }),
  (dispatch) => ({ dispatch })
)(SidebarComponent)
