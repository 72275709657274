/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { Component } from 'react'
import { Row, Col, ProgressBar, Image } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import './manualInput.scss'
import Formatter from '../../../../helper/formatter'
import authConstant from '../../../../store/constant/auth'
// eslint-disable-next-line
import report from '../../../../api/action/report'
import DateTime from '../../../../helper/datetime'
import icons from '../../../../helper/icons'
import iconName from '../../../../helper/constant/iconName'
import globalConstant from '../../../../store/constant/global'
import translate from '../../../../helper/translate'
import {
  buttonResource,
  columnResource,
  placeholderResource,
  titleSentence,
} from '../../../../helper/translatePage/languageResource'

class TransferPayment extends Component {
  constructor(props) {
    super(props)
    this.state = { detail: false, load: false }

    this.handleDetail = this.handleDetail.bind(this)
  }

  handleDetail() {
    // eslint-disable-next-line
    this.setState({ detail: !this.state.detail })
  }

  handleNext = () => {
    this.props.btnNext(true)
  }

  dateFormat = (timestamp) => {
    const d = new Date(timestamp)
    // eslint-disable-next-line
    return d.getFullYear() + '-'+ (d.getMonth() + 1) + '-' + d.getDate()
  }

  handleBack = () => {
    this.props.onBack('result-inquiry')
  }

  copyText = (copyText) => {
    navigator.clipboard.writeText(copyText)

    /* Alert the copied text */
    // eslint-disable-next-line no-alert
    alert(`Copied the text: ${copyText}`)
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { tabledata, bankChosen, orderInfo, language } = this.props
    const { detail, load } = this.state
    const expDate = DateTime.format(orderInfo.expired, DateTime.FMT_DATE_TIME_YMDHMS3)
    const numbercode =
      orderInfo.channel.channel_type === 'VA'
        ? orderInfo.channel.virtual_account_number
        : orderInfo.channel.account_number
    // eslint-disable-next-line radix
    const total = orderInfo.channel.total_amount
    return (
      <>
        <div className="">
          {load ? (
            <ProgressBar
              style={{ width: '100%' }}
              className="mt-3 mb-3"
              variant="info"
              label="Please Wait..."
              animated
              now={100}
            />
          ) : (
            <>
              <div className="mb-3 mt-3 ml-3 d-flex align-items-center" onClick={this.handleBack}>
                <button type="button" className="btn btn-outline-primary back-button">
                  <Image src={icons(iconName.backButton)} className="" />
                </button>
                <span className="ml-3">
                  <div className="fontSize-16">
                    {translate('transferPaymentInfo1', language, titleSentence)}
                  </div>
                </span>
              </div>
              <div className="container-bill-card">
                <div className="container-inquiry-info mb-5">
                  <div className="header">
                    <div className="info-bank">
                      <img
                        className="image-bank"
                        src={
                          bankChosen.url_image === '' ? icons(iconName.gubuk) : bankChosen.url_image
                        }
                        alt="LOGO CHANNEL BANK"
                      />
                      <div className="fontSize-19 ml-3">
                        <div>{bankChosen.namaChannel}</div>
                        <div className="color-grey">{bankChosen.account_number_name}</div>
                      </div>
                    </div>
                    <div className="info-id text-active fontSize-17-Medium">
                      {translate('transferPaymentInfo3', language, titleSentence)} #
                      {tabledata.id_transaksi}
                    </div>
                  </div>
                  <div className="container-copy-data mb-3">
                    <div className="fontSize-19-Bold copy-data">
                      <div className="text">{numbercode}</div>
                      <div className="button-copy" onClick={() => this.copyText(numbercode)}>
                        <Image src={icons(iconName.copy)} className="" />
                        <div className="ml-2 button-copy-text fontSize-16-Medium color-white">
                          {translate('copy', language, buttonResource)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <Row className="mt-3">
                    <Col className="fontSize-16-SemiBold color-grey">
                      {translate('nominalTransfer', language, placeholderResource)}
                    </Col>
                    <Col align="right" className="fontSize-16-SemiBold">
                      Rp{' '}
                      {orderInfo.kode_voucher === ''
                        ? Formatter.price(orderInfo.amount)
                        : Formatter.price(orderInfo.payment_amount)}
                    </Col>
                  </Row>
                  {orderInfo.channel.channel_type === 'VA' && (
                    <Row className="mt-3">
                      <Col className="fontSize-16-SemiBold color-grey">
                        {translate('serviceFee', language, titleSentence)}
                      </Col>
                      <Col align="right" className="fontSize-16-SemiBold">
                        Rp {orderInfo.service_fee}
                      </Col>
                    </Row>
                  )}
                  {orderInfo.channel.channel_type === 'TRANSFER' && (
                    <Row className="mt-3">
                      <Col className="fontSize-16-SemiBold color-grey">
                        {translate('serviceFees', language, placeholderResource)}
                      </Col>
                      <Col align="right" className="fontSize-16-SemiBold">
                        Rp {orderInfo.channel.unique_amount}
                      </Col>
                    </Row>
                  )}
                  <Row className="mt-3">
                    <Col className="fontSize-16-SemiBold color-grey">
                      {translate('adminFee', language, columnResource)}
                    </Col>
                    <Col align="right" className="fontSize-16-SemiBold">
                      Rp {Formatter.price(orderInfo.feeamount)}
                    </Col>
                  </Row>
                  {orderInfo.kode_voucher !== '' && (
                    <Row className="mt-3">
                      <Col className="fontSize-16-SemiBold color-grey">
                        {translate('voucher', language, placeholderResource)}
                      </Col>
                      <Col align="right" className="fontSize-16-SemiBold">
                        <strong>- </strong>(Rp {Formatter.price(orderInfo?.voucher_amount)})
                      </Col>
                    </Row>
                  )}
                  <div className="container-copy-data mt-3 mb-3">
                    <div className="fontSize-19-Bold copy-data">
                      <div className="text">Rp {Formatter.price(total)}</div>
                      <div className="button-copy" onClick={() => this.copyText(total)}>
                        <Image src={icons(iconName.copy)} className="" />
                        <div className="ml-2 button-copy-text fontSize-16-Medium color-white">
                          {translate('copy', language, buttonResource)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginBottom: 20 }}>
                    <Image src={icons(iconName.alert)} className="" />
                    <div className="color-pink-dark fontSize-16 d-inline ml-4">
                      {translate('topupInfo2', language, titleSentence)}
                    </div>
                  </div>
                </div>
                <div className="footer-title" onClick={this.handleDetail}>
                  <div className="fontSize-16">
                    {translate('transferPaymentInfo2', language, titleSentence)}
                  </div>
                  <Image
                    src={icons(iconName.fullArrowRightActive, process.env.REACT_APP_BRAND_NAME)}
                  />
                </div>
                <div className="detail pt-3">
                  {detail && (
                    <div className="container-inquiry-info mb-5">
                      <div className="image-logo">
                        <Image
                          src={
                            tabledata.url_image !== '' ? tabledata.url_image : icons(iconName.gubuk)
                          }
                          className=""
                        />
                      </div>
                      <Row>
                        <Col sm="6" xs="12">
                          <div className="fontSize-16-Bold text-active">
                            {translate('destinationAccount', language, titleSentence)}
                          </div>
                          <div className="fontSize-16-Bold">
                            {tabledata.no_pelanggan}
                            <br />
                            {tabledata.nama_pelanggan}
                          </div>
                        </Col>
                        <Col sm="6" xs="12">
                          <div className="fontSize-16-Bold text-active">
                            {translate('nominalTransfer', language, placeholderResource)}
                          </div>
                          <div className="fontSize-16-Bold">
                            Rp {Formatter.price(tabledata.nominal)}
                          </div>
                        </Col>
                        <Col sm="6" xs="12">
                          <div className="fontSize-16-Bold text-active mt-3">
                            {translate('news', language, titleSentence)}
                          </div>
                          <div className="fontSize-16-Bold">
                            {tabledata.catatan ? tabledata.catatan : '-'}
                          </div>
                        </Col>
                        <Col sm="6" xs="12">
                          <div className="fontSize-16-Bold text-active mt-3">
                            {translate('adminFee', language, titleSentence)}
                          </div>
                          <div className="fontSize-16-Bold">
                            Rp {Formatter.price(tabledata.nominal_admin)}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )}
                </div>
                <div className=" fontSize-14 text-center">
                  {translate('topupInfo3', language, titleSentence)}
                  {expDate}
                  {' WIB '}
                  {translate('topupInfo3add', language, titleSentence)}
                </div>
                <div
                  className="mt-3 button-change-method fontSize-16-Medium text-active text-center"
                  onClick={this.handleNext}
                >
                  {translate('transferPaymentInfo4', language, titleSentence)}
                </div>
              </div>
            </>
          )}
        </div>
      </>
    )
  }
}

TransferPayment.defaultProps = {
  tabledata: '',
  orderInfo: '',
  bankChosen: '',
  btnNext: '',
  onBack: '',
}

TransferPayment.propTypes = {
  tabledata: PropTypes.string,
  orderInfo: PropTypes.string,
  bankChosen: PropTypes.string,
  btnNext: PropTypes.string,
  onBack: PropTypes.string,
}
export default connect(
  (state) => ({
    appUserData: state.Auth[authConstant.USER_DATA],
    language: state.Global[globalConstant.LANGUAGE],
  }),
  (dispatch) => ({ dispatch })
)(TransferPayment)
