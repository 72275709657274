/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable valid-typeof */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable eqeqeq */
/* eslint-disable no-lonely-if */
/* eslint-disable react/react-in-jsx-scope */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Container, Form, Image, InputGroup, Spinner } from 'react-bootstrap'
import Select from 'react-select'
import Countdown from 'react-countdown'
// eslint-disable-next-line
import './topup.scss'
import ReCAPTCHA from 'react-google-recaptcha'
import BaseScreen from '../../base'
import globalConstant from '../../../store/constant/global'
import auth from '../../../store/constant/auth'
import Formatter from '../../../helper/formatter'
import { topup } from '../../../api/action/transaction'
import AlertDialog from '../../dialog/alert'
import DateTime from '../../../helper/datetime'
import dataBankList from '../../../api/action/dataBankList'
import LogoBRI from '../../../assets/imgs/logo/logo-bank-bri.png'
import LogoMandiri from '../../../assets/imgs/logo/logo-bank-mandiri.png'
import LogoBca from '../../../assets/imgs/logo/logo-bank-bca.png'
import LogoBNI from '../../../assets/imgs/logo/logo-bank-bni.png'
import LogoPermata from '../../../assets/imgs/logo/logo-bank-permata.png'
import checkAccess from '../../../helper/access'
import roleAccess from '../../../store/constant/roleAccess'
import AccessDenied from '../../component/AccessDenied'
import translate from '../../../helper/translate'
import {
  buttonResource,
  headerPageResource,
  placeholderResource,
  titleSentence,
} from '../../../helper/translatePage/languageResource'
import icons from '../../../helper/icons'
import iconName from '../../../helper/constant/iconName'
import getCsrf from '../../../api/action/getCsrf'
import logout from '../../../api/action/logout'

class TopUpCreateScreen extends BaseScreen {
  constructor(props) {
    super(props, ['page--topup'])

    this.state = {
      selectedBank: null,
      nominal: '',
      pin: '',
      alertError: '',
      loading: false,
      bankList: [],
      modal: false,
      dataSuccessTopup: null,
      date: DateTime.parse(new Date(), DateTime.FMT_DATE_YMD).toDate(),
      showPass: false,
      action: 'topup',
      recaptcha: '',
    }

    this.captchaRef = React.createRef().current
  }

  onChangeRecaptcha = (value) => {
    this.setState({ recaptcha: value })
  }

  componentDidMount() {
    this.getBank()
    if (this.props.isTestUser) {
      this.setState({ recaptcha: process.env.REACT_APP_RECAPTCHA_TES })
    }
  }

  modalClosed = () => {
    this.setState({ modal: false, selectedBank: null, nominal: '', pin: '' })
  }

  modalShowed = () => {
    this.setState({ modal: true })
  }

  selectHandle = (e) => {
    this.setState({ selectedBank: e })
  }

  handleNominal = (e) => {
    this.setState({ nominal: Formatter.price(e.target.value) })
  }

  handlePin = (e) => {
    this.setState({ pin: e.target.value })
  }

  showError = (alertError) => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ alertError })
  }

  closeError = () => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ alertError: '' })
  }

  getBank = async () => {
    this.setState({ loading: true })
    const { dispatch } = this.props
    try {
      let banks = await dispatch(dataBankList())
      if (banks.isOK()) {
        banks = banks.getResultAsObject()
        this.setState({ loading: false })
        const bankOptions = banks.data.nonipg[0].data.map((bank) => {
          return {
            value: bank.codeBank,
            label: bank.namaBank,
          }
        })
        this.setState({ bankList: bankOptions })
      } else {
        this.setState({ loading: false })
        this.setState({ bankList: [] })
        this.setState({ alertError: banks.getRD() })
      }
    } catch (error) {
      console.log('check error', error)
      this.setState({ loading: false })
      this.setState({ bankList: [] })
      this.setState({ alertError: 'Something Goes Wrong, Please Contact Us in linkqu.id' })
    }
  }

  copyText = (copyText) => {
    navigator.clipboard.writeText(copyText)

    /* Alert the copied text */
    alert(`Copied the text: ${copyText}`)
  }

  handleShowPass = (type) => {
    switch (type) {
      case 'show':
        this.setState({ showPass: true })
        break
      case 'hidden':
        this.setState({ showPass: false })
        break

      default:
        this.setState({ showPass: false })
        break
    }
  }

  onSubmit = async () => {
    // eslint-disable-next-line no-unused-vars
    const { dispatch, appUserData, token } = this.props
    const { nominal, selectedBank, pin, recaptcha } = this.state

    if (!this.props.isTestUser) {
      this.captchaRef.reset()
    }

    if (selectedBank === null) {
      dispatch({ type: globalConstant.IS_LOADING, payload: false })
      this.showError('Anda belum memilih Bank')
    } else if (nominal === '') {
      dispatch({ type: globalConstant.IS_LOADING, payload: false })
      this.showError('Anda belum memasukkan jumlah nominal')
    } else if (pin === '') {
      dispatch({ type: globalConstant.IS_LOADING, payload: false })
      this.showError('Anda belum memasukkan password Anda')
    } else if (!recaptcha) {
      if (!this.props.isTestUser) {
        dispatch({ type: globalConstant.IS_LOADING, payload: false })
        this.showError('Please fill recatpcha')
      }
    } else {
      let response = await dispatch(getCsrf())
      if (response.isOK()) {
        response = await dispatch(
          topup(
            appUserData.username_agen,
            appUserData.username,
            nominal,
            selectedBank.value,
            this.state.pin,
            recaptcha
          )
        )

        if (response.isOK()) {
          this.setState({
            dataSuccessTopup: response.data.data.konten,
            recaptcha: '',
            modal: true,
            action: 'success',
          })
          this.props.onSuccess('success')
        } else {
          this.setState({ recaptcha: '' })
          if (response.getRD() == null) {
            this.showError('Failed to topup')
          } else {
            this.showError(response.getRD())
          }
        }
      } else {
        dispatch(logout())
      }
    }
  }

  countdownCustomComponent = ({ hours, minutes, seconds }) => {
    return (
      <div className="d-flex align-items-center">
        <div className="hours d-flex flex-column align-items-center">
          <span style={{ fontSize: 22, fontWeight: 'bolder' }}>{hours}</span>
        </div>
        <span style={{ fontSize: 30, fontWeight: 'bold', margin: '0 8px' }}>:</span>
        <div className="hours d-flex flex-column align-items-center">
          <span style={{ fontSize: 22, fontWeight: 'bolder' }}>{minutes}</span>
        </div>
        <span style={{ fontSize: 30, fontWeight: 'bold', margin: '0 8px' }}>:</span>
        <div className="hours d-flex flex-column align-items-center">
          <span style={{ fontSize: 22, fontWeight: 'bolder' }}>{seconds}</span>
        </div>
      </div>
    )
  }

  logo = (bankName) => {
    switch (bankName) {
      case 'BANK MANDIRI':
        return LogoMandiri
      case 'BANK BRI':
        return LogoBRI
      case 'BANK BCA':
        return LogoBca
      case 'BANK BNI':
        return LogoBNI
      case 'BANK PERMATA':
        return LogoPermata

      default:
        return null
    }
  }

  modalSuccess = (data) => {
    return (
      <Container fluid className="p-3 container-success-topup">
        <div className="subcontainer-success-topup">
          <div className="container-flex-space-between">
            <div className="box-info">
              <div className="text text-active fontSize-17">ORDER ID #{data?.idDeposit}</div>
              <div className="color-grey fontSize-16">{`${DateTime.format(
                this.state.date,
                DateTime.FMT_DATE_TIME_YMDHMS2
              )} WIB)`}</div>
            </div>
            <div className="box-status fontSize-17">
              {translate('topupInfoWaitingTransfer', this.props.language, titleSentence)}
            </div>
          </div>
          <div className="container-bank-info">
            <Image src={this.logo(data?.namabank)} />
            <div className="bank-info ml-3">
              <div className="fontSize-19">{data?.namabank}</div>
              <div className="color-grey fontSize-19">{data?.atasNamaRekening}</div>
            </div>
          </div>
          <div className="container-copy-data mb-3">
            {/* eslint-disable-next-line */}
            <div className="fontSize-19-Bold copy-data">
              <div className="text">{data?.noRekening}</div>
              <div className="button-copy" onClick={() => this.copyText(data?.noRekening)}>
                <Image src={icons(iconName.copy)} className="" />
                <div className="ml-2 button-copy-text fontSize-16-Medium color-white">
                  {translate('copy', this.props.language, buttonResource)}
                </div>
              </div>
            </div>
          </div>
          <div className="container-flex-space-between mb-2">
            <div className="color-grey fontSize-16 ">
              {translate('nominalTransfer', this.props.language, placeholderResource)}
            </div>
            <div className="color-grey fontSize-16 ">Rp. {Formatter.price(data?.nominal)}</div>
          </div>
          <div className="container-flex-space-between mb-2">
            <div className="color-grey fontSize-16">
              {translate('serviceFees', this.props.language, placeholderResource)}
            </div>
            <div className="color-grey fontSize-16">Rp. {Formatter.price(data?.nominalUnik)}</div>
          </div>
          <div className="container-copy-data mb-3">
            {/* eslint-disable-next-line */}
            <div className="fontSize-19-Bold copy-data">
              <div className="text">Rp {data?.nominalTransfer}</div>
              <div
                className="button-copy"
                onClick={() => this.copyText(Formatter.price(data?.nominalTransfer))}
              >
                <Image src={icons(iconName.copy)} className="" />
                <div className="ml-2 button-copy-text fontSize-16-Medium color-white">
                  {translate('copy', this.props.language, buttonResource)}
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginBottom: 50 }}>
            <Image src={icons(iconName.alert)} className="" />
            <div className="color-pink-dark fontSize-16 d-inline ml-4">
              {translate('topupInfo2', this.props.language, titleSentence)}
            </div>
          </div>
          <div className="container-expired">
            <Countdown
              // eslint-disable-next-line prettier/prettier
              date={data?.expiredTime}
              renderer={this.countdownCustomComponent}
            />
            <div className=" fontSize-16 d-inline ml-4">
              {translate('topupInfo3', this.props.language, titleSentence)}
              {`${DateTime.format(this.state.date, DateTime.FMT_DATE_TIME_YMDHMS2)} WIB)`}
              {translate('topupInfo3add', this.props.language, titleSentence)}
            </div>
          </div>
        </div>
      </Container>
    )
  }

  createAccess = () => {
    return checkAccess(this.props.appUserAccess, 'topup', 'ADD')
  }

  renderTopup() {
    return (
      <Container fluid className="p-3 container-transfer-topup">
        <div className="fontSize-20 mb-5">
          {translate('manualTf', this.props.language, headerPageResource)}
        </div>
        {!this.createAccess() ? (
          <AccessDenied />
        ) : (
          <>
            <div className="container-split-form-topup">
              <Form.Group className="form-left">
                <Form.Label>
                  {translate('chooseBank', this.props.language, titleSentence)}
                </Form.Label>
                {this.state.loading ? (
                  <>
                    <br />
                    <Spinner size="xs" className="mr-1" animation="border" variant="primary" />
                  </>
                ) : (
                  <Select
                    value={this.state.selectedBank}
                    options={this.state.bankList}
                    onChange={this.selectHandle}
                    placeholder={translate('select', this.props.language, placeholderResource)}
                    className="custom-input fontSize-16"
                  />
                )}
              </Form.Group>
              <Form.Group>
                <Form.Label>Nominal</Form.Label>
                <Form.Control
                  placeholder={translate('inputNominal', this.props.language, titleSentence)}
                  onChange={this.handleNominal}
                  value={this.state.nominal}
                  className="custom-input fontSize-16"
                />
              </Form.Group>
            </div>
            <div className="container-split-form-topup">
              <Form.Group
                controlId="formBasicPassword"
                className="form-password form-left mb-3 mt-2"
              >
                <Form.Label>{translate('accPass', this.props.language, titleSentence)}</Form.Label>
                <InputGroup>
                  <Form.Control
                    placeholder={translate('inputPass', this.props.language, placeholderResource)}
                    type={this.state.showPass ? 'text' : 'password'}
                    onChange={this.handlePin}
                    value={this.state.pin}
                    style={{
                      borderRight: 'none',
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    }}
                    className="custom-input fontSize-16"
                  />
                  {this.state.showPass ? (
                    <InputGroup.Text onClick={() => this.handleShowPass('hidden')}>
                      <Image src={icons(iconName.showPass)} />
                    </InputGroup.Text>
                  ) : (
                    <InputGroup.Text onClick={() => this.handleShowPass('show')}>
                      <Image src={icons(iconName.hidePass)} />
                    </InputGroup.Text>
                  )}
                </InputGroup>
              </Form.Group>
              {!this.props.isTestUser && (
                <ReCAPTCHA
                  ref={(ref) => {
                    this.captchaRef = ref
                  }}
                  className="mt-3"
                  sitekey={process.env.REACT_APP_GRECAPTCHA_KEY}
                  onChange={(e) => {
                    this.onChangeRecaptcha(e, 2)
                  }}
                />
              )}
              <Form.Group>
                <button
                  type="button"
                  className={`btn btn-primary btn-block color-white btn-custom fontSize-17 ${
                    this.props.appIsLoading ? 'disabled' : ''
                  }`}
                  onClick={this.onSubmit}
                >
                  {translate('create', this.props.language, buttonResource)}
                </button>
              </Form.Group>
            </div>
          </>
        )}
      </Container>
    )
  }

  render() {
    const { action } = this.state
    return (
      <>
        {action === 'topup' && this.renderTopup()}
        {action === 'success' && this.modalSuccess(this.state.dataSuccessTopup)}
        <AlertDialog
          show={!!this.state.alertError}
          onHide={this.closeError}
          onConfirm={this.closeError}
          type="warning"
          messageTitle={this.state.alertError}
        />
      </>
    )
  }
}

TopUpCreateScreen.defaultProps = {
  appIsLoading: false,
}

TopUpCreateScreen.propTypes = {
  appIsLoading: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
    language: state.Global[globalConstant.LANGUAGE],
    appUserData: state.Auth[auth.USER_DATA],
    token: state.Auth[auth.TOKEN],
    appUserAccess: state.RoleAccess[roleAccess.ACCESS],
    isTestUser: state.Global[globalConstant.IS_TEST_USER],
  }),
  (dispatch) => ({ dispatch })
)(TopUpCreateScreen)
