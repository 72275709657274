import MessageSender from '../sender'
import BaseMessage from '../message'

export default (idAgen, idOtp, otp, captcha) => async () => {
  const msg = new BaseMessage()

  msg.setPath('/control/generate_setting_callback_url')
  msg.setItem('id_agen', idAgen)
  msg.setItem('id_otp', idOtp)
  msg.setItem('otp', otp)
  msg.setItem('captcha', captcha)

  const response = await new MessageSender().doPost(msg)

  return response
}
