export default {
  USER: 'AUTH_USER',
  CLEAR: 'AUTH_CLEAR',
  USER_ID: 'AUTH_USER_ID',
  USER_NAME: 'AUTH_USER_NAME',
  USER_EMAIL: 'AUTH_USER_EMAIL',
  USER_GROUP: 'AUTH_USER_GROUP',
  USER_DATA: 'AUTH_USER_DATA',
  USER_TYPE: 'AUTH_USER_TYPE',
  AGENT_ID: 'AUTH_AGENT_ID',
  AGENT_NAME: 'AUTH_AGENT_NAME',
  AGENT_USER: 'AUTH_AGENT_USER',
  AGENT_GROUP: 'AUTH_AGENT_GROUP',
  STATUS_REG: 'AUTH_STATUS_REG',
  USER_PARENT: 'AUTH_USER_PARENT',
  STATUS_KYC: 'STATUS_KYC',
  ENABLE_API: 'ENABLE_API',
  USER_PASS: 'USER_PASS',
  USER_BALANCE: 'USER_BALANCE',
  USER_HOLDBALANCE: 'USER_HOLDBALANCE',
}
