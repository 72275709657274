import MessageSender from '../sender'
import BaseMessage from '../message'

export default (idOtp, otp, captcha, email, kode) => async () => {
  const msg = new BaseMessage()

  msg.setPath('/control/create_signature')
  msg.setItem('id_otp', idOtp)
  msg.setItem('otp', otp)
  msg.setItem('captcha', captcha)
  msg.setItem('email_destination', email)
  msg.setItem('kode', kode)

  const response = await new MessageSender().doPost(msg)

  return response
}
