/* eslint-disable no-unused-vars */
import React from 'react'
import { Badge, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Formatter from '../../../helper/formatter'
import translate from '../../../helper/translate'
import { columnResource } from '../../../helper/translatePage/languageResource'
import dataType from '../../../helper/constant/dataType'

const DisbursementDetailColumn = (actionFunction, languange) => ({
  key: 'id',
  columns: [
    {
      name: translate('groupId', languange, columnResource),
      selector: 'group_id',
      sortable: true,
      type: dataType.INTEGER,
      showFilter: false,
    },
    {
      name: translate('groupName', languange, columnResource),
      selector: 'group_name',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('name', languange, columnResource),
      selector: 'customername',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('phone', languange, columnResource),
      selector: 'phone',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: 'Bank',
      selector: 'namabank',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('accNumber', languange, columnResource),
      selector: 'norekeningtujuan',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('amount', languange, columnResource),
      selector: 'nominal',
      sortable: true,
      cell: (row) => Formatter.price(Formatter.format2(row.nominal)),
      type: dataType.INTEGER,
      showFilter: true,
    },
    {
      name: 'Inquiry ID',
      selector: 'id_transaksi',
      type: dataType.INTEGER,
      showFilter: true,
    },
    {
      name: translate('payId', languange, columnResource),
      selector: 'payment_id',
      type: dataType.INTEGER,
      showFilter: true,
    },
    {
      name: translate('processTime', languange, columnResource),
      selector: 'processed_time',
      type: dataType.DATERANGE,
      showFilter: true,
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      cell: (row) => {
        let word
        let color
        switch (row.status) {
          case -2:
            word = 'On Process'
            color = 'warning'
            break
          case -1:
            word = 'Queue'
            color = 'info'
            break
          case 1:
            word = 'Success'
            color = 'success'
            break
          case 0:
            word = 'New'
            color = 'primary'
            break
          case 2:
          default:
            word = 'Failed'
            color = 'danger'
            break
        }
        return <Badge variant={color}>{word}</Badge>
      },
      showFilter: true,
      type: dataType.ENUM,
      default_value: [
        {
          id: '-2',
          name: 'On Process',
        },
        {
          id: '-1',
          name: 'Queue',
        },
        {
          id: '1',
          name: 'Success',
        },
        {
          id: '0',
          name: 'New',
        },
        {
          id: '2',
          name: 'Failed',
        },
      ],
    },
    {
      name: translate('action', languange, columnResource),
      cell: (row) => {
        return (
          <div style={{ flexDirection: 'column' }}>
            <div className="mb-1">
              <Button
                size="sm"
                variant="outline-info"
                className="mr-1"
                data-action="info"
                onClick={(e) => actionFunction(row, e)}
              >
                <FontAwesomeIcon fixedWidth icon="info-circle" />
              </Button>
            </div>
            {`${row.status}` === '1' && (
              <div>
                <Button
                  size="sm"
                  variant="outline-info"
                  className="mr-1"
                  data-action="print"
                  onClick={(e) => actionFunction(row, e)}
                >
                  <FontAwesomeIcon fixedWidth icon="scroll" />
                </Button>
              </div>
            )}
          </div>
        )
      },
      showFilter: false,
    },
  ],
})

export default DisbursementDetailColumn
