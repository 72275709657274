/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-plusplus */
/* eslint-disable eqeqeq */
/* eslint-disable import/named */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/react-in-jsx-scope */
import React from 'react'
import { connect } from 'react-redux'
import { Button, Col, Form, Image, InputGroup, ProgressBar, Row } from 'react-bootstrap'
import PropTypes from 'prop-types'
import Select from 'react-select'

import './manualInput.scss'
import ReCAPTCHA from 'react-google-recaptcha'
import BaseScreen from '../../../base'
import getTransferPurposeList from '../../../../api/action/dataTransferPurposeList'
import authConstant from '../../../../store/constant/auth'
import globalConstant from '../../../../store/constant/global'
import AlertDialog from '../../../dialog/alert'
import AccessDenied from '../../../component/AccessDenied'
import {
  getBank,
  withdrawInquiry,
  checkStatus,
  getDetail,
  getChannelPayment,
  listVoucher,
  getEwalletList,
  ewalletInquiry,
  getTransactionDestionationStored,
  getRetailList,
} from '../../../../api/action/transaction'
// eslint-disable-next-line
import getBankList from '../../../../api/action/dataBankList'
import Formatter from '../../../../helper/formatter'
import checkAccess from '../../../../helper/access'
import InquiryComponent from './inquiryResult'
import ChoosePayment from './choosePayment'
import TransferPayment from './transferPayment'
import roleAccess from '../../../../store/constant/roleAccess'
import translate from '../../../../helper/translate'
import {
  buttonResource,
  headerPageResource,
  placeholderResource,
  sidebarLangResource,
  titleSentence,
} from '../../../../helper/translatePage/languageResource'
import icons from '../../../../helper/icons'
import iconName from '../../../../helper/constant/iconName'
import getCsrf from '../../../../api/action/getCsrf'
import logout from '../../../../api/action/logout'

class ManualInputScreen extends BaseScreen {
  constructor(props) {
    super(props, ['page--withdraw'])

    this.state = {
      isOpen: false,
      listBank: [],
      listEwallet: [],
      listRetail: [],
      senderName: '',
      transferPurpose: '',
      selectedOptionTransferPurpose: null,
      listTransferPurpose: [],
      action: 'inquiry',
      actionInquiry: 'bank',
      transferType: 'bank',
      tabledata: null,
      orderInfo: null,
      nominalbayar: 0,
      biayaadmin: 2500,
      catatan: '',
      produkname: '',
      idtransaksi: 0,
      checksum: '',
      uuidUser: '',
      balanceFresh: false,
      uniqcode: Math.floor(Math.random() * (999 - 100 + 1) + 100),
      bankChosen: '',
      bankCode: '',
      bankNumber: '',
      selectedOption: null,
      banks: [],
      channel: [],
      voucherList: [],
      showPass: false,
      showModal: false,
      isVA: false,
      agree: false,
      destinationStored: [],
      selectedDestinationStored: null,
      showCardOptionTransferPicker: true,
      recaptcha: '',
      cardSubHeaderPicker: [
        {
          iconActive: icons(iconName.iconSaveWhite),
          iconInactive: icons(iconName.save),
          title: translate('transferMoneyCardSubpicker1', this.props.languange, headerPageResource),
          active: false,
          component: 'transfer_before',
          show: false,
        },
        {
          iconActive: icons(iconName.bankCardWhite),
          iconInactive: icons(iconName.bankCard, process.env.REACT_APP_BRAND_NAME),
          title: translate('transferMoneyCardSubpicker2', this.props.languange, titleSentence),
          active: true,
          component: 'bank',
          show: true,
        },
        {
          iconActive: icons(iconName.walletWhite),
          iconInactive: icons(iconName.wallet, process.env.REACT_APP_BRAND_NAME),
          title: translate('transferMoneyCardSubpicker3', this.props.languange, titleSentence),
          active: false,
          component: 'ewallet',
          show: true,
        },
        {
          iconActive: icons(iconName.storeWhite),
          iconInactive: icons(iconName.store, process.env.REACT_APP_BRAND_NAME),
          title: translate('transferMoneyCardSubpicker4', this.props.languange, titleSentence),
          active: false,
          component: 'retail',
          show: true,
        },
      ],
      cardOptionTransferPicker: [
        {
          title: 'Transfer',
          active: true,
          isVa: false,
        },
        {
          title: translate('virtalAccount', this.props.languange, sidebarLangResource),
          active: false,
          isVa: true,
        },
      ],
    }

    this.captchaRef = React.createRef().current
    this.showTransferMethod = this.showTransferMethod.bind(this)
    this.showTransferPayment = this.showTransferPayment.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()

    const { dispatch } = this.props
    const { action } = this.state
    if (this.props.isTestUser) {
      this.setState({ recaptcha: process.env.REACT_APP_RECAPTCHA_TES })
    }

    if (action === 'inquiry') {
      dispatch(getBank()).then((listBank) => {
        this.setState({
          listBank,
        })
      })
      dispatch(getEwalletList()).then((list) => {
        this.setState({
          listEwallet: list,
        })
      })
      dispatch(getRetailList()).then((list) => {
        this.setState({
          listRetail: list,
        })
      })
      this.getListTransferPurpose()
      // this.getTransactionDestionationStored()
      this.getListVoucher()
    }

    if (action === 'checkstatus') {
      this.doCheckStatusInterval()
    }
  }

  onChangeRecaptcha = (value) => {
    this.setState({ recaptcha: value })
  }

  getListTransferPurpose = async () => {
    const { dispatch } = this.props
    try {
      const data = await dispatch(getTransferPurposeList())

      this.setState({
        listTransferPurpose: data.data.data,
      })
    } catch (error) {
      console.error('error', error)
    }
  }

  getTransactionDestionationStored = async () => {
    const { dispatch } = this.props
    try {
      const data = await dispatch(getTransactionDestionationStored())

      this.setState({
        destinationStored: data.data,
      })
    } catch (error) {
      console.error('error', error)
    }
  }

  componentWillUnmount() {
    if (this.checkInterval) {
      clearInterval(this.checkInterval)
    }
  }

  doCheckStatusInterval = () => {
    this.checkInterval = setInterval(this.doCheckStatus, 3000)
  }

  doCheckStatus = async () => {
    const { dispatch } = this.props
    const { idtransaksi, checksum, uuidUser } = this.state

    const response = await dispatch(checkStatus(uuidUser, checksum, idtransaksi))
    if (response.isOK()) {
      if (this.checkInterval) {
        clearInterval(this.checkInterval)
      }
      const { payment_reff: idpayment, id_produk: idproduk } = response.getResultAsObject()
      const response2 = await dispatch(getDetail(uuidUser, checksum, idproduk, idpayment))
      if (response2.isOK()) {
        const { tabledata, produkname } = response2.getResultAsObject()

        this.setState({
          tabledata,
          produkname,
          idpayment,
        })
      } else {
        this.setState({ alertError: response.getRD(), action: 'inquiry' })
      }
    }

    this.setState({ balanceFresh: true })
  }

  handleSelectPurposeList = (selectedOptionTransferPurpose) => {
    this.setState({
      transferPurpose: selectedOptionTransferPurpose.value,
      selectedOptionTransferPurpose,
    })
  }

  doSubmit = async () => {
    const {
      bankCode,
      bankNumber,
      nominal,
      password,
      catatan,
      transferType,
      isVA,
      transferPurpose,
      senderName,
      recaptcha,
    } = this.state

    let error
    if (!bankCode) {
      error = 'Choose destination bank name'
    } else if (!bankNumber) {
      error = 'Enter destination account number'
      // eslint-disable-next-line radix
    } else if (!nominal || parseInt(nominal) <= 0) {
      error = 'Enter transfer amount (must be greater than 0)'
    } else if (!password) {
      error = 'Enter account password'
    } else if (!bankCode && transferType === 'ewallet') {
      error = 'Choose E-Wallet destination'
    } else if (!recaptcha) {
      if (!this.props.isTestUser) {
        error = 'Please fill recatpcha'
      }
    } else if (!/^[0-9-]*$/.test(bankNumber)) {
      error = 'Please check account number'
    }

    if (!this.props.isTestUser) {
      this.captchaRef.reset()
    }

    if (error) {
      this.showError(error)
    } else {
      const { dispatch } = this.props
      let response = await dispatch(getCsrf())
      if (response.isOK()) {
        const inq = transferType === 'bank' ? withdrawInquiry : ewalletInquiry

        response = await dispatch(
          inq(
            bankCode,
            bankNumber.replace(/[^0-9]/g, ''),
            nominal.replace(/[^0-9]/g, ''),
            password,
            catatan,
            isVA,
            transferPurpose,
            senderName,
            recaptcha
          )
        )
        if (response.isOK()) {
          this.props.onChangeState('result-inquiry')
          const {
            tabledata,
            nominal: nominalbayar,
            produkname,
            checksum,
            uuid_user: uuidUser,
            idtransaksi,
            url_image,
          } = response.getResultAsObject()
          // eslint-disable-next-line
          const inquirydata = {
            // eslint-disable-next-line
            catatan: tabledata['CATATAN'],
            // eslint-disable-next-line
            id_transaksi: tabledata['ID TRANSAKSI'],
            // eslint-disable-next-line
            keterangan: tabledata['KETERANGAN'],
            // eslint-disable-next-line
            nama_channel: tabledata['NAMA CHANNEL'],
            // eslint-disable-next-line
            nama_pelanggan: tabledata['NAMA PELANGGAN'],
            // eslint-disable-next-line
            nama_produk: tabledata['NAMA PRODUK'],
            // eslint-disable-next-line
            no_pelanggan: tabledata['NO PELANGGAN'],
            // eslint-disable-next-line
            nominal: tabledata['NOMINAL'],
            // eslint-disable-next-line
            nominal_admin: tabledata['NOMINAL ADMIN'],
            // eslint-disable-next-line
            tanggal_transaksi: tabledata['TANGGAL TRANSAKSI'],
            url_image: url_image,
          }
          await this.getChannelPayment(idtransaksi)
          this.setState({
            tabledata: inquirydata,
            nominalbayar,
            produkname,
            checksum,
            uuidUser,
            idtransaksi,
            action: 'result-inquiry',
            bankChosen: '',
            biayaadmin: tabledata['NOMINAL ADMIN'],
            recaptcha: '',
          })
        } else {
          this.showError(response.getRD())
        }
      } else {
        dispatch(logout())
      }
    }
  }

  doReset = () => {
    this.setState({
      action: 'inquiry',
      bankCode: '',
      bankNumber: '',
      bankName: '',
      nominal: '0',
      password: '',
      pin: '',
    })
  }

  getChannelPayment = async (id_inquiry) => {
    const { dispatch } = this.props
    try {
      const channel = await dispatch(getChannelPayment(id_inquiry))
      this.setState({ channel: channel.data })
    } catch (error) {
      console.error('error', error)
    }
  }

  getListBank = async () => {
    const { dispatch } = this.props
    try {
      const banks = await dispatch(getBankList())
      this.setState({
        banks: banks.data.nonipg && banks.data.nonipg.length ? banks.data.nonipg[0] : null,
      })
    } catch (error) {
      console.error('error', error)
    }
  }

  getListVoucher = async () => {
    const { dispatch } = this.props

    try {
      const voucher = await dispatch(listVoucher())
      if (voucher.data.rc == '00') {
        this.setState({ voucherList: voucher.data.data })
      } else {
        this.setState({ voucherList: null })
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  handleChange = (e) => {
    let value

    if (e.target.name === 'bankNumber') {
      value = Formatter.serial(e.target.value, 24)
    } else if (e.target.name === 'nominal') {
      value = Formatter.price(e.target.value)
    } else {
      value = e.target.value
    }
    this.setState({ [e.target.name]: value })
  }

  handleSelect = (selectedOption) => {
    const allowTransferVA = ['008', '009', '014', '002', '011', '022', '013']
    let isVa = false
    if (allowTransferVA.indexOf(selectedOption.value) !== -1) {
      isVa = true
    }

    this.setState({ bankCode: selectedOption.value, selectedOption, isVa: isVa })
  }

  showTransferMethod() {
    this.setState({ action: 'transfer-method' })
  }

  showTransferPayment(bank, createOrderData) {
    this.setState({ action: 'transfer-payment', bankChosen: bank, orderInfo: createOrderData })
  }

  handleBack = (step) => {
    const { dispatch } = this.props

    if (step === 'inquiry') {
      dispatch(getBank()).then((listBank) => {
        this.setState({
          listBank,
        })
      })
      dispatch(getEwalletList()).then((list) => {
        this.setState({
          listEwallet: list,
        })
      })
      dispatch(getRetailList()).then((list) => {
        this.setState({
          listRetail: list,
        })
      })
      this.getListTransferPurpose()
      // this.getTransactionDestionationStored()
      this.getListVoucher()
    }
    this.props.onChangeState('domestic_page')
    this.setState({ action: step })
  }

  handleBackList = () => {
    window.location.href = '/transaction/transfer'
  }

  handleShowedPass = (action) => {
    switch (action) {
      case 'show':
        this.setState({ showPass: true })
        break
      case 'hidden':
        this.setState({ showPass: false })
        break

      default:
        this.setState({ showPass: false })
        break
    }
  }

  createAccess = () => {
    return checkAccess(this.props.appUserAccess, 'transfer_create', 'ADD')
  }

  handleTransferType = (e) => {
    this.setState({ transferType: e.target.value, bankCode: '', selectedOption: null })
  }

  transferVA = (type, codeBank) => {
    const allowTransferVA = ['008', '009', '014', '002', '011', '022', '013']
    if (type === 'bank') {
      if (allowTransferVA.indexOf(codeBank) !== -1) {
        return true
      }
    }

    return false
  }

  handleTransferNumber(item, dataPicker) {
    const datas = []
    let datasTemp = []
    let isVa = false
    for (const data of dataPicker) {
      datasTemp = data
      if (datasTemp.title == item.title) {
        data.active = true
        isVa = item.isVa
      } else {
        data.active = false
      }
      datas.push(datasTemp)
    }

    this.setState({ cardOptionTransferPicker: datas, isVA: isVa })
  }

  setComponent = (item, dataPicker) => {
    const datas = []
    let datasTemp = []
    let activeData = ''
    let showCardOptionTransferPicker = false
    let transferType = ''
    for (const data of dataPicker) {
      datasTemp = data
      if (datasTemp.component == item.component) {
        datasTemp.active = true
        activeData = item.component
        showCardOptionTransferPicker = datasTemp.component == 'bank'
        transferType = datasTemp.component
      } else {
        datasTemp.active = false
      }
      datas.push(datasTemp)
    }

    this.setState({
      cardSubHeaderPicker: datas,
      actionInquiry: activeData,
      showCardOptionTransferPicker: showCardOptionTransferPicker,
      transferType: transferType,
    })
  }

  // setActive = (item) => {
  //   // let { selectedDestinationStored } = this.state
  //   this.setState({ selectedDestinationStored: item, bankNumber: item.accountnumber })
  // }

  // renderInquiryDestinationStored() {
  //   const {
  //     destinationStored,
  //     selectedDestinationStored,
  //     appIsLoading,
  //     bankNumber,
  //     nominal,
  //     password,
  //     catatan,
  //     isVA,
  //     bankCode,
  //   } = this.state

  //   return (
  //     <div className="container-destination-stored">
  //       <Row>
  //         <Col xs="12" sm="6">
  //           {destinationStored.map((item) => (
  //             <div className="card-payment card-payment-active" onClick={() => this.setActive(item)}>
  //               <Image src={item.url_image} className="" />
  //               <div className="description">
  //                 <div className="title fontSize-17-Bold">{item.accountname}</div>
  //                 <div className="subtitle fontSize-14 color-grey">{item.namabank}</div>
  //                 <div className="subtitle fontSize-14 color-grey">{item.accountnumber}</div>
  //               </div>
  //             </div>
  //           ))}
  //         </Col>
  //         <Col xs="12" sm="6">
  //           {selectedDestinationStored && (
  //             <div className="box-form-inquiry">
  //               <Image src={selectedDestinationStored.url_image} className="" />
  //               <div className="description">
  //                 <div className="title fontSize-17-Bold">
  //                   {selectedDestinationStored.accountname}
  //                 </div>
  //                 <div className="subtitle fontSize-14 color-grey">
  //                   {selectedDestinationStored.namabank}
  //                 </div>
  //                 <div className="subtitle fontSize-14 color-grey">
  //                   {selectedDestinationStored.accountnumber}
  //                 </div>
  //               </div>
  //               <Form.Group className="">
  //                 <Form.Label className="fontSize-16 color-grey">
  //                   {translate('noteOptional', this.props.languange, placeholderResource)}
  //                 </Form.Label>
  //                 <Form.Control
  //                   type="text"
  //                   maxLength="25"
  //                   name="catatan"
  //                   placeholder={translate('note', this.props.languange, placeholderResource)}
  //                   value={catatan}
  //                   onChange={this.handleChange}
  //                   className="custom-input fontSize-16"
  //                 />
  //               </Form.Group>
  //               <Form.Group className="form-password mt-2">
  //                 <Form.Label className="fontSize-16 color-grey">
  //                   {translate('inputPass', this.props.languange, placeholderResource)}
  //                 </Form.Label>
  //                 <InputGroup>
  //                   <Form.Control
  //                     type={this.state.showPass ? 'text' : 'password'}
  //                     maxLength="64"
  //                     name="password"
  //                     placeholder={translate(
  //                       'inputPass',
  //                       this.props.languange,
  //                       placeholderResource
  //                     )}
  //                     value={password}
  //                     onChange={this.handleChange}
  //                     className="custom-input fontSize-16"
  //                   />
  //                   {this.state.showPass ? (
  //                     <InputGroup.Text onClick={() => this.handleShowedPass('hidden')}>
  //                       <Image src={icons(iconName.hidePass)} />
  //                     </InputGroup.Text>
  //                   ) : (
  //                     <InputGroup.Text onClick={() => this.handleShowedPass('show')}>
  //                         <Image src={icons(iconName.showPass)} />
  //                     </InputGroup.Text>
  //                   )}
  //                 </InputGroup>
  //               </Form.Group>
  //               <Button
  //                 type="submit"
  //                 size="md"
  //                 variant="primary"
  //                 block
  //                 disabled={appIsLoading}
  //                 onClick={this.doSubmit}
  //                 className="btn-custom btn-custom-reset-password fontSize-17"
  //               >
  //                 <Image src={icons(iconName.checkSquare)} className="mr-2" />
  //                 {translate('checkSecond', this.props.languange, buttonResource)}
  //               </Button>
  //             </div>
  //           )}
  //         </Col>
  //       </Row>
  //     </div>
  //   )
  // }

  renderInquiryForm() {
    const {
      listBank,
      bankNumber,
      nominal,
      password,
      catatan,
      senderName,
      isVA,
      bankCode,
      selectedOption,
      listEwallet,
      listRetail,
      transferType,
      actionInquiry,
      cardOptionTransferPicker,
      listTransferPurpose,
      selectedOptionTransferPurpose,
      showCardOptionTransferPicker,
    } = this.state

    const { appIsLoading } = this.props
    let label =
      isVA && this.transferVA(transferType, bankCode)
        ? translate('inputAccountNumberVa', this.props.languange, placeholderResource)
        : bankCode == 'IDMTARIKTUNAI'
        ? translate('inputAccountNumberRetail', this.props.languange, placeholderResource)
        : translate('inputAccountNumber', this.props.languange, placeholderResource)

    const options = []
    const ewalletOptions = []
    const retailOptions = []
    if (actionInquiry === 'bank' && listBank && listBank.length) {
      for (let i = 0; i < listBank.length; i++) {
        const option = { value: listBank[i].kodebank, label: listBank[i].namabank }
        options.push(option)
      }
    } else if (actionInquiry === 'ewallet' && listEwallet && listEwallet.length) {
      label = translate('inputAccountNumberEmoney', this.props.languange, placeholderResource)
      for (let i = 0; i < listEwallet.length; i++) {
        const option = { value: listEwallet[i].id_produk, label: listEwallet[i].nama_produk }
        ewalletOptions.push(option)
      }
    } else if (actionInquiry === 'retail' && listRetail && listRetail.length) {
      label = translate('inputAccountNumberRetail', this.props.languange, placeholderResource)
      for (let i = 0; i < listRetail.length; i++) {
        const option = { value: listRetail[i].id_produk, label: listRetail[i].nama_produk }
        retailOptions.push(option)
      }
    }

    const transferPurposeOptions = []
    if (listTransferPurpose && listTransferPurpose.length) {
      for (let i = 0; i < listTransferPurpose.length; i++) {
        const option = { value: listTransferPurpose[i].id, label: listTransferPurpose[i].name }
        transferPurposeOptions.push(option)
      }
    }

    return (
      <>
        {this.transferVA(transferType, bankCode) ? (
          <Row>
            {showCardOptionTransferPicker &&
              cardOptionTransferPicker.map((item) => (
                <Col
                  key={item.component}
                  md="3"
                  sm="6"
                  xs="12"
                  className="mb-2"
                  onClick={() => this.handleTransferNumber(item, cardOptionTransferPicker)}
                >
                  <div
                    className={
                      item.active ? 'card-subpicker card-subpicker-active' : 'card-subpicker'
                    }
                  >
                    <div className="d-inline-block">
                      <div
                        className={
                          item.active ? 'color-white fontSize-17' : 'text-active fontSize-17'
                        }
                      >
                        {item.title}
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
          </Row>
        ) : (
          <></>
        )}
        <div className="transfer">
          <Row>
            <Col sm="6" xs="12">
              {actionInquiry === 'bank' && (
                <div className="width-95">
                  <label className="fontSize-16 color-grey">
                    {translate('chooseBank', this.props.languange, placeholderResource)}
                  </label>
                  <Select
                    options={options}
                    onChange={this.handleSelect}
                    value={selectedOption}
                    placeholder={translate('select', this.props.languange, placeholderResource)}
                    className="custom-input fontSize-16"
                  />
                </div>
              )}
              {actionInquiry === 'ewallet' && (
                <div className="width-95">
                  <label className="fontSize-16 color-grey">
                    {translate('chooseEmoney', this.props.languange, placeholderResource)}
                  </label>
                  <Select
                    options={ewalletOptions}
                    onChange={this.handleSelect}
                    value={selectedOption}
                    placeholder={translate('select', this.props.languange, placeholderResource)}
                    className="custom-input fontSize-16"
                  />
                </div>
              )}
              {actionInquiry === 'retail' && (
                <div className="width-95">
                  <label className="fontSize-16 color-grey">
                    {translate('chooseRetail', this.props.languange, placeholderResource)}
                  </label>
                  <Select
                    options={retailOptions}
                    onChange={this.handleSelect}
                    value={selectedOption}
                    placeholder={translate('select', this.props.languange, placeholderResource)}
                    className="custom-input fontSize-16"
                  />
                </div>
              )}
            </Col>
            <Col sm="6" xs="12">
              <Form.Group className="">
                <Form.Label className="fontSize-16 color-grey">{label}</Form.Label>
                <Form.Control
                  type="text"
                  name="bankNumber"
                  maxLength="24"
                  placeholder={label}
                  value={bankNumber}
                  onChange={this.handleChange}
                  className="custom-input fontSize-16"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm="6" xs="12">
              <Form.Group className="width-95">
                <Form.Label className="fontSize-16 color-grey">
                  {translate('nominalTransfer', this.props.languange, placeholderResource)}
                </Form.Label>
                <Form.Control
                  type="text"
                  maxLength="15"
                  name="nominal"
                  placeholder={translate('inputNominal', this.props.languange, placeholderResource)}
                  value={nominal}
                  onChange={this.handleChange}
                  className="custom-input fontSize-16"
                />
              </Form.Group>
            </Col>
            <Col sm="6" xs="12">
              <Form.Group>
                <Form.Label className="fontSize-16 color-grey">
                  {translate('senderName', this.props.languange, placeholderResource)}
                </Form.Label>
                <Form.Control
                  type="text"
                  maxLength="15"
                  name="senderName"
                  placeholder={translate('senderName', this.props.languange, placeholderResource)}
                  value={senderName}
                  onChange={this.handleChange}
                  className="custom-input fontSize-16"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm="6" xs="12">
              <Form.Group className="width-95">
                <Form.Label className="fontSize-16 color-grey">
                  {translate('noteOptional', this.props.languange, placeholderResource)}
                </Form.Label>
                <Form.Control
                  type="text"
                  maxLength="25"
                  name="catatan"
                  placeholder={translate('note', this.props.languange, placeholderResource)}
                  value={catatan}
                  onChange={this.handleChange}
                  className="custom-input fontSize-16"
                />
              </Form.Group>
            </Col>
            <Col sm="6" xs="12">
              <div>
                <label className="fontSize-16 color-grey">
                  {translate('senderGoal', this.props.languange, placeholderResource)}
                </label>
                <Select
                  options={transferPurposeOptions}
                  onChange={this.handleSelectPurposeList}
                  value={selectedOptionTransferPurpose}
                  placeholder={translate('select', this.props.languange, placeholderResource)}
                  className="custom-input fontSize-16"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm="6" xs="12">
              <Form.Group className="form-password mt-2">
                <Form.Label className="fontSize-16 color-grey">
                  {translate('inputPass', this.props.languange, placeholderResource)}
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    type={this.state.showPass ? 'text' : 'password'}
                    maxLength="64"
                    name="password"
                    placeholder={translate('inputPass', this.props.languange, placeholderResource)}
                    value={password}
                    onChange={this.handleChange}
                    className="custom-input fontSize-16"
                  />
                  {this.state.showPass ? (
                    <InputGroup.Text onClick={() => this.handleShowedPass('hidden')}>
                      <Image src={icons(iconName.showPass)} />
                    </InputGroup.Text>
                  ) : (
                    <InputGroup.Text onClick={() => this.handleShowedPass('show')}>
                      <Image src={icons(iconName.hidePass)} />
                    </InputGroup.Text>
                  )}
                </InputGroup>
              </Form.Group>
            </Col>
            <Col sm="6" xs="12">
              {!this.props.isTestUser && (
                <ReCAPTCHA
                  ref={(ref) => {
                    this.captchaRef = ref
                  }}
                  sitekey={process.env.REACT_APP_GRECAPTCHA_KEY}
                  onChange={(e) => {
                    this.onChangeRecaptcha(e, 2)
                  }}
                />
              )}
            </Col>
            <Col sm="6" xs="12">
              <Button
                type="submit"
                size="md"
                block
                disabled={appIsLoading}
                onClick={this.doSubmit}
                className="btn-custom btn-custom-reset-password fontSize-17"
              >
                <Image src={icons(iconName.checkSquare)} className="mr-2" />
                {translate('checkSecond', this.props.languange, buttonResource)}
              </Button>
            </Col>
          </Row>
        </div>
      </>
    )
  }

  renderInquiry() {
    const { actionInquiry, cardSubHeaderPicker } = this.state
    return (
      <div className="subcontainer-inquiry-transfer-money mt-5 ml-3">
        <div className="title-subcontainer fontSize-16-Medium mb-3">
          {translate('transferDestination', this.props.languange, headerPageResource)}
        </div>
        <Row>
          {cardSubHeaderPicker.map((item) => {
            if (item.show) {
              return (
                <Col
                  key={item.component}
                  md="3"
                  sm="6"
                  xs="12"
                  className="mb-2"
                  onClick={() => this.setComponent(item, cardSubHeaderPicker)}
                >
                  <div
                    className={
                      item.active ? 'card-subpicker card-subpicker-active' : 'card-subpicker'
                    }
                  >
                    <Image
                      src={item.active ? item.iconActive : item.iconInactive}
                      className="d-inline-block icon-button mr-2"
                    />
                    <div className="d-inline-block">
                      <div
                        className={
                          item.active ? 'color-white fontSize-17' : 'text-active fontSize-17'
                        }
                      >
                        {item.title}
                      </div>
                    </div>
                  </div>
                </Col>
              )
            }
            return <></>
          })}
        </Row>
        <hr />
        {actionInquiry !== 'transfer_stored' ? this.renderInquiryForm() : <></>}
        {/* {actionInquiry === 'transfer_stored' ? this.renderInquiryDestinationStored() : <></>} */}
      </div>
    )
  }

  render() {
    const { appIsLoading } = this.props
    const {
      action,
      alertError,
      // balanceFresh,
      tabledata,
      uniqcode,
      biayaadmin,
      bankChosen,
      banks,
      channel,
      bankNumber,
      bankCode,
      voucherList,
      transferType,
    } = this.state
    return (
      <>
        <div className="main-form container-inquiry-transfer-money">
          {!this.createAccess() ? (
            <AccessDenied />
          ) : (
            <>
              {appIsLoading && (
                <ProgressBar
                  style={{ width: '100%' }}
                  className="mt-3 mb-3"
                  variant="info"
                  label="Please Wait..."
                  animated
                  now={100}
                />
              )}
              {action === 'inquiry' && this.renderInquiry()}
              {action === 'result-inquiry' && (
                <InquiryComponent
                  tableData={tabledata}
                  transferType={transferType}
                  onBack={this.handleBack}
                  btnNext={this.showTransferMethod}
                  action={action}
                  nextBtn="true"
                />
              )}
              {action === 'transfer-method' && (
                <>
                  {/* eslint-disable-next-line */}
                  <ChoosePayment
                    onSelect={this.showTransferPayment}
                    onBack={this.handleBack}
                    banks={banks}
                    channel={channel}
                    tabledata={tabledata}
                    uniqcode={uniqcode}
                    biayaadmin={biayaadmin}
                    rekeningTujuan={bankNumber}
                    bankChoosen={bankCode}
                    voucherList={voucherList}
                    transferType={transferType}
                  />
                </>
              )}
              {action === 'transfer-payment' && (
                <TransferPayment
                  tabledata={tabledata}
                  uniqcode={uniqcode}
                  biayaadmin={biayaadmin}
                  bankChosen={bankChosen}
                  transferType={transferType}
                  orderInfo={this.state.orderInfo}
                  btnNext={this.showTransferMethod}
                  onBack={this.handleBack}
                />
              )}
            </>
          )}
        </div>
        <AlertDialog
          show={!!alertError}
          onHide={this.closeError}
          onConfirm={this.closeError}
          type="warning"
          messageTitle={alertError}
        />
      </>
    )
  }
}

ManualInputScreen.defaultProps = {
  appIsLoading: false,
}

ManualInputScreen.propTypes = {
  appIsLoading: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
    appUserPass: state.Auth[authConstant.USER_PASS],
    appUserAccess: state.RoleAccess[roleAccess.ACCESS],
    languange: state.Global[globalConstant.LANGUAGE],
    isTestUser: state.Global[globalConstant.IS_TEST_USER],
  }),
  (dispatch) => ({ dispatch })
)(ManualInputScreen)
