/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable eqeqeq */
import React from 'react'
import { connect } from 'react-redux'
import { Container, Row, Col, Image } from 'react-bootstrap'
import PropTypes from 'prop-types'
import './domestic.scss'
import BaseScreen from '../../base'
import globalConstant from '../../../store/constant/global'
import auth from '../../../store/constant/auth'

import roleAccess from '../../../store/constant/roleAccess'
import checkAccess from '../../../helper/access'
import AccessDenied from '../../component/AccessDenied'
import translate from '../../../helper/translate'
import { sidebarLangResource, titleSentence } from '../../../helper/translatePage/languageResource'
import icons from '../../../helper/icons'
import iconName from '../../../helper/constant/iconName'
import ManualInput from './manual/manualInput'
import Bulk from './bulk'

class DomesticScreen extends BaseScreen {
  constructor(props) {
    super(props, ['page--transfer'])
    this.state = {
      balanceFresh: false,
      isParent: this.props.appUserData?.id_user_parent == null,
      cardHeaderPicker: [
        {
          icon: icons(iconName.cursorInputActive, process.env.REACT_APP_BRAND_NAME),
          title: translate('manualInput', this.props.languange, titleSentence),
          description: translate('manualInputCardSubtitle', this.props.languange, titleSentence),
          active: true,
          component: 'manual_input',
          show: this.createAccessTransferMoney(),
        },
        {
          icon: icons(iconName.bulkTransferActive, process.env.REACT_APP_BRAND_NAME),
          title: translate('bulkCardTitle', this.props.languange, titleSentence),
          description: translate('bulkCardSubtitle', this.props.languange, titleSentence),
          active: false,
          component: 'bulk',
          show: this.createAccessBulk(),
        },
      ],
      activeComponent: 'manual_input',
      action: 'domestic_page',
    }
  }

  componentDidMount() {
    super.componentDidMount()
  }

  readAccess = () => {
    return checkAccess(this.props.appUserAccess, 'domestic', 'READ')
  }

  createAccessTransferMoney = () => {
    return checkAccess(this.props.appUserAccess, 'create_transaction_transfer_money', 'ADD')
  }

  createAccessBulk = () => {
    return checkAccess(this.props.appUserAccess, 'create_transaction_transfer_bulk', 'ADD')
  }

  translation = (key, resource) => {
    return translate(key, this.props.languange, resource)
  }

  setComponent = (item) => {
    const { cardHeaderPicker } = this.state
    let { activeComponent } = this.state
    const datas = []
    let datasTemp = []
    for (const data of cardHeaderPicker) {
      datasTemp = data
      if (datasTemp.component == item.component) {
        data.active = true
        activeComponent = item.component
      } else {
        data.active = false
      }
      datas.push(datasTemp)
    }

    this.setState({ cardHeaderPicker: datas, activeComponent: activeComponent })
  }

  getActionFromChild = (action) => {
    this.setState({ action: action })
  }

  render() {
    this.props.dispatch({
      type: globalConstant.HEADER_TITLE,
      payload: translate('domestic', this.props.language, sidebarLangResource),
    })
    // eslint-disable-next-line
    const { cardHeaderPicker, activeComponent, alertError, balanceFresh, action } = this.state
    return (
      <>
        {!this.readAccess() ? (
          <AccessDenied />
        ) : (
          <>
            <Container fluid className="p-3">
              {action === 'domestic_page' ? (
                <div className="container-domestic">
                  <div className="fontSize-20 ml-2">
                    {this.translation('domesticTitle1', titleSentence)}
                  </div>
                  <div className="fontSize-16 ml-2">
                    {this.translation('domesticTitle2', titleSentence)}
                  </div>
                  <div className="d-flex container-subtitle ml-2">
                    <Image src={icons(iconName.alertGrey)} className="mr-3" />
                    <div>
                      <div className="fontSize-14-Regular color-grey">
                        {this.translation('domesticSubtitle1', titleSentence)}
                      </div>
                      <div className="fontSize-14-Regular color-grey">
                        {this.translation('domesticSubtitle2', titleSentence)}
                      </div>
                      <div className="fontSize-14-Regular color-grey">
                        {this.translation('depoTf3', titleSentence)}
                      </div>
                    </div>
                  </div>
                  <Row className="mt-4">
                    {cardHeaderPicker.map((item) => {
                      if (item.show) {
                        return (
                          <Col
                            key={item.component}
                            md="6"
                            sm="12"
                            className="mb-2"
                            onClick={() => this.setComponent(item)}
                          >
                            <div
                              className={
                                item.active ? 'card-picker card-picker-active' : 'card-picker'
                              }
                            >
                              <div className="d-inline-block container-icon">
                                <Image src={item.icon} className="d-inline-block icon-button" />
                              </div>
                              <div className="d-inline-block">
                                <div className="title fontSize-18-Bold">{item.title}</div>
                                <div className="description fontSize-16-Medium color-grey">
                                  {item.description}
                                </div>
                              </div>
                              <Image
                                src={
                                  item.active
                                    ? icons(
                                        iconName.fullArrowRightActive,
                                        process.env.REACT_APP_BRAND_NAME
                                      )
                                    : icons(iconName.arrowRight)
                                }
                                className="d-inline-block icon-button"
                              />
                            </div>
                          </Col>
                        )
                      }

                      return <></>
                    })}
                  </Row>
                </div>
              ) : (
                <></>
              )}
              {activeComponent === 'manual_input' ? (
                <ManualInput onChangeState={this.getActionFromChild} />
              ) : (
                <></>
              )}
              {activeComponent === 'bulk' ? <Bulk /> : <></>}
            </Container>
          </>
        )}
      </>
    )
  }
}

DomesticScreen.defaultProps = {
  appIsLoading: false,
}

DomesticScreen.propTypes = {
  appIsLoading: PropTypes.bool,
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
    appUserAccess: state.RoleAccess[roleAccess.ACCESS],
    languange: state.Global[globalConstant.LANGUAGE],
    usernameAgen: state.Auth[auth.AGENT_USER],
    appEnableAPI: state.Auth[auth.ENABLE_API],
    appUserData: state.Auth[auth.USER_DATA],
  }),
  (dispatch) => ({ dispatch })
)(DomesticScreen)
