import MessageSender from '../sender'
import BaseMessage from '../message'

export default (path, period = '', type = '') => async () => {
  const msg = new BaseMessage()
  msg.setPath(`report/${path}`)
  msg.setItem('period', period)
  msg.setItem('type', type)

  const response = await new MessageSender().doGet(msg)

  if (response.isOK()) {
    return response.getResultAsObject()
  }

  return []
}
