/* eslint-disable react/no-danger */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/react-in-jsx-scope */
import DOMPurify from 'dompurify'
import DateTime from '../../../helper/datetime'
import translate from '../../../helper/translate'
import { columnResource } from '../../../helper/translatePage/languageResource'

const htmlContent = (content) => {
  return { __html: DOMPurify.sanitize(content) }
}

const requestPaymentListCol = (language) => ({
  column: [
    {
      name: translate('paymentName', language, columnResource),
      selector: (row) => row.namePayment,
      sortable: true,
    },
    {
      name: translate('paymentDesc', language, columnResource),
      cell: (row) => <div dangerouslySetInnerHTML={htmlContent(row.descPayment)}></div>,
      sortable: false,
    },
    {
      name: translate('paymentAmount', language, columnResource),
      selector: (row) => row.amount,
      sortable: true,
    },
    {
      name: translate('limit', language, columnResource),
      selector: (row) => `${row.count}/${row.number}`,
      sortable: false,
    },
    {
      name: 'Total',
      selector: (row) => row.total,
      sortable: true,
    },
    {
      name: 'Via',
      selector: (row) => row.via,
      sortable: true,
    },
    {
      name: translate('createDate', language, columnResource),
      selector: 'timeRequest',
      sortable: true,
      cell: (row) => (
        <span>{DateTime.format(row.timeRequest, DateTime.FMT_DATE_TIME_YMDHMS3)}</span>
      ),
    },
    {
      name: translate('paymentDue', language, columnResource),
      selector: 'timeExpired',
      sortable: true,
      cell: (row) => {
        const isExpired = row.timeExpired < DateTime.today(DateTime.FMT_DATE_TIME_YMDHMS)
        return (
          <>
            {isExpired ? (
              <span className="text-primary">
                {translate('expAt', language, columnResource)}
                <br />
                {DateTime.format(row.timeExpired, DateTime.FMT_DATE_TIME_YMDHMS3)}
              </span>
            ) : (
              <span>{DateTime.format(row.timeExpired, DateTime.FMT_DATE_TIME_YMDHMS3)}</span>
            )}
          </>
        )
      },
    },
  ],
})

export default requestPaymentListCol
