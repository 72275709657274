/* eslint-disable prettier/prettier */
/* eslint-disable import/prefer-default-export */
export const resetPasswordLangResource = {
  en: {
    title: "Change Password",
    labelOld: "Old Password",
    labelNew: "New Password",
    labelConfirm: "Confirm Password",
    placeOld: "Enter old password",
    placeNew: "Enter new password",
    placeConfirm: "Repeat new password",
  },
  id: {
    title: "Ubah Kata Sandi",
    labelOld: "Kata Sandi Lama",
    labelNew: "Kata Sandi Baru",
    labelConfirm: "Konfirmasi Kata Sandi",
    placeOld: "Masukkan kata sandi lama",
    placeNew: "Masukkan kata sandi baru",
    placeConfirm: "Ulangi kata sandi baru",
  },
};

export const sidebarLangResource = {
  en: {
    setting : "Settings",
    settingCredential : "Credential",
    settingAccountFavourite : "Account Favourite",
    daily : "Daily",
    monitoring : "Monitoring",
    home: "Home",
    accountStatement: "Account Statement",
    disbursement: "Send Money",
    myHistory: "My History",
    historyTransaction: "Transaction History",
    historyTopup: "Topup history",
    payment: "Payment",
    transferMoney: "Money Transfer",
    domestic: "Domestic",
    international: "International",
    domesticTransfer: "Domestic Transfers",
    singleTransfer: "Balance Transfer",
    bulkTransfer: "Bulk Balance Transfer",
    bulkTransferData: "Bulk Balance Transfer Data",
    transferAPI: "Transaction Data",
    transferData: "Transfer Data",
    virtualAccount: "Virtual Account",
    requestPayment: "Receive Money",
    createPaymentCheckout: "Create Payment Checkout",
    createQRIS: "Create QRIS",
    QRIS: "QRIS",
    Report: "Report",
    dailySuccessTransaction: "Daily Successful Transactions",
    daily_success_trx: "Daily Success",
    dailyOmzet: "Daily Turnover",
    dailyCallback: "Daily Callback",
    daily_omzet: "Daily turnover",
    daily_callback: "Daily callback",
    account: "Account",
    myProfile: "My Account",
    myData: "My Data",
    changePassword: "Change Password",
    accountManagement: "Account Management",
    systemSetting: "System Settings",
    callbacks: "Callback URLs",
    credential: "Credential Server",
    vaReserved: "Virtual Account Saved",
    vaDescriptionReserved: "View transaction data during the VA you are using",
    vaDescriptionTransaction: "View the virtual account data you saved",
    vaTransaction: "Virtual Account",
    vaTransactionBulk: "Virtual Account Bulk",
    vaTransactionHistory: "Virtual Account History",
    monitorHoldBalance: "Monitoring Hold Balance",
    monitorCallback: "Monitoring Callback",
    settlementProcess: "Settlement Merchant In Process",
    settlementSent: "Settlement Merchant Sent",
    settlementDescriptionProcess: "View your merchant settlement data that is still in process",
    settlementDescriptionSent: "View your sent merchant settlement data",
    settlement: "Settlements",
    merchantMember: "Build Merchant",
    dataMember: "Build Merchant Data",
    reportMember: "Built Merchant Graph",
    aggregatorFeeDaily: "Daily Revenue Sharing",
    aggregatorFeeMonthly: "Monthly Revenue Sharing",
    resumeShareProfitByMitraDaily: "Resumes of Daily Profit Sharing per Partner",
    resumeShareProfitByMitraMonthly: "Monthly Profit Sharing resume per Partner",
    reportTransactionPaymentSuccessByAgent : "Payment Transaction Success By Agent",
    reportTransactionPaymentSuccessByProduct : "Payment Transaction Success By Product",
    saldo : "Deposit Balance",
    saldoPersonal : "Unique Nominal Remaining Funds",
    withdraw: "Withdraw Balance",
    exportData: "Download Data",
  },
  id: {
    exportData: "Unduh Data",
    withdraw: "Tarik Saldo",
    setting : "Pengaturan",
    settingCredential : "Credential",
    settingAccountFavourite : "Nomor Rekening Favorit",
    daily : "Daily",
    monitoring : "Monitoring",
    home: "Beranda",
    accountStatement: "Laporan Rekening",
    disbursement: "Kirim Uang",
    myHistory: "Riwayat Saya",
    historyTransaction: "Riwayat Transaksi",
    historyTopup: "Riwayat Topup",
    payment: "Pembayaran",
    transferMoney: "Transfer Uang",
    domestic: "Domestik",
    international: "Internasional",
    domesticTransfer: "Domestik Transfer",
    singleTransfer: "Transfer Saldo",
    bulkTransfer: "Transfer Saldo Bulk",
    bulkTransferData: "Data Transfer Saldo Bulk",
    transferAPI: "Transaction Data",
    transferData: "Data Transfer",
    virtalAccount: "Akun Virtual",
    requestPayment: "Terima Uang",
    createPaymentCheckout: "Buat Pembayaran Checkout",
    createQRIS: "Buat QRIS",
    QRIS: "QRIS",
    Report: "Laporan",
    dailySuccessTransaction: "Transaksi Sukses Harian",
    daily_success_trx: "Sukses Harian",
    dailyOmzet: "Omzet Harian",
    dailyCallback: "Callback Harian",
    daily_omzet: "Omzet Harian",
    daily_callback: "Callback Harian",
    account: "Akun",
    myProfile: "Akun Saya",
    myData: "Data Saya",
    changePassword: "Ubah Password",
    accountManagement: "Manajemen Akun",
    systemSetting: "Pengaturan Sistem",
    callback: "URL Callback",
    credential: "Credential Server",
    vaReserved: "Virtual Account Disimpan",
    vaDescriptionReserved: "Lihat data transaksi yang selama VA yang Anda gunakan",
    vaDescriptionTransaction: "Lihat data virtual account yang Anda simpan",
    vaTransaction: "Akun Virtual",
    vaTransactionBulk: "Bulk Akun Virtual",
    vaTransactionHistory: "Riwayat Akun Virtual",
    monitorHoldBalance: "Monitoring Saldo Ditahan",
    monitorCallback: "Monitoring Callback",
    settlementProcess: "Settlement Merchant Dalam Proses",
    settlementSent: "Settlement Merchant Terkirim",
    settlementDescriptionProcess: "Lihat data settlement merchant Anda yang masih dalam proses",
    settlementDescriptionSent: "Lihat data settlement merchant Anda yang terkirim",
    settlement: "Settlement",
    merchantMember: "Merchant Binaan",
    dataMember: "Data Merchant Binaan",
    reportMember: "Grafik Merchant Binaan",
    aggregatorFeeDaily: "Bagi Hasil Harian",
    aggregatorFeeMonthly: "Bagi Hasil Bulanan",
    resumeShareProfitByMitraDaily: "Resume Bagi Hasil Harian per Mitra",
    resumeShareProfitByMitraMonthly: "Resume Bagi Hasil Bulanan per Mitra",
    reportTransactionPaymentSuccessByAgen : "Transaksi Pembayaran Sukses Oleh Agen",
    reportTransactionPaymentSuccessByProduct : "Transaksi Pembayaran Sukses Oleh Produk",
    saldo : "Saldo Simpanan",
    saldoPersonal : "Sisa Dana Nominal Unik",
  },
};

export const accountLangResource = {
  en: {
    title: "My Account",
    accountType: "Account Type:",
    personalPhone: "Phone Number:",
    personalBirth: "Date of Birth:",
    personalAddress: "Address:",
    titleCompany: "Company",
    companyName: "Name:",
    companyAddress: "Address:",
    companyPhone: "No. Phone:",
    titleOwner: "Owner",
    ownerName: "Name:",
    ownerPhone: "Phone Number:",
    titleCP: "Representative",
    cpName: "Name:",
    cpPosition: "Title:",
    cpPhone: "Phone Number:",
    titleBank: "Bank Account",
    bankName: "Name:",
    bankBranch: "Branch:",
    bankAccNumb: "Account Number:",
    bankAccName: "Account Name:",
    affiliationCopy: "Copy URL",
    affiliationTitle: "Affiliate Web URL:",
    affiliationandroid: "Affiliate Android URL:",
    referralCopy: "Copy Code",
    referralTitle: "Referral Code:",
    myData: "My Data",
    changePassword: "Change Password",
    accountManagement: "Account Management",
    textUpgrade: `Continue verification to enjoy all ${process.env.REACT_APP_BRAND_NAME} Bisnis features`,
    buttonUpgrade: "Continue Verification",
    benefit1: 'API Access',
    benefit2: 'Unlimited Cash Out',
    benefit3: 'Unlimited Disbursement API Access Maximum Transaction Amount: 50,000,000/Transaction',
    benefit4: 'Best price',
    benefit5: 'Priority Support',
    website: 'Website:',
    ownerCompany: 'Company Owner',
    representativeCompany: 'Company Representative',
  },
  id: {
    title: "Akun Saya",
    accountType: "Jenis Akun:",
    personalPhone: "Nomor Telpon:",
    personalBirth: "Tanggal Lahir:",
    personalAddress: "Alamat:",
    titleCompany: "Perusahaan",
    companyName: "Nama:",
    companyAddress: "Alamat:",
    companyPhone: "No. Telpon:",
    titleOwner: "Pemilik",
    ownerName: "Nama:",
    ownerPhone: "Nomor Telpon:",
    titleCP: "Perwakilan",
    cpName: "Nama:",
    cpPosition: "Jabatan:",
    cpPhone: "Nomor Telpon:",
    titleBank: "Akun Bank",
    bankName: "Nama:",
    bankBranch: "Cabang:",
    bankAccNumb: "Nomor Rekening:",
    bankAccName: "Nama Rekening:",
    affiliationCopy: "Salin URL",
    affiliationTitle: "URL Afiliasi Web:",
    affiliationAndroid: "URL Afiliasi Android:",
    referralCopy: "Salin Kode",
    referralTitle: "Kode Referral:",
    myData: "Data Saya",
    changePassword: "Ubah Password",
    accountManagement: "Manajemen Akun",
    textUpgrade: `Lanjutkan verifikasi untuk menikmati seluruh fitur ${process.env.REACT_APP_BRAND_NAME} Bisnis`,
    buttonUpgrade: "Lanjutkan Verifikasi",
    benefit1: 'Akses API',
    benefit2: 'Pencairan Tanpa Batas',
    benefit3: 'Akses API Pencairan Tidak Terbatas Jumlah Transaksi Maksimum: 50.000.000/Transaksi',
    benefit4: 'Harga terbaik',
    benefit5: 'Dukungan Prioritas',
    website: 'Website:',
    ownerCompany: 'Pemilik Perusahaan',
    representativeCompany: 'Perwakilan Perusahaan',
  },
};

export const buttonResource = {
  id: {
    check: "CEK",
    requestPayment: "Buat Terima Uang",
    back: "Kembali",
    upgrade: "Tingkatkan",
    upgradeNow: "Tingkatkan Sekarang",
    save: "Simpan",
    detail: "Detail",
    orderConfirm: "Konfirmasi",
    copy: "Salin",
    reset: "Ulang",
    guide: "Panduan",
    smCheck: "Cek", 
    cancel: "Batal",
    download: "Unduh disini!",
    addAcc: "Tambah Akun",
    edit: "Ubah",
    setAccess: "Ubah Akses",
    setDelete: "Hapus",
    close: "Tutup",
    create: "Buat",
    download2: "Unduh",
    next: "Selanjutnya",
    access: "Hak Akses",
    checkSecond: "Periksa",
    withdrawButton: "Lanjut ke pembayaran",
    transferVa: "Transfer akun virtual",
  },
  en: {
    check: "CHECK",
    requestPayment: "Make Receive Money",
    back: "Back",
    upgrade: "Upgrade",
    upgradeNow: "Upgrade Now",
    save: "Save",
    details: "Details",
    orderConfirm: "Confirm",
    copy: "Copy",
    reset: "Reset",
    guide: "Guide",
    smCheck: "Check",
    cancel: "Cancel",
    download: "Download here!",
    addAcc: "Add Account",
    edit: "Change",
    setAccess: "Change Access",
    setDelete: "Delete",
    close: "Close",
    create: "Create",
    download2: "Download",
    next: "Next",
    access: "Access Rights",
    checkSecond: "Check",
    withdrawButton: "Proceed to payment",
    transferVa: "Virtual account transfers",
  },
}

export const headerPageResource = {
  id: {
    transferMoneyCardSubpicker1: "Tujuan Tersimpan",
    accountStatement: "Laporan Rekening",
    transferMoney: "Transfer Uang",
    singleTransfer: "Transfer Saldo",
    requestPayment: "Data Terima Uang",
    detailRequestPayment: "Detail Terima Uang",
    detailTransferMoney: "Detail Transfer Uang",
    bulkTrxList: "Daftar Transfer Saldo Bulk",
    apiTrx: "Data Transaksi",
    bankData: "Data Akun Bank",
    editAcc: "Ubah Akun",
    hapusAcc: "Hapus Akun",
    hapusAccKonten: "Apakah Anda yakin ingin menghapus akun?",
    topup: "Top-Up Saldo",
    virtualAccount: "Akun Virtual",
    manualTf: "Transfer Manual",
    merchantMember: "Merchant Binaan",
    aggregatorFeeDaily: "Bagi Hasil Harian",
    transferDestination: "Pilih tujuan transfer",
  },
  en: {
    transferMoneyCardSubpicker1: "Saved Destinations",
    accountStatement: "Account Statement",
    transferMoney: "Money Transfer",
    singleTransfer: "Balance Transfer",
    requestPayment: "Data Receive Money",
    detailRequestPayment: "Receive Money Details",
    detailTransferMoney: "Money Transfer Details",
    bulkTrxList: "Bulk Balance Transfer List",
    apiTrx: "Transaction Data",
    bankData: "Bank Account Data",
    editAcc: "Change Account",
    hapusAcc: "Delete Account",
    hapusAccKonten: "Are you sure you want to delete account?",
    topup: "Top-Up Balance",
    virtualAccount: "Virtual Account",
    manualTf: "Manual Transfers",
    merchantMember: "Build Merchant",
    aggregatorFeeDaily: "Daily Revenue Sharing",
    transferDestination: "Select transfer destination",
  },
}

export const placeholderResource = {
  id: {
    transferRequirements: "Keperluan Transfer",
    search: "Masukkan Kata Kunci",
    uniqueCode: "Kode Unik",
    serviceFees: "Biaya Layanan",
    choose: "Pilih",
    like: "Seperti",
    startWith: "Diawali dengan",
    equalsTo: "Sama dengan",
    notEqualsTo: "Tidak sama dengan",
    noData: "Tidak ada data untuk ditampilkan",
    select: "Pilih...",
    accountNumber: "Nomor Rekening",
    accountNumber2: "Nomor Rekening Tujuan",
    inputCountry: "Masukkan negara tujuan",
    inputAccountNumber: "Masukkan nomor rekening",
    inputAccountNumberVa: "Masukkan nomor virtual account tujuan",
    inputAccountNumberEmoney: "Masukkan nomor e-money tujuan",
    inputAccountNumberRetail: "Masukkan Nomor pelanggan",
    nominalTransfer: "Jumlah Transfer",
    nominalChoice: "Pilih Nominal",
    nominalOther: "Nominal Lain",
    inputNominal: "Masukkan jumlah transfer",
    inputNominalIdr: "Masukkan jumlah transfer dalam IDR",
    note: "Catatan",
    accountPass: "Kata Sandi Akun",
    inputPass: "Masukkan kata sandi",
    searchs: "Cari...",
    searchBy: "Cari berdasarkan nama atau deskripsi",
    viewAll: "Tampilkan Semua",
    expired: "Kedaluwarsa",
    waitingPay: "Menunggu Pembayaran",
    donePay: "Sudah Dibayar",
    processTf: "Dalam Proses Transfer",
    success: "Sukses",
    fail: "Gagal",
    opName: "Nama Order Pembayaran",
    opInputName: "Masukkan nama order pembayaran",
    opNominal: "Nominal",
    opInputNominal: "Masukkan nominal order pembayaran",
    opDesc: "Keterangan",
    opExpDate: "Tanggal Kedaluwarsa",
    opMaxUse: "Maksimum Penggunaan",
    rewritePass: "Ketik Ulang Kata Sandi",
    inputMail: "Masukkan Email",
    inputName: "Masukkan Name",
    inputOldPass: "Masukkan Kata Sandi Akun Lama",
    inputNewPass: "Masukkan Kata Sandi Akun Baru",
    rewriteNewPass: "Ketik Ulang Kata Sandi Akun Baru",
    chooseBank: "Pilih bank tujuan",
    chooseCountry: "Pilih negara tujuan",
    chooseEmoney: "Pilih e-money tujuan",
    chooseRetail: "Pilih tarik tunai retail tujuan",
    senderName: "Nama Pengirim(Opsional)",
    noteOptional: "Catatan(Opsional)",
    senderGoal: "Tujuan transfer",
    voucher: "Voucher Diskon",
    retryCallbackConfirm: "Apa kamu yakin ingin mengirim callback?",
    or: "Atau",
    inputCodeBank: "Masukkan Kode Bank Tujuan",
    inputSwiftCode: "Masukkan Swift Code Tujuan",
    inputCity: "Masukkan Kota Tujuan",
    inputAddress: "Masukkan Alamat Tujuan",
    inputNameDestination: "Masukkan Atas Nama Rekening Tujuan",
    inputIDNumberReceived: "Masukkan Nomor ID Penerima",
    inputPhoneNumberReceived: "Masukkan Nomor Telepon Penerima",
    inputIfsCode: "Masukkan IFS Code",
    inputNationality: "Masukkan Kebangsaan",
  },
  en: {
    inputNationality: "Enter Nationality",
    inputIfsCode: "Enter IFS Code",
    inputPhoneNumberReceived: "Enter Phone Number Received",
    inputCodeBank: "Enter the Destination Bank Code",
    inputSwiftCode: "Enter the Destination Swift Code",
    inputCity: "Enter Destination City",
    inputAddress: "Enter the Destination Address",
    inputNameDestination: "Enter in the Name of the Destination Account",
    inputIDNumberReceived: "Enter Recipient ID Number",
    or: "Or",
    transferRequirements: "Transfer Requirements",
    search: "Enter Keyword",
    serviceFees: "Service Fees",
    choose: "Select",
    likes: "Like",
    startWith: "Starts with",
    equalsTo: "Equals",
    notEqualsTo: "Not equal to",
    noData: "No data to display",
    select: "Select...",
    accountDestination: "Fill in your Destination Account Data",
    accountNumber: "Account Number",
    accountNumber2: "Destination Account Number",
    inputCountry: "Enter destination country",
    inputAccountNumber: "Enter account number",
    inputAccountNumberVa: "Enter the destination virtual account number",
    inputAccountNumberEmoney: "Enter the destination e-money number",
    inputAccountNumberRetail: "Enter customer Number",
    nominalTransfer: "Transfer Amount",
    nominalChoice: "Select Nominal",
    nominalOther: "Other Nominal",
    inputNominal: "Enter transfer amount",
    inputNominalIdr: "Enter the transfer amount in IDR",
    note: "Note",
    accountPass: "Account Password",
    inputPass: "Enter password",
    searchs: "Search...",
    searchBy: "Search by name or description",
    viewAll: "Show All",
    expired: "Expired",
    waitingPay: "Waiting Payment",
    donePay: "Already Paid",
    processTf: "In Process Transfer",
    success: "Success",
    file: "Failed",
    opName: "Payment Order Name",
    opInputName: "Enter the payment order name",
    opNominal: "Nominal",
    opInputNominal: "Enter the payment order nominal",
    opDesc: "Remark",
    opExpDate: "Expiration Date",
    opMaxUse: "Maximum Usage",
    rewritePass: "Retype Password",
    inputMail: "Enter Email",
    inputName: "Enter Name",
    inputOldPass: "Enter Old Account Password",
    inputNewPass: "Enter New Account Password",
    rewriteNewPass: "Retype New Account Password",
    chooseBank: "Select a destination bank",
    chooseCountry: "Select a destination country",
    chooseEmoney: "Select destination e-money",
    chooseRetail: "Select destination retail cash advance",
    senderName: "Sender Name(Optional)",
    noteOptional: "Note(Optional)",
    senderGoal: "Purpose Transaction",
    voucher: "Discount Voucher",
    retryCallbackConfirm: "Are you sure you want to send a callback?",
  },
}

export const columnResource = {
  id: {
    time: "Waktu",
    product: "Produk",
    name: "Nama",
    type: "Tipe",
    debt: "Debit",
    credit: "Kredit",
    remark: "Komentar",
    lastBalance: "Saldo Terakhir",
    currentBalance: "Saldo Saat Ini",
    print: "Cetak",
    export: "Ekspor",
    paymentName: "Nama Pembayaran",
    paymentDesc: "Deskripsi Pembayaran",
    paymentAmount: "Jumlah Pembayaran",
    limit: "Jumlah/Limit Pembayaran",
    paymentDue: "Batas Pembayaran",
    createDate: "Tanggal Dibuat",
    finishDate: "Tanggal selesai",
    amount: "Nominal",
    action: "Aksi",
    expAt: "Kedaluwarsa pada",
    alreadyExp: "Sudah Kedaluwarsa pada",
    groupName: "Nama Grup",
    groupDesc: "Deskripsi Grup",
    trxCount: "Jumlah Transfer",
    groupId: "ID Grup",
    phone: "Telefon",
    accNumber: "Nomor Rekening",
    payId: "ID Pembayaran",
    processTime: "Waktu Diproses",
    bankName: "Nama Bank",
    vaNumber: "No Virtual Akun",
    billType: "Jenis Tagihan",
    expDate: "Tanggal Kedaluwarsa",
    payTime: "Waktu Pembayaran",
    custName: "Nama Pelanggan",
    amountOri: "Nominal Origin",
    vaNumb: "Nomor VA",
    vaOwnerName: "Nama Pemilik VA",
    payGuide: "Panduan Pembayaran",
    note: "Catatan",
    adminFee: "Biaya Admin",
    accName: "Nama Akun",
    option: "Opsi",
    trxType: "Tipe Trx",
    statBalance: "Status Saldo",
    productName: "Nama Produk",
    addInfo: "Info Tambahan",
    active: "Aktif",
    notActive: "Tidak Aktif",
    idTrx: "ID Transaksi",
    dateTrx: "Tanggal",
    dateSettlement: "Tanggal Settlement",
    dateMutation: "Tanggal Mutasi",
    totalDay: "Jumlah Hari",
    agentName: "Nama Agen",
    username: "Username",
    partnerReff: "Partner Reff",
    holdAmount: "Jumlah Ditahan",
    isSettle: "Sudah Settle?",
    waitingSettlement: "Menunggu",
    proccess: "Proses",
    failed: "Gagal",
    completedSettlement: "Selesai",
    tglTrx: "Tanggal Transaksi",
    tglSettle: "Tanggal Settlement",
    namaPemilik: "Nama",
    namaProduk: "Nama Produk",
    rekonnum: "Nominal Settlement",
    rekonnumhold: "Nominal Ditahan",
    failLogin: "Gagal Login",
    unknown: "Tidak Diketahui",
    true: "Benar",
    false: "Salah",
    balance: "Saldo",
    balanceFreeze: "Saldo Freeze",
    idRefferer: "ID Sponsor",
    nickname: "Nama Panggilan", 
    initialName: "Nama Inisial", 
    email: "Email",
    province: "Provinsi",
    idAgen: "ID Agen",
    isBlocked: "Diblokir",
    city: "Kota",
    grupAgen: "Grup",
    commision: "Setting Harga",
    activationDate: "Tanggal Aktivasi",
    registrationDate: "Tanggal Registrasi",
    reportDate: "Tanggal Laporan",
    reportMonth: "Nama Bulan",
    trxVa: "Jumlah Transaksi VA",
    feeVa: "Fee VA",
    trxTrf: "Jumlah Transaksi WD",
    feeTrf: "Fee WD",
    totalTrx: "Total Transaksi",
    totalFee: "Total Fee",
    companyName: "Nama Perusahaan",
    alreadyPaid: "Sudah Dibayar",
    notYetPaid: "Belum Dibayar",
    subAccountName: "Nama Sub akun",
    customerId: "ID Customer",
    customerName: "Nama Customer",
    customerPhone: "Nomor Telepon Customer",
    customerEmail: "Email Customer",
    bankCode: "Kode Bank",
    status: "Status",
    response: "Response",
    kindTrx: "Jenis Transaksi",
    send_mail: "Kirim Email",
    description: "Deskripsi",
    menu: "Menu",
    accountName: "Nama Rekening",
    ChargePercentage: "Charge Percentage",
    ChargeNominal: "Charge Nominal",
    danaTalangan: "Dana Talangan",
  },
  en: {
    ChargePercentage: "Charge Percentage",
    ChargeNominal: "Charge Nominal",
    danaTalangan: "Dana Talangan",
    accountName: "Account Name",
    menu: "Menu",
    description: "Description",
    send_mail: "Send Email",
    kindTrx: "Transaction Type",
    response: "Response",
    status: "Status",
    bankCode: "Bank Code",
    customerId: "Customer ID",
    customerName: "Customer Name",
    customerPhone: "Customer Phone",
    customerEmail: "Customer Email",
    subAccountName: "Sub Account Name",
    time: "Time",
    product: "Product",
    name: "Name",
    type: "Type",
    debt: "Debt",
    credit: "Credit",
    remark: "Remark",
    lastBalance: "Last Balance",
    currentBalance: "Current Balance",
    print: "Print",
    export: "Export",
    paymentName: "Payment Name",
    paymentDesc: "Payment Description",
    paymentAmount: "Payment Amount",
    limit: "Payment Amount/Limit",
    paymentDue: "Payment Due",
    createDate: "Created Date",
    finishDate: "Date Completed",
    amount: "Amount",
    action: "Action",
    expAt: "Expired at",
    alreadyExp: "Already Expired at",
    groupName: "Group Name",
    groupDesc: "Group Description",
    trxCount: "Transfer Count",
    groupId: "Group ID",
    phone: "Phone",
    accNumber: "Account Number",
    payId: "Payment ID",
    processTime: "Processed Time",
    bankName: "Bank Name",
    vaNumber: "Virtual Account No",
    billType: "Bill Type",
    expDate: "Expired Date",
    payTime: "Payment Time",
    custName: "Customer Name",
    amountOri: "Amount Origin",
    vaNumb: "VA Number",
    vaOwnerName: "VA Owner Name",
    payGuide: "Payment Guide",
    note: "Note",
    adminFee: "Admin Fee",
    accName: "Account Name",
    option: "Option",
    trxType: "Trx Type",
    statBalance: "Status Balance",
    productName: "Product Name",
    addInfo: "Add Info",
    active: "Active",
    notActive: "Not Active",
    idTrx: "ID Transaction",
    dateTrx: "Date",
    dateSettlement: "Settlement Date",
    dateMutation: "Mutation Date",
    totalDay: "Number of Days",
    agentName: "Agent Name",
    username: "Username",
    partnerReff: "Partner Reff",
    holdAmount: "Hold Amount",
    isSettle: "Is Settle?",
    waitingSettlement: "Waiting",
    proccess: "Proccess",
    failed: "Failed",
    completedSettlement: "Completed",
    tglTrx: "Transaction Date",
    tglSettle: "Settlement Date",
    namaPemilik: "Name",
    namaProduk: "Produk Name",
    rekonnum: "Settlement Amount",
    rekonnumhold: "Hold Amount",
    failLogin: "Fail Login",
    unknown: "Unknown",
    true: "True",
    false: "False",
    balance: "Balance",
    balanceFreeze: "Balance Freeze",
    idRefferer: "ID Referrer",
    nickname: "Nickname", 
    initialName: "Initials", 
    email: "Email",
    province: "Province",
    idAgen: "ID Agen",
    isBlocked: "Is Blocked",
    city: "City",
    grupAgen: "Group",
    commision: "Price",
    activationDate: "Activation Date",
    registrationDate: "Registration Date",
    reportDate: "Report Date",
    reportMonth: "Month",
    trxVa: "Count Transaction VA",
    feeVa: "Fee VA",
    trxTrf: "Jumlah Transaksi WD",
    feeTrf: "Fee WD",
    totalTrx: "Total Transaksi",
    totalFee: "Total Fee",
    companyName: "Company Name",
    alreadyPaid: "Already Paid",
    notYetPaid: "Not Yet Paid",
  },
}

export const titleSentence = {
  id : {
    topupInfo2: "Pastikan jumlahnya benar hingga 3 digit terakhir",
    topupInfo3: "Mohon transfer sebelum ",
    topupInfo3add: " atau transaksimu akan dibatalkan otomatis oleh sistem",
    topupInfo4: "Silahkan periksa email Anda untuk melihat informasi Top-up di bawah ini.",
    topupInfoWaitingTransfer: "Menunggu transfer",
    withdraw: "Tarik Saldo",
    withdrawInfo1: "Periksa kembali transaksi Anda!",
    withdrawInfo2: "Pastikan nama dan nomor rekening tujuan sudah benar",
    withdrawInfoOtp: "Silahkan periksa email Anda untuk melihat kode OTP",
    domesticTitle1: "Pilih metode transfer",
    domesticTitle2: "Silahkan pilih metode transfer yang sesuai dengan kebutuhan Anda",
    domesticSubtitle1: "Proses transfer akan sedikit lebih lama pada jam downtime bank (23:00 WIB - 06:00 WIB).",
    domesticSubtitle2: "Namun akan tetap diproses setelah jam downtime  berakhir.",
    transferMoneyCardSubpicker1: "Tujuan Tersimpan",
    transferMoneyCardSubpicker2: "Rekening Bank",
    transferMoneyCardSubpicker3: "E-Wallet",
    transferMoneyCardSubpicker4: "Retail",
    manualInputInfoback1: "Periksa kembali transaksi Anda!",
    manualInputInfoback1Add: "Pastikan nama dan nomor rekening tujuan sudah benar",
    manualInputInquiryResultInfo1: "Ketentuan Biaya Transfer bisa dibaca di ",
    choosePaymentTransferInfo1: "Silahkan pilih metode transfer uang di bawah ini",
    choosePaymentTransferInfoVoucher1: "Gunakan voucher di bawah ini untuk mendapatkan potongan sesuai dengan nominal yang tercantum pada voucher",
    choosePaymentTransferInfoVoucher2: "Pilih voucher disini",
    choosePaymentTransferInfoVoucher3: "Pilih voucher dibawah ini",
    choosePaymentTransferInfoVoucher4: "Saya setuju dengan syarat & ketentuan bertransaksi menggunakan LinkQu",
    choosePaymentTransferInfoVoucher5: "Ringkasan Pembayaran",
    choosePaymentTransferInfoVoucher6: "Nominal Transfer",
    choosePaymentTransferInfoVoucher7: "Total biaya transfer",
    choosePaymentTransferInfoVoucher8: "Kode unik akan diinformasikan pada tahapan selanjutnya",
    choosePaymentTransferInfoVoucher9: "Diskon Transfer",
    choosePaymentTransferInfoVoucher10: "Biaya transfer",
    transferPaymentInfo1: "Segera lakukan pembayaran dengan rincian berikut ini",
    transferPaymentInfo2: "Detail Transaksi",
    transferPaymentInfo3: "ID TRANSAKSI",
    transferPaymentInfo4: "Ubah Metode Pembayaran",
    bulkInfo1: "Buat transaksi via Excel / CSV",
    bulkInfo2: "1. Pilih format template yang cocok dengan kebutuhan Anda, kemudian isi template sesuai format ayng sudah disediakan. Data yang diisi tidak boleh melebihi 20.000 transaksi.",
    bulkInfo3: "Excel / CSV",
    bulkInfo4: "Untuk mengisi kolom Bank Tujuan pada template, lihat kode bank ",
    bulkInfo4Add: "di sini.",
    bulkInfo5: "2. Unggah File Excel/CSV Anda di bawah ini untuk membuat transaksi.",
    bulkInfo6: "Taruh file Excel / CSV di sini",
    or: "atau",
    bulkInfo7: "Pilih file",
    bulkInfo8: "Ukuran maksimal yang boleh diunggah 10 MB",
    bulkInfo9: "3. Masukkan nama grup",
    bulkInfo10: "4. Deskripsi",
    bulkInfo11: "Masukkan nama grup",
    bulkInfo12: "Deskripsi",
    bulkCardTitle: "Unggah Excel / CSV",
    bulkCardSubtitle: "Menggunakan file Excel / CSV hingga 20.000 transaksi",
    deposit: "Isi Saldo",
    availableBalance: "Saldo Tersedia",
    transferType: "Tipe Transfer",
    bankName: "Nama Bank",
    accountNumber: "Nomor Rekening",
    expTime: "Waktu Kedaluwarsa Transfer",
    successTime: "Waktu Sukses Transfer",
    totalPay: "Total Pembayaran",
    payStatement: "Pastikan pembayaran transfer kamu sama persis dengan nominal Total Pembayaran di atas (hingga 3 digit terakhir)",
    accName: "Nama Akun",
    amount: "Jumlah",
    adminFee: "Biaya Admin",
    serviceFee: "Harga Layanan",
    pinTrx: "PIN Transaksi",
    otp: "Kode OTP",
    checkOtpMail: "Cek Email Anda untuk Melihat Kode OTP",
    checkTrx: "Periksa kembali transaksinya, pastikan nama dan nomor rekening tujuannya benar.",
    accPass: "Kata Sandi Akun",
    needCsv: "Butuh contoh berkas CSV?",
    needBankCode: "Butuh informasi Bank terdaftar?",
    groupInfo: "Informasi Grup",
    groupName: "Nama Grup",
    groupDesc: "Deskripsi Grup",
    sandboxMode: "Anda di dalam Mode Sandbox. Fitur ini hanya bisa dijalankan di dalam Mode Production.",
    confirmPass: "Konfirmasi Kata Sandi",
    yourPass: "Kata Sandi Anda" ,
    neverShareMail: "jangan pernah bagikan email kepada orang lain.",
    oldPass: "Kata Sandi Akun Lama",
    newPass: "Kata Sandi Akun Baru",
    confNewPass: "Konfirmasi Kata Sandi Akun Baru",
    tfVa: "Top-Up Saldo menggunakan Akun Virtual",
    tfManual: "Top-Up Saldo menggunakan Transfer Manual",
    hours: "Jam",
    minutes: "Menit",
    seconds: "Detik",
    trxInfo: "Transfer tepat 3 digit terakhir agar transaksi berhasil",
    timeRemain: "Sisa Waktu",
    topupInfo: "Silahkan periksa email anda untuk melihat informasi Top-Up di atas.",
    chooseBank: "Pilih Bank",
    inputNominal: "Masukkan Nominal Top-Up",
    groupNameEx: "Contoh: Gaji Karyawan",
    groupDescEx: "Contoh: Gaji pada Periode Januari 2021",
    chooseVa: "Pilih VA:",
    callbackInfo: `URL Callback merupakan URL di Server Anda yang akan digunakan oleh Sistem ${process.env.REACT_APP_BRAND_NAME} untuk mengirimkan data Status Transaksi yang Anda lakukan.`,
    aggMonthFee: "Total Biaya Bulan Ini",
    aggMonthFeeVa: "Biaya Transaksi VA Bulan Ini",
    aggMonthFeeTf: "Biaya Transaksi Transfer Bulan Ini",
    aggTodayFee: "Total Biaya Hari Ini",
    aggTodayFeeVa: "Biaya Transaksi VA Hari Ini",
    aggTodayFeeTf: "Biaya Transaksi Transfer Hari ini",
    aggMonthTrx: "Total Transaksi Bulan Ini",
    aggMonthTrxVa: "Transaksi VA Bulan Ini",
    aggMonthTrxTf: "Transaksi Transfer Bulan Ini",
    aggTodayTrx: "Total Transaksi Hari Ini",
    aggTodayTrxVa: "Transaksi VA Hari Ini",
    aggTodayTrxTf: "Transaksi Transfer Hari Ini",
    mitraTrx: "Transaksi Sukses",
    mitraTrxVa: "Transaksi VA Sukses",
    mitraTrxTf: "Transaksi Transfer Sukses",
    mitraTrxEmoney: "Transaksi E-Money Sukses",
    mitraAllTrxOmzet: "Total Transaksi Omzet",
    mitraVAOmzet: "Cash In Transaksi Omzet",
    mitraTFOmzet: "Cash Out Transaksi Omzet",
    holdBalance: "Saldo Ditahan",
    mitraHoldBalance: "Saldo Mitra Ditahan",
    mitraCBpending: "Pending Transaksi",
    mitraVAOmzetThisMonth: "Omzet Cash In Bulan Ini",
    mitraTFOmzetThisMonth: "Omzet Cash Out Bulan Ini",
    payLink: "Link Pembayaran",
    tfInfo: "Proses transfer akan lebih lama pada jam downtime bank (antara 23:00 s.d. 06:00 WIB). Tapi tenang, transfer uang kamu tetap akan diproses setelah jam downtime tersebut berakhir.",
    depoVa : "Deposit yang menggunakan VA (Virtual Account) akan selalu terproses secara otomatis dan real-time.",
    depoTf1: "Deposit yang menggunakan Transfer Manual akan terproses secara otomatis dalam waktu sekitar 5-15 menit.",
    depoTf2: "Deposit yang menggunakan Transfer Manual pada jam downtime bank, yaitu 23:00 s.d. 06:00 (WIB), ada kemungkinan tidak bisa langsung terproses secara otomatis (baru bisa terproses sesudahnya).",
    depoTf3: "Deposit yang menggunakan Transfer melalui channel EDC tidak akan diproses. Harap menggunakan channel lain seperti mBanking, iBanking, ATM, Teller",
    sendfeedback: "Umpan Balik",
    manualInput: "Manual Input",
    manualInputCardSubtitle: "Memasukkan input manual untuk kirim uang",
    bulkTransfer: "Bulk Transfer",
    api: "Data Transaksi",
    orderConfirm: "Konfirmasi",
    destinationAccount: "Rekening tujuan",
    news: "Berita",
    manualInputInfoAlert1: "Pilih nama bank tujuan",
    dataNotAvailable: "Tidak ada Data",
    pleaseActivateBusiness: "Mohon maaf, Anda belum bisa menggunakan fitur ini karena Akun Bisnis Anda belum diaktivasi. Mohon hubungi Tim LinkQu untuk aktivasi Akun Bisnis Anda.",
    warningModeSandbox: "Anda di dalam Mode Sandbox. Fitur ini hanya bisa dijalankan di dalam Mode Production.",
    infoCredential1: "Berikut adalah beberapa setting yang harus dilakukan agar sistem Anda bisa mengakses API LinkQu.",
    infoCredential2: "Buat transaksi via Excel / CSV",
    infoCredential3: "Untuk menggunakan API LinkQu, dibutuhkan nilai Client ID dan Client Secret.",
    infoCredential4: "PIN Transaksi",
    infoCredential5: "Untuk menggunakan API LinkQu, juga dibutuhkan nilai PIN Transaksi.",
    infoCredential6: "Nilai PIN Transaksi dibuatkan oleh LinkQu dan dikirimkan ke email Anda yang terdaftar di LinkQu.",
    infoCredential7: "IP Whitelist adalah daftar alamat IP milik Anda yang diijinkan untuk mengakses API LinkQu.",
    infoCredential8: "Daftar IP Whitelist yang sudah didaftarkan saat ini:",
    infoCredential9: "Belum ada IP Whitelist",
    infoCredential20: "Signature",
    infoCredential21: "Status Signature saat ini : ",
    sendCredential: "Kirim client ID & client secret",
    sendPin: "Kirim PIN Transaksi",
    activateSignature: "Aktifkan Signature",
    resetSignature: "Reset Signature",
    bulkCreateVa: "Bulk VA",
    mutation: "Mutasi",
    mutationDetail: "Detail Mutasi",
  },
  en: {
    mutation: "Mutation",
    mutationDetail: "Mutation Detail",
    bulkCreateVa: "Bulk VA",
    topupInfo2: "Make sure the number is correct up to the last 3 digits",
    topupInfo3: "Please transfer before ",
    topupInfo3add: " or your transaction will be automatically canceled by the system",
    topupInfo4: "Please check your email for the Top-up information below.",
    topupInfoWaitingTransfer: "Waiting for transfer",
    withdraw: "Withdraw Balance",
    withdrawInfo1: "Check your transaction again!",
    withdrawInfo2: "Make sure the destination account name and number are correct",
    withdrawInfoOtp: "Please check your email for the OTP code",
    domesticTitle1: "Select transfer method",
    domesticTitle2: "Please choose a transfer method that suits your needs",
    domesticSubtitle1: "The transfer process will take a little longer during bank downtime (23:00 WIB - 06:00 WIB))",
    domesticSubtitle2: "However will still be processed after the downtime hours end.",
    transferMoneyCardSubpicker1: "Saved Destinations",
    transferMoneyCardSubpicker2: "Bank Account",
    transferMoneyCardSubpicker3: "E-Wallet",
    transferMoneyCardSubpicker4: "Retail",
    manualInputInfoback1: "Check your transaction again!",
    manualInputInfoback1Add: "Make sure the destination account name and number are correct",
    manualInputInquiryResultInfo1: "Transfer Fee Terms can be read at ",
    choosePaymentTransferInfo1: "Please select a money transfer method below",
    choosePaymentTransferInfoVoucher1: "Use the voucher below to get a discount according to the amount stated on the voucher",
    choosePaymentTransferInfoVoucher2: "Select voucher here",
    choosePaymentTransferInfoVoucher3: "Select a voucher below",
    choosePaymentTransferInfoVoucher4: "I agree to the terms & conditions of transacting using LinkQu",
    choosePaymentTransferInfoVoucher5: "Payment Summary",
    choosePaymentTransferInfoVoucher6: "Nominal Transfer",
    choosePaymentTransferInfoVoucher7: "Total transfer fee",
    choosePaymentTransferInfoVoucher8: "A unique code will be communicated at a later stage",
    choosePaymentTransferInfoVoucher9: "Transfer Discount",
    choosePaymentTransferInfoVoucher10: "Transfer fee",
    transferPaymentInfo1: "Immediately make a payment with the following details",
    transferPaymentInfo2: "Transaction Details",
    transferPaymentInfo3: "TRANSACTION ID",
    transferPaymentInfo4: "Change Payment Method",
    bulkInfo1: "Create transactions via Excel / CSV",
    bulkInfo2: "1. Select the template format that suits your needs, then fill in the template according to the format provided. The data entered must not exceed 20,000 transactions.",
    bulkInfo3: "Excel / CSV",
    bulkInfo4: "To fill in the Destination Bank column on the template, see the bank code",
    bulkInfo4Add: "here.",
    bulkInfo5: "2. Upload your Excel/CSV File below to make a transaction.",
    bulkInfo6: "Put Excel / CSV file here",
    or: "or",
    bulkInfo7: "Select files",
    bulkInfo8: "Maximum upload size 10 MB",
    bulkInfo9: "3. Enter group name",
    bulkInfo10: "4.Description",
    bulkInfo11: "Enter group name",
    bulkInfo12: "Description",
    bulkCardTitle: "Excel/CSV Upload",
    bulkCardSubtitle: "Uses Excel / CSV files for up to 20,000 transactions",
    deposit: "Fill Balance",
    availableBalance: "Available Balance",
    transferType: "Transfer Type",
    bankName: "Bank Name",
    accountNumber: "Account Number",
    expTime: "Transfer Expiration Time",
    successTime: "Transfer Success Time",
    totalPay: "Total Payment",
    payStatement: "Make sure your transfer payment is exactly the same as the Total Payment above (up to the last 3 digits)",
    accName: "Account Name",
    amount: "Amount",
    adminFee: "Admin Fee",
    serviceFee: "Service Fee",
    pinTrx: "Transaction PIN",
    otp: "OTP code",
    checkOtpMail: "Check Your Email For OTP Code",
    checkTrx: "Check the transaction again, make sure the destination name and account number are correct.",
    accPass: "Account Password",
    needCsv: "Need a sample CSV file?",
    needBankCode: "Need Bank information registered?",
    groupInfo: "Group Information",
    groupName: "Group Name",
    groupDesc: "Group Description",
    sandboxMode: "You are in Sandbox Mode. This feature only works in Production Mode.",
    confirmPass: "Confirm Password",
    yourPass: "Your Password",
    neverShareMail: "never share email with others.",
    oldPass: "Old Account Password",
    newPass: "New Account Password",
    confNewPass: "Confirm New Account Password",
    tfVa: "Top-Up Balance using a Virtual Account",
    tfManual: "Top-Up Balance using Manual Transfer",
    hours: "Hours",
    minutes: "Minutes",
    seconds: "Seconds",
    trxInfo: "Transfer exactly the last 3 digits for a successful transaction",
    timeRemain: "Time Remaining",
    topupInfo: "Please check your email for the Top-Up information above.",
    chooseBank: "Select Bank",
    inputNominal: "Enter Top-Up Amount",
    groupNameEx: "Example: Employee Salary",
    groupDescEx: "Example: Salary in January 2021 Period",
    chooseVa: "Select VA:",
    callbackInfo: `Callback URL is the URL on your Server that will be used by the ${process.env.REACT_APP_BRAND_NAME} System to send the Transaction Status data that you are doing.`,
    aggMonthFee: "Total Fee This Month",
    aggMonthFeeVa: "This Month's VA Transaction Fee",
    aggMonthFeeTf: "This Month's Transfer Transaction Fee",
    aggTodayFee: "Total Fee Today",
    aggTodayFeeVa: "Today's VA Transaction Fee",
    aggTodayFeeTf: "Today's Transfer Transaction Fee",
    aggMonthTrx: "Total Transactions of the Month",
    aggMonthTrxVa: "This Month's VA Deals",
    aggMonthTrxTf: "This Month's Transfer Transaction",
    aggTodayTrx: "Total Transactions Today",
    aggTodayTrxVa: "Today's VA Deals",
    aggTodayTrxTf: "Today's Transfer Transaction",
    mitraTrx: "Successful Transaction",
    mitraTrxVa: "Successful VA Transaction",
    mitraTrxTf: "Transfer Transaction Successful",
    mitraTrxEmoney: "Successful E-Money Transactions",
    mitraAllTrxOmzet: "Total Turnover Transactions",
    mitraVAOmzet: "Cash In Omzet Transactions",
    mitraTFOmzet: "Cash Out Turnover Transactions",
    holdBalance: "Holding Balance",
    mitraHoldBalance: "Partner Balance on Hold",
    mitraCBpending: "Transaction Pending",
    mitraVAOmzetThisMonth: "Cash In Turnover This Month",
    mitraTFOmzetThisMonth: "Cash Out Turnover This Month",
    payLink: "Payment Link",
    tfInfo: "The transfer process will take longer during bank downtime hours (between 23:00 to 06:00 WIB). But don't worry, your money transfer will still be processed after the downtime ends.",
    depoVa : "Deposits using a VA (Virtual Account) will always be processed automatically and in real-time.",
    depoTf1: "Deposits using Manual Transfer will be processed automatically in about 5-15 minutes.",
    depoTf2: "Deposits that use Manual Transfer during bank downtime hours, namely 23:00 to 06:00 (WIB), there is a possibility that they cannot be processed automatically (only can be processed afterwards).",
    depoTf3: "Deposits that use Transfer via the EDC channel will not be processed. Please use other channels such as mBanking, iBanking, ATM, Teller",
    sendfeedback: "Feedback",
    manualInput: "Manual Input",
    manualInputCardSubtitle: "Enter manual input to send money",
    bulkTransfer: "Bulk Transfer",
    api:"Data Transaction",
    orderConfirm: "Confirm",
    destinationAccount: "Destination account",
    news: "News",
    manualInputInfoAlert1: "Select destination bank name",
    dataNotAvailable: "No Data",
    pleaseActivateBusiness: "Sorry, you cannot use this feature yet because your Business Account has not been activated. Please contact the LinkQu Team to activate your Business Account.",
    warningModeSandbox: "You are in Sandbox Mode. This feature can only be run in Production Mode.",
    infoCredential1: "Here are some settings that must be done so that your system can access the LinkQu API.",
    infoCredential2: "Make transactions via Excel / CSV",
    infoCredential3: "To use the LinkQu API, Client ID and Client Secret values are required.",
    infoCredential4: "Transaction PIN",
    infoCredential5: "To use the LinkQu API, a Transaction PIN value is also required.",
    infoCredential6: "Transaction PIN value is generated by LinkQu and sent to your email registered with LinkQu.",
    infoCredential7: "IP Whitelist is a list of your IP addresses that are allowed to access the LinkQu API.",
    infoCredential8: "Currently registered IP Whitelist:",
    infoCredential9: "No IP Whitelist yet",
    infoCredential20: "Signature",
    infoCredential21: "Current Signature Status : ",
    sendCredential: "Send client ID & client secret",
    sendPin: "Send Transaction PIN",
    activateSignature: "Activate Signature",
    resetSignature: "Reset Signature",
  }

}

export const loginResource = {
  id: {
    welcome: "Selamat datang kembali",
    akun: "Belum punya akun",
    daftar: "Daftar di sini",
    login: "Masuk",
    email: "Masukkan alamat email",
    password: "Kata Sandi",
    password2: "Masukkan kata sandi",
    show: "Lihat",
    hide: "Sembunyikan",
    forgot: "Lupa kata sandi ?"
  },
  en: {
    welcome: "Welcome back",
    akun: "Don't have an account yet",
    daftar: "Register here",
    login: "Login",
    email: "Your account email address",
    password: "Password",
    password2: "Enter password",
    show: "Show",
    hide: "Hide",
    forgot: "Forgot your password ?"

  },
}