import MessageSender from '../sender'
import BaseMessage from '../message'

export default (
  path,
  selectedVa,
  page,
  sort,
  countPerPage,
  filters = [],
  exportCsv = 0
) => async () => {
  const msg = new BaseMessage()
  msg.setPath(`data/${path}`)
  msg.setItem('vaCode', selectedVa)
  msg.setItem('page', page)
  msg.setItem('limit', countPerPage)
  msg.setItem('sort', sort)
  if (filters.length > 0) {
    msg.setItem('filters', filters)
  }
  msg.setItem('exportCsv', exportCsv)

  const response = await new MessageSender().doGet(msg)

  if (exportCsv) {
    return response
  }

  if (response.isOK()) {
    return response.getResultAsObject()
  }

  return {}
}
