/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable valid-typeof */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable eqeqeq */
/* eslint-disable no-lonely-if */
/* eslint-disable react/react-in-jsx-scope */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Container, Form, Spinner } from 'react-bootstrap'
import Select from 'react-select'
import { withRouter } from 'react-router-dom'
// eslint-disable-next-line
import './dana.scss'
import ReCAPTCHA from 'react-google-recaptcha'
import BaseScreen from '../../base'
import globalConstant from '../../../store/constant/global'
import auth from '../../../store/constant/auth'
import { danaTalangan, danaTalanganNext, getKodeProduk } from '../../../api/action/transaction'
import AlertDialog from '../../dialog/alert'
import DateTime from '../../../helper/datetime'
import checkAccess from '../../../helper/access'
import roleAccess from '../../../store/constant/roleAccess'
import AccessDenied from '../../component/AccessDenied'
import translate from '../../../helper/translate'
import { placeholderResource } from '../../../helper/translatePage/languageResource'
import getCsrf from '../../../api/action/getCsrf'
import logout from '../../../api/action/logout'

class AddTalanganScreen extends BaseScreen {
  constructor(props) {
    super(props, ['page--dana'])

    this.state = {
      alertError: '',
      loading: false,
      modal: false,
      dataInfo: null,
      date: DateTime.parse(new Date(), DateTime.FMT_DATE_YMD).toDate(),
      showPass: false,
      action: 'danatalangan',
      recaptcha: '',
      selectedKey: null,
      keyList: [],
      date2: '',
      dateto: '',
    }
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleDateChangeto = this.handleDateChangeto.bind(this)
    this.captchaRef = React.createRef().current
  }

  handleDateChange(e) {
    this.setState({ date2: e.target.value })
  }

  handleDateChangeto(e) {
    const startDate = this.state.date2
    const endDate = e.target.value
    this.setState({ dateto: e.target.value })
    if (startDate && endDate < startDate) {
      this.setState({ dateto: '' })
      this.showError('Tanggal To tidak boleh lebih awal dari tanggal From')
    }
  }

  onChangeRecaptcha = (value) => {
    this.setState({ recaptcha: value })
  }

  componentDidMount() {
    this.getProduk()
  }

  modalClosed = () => {
    this.setState({ modal: false })
  }

  modalShowed = () => {
    this.setState({ modal: true })
  }

  selectHandle = (e) => {
    this.setState({ selectedKey: e })
  }

  showError = (alertError) => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ alertError })
  }

  closeError = () => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ alertError: '' })
  }

  getProduk = async () => {
    this.setState({ loading: true })
    const { dispatch } = this.props
    try {
      const produk = await dispatch(getKodeProduk())
      if (produk !== null) {
        this.setState({ loading: false })
        const produkOptions = produk.map((d) => {
          return {
            value: d.id_produk,
            label: d.nama_produk,
          }
        })
        this.setState({ keyList: produkOptions })
      } else {
        this.setState({ loading: false })
        this.setState({ keyList: [] })
        this.setState({ alertError: produk.getRD() })
      }
    } catch (error) {
      console.log('check error', error)
      this.setState({ loading: false })
      this.setState({ keyList: [] })
      this.setState({ alertError: 'Something Goes Wrong, Please Contact Us in linkqu.id' })
    }
  }

  onSubmit = async (id) => {
    // eslint-disable-next-line no-unused-vars
    const { dispatch, appUserData, history } = this.props
    const { date2, selectedKey, dateto, recaptcha } = this.state
    if (id === 1) {
      this.captchaRef.reset()
    }

    if (selectedKey === null) {
      dispatch({ type: globalConstant.IS_LOADING, payload: false })
      this.showError('Anda belum memilih kode produk')
    } else if (date2 === '') {
      dispatch({ type: globalConstant.IS_LOADING, payload: false })
      this.showError('Anda belum memasukkan tanggal from')
    } else if (dateto === '') {
      dispatch({ type: globalConstant.IS_LOADING, payload: false })
      this.showError('Anda belum memasukkan tanggal to')
    } else if (!recaptcha) {
      dispatch({ type: globalConstant.IS_LOADING, payload: false })
      this.showError('Please fill recatpcha')
    } else {
      let response = await dispatch(getCsrf())
      if (response.isOK()) {
        if (id === 2) {
          response = await dispatch(
            danaTalanganNext(appUserData.username_agen, date2, dateto, selectedKey.value, recaptcha)
          )
        } else {
          response = await dispatch(
            danaTalangan(appUserData.username_agen, date2, dateto, selectedKey.value, recaptcha)
          )
        }
        if (response.isOK()) {
          history.push('/data/dana_talangan')
        } else {
          if (response.getRD() == null) {
            this.showError('Failed to save')
          } else if (response.getRC() == '99') {
            this.setState({
              dataInfo: JSON.parse(response.data.data),
              modal: true,
              action: 'gagal',
            })
          } else {
            this.showError(response.getRD())
            this.setState({
              modal: false,
              action: 'danatalangan',
            })
          }
        }
      } else {
        dispatch(logout())
      }
    }
  }

  modalInfo = (data) => {
    return (
      <Container fluid className="p-3 container-success">
        <div className="subcontainer-success">
          <button
            type="button"
            className={`btn btn-primary color-white fontSize-17 ${
              this.props.appIsLoading ? 'disabled' : ''
            }`}
            onClick={() => this.onSubmit(2)}
            style={{
              width: '200px',
              backgroundColor: '#FF0000',
              position: 'absolute',
              right: '10px',
              marginRight: '10px',
            }}
          >
            Tetap Simpan
          </button>
          <div className="border-bottom" style={{ marginTop: '40px' }} />
          <div className="container-flex-space-between">
            <div className="fontSize-19">
              Beberapa tanggal ditolak sistem silahkan hubungi admin
            </div>
          </div>
          <div className="container-flex-space-between">
            {/* <div className="box-info"> */}
            <ul>
              {data.missing_dates.length > 0 ? (
                data.missing_dates.map((date) => <li key={date}>{date}</li>)
              ) : (
                <></>
              )}
            </ul>
            {/* </div> */}
          </div>
        </div>
      </Container>
    )
  }

  createAccess = () => {
    return checkAccess(this.props.appUserAccess, 'data_dana_talangan', 'ADD')
  }

  renderDana() {
    return (
      <Container fluid className="p-3 container-transfer container-content mt-4">
        {/* <div className="fontSize-20 mb-5">Tambah Dana Talangan</div> */}
        {!this.createAccess() ? (
          <AccessDenied />
        ) : (
          <>
            <div className="container-split-form">
              <Form.Group className="form-left">
                <Form.Label>Tanggal From</Form.Label>
                <Form.Control
                  type="date"
                  value={this.state.date2}
                  onChange={this.handleDateChange}
                  placeholder="Select a date"
                  className="custom-input fontSize-16"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Tanggal To</Form.Label>
                <Form.Control
                  type="date"
                  value={this.state.dateto}
                  onChange={this.handleDateChangeto}
                  placeholder="Select a date"
                  className="custom-input fontSize-16"
                />
              </Form.Group>
            </div>
            <div className="container-split-form">
              <Form.Group className="form-left">
                <Form.Label>Kode Produk</Form.Label>
                {this.state.loading ? (
                  <>
                    <br />
                    <Spinner size="xs" className="mr-1" animation="border" variant="primary" />
                  </>
                ) : (
                  <Select
                    value={this.state.selectedKey}
                    options={this.state.keyList}
                    onChange={this.selectHandle}
                    placeholder={translate('select', this.props.language, placeholderResource)}
                    className="custom-input fontSize-16"
                  />
                )}
              </Form.Group>
              <ReCAPTCHA
                ref={(ref) => {
                  this.captchaRef = ref
                }}
                className="mt-3"
                sitekey={process.env.REACT_APP_GRECAPTCHA_KEY}
                onChange={(e) => {
                  this.onChangeRecaptcha(e, 2)
                }}
              />
              <Form.Group>
                <button
                  type="button"
                  className={`btn btn-primary btn-block color-white btn-custom fontSize-17 ${
                    this.props.appIsLoading ? 'disabled' : ''
                  }`}
                  onClick={() => this.onSubmit(1)}
                >
                  Tambah
                </button>
              </Form.Group>
            </div>
          </>
        )}
      </Container>
    )
  }

  render() {
    const { action } = this.state
    return (
      <>
        {action === 'danatalangan' && this.renderDana()}
        {action === 'success' && this.modalSuccess(this.state.dataSuccessTopup)}
        {action === 'gagal' && this.modalInfo(this.state.dataInfo)}

        <AlertDialog
          show={!!this.state.alertError}
          onHide={this.closeError}
          onConfirm={this.closeError}
          type="warning"
          messageTitle={this.state.alertError}
        />
      </>
    )
  }
}

AddTalanganScreen.defaultProps = {
  appIsLoading: false,
}

AddTalanganScreen.propTypes = {
  appIsLoading: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
}

export default withRouter(
  connect(
    (state) => ({
      appIsLoading: state.Global[globalConstant.IS_LOADING],
      language: state.Global[globalConstant.LANGUAGE],
      appUserData: state.Auth[auth.USER_DATA],
      token: state.Auth[auth.TOKEN],
      appUserAccess: state.RoleAccess[roleAccess.ACCESS],
      appAgentUser: state.Auth[auth.AGENT_USER],
    }),
    (dispatch) => ({ dispatch })
  )(AddTalanganScreen)
)
