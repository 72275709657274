const translate = (key, language, resource) => {
  let result = ''
  if (language === 'id') {
    // eslint-disable-next-line
      result = resource.id[key]
  } else {
    // eslint-disable-next-line
      result = resource.en[key]
  }

  return result !== undefined ? result : 'Language not found'
}

export default translate
