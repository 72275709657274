import MessageSender from '../sender'
import BaseMessage from '../message'

export default (idOtp, otp, captcha, email) => async () => {
  const msg = new BaseMessage()

  msg.setPath('/control/generate_pin')
  msg.setItem('id_otp', idOtp)
  msg.setItem('otp', otp)
  msg.setItem('captcha', captcha)
  msg.setItem('email_destination', email)

  const response = await new MessageSender().doPost(msg)

  return response
}
