import MessageSender from '../sender'
import BaseMessage from '../message'

export default (idAgen, filter, limit, page) => async () => {
  const msg = new BaseMessage()
  msg.setPath('data/request_payment_list')
  msg.setItem('idAgen', idAgen)
  msg.setItem('filter', filter)
  msg.setItem('limit', limit)
  msg.setItem('page', page)

  const response = await new MessageSender().doGet(msg)

  return response
}
