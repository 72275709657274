/* eslint-disable react/react-in-jsx-scope */
import { Switch, Route, Redirect } from 'react-router-dom'
import { useSelector, shallowEqual } from 'react-redux'
import LoginScreen from '../screen/auth/login'
import DashboardScreen from '../screen'
import AccountScreen from '../screen/account/account'
import ResetPasswordScreen from '../screen/account/resetPassword'
import AccountManagementScreen from '../screen/accountManagement/accountManagement'
import ReportScreen from '../screen/report/report'
import DataScreen from '../screen/report/data'
import TopUpScreen from '../screen/report/howto/topup'
import CredentialServerScreen from '../screen/tools/credential_server'
import TransferDetailScreen from '../screen/transaction/transfer/transferDetail'
import RequestPaymentScreen from '../screen/requestPayment'
import PaymentRequestPublic from '../screen/paymentPublic/PaymentRequestPublic'
import SingleTransferScreen from '../screen/transaction/withdraw'
import Invoice from '../screen/component/invoice'
import PrivateRoute from './wrapper'
import DomesticScreen from '../screen/transferMoney/Domestic/domestic'
import InternationalScreen from '../screen/transferMoney/International/international'
import bulkRegisterVaScreen from '../screen/registerVa/bulkRegisterVaScreen'
import BeritaScreen from '../screen/news/berita'
import TalanganScreen from '../screen/report/howto/add_talangan'
import global from '../store/constant/global'
import icons from '../helper/icons'
import iconName from '../helper/constant/iconName'

export default function Routes() {
  const isLogin = useSelector((state) => state.Global[global.IS_LOGIN], shallowEqual)

  return (
    <Switch>
      <PrivateRoute path="/" exact component={DashboardScreen} />
      <PrivateRoute path="/account" exact component={AccountScreen} />
      <PrivateRoute path="/account/reset_password" exact component={ResetPasswordScreen} />
      <PrivateRoute path="/account_management" exact component={AccountManagementScreen} />
      <PrivateRoute path="/report/:path/:type?" exact component={ReportScreen} />
      <PrivateRoute path="/topup" exact component={TopUpScreen} />
      <PrivateRoute path="/data/:path" exact component={DataScreen} />
      <PrivateRoute path="/invoice/:idTrx/:date" exact component={Invoice} />
      <PrivateRoute path="/tools/credential_server" exact component={CredentialServerScreen} />
      <PrivateRoute path="/register_va/bulk" exact component={bulkRegisterVaScreen} />
      <PrivateRoute path="/transfer_money/domestic" exact component={DomesticScreen} />
      <PrivateRoute path="/transfer_money/international" exact component={InternationalScreen} />
      <PrivateRoute path="/add-item" exact component={TalanganScreen} />
      <PrivateRoute path="/news/:id" exact component={BeritaScreen} />
      <PrivateRoute
        path="/transaction/transfer/detail/:id"
        exact
        component={TransferDetailScreen}
      />
      <PrivateRoute
        path="/transaction/single_disbursement"
        exact
        component={SingleTransferScreen}
      />
      <PrivateRoute path="/request-payment" exact component={RequestPaymentScreen} />
      <Route path="/pay/:idPayment" exact component={PaymentRequestPublic} />
      <Route path="/auth/login" exact component={LoginScreen} />
      <PrivateRoute
        path="/not-found"
        component={() => (
          <div className="content-wrapper d-flex flex-column justify-content-center align-items-center container-access-denied mt-5">
            <img
              alt="Access Denied"
              src={icons(iconName.limitLogo, process.env.REACT_APP_BRAND_NAME)}
              width="30%"
              height="30%"
            />
            <h3>404 Not Found</h3>
          </div>
        )}
      />
      <Route
        render={() => (isLogin ? <Redirect to="/not-found" /> : <Redirect to="/auth/login" />)}
      />
    </Switch>
  )
}
