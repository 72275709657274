/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/named */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
/* eslint-disable no-lonely-if */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable radix */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import {
  Container,
  Form,
  Button,
  ProgressBar,
  Table,
  Row,
  Col,
  Modal,
  InputGroup,
  Image,
} from 'react-bootstrap'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { useHistory } from 'react-router-dom'

import './international.scss'
import BaseScreen from '../../base'
import authConstant from '../../../store/constant/auth'
import globalConstant from '../../../store/constant/global'
import AlertDialog from '../../dialog/alert'
import {
  checkStatus,
  getDetail,
  createOTP,
  getCountry,
  withdrawInquiryRemittance,
  withdrawPaymentRemittance,
  getBankInternational,
  getTransferPurposeListRemittance,
  getCurrencyByCountry,
} from '../../../api/action/transaction'
import Formatter from '../../../helper/formatter'
import Browser from '../../../helper/browser'
import DateTime from '../../../helper/datetime'
import LogoBank from '../../../helper/iconBanks'
import translate from '../../../helper/translate'
import {
  buttonResource,
  headerPageResource,
  placeholderResource,
  titleSentence,
} from '../../../helper/translatePage/languageResource'
import AccessDenied from '../../component/AccessDenied'
import GubukLogo from '../../../assets/imgs/logo/Gubuk.png'
import icons from '../../../helper/icons'
import iconName from '../../../helper/constant/iconName'
import checkAccess from '../../../helper/access'
import roleAccess from '../../../store/constant/roleAccess'
import getCsrf from '../../../api/action/getCsrf'
import logout from '../../../api/action/logout'

function withParams(Component) {
  return (props) => <Component {...props} navigate={useHistory()} />
}
class InternationalScreen extends BaseScreen {
  constructor(props) {
    super(props, ['page--withdraw'])

    this.state = {
      listcountry: [],
      listcurrencycountry: [],
      listbank: [],
      action: 'inquiry',
      balance: localStorage.getItem('currentBalance') ? localStorage.getItem('currentBalance') : 0,
      bankCode: '',
      detail: false,
      dataPayment: null,
      showModal: false,
      agree: false,
      showPass: false,
      urlImage: '',
      nominal: 0,
      nominaldestination: 0,
      accountNumber: '',
      anrekening: '',
      city: '',
      address: '',
      idnumberreceiver: '',
      swiftCode: '',
      phoneNumberReceiver: '',
      currency: '',
      selling_rate: 0,
      selectedOptionCountry: null,
      selectedOptionCurrencyCountry: null,
      selectedOptionBank: null,
      selectedOptionPurpose: null,
      listPurpose: [],
      purposeTransaction: '',
      ifsCode: '',
      nationality: '',
    }

    // this.showTransferMethod = this.showTransferMethod.bind(this)
    this.showTransferPayment = this.showTransferPayment.bind(this)
    this.handleDetail = this.handleDetail.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()

    const { dispatch } = this.props
    const { action } = this.state
    if (action === 'inquiry') {
      dispatch(getCurrencyByCountry()).then((listcurrencycountry) => {
        this.setState({
          listcurrencycountry,
        })
      })
      dispatch(getCountry()).then((listcountry) => {
        this.setState({
          listcountry,
        })
      })
      dispatch(getTransferPurposeListRemittance()).then((listPurpose) => {
        this.setState({
          listPurpose,
        })
      })
    }

    if (action === 'checkstatus') {
      this.doCheckStatusInterval()
    }
  }

  componentWillUnmount() {
    if (this.checkInterval) {
      clearInterval(this.checkInterval)
    }
  }

  readAccess = () => {
    // return checkAccess(this.props.appUserAccess, 'international', 'READ') && this.props.appEnableAPI
    return (
      checkAccess(this.props.appUserAccess, 'international', 'READ') &&
      this.props.appEnableAPI &&
      (this.props.appAgentUser == 'LI5350WBI' || this.props.appAgentUser == 'LI8781PBT')
    )
  }

  resetState = () => {
    this.setState({
      action: 'inquiry',
      balance: localStorage.getItem('currentBalance') ? localStorage.getItem('currentBalance') : 0,
      bankCode: '',
      detail: false,
      dataPayment: null,
      showModal: false,
      agree: false,
      showPass: false,
      urlImage: '',
      nominal: 0,
      nominaldestination: 0,
      accountNumber: '',
      anrekening: '',
      city: '',
      address: '',
      idnumberreceiver: '',
      swiftCode: '',
      phoneNumberReceiver: '',
      currency: '',
      selling_rate: 0,
      password: '',
      purposeTransaction: '',
      selectedOptionCountry: null,
      selectedOptionCurrencyCountry: null,
      selectedOptionBank: null,
      selectedOptionPurpose: null,
    })
  }

  doCheckStatusInterval = () => {
    this.checkInterval = setInterval(this.doCheckStatus, 10000)
  }

  doCheckStatus = async () => {
    const { dispatch } = this.props
    const { idtransaksi, checksum, uuidUser } = this.state

    const response = await dispatch(checkStatus(uuidUser, checksum, idtransaksi))
    if (response.isOK()) {
      if (this.checkInterval) {
        clearInterval(this.checkInterval)
      }
      const { payment_reff: idpayment, id_produk: idproduk } = response.getResultAsObject()
      const response2 = await dispatch(getDetail(uuidUser, checksum, idproduk, idpayment))
      if (response2.isOK()) {
        const { tabledata, produkname } = response2.getResultAsObject()

        this.setState({
          tabledata,
          produkname,
          idpayment,
        })
      } else {
        this.setState({ alertError: response.getRD(), action: 'inquiry' })
      }
    }
  }

  doPrint = async () => {
    // const { appUserPass } = this.props
    // const { idpayment } = this.state
    // let host = ''
    // if (process.env.REACT_APP_ENV === 'development') {
    //   host = 'http://localhost:3001/'
    // }
    // Browser.redirect(
    //   `${host}api/invoice?id_transaksi=${idpayment}&date=${DateTime.format(
    //     new Date(),
    //     DateTime.FMT_DATE_YMD
    //   )}&token=${appUserPass}`
    // )
  }

  doPayment = async () => {
    const { accountNumber, nominal, idtransaksi, checksum, uuidUser, pin } = this.state

    if (!pin) {
      this.showError('Enter OTP')
    } else {
      const { dispatch } = this.props
      let response = await dispatch(getCsrf())
      if (response.isOK()) {
        response = await dispatch(
          withdrawPaymentRemittance(accountNumber, nominal, idtransaksi, checksum, uuidUser, pin)
        )

        if (response.isOK()) {
          const { tabledata } = response.getResultAsObject()

          const paymentData = {
            // eslint-disable-next-line
            catatan: tabledata['CATATAN'],
            // eslint-disable-next-line
            id_transaksi: tabledata['ID TRANSAKSI'],
            // eslint-disable-next-line
            keterangan: tabledata['KETERANGAN'],
            // eslint-disable-next-line
            nama_channel: tabledata['NAMA CHANNEL'],
            // eslint-disable-next-line
            nama_pelanggan: tabledata['NAMA PELANGGAN'],
            // eslint-disable-next-line
            nama_produk: tabledata['NAMA PRODUK'],
            // eslint-disable-next-line
            no_pelanggan: tabledata['NO PELANGGAN'],
            // eslint-disable-next-line
            nominal: tabledata['NOMINAL'],
            // eslint-disable-next-line
            nominal_admin: tabledata['NOMINAL ADMIN'],
            // eslint-disable-next-line
            tanggal_transaksi: tabledata['TANGGAL TRANSAKSI'],
            // eslint-disable-next-line
            token_code: tabledata['KODE PENGAMBILAN'],
            // eslint-disable-next-line
            note: tabledata['CARA BAYAR'],
          }

          this.setState({
            tabledata: paymentData,
            dataPayment: response.getResultAsObject(),
            action: 'transfer-payment',
          })
        } else {
          this.showError(response.getRD())
        }
      } else {
        dispatch(logout())
      }
    }
  }

  doSubmit = async () => {
    const {
      countrydestination,
      nominal,
      nominaldestination,
      accountNumber,
      city,
      address,
      idnumberreceiver,
      phoneNumberReceiver,
      swiftCode,
      anrekening,
      password,
      currency,
      selectedOptionCountry,
      selectedOptionCurrencyCountry,
      selectedOptionBank,
      purposeTransaction,
      bankCode,
      ifsCode,
      nationality,
    } = this.state
    let error
    if (!countrydestination) {
      error = 'Choose destination country name'
    } else if (
      !nominal ||
      parseInt(nominal) <= 0 ||
      !nominaldestination ||
      parseInt(nominaldestination) <= 0
    ) {
      error = 'Enter transfer amount (must be greater than 0)'
      // eslint-disable-next-line radix
    } else if (!nominal || parseInt(nominal) <= 0) {
      error = 'Enter transfer amount (must be greater than 0)'
    } else if (!accountNumber) {
      error = 'Enter Account Number Destination'
    } else if (!city) {
      error = 'Enter City Destination'
    } else if (!address) {
      error = 'Enter Address Destination'
    } else if (!idnumberreceiver) {
      error = 'Enter ID Number Received'
    } else if (!swiftCode) {
      error = 'Enter Swift Code Destination'
    } else if (!anrekening) {
      error = 'Enter Name Destination Account'
    } else if (!password) {
      error = 'Enter Password Account'
    } else if (!purposeTransaction) {
      error = 'Enter Purpose Transaction'
    }

    if (
      (currency === 'PHP' ||
        currency === 'INR' ||
        currency === 'MYR' ||
        currency === 'SGD' ||
        currency === 'CNY' ||
        currency === 'HKD') &&
      bankCode == ''
    ) {
      error = 'Enter Bank Destination'
    }

    if (
      (currency === 'CNY' || currency === 'HKD' || currency === 'SGD') &&
      phoneNumberReceiver == ''
    ) {
      error = 'Enter Phone Number Receiver'
    }

    if (currency === 'INR' && ifsCode == '') {
      error = 'Enter IFS Code'
    }

    if ((currency === 'MYR' || currency === 'SGD') && nationality == '') {
      error = 'Enter IFS Code'
    }

    if (error) {
      this.showError(error)
    } else {
      const { dispatch } = this.props
      let response = await dispatch(getCsrf())
      if (response.isOK()) {
        response = await dispatch(
          withdrawInquiryRemittance(
            countrydestination,
            nominal,
            nominaldestination,
            accountNumber,
            city,
            address,
            idnumberreceiver,
            swiftCode,
            anrekening,
            password,
            currency,
            bankCode,
            purposeTransaction,
            phoneNumberReceiver,
            ifsCode,
            nationality
          )
        )

        if (response.isOK()) {
          const resOTP = await dispatch(createOTP(password, nominal))
          if (resOTP.isOK()) {
            const {
              tabledata,
              nominal: nominalbayar,
              produkname,
              checksum,
              uuid_user: uuidUser,
              idtransaksi,
              // url_image,
            } = response.getResultAsObject()
            // eslint-disable-next-line
            const inquirydata = {
              // eslint-disable-next-line
              catatan: tabledata['CATATAN'],
              // eslint-disable-next-line
              id_transaksi: tabledata['ID TRANSAKSI'],
              // eslint-disable-next-line
              keterangan: tabledata['KETERANGAN'],
              // eslint-disable-next-line
              nama_channel: tabledata['NAMA CHANNEL'],
              // eslint-disable-next-line
              nama_pelanggan: tabledata['NAMA PELANGGAN'],
              // eslint-disable-next-line
              nama_produk: tabledata['NAMA PRODUK'],
              // eslint-disable-next-line
              no_pelanggan: tabledata['NO PELANGGAN'],
              // eslint-disable-next-line
              nominal: tabledata['NOMINAL'],
              // eslint-disable-next-line
              nominal_admin: tabledata['NOMINAL ADMIN'],
              // eslint-disable-next-line
              tanggal_transaksi: tabledata['TANGGAL TRANSAKSI'],
            }
            this.setState({
              tabledata: inquirydata,
              nominalbayar,
              produkname,
              checksum,
              uuidUser,
              idtransaksi,
              action: 'result-inquiry',
              // bankChosen: '',
              // urlImage: url_image,
            })
          } else {
            this.showError(resOTP.getRD())
          }
        } else {
          this.showError(response.getRD())
        }
      } else {
        dispatch(logout())
      }
    }
  }

  doReset = () => {
    this.setState({
      action: 'inquiry',
      bankCode: '',
      bankNumber: '',
      bankName: '',
      nominal: '0',
      password: '',
      pin: '',
      selectedOptionCountry: null,
      selectedOptionCurrencyCountry: null,
      selectedOptionBank: null,
    })
  }

  handleChange = (e) => {
    const { selling_rate } = this.state
    let value

    if (e.target.name === 'bankNumber') {
      value = Formatter.serial(e.target.value, 24)
    } else if (e.target.name === 'nominal' || e.target.name === 'nominaldestination') {
      if (selling_rate == 0) {
        this.showError('Choose destination country name')
        value = 0
      } else {
        value = Formatter.price(e.target.value)
      }
    } else {
      value = e.target.value
    }

    if (e.target.value !== 0 && selling_rate !== 0 && e.target.name === 'nominal') {
      const valuetemp = e.target.value.replace(/[^0-9]/gi, '') / parseInt(selling_rate)
      this.setState({
        nominaldestination: Formatter.price(valuetemp > 1 ? valuetemp.toFixed(0) : 0),
      })
    }
    if (e.target.value !== 0 && selling_rate !== 0 && e.target.name === 'nominaldestination') {
      const valuetemp = parseInt(e.target.value.replace(/[^0-9]/gi, '')) * parseInt(selling_rate)
      this.setState({
        nominal: Formatter.price(valuetemp),
      })
    }
    this.setState({ [e.target.name]: value })
  }

  handleSelect = (selectedOption, prop) => {
    const { dispatch } = this.props
    if (prop.name && prop.name === 'currencycountry') {
      // eslint-disable-next-line prefer-const
      let option = this.state.listcurrencycountry.filter(
        (x) => x.id_country === selectedOption.value
      )[0]
      option.label = selectedOption.label
      option.value = selectedOption.value
      dispatch(getBankInternational(option.country_code)).then((listbank) => {
        this.setState({
          listbank,
        })
      })
      this.setState({
        countrydestination: option.country_code,
        currency: option.currency,
        selling_rate: option.selling_rate,
        selectedOptionCurrencyCountry: option,
      })
    } else if (prop.name && prop.name === 'country') {
      // eslint-disable-next-line prefer-const
      let option = this.state.listcountry.filter((x) => x.id_country === selectedOption.value)[0]
      option.label = selectedOption.label
      option.value = selectedOption.value
      this.setState({
        nationality: option.country_code,
        selectedOptionCountry: option,
      })
    } else if (prop.name && prop.name === 'bank') {
      // eslint-disable-next-line prefer-const
      let option = this.state.listbank.filter((x) => x.id === selectedOption.value)[0]
      option.label = selectedOption.label
      option.value = selectedOption.value
      this.setState({
        bankCode: selectedOption.value,
        selectedOptionBank: option,
      })
    } else if (prop.name && prop.name === 'purpose') {
      // eslint-disable-next-line prefer-const
      let option = this.state.listPurpose.filter((x) => x.id === selectedOption.value)[0]
      option.label = selectedOption.label
      option.value = selectedOption.value
      this.setState({
        purposeTransaction: selectedOption.value,
        selectedOptionPurpose: option,
      })
    }
  }

  showTransferPayment(bank) {
    this.setState({ action: 'transfer-payment', bankChosen: bank })
  }

  renderStatus() {
    const { tabledata, nominalbayar, nominaladmin, produkname } = this.state

    if (tabledata !== null && typeof tabledata === 'object') {
      return (
        <>
          <Table hover>
            <thead>
              <tr>
                <th colSpan="2">{produkname}</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(tabledata).map((key) => (
                <tr>
                  <td>{key}</td>
                  <td>{tabledata[key]}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th>ADMIN FEE</th>
                <th>Rp {Formatter.price(nominaladmin)}</th>
              </tr>
              <tr>
                <th>TOTAL PAYMENT</th>
                <th>
                  <strong>Rp {Formatter.price(nominalbayar)}</strong>
                </th>
              </tr>
            </tfoot>
          </Table>
        </>
      )
    }
    return (
      <ProgressBar
        style={{ width: '100%' }}
        className="mt-3 mb-3"
        variant="info"
        label="Please Wait..."
        animated
        now={100}
      />
    )
  }

  // used for insert pin transaction
  renderPayment() {
    const { appIsLoading } = this.props
    const { tabledata, nominalbayar, nominaladmin, produkname, pin } = this.state

    return (
      <div className="pl-5 pr-5 pb-5">
        <Table hover>
          <thead>
            <tr>
              <th colSpan="2">{produkname}</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(tabledata).map((key) => (
              <tr>
                <td>{key}</td>
                <td>{tabledata[key]}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th>ADMIN FEE AAA</th>
              <th>Rp {Formatter.price(nominaladmin)}</th>
            </tr>
            <tr>
              <th>TOTAL PAYMENT</th>
              <th>
                <strong>Rp {Formatter.price(nominalbayar)}</strong>
              </th>
            </tr>
          </tfoot>
        </Table>
        <Form.Group className="mb-3 mt-3 payment-form">
          <Form.Label className="color-grey fontSize-16-SemiBold">
            {translate('pinTrx', this.props.languange, titleSentence)}
          </Form.Label>
          <Form.Control
            type="password"
            maxLength="64"
            name="pin"
            value={pin}
            onChange={this.handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Button
            type="submit"
            size="md"
            variant="danger"
            block
            disabled={appIsLoading}
            onClick={this.doPayment}
          >
            Transfer
          </Button>
        </Form.Group>
        <Form.Group>
          <Button
            type="button"
            size="md"
            variant="outline-secondary"
            block
            disabled={appIsLoading}
            onClick={this.doReset}
          >
            {translate('reset', this.props.languange, buttonResource)}
          </Button>
        </Form.Group>
      </div>
    )
  }

  handleDetail() {
    this.setState({ detail: !this.state.detail })
  }

  // used for show detail payment by balance
  transferPayment() {
    const { appIsLoading } = this.props
    const { tabledata, selectedOptionCurrencyCountry, urlImage } = this.state
    // eslint-disable-next-line
    const total = parseInt(tabledata.nominal) + parseInt(tabledata.nominal_admin)
    return (
      <Container fluid className="p-3 container-success-topup">
        <div className="subcontainer-success-topup">
          <div className="container-flex-space-between">
            <div className="box-info">
              <div className="text color-red fontSize-17">ORDER ID #{tabledata.id_transaksi}</div>
              <div className="color-grey fontSize-16">{tabledata.tanggal_transaksi} WIB</div>
            </div>
          </div>
          <div className="container-bank-info">
            <Image src={urlImage != '' ? urlImage : GubukLogo} />
            <div className="bank-info ml-3">
              <div className="fontSize-19">{tabledata.nama_channel}</div>
              <div className="color-grey fontSize-19">{tabledata.nama_pelanggan}</div>
            </div>
          </div>
          <div className="container-copy-data mb-3">
            {/* eslint-disable-next-line */}
            <div className="fontSize-19-Bold copy-data">
              <div className="text">{tabledata.no_pelanggan}</div>
              <div className="button-copy" onClick={() => this.copyText(tabledata.no_pelanggan)}>
                <Image src={icons(iconName.copy)} className="" />
                <div className="ml-2 button-copy-text fontSize-16-Medium color-white">
                  {translate('copy', this.props.language, buttonResource)}
                </div>
              </div>
            </div>
          </div>
          <div className="container-flex-space-between mb-2">
            <div className="color-grey fontSize-16 ">
              {translate('nominalTransfer', this.props.language, placeholderResource)}
            </div>
            <div className="color-grey fontSize-16 ">Rp. {Formatter.price(tabledata.nominal)}</div>
          </div>
          <div className="container-flex-space-between mb-2">
            <div className="color-grey fontSize-16">
              {translate('adminFee', this.props.language, titleSentence)}
            </div>
            <div className="color-grey fontSize-16">
              Rp. {Formatter.price(tabledata.nominal_admin)}
            </div>
          </div>
          <div className="container-copy-data mb-3">
            {/* eslint-disable-next-line */}
            <div className="fontSize-19-Bold copy-data">
              <div className="text">
                Rp {parseInt(tabledata.nominal) + parseInt(tabledata.nominal_admin)}
              </div>
              <div
                className="button-copy"
                onClick={() =>
                  this.copyText(parseInt(tabledata.nominal) + parseInt(tabledata.nominal_admin))
                }
              >
                <Image src={icons(iconName.copy)} className="" />
                <div className="ml-2 button-copy-text fontSize-16-Medium color-white">
                  {translate('copy', this.props.language, buttonResource)}
                </div>
              </div>
            </div>
          </div>
          {tabledata.token_code && (
            <Form.Group>
              <div className="footer-title">
                <span>KODE PENGAMBILAN</span>
              </div>
              <Row className="copy-wrapper">
                <div className="col" style={{ textAlign: 'center' }}>
                  {tabledata.token_code}
                </div>
              </Row>
              <div>{tabledata.note}</div>
            </Form.Group>
          )}
        </div>
      </Container>
    )
  }

  // transferMethod() {
  //   // const { appIsLoading } = this.props
  //   return (
  //     <div className="pl-5 pr-5 pb-5">
  //       Dengan memilih metode transfer, saya telah setuju dengan syarat & ketentuan transaksi LinkQu
  //       <div className="p-3 payment-method mb-3">
  //         <div className="method-list">
  //           <button
  //             type="button"
  //             className="list-wrapper"
  //             name="mandiri"
  //             onClick={() => this.showTransferPayment('mandiri')}
  //           >
  //             <div className="bank">
  //               <img src={LogoBank('mandiri')} alt="" className="bank-logo" />
  //               <span>Mandiri</span>
  //             </div>
  //             <div className="arrow text-primary" style={{ textAlign: 'right' }}>
  //               <FontAwesomeIcon icon={faChevronRight} size="2x" />
  //             </div>
  //           </button>
  //           <button
  //             type="button"
  //             className="list-wrapper"
  //             name="bni"
  //             onClick={() => this.showTransferPayment('bank bni')}
  //           >
  //             <div className="bank">
  //               <img src={LogoBank('bank bni')} alt="" className="bank-logo" />
  //               <span>BNI</span>
  //             </div>
  //             <div className="arrow text-primary" style={{ textAlign: 'right' }}>
  //               <FontAwesomeIcon icon={faChevronRight} size="2x" />
  //             </div>
  //           </button>
  //           <button
  //             type="button"
  //             className="list-wrapper"
  //             name="permata"
  //             onClick={() => this.showTransferPayment('permata bank')}
  //           >
  //             <div className="bank">
  //               <img src={LogoBank('permata bank')} alt="" className="bank-logo" />
  //               <span>Permata</span>
  //             </div>
  //             <div className="arrow text-primary" style={{ textAlign: 'right' }}>
  //               <FontAwesomeIcon icon={faChevronRight} size="2x" />
  //             </div>
  //           </button>
  //           <button
  //             type="button"
  //             className="list-wrapper"
  //             name="bri"
  //             onClick={() => this.showTransferPayment('bank bri')}
  //           >
  //             <div className="bank">
  //               <img src={LogoBank('bank bri')} alt="" className="bank-logo" />
  //               <span>bri</span>
  //             </div>
  //             <div className="arrow text-primary" style={{ textAlign: 'right' }}>
  //               <FontAwesomeIcon icon={faChevronRight} size="2x" />
  //             </div>
  //           </button>
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }

  handleShowedPass = (action) => {
    switch (action) {
      case 'show':
        this.setState({ showPass: true })
        break
      case 'hidden':
        this.setState({ showPass: false })
        break

      default:
        this.setState({ showPass: false })
        break
    }
  }

  resultInquiry() {
    const { appIsLoading } = this.props
    const { tabledata, selectedOptionCurrencyCountry, urlImage } = this.state
    // eslint-disable-next-line
    let bankName = ''
    if (tabledata !== null) {
      bankName = tabledata.nama_channel
    }
    const total = parseInt(tabledata.nominal) + parseInt(tabledata.nominal_admin)
    return (
      <div className="container-result-withdraw">
        <div className="p-3 result-inquiry-withdraw card mb-3">
          <div className="header-card d-flex align-items-center">
            <img
              src={urlImage != '' ? urlImage : GubukLogo}
              alt={selectedOptionCurrencyCountry.label}
              className="bank-logo mr-3"
            />
          </div>
          <Row className="mb-3">
            <Col sm="7">
              <span className="props fontSize-16-SemiBold color-red">
                {translate('accountNumber', this.props.languange, titleSentence)}
              </span>
              <br />
              <span className="fontSize-16-SemiBold">{tabledata.no_pelanggan}</span>
              <br />
              <span className="text-uppercase fontSize-16-SemiBold ">
                {tabledata.nama_pelanggan}
              </span>
            </Col>
            <Col sm="5">
              <span className="props fontSize-16-SemiBold color-red">
                {translate('amount', this.props.languange, titleSentence)}
              </span>
              <br />
              <span className="fontSize-16-SemiBold">Rp {Formatter.price(tabledata.nominal)}</span>
            </Col>
          </Row>
          <Row>
            <Col sm="7">
              <div className="container-total">
                <span className="props fontSize-16-SemiBold ">Total</span>
                <br />
                <span className="fontSize-16-Medium">Rp {Formatter.price(total)}</span>
              </div>
            </Col>
            <Col sm="5">
              <span className="props fontSize-16-SemiBold color-red">
                {translate('adminFee', this.props.languange, titleSentence)}
              </span>
              <br />
              <span className="fontSize-16-SemiBold">
                Rp {Formatter.price(tabledata.nominal_admin)}
              </span>
            </Col>
          </Row>
        </div>
        <Form.Group className="mt-5 payment-form">
          <div className="mb-3">
            <Image src={icons(iconName.alert)} className="" />
            <div className="color-pink-dark fontSize-16 d-inline ml-4">
              {translate('withdrawInfoOtp', this.props.languange, titleSentence)}
            </div>
          </div>
          <Row>
            <Col sm="7">
              <InputGroup className="form-password">
                <Form.Control
                  type={this.state.showPass ? 'text' : 'password'}
                  maxLength="64"
                  name="pin"
                  value={this.state.pin}
                  onChange={this.handleChange}
                  className="custom-input fontSize-16"
                />
                {this.state.showPass ? (
                  <InputGroup.Text onClick={() => this.handleShowedPass('hidden')}>
                    <Image src={icons(iconName.showPass)} />
                  </InputGroup.Text>
                ) : (
                  <InputGroup.Text onClick={() => this.handleShowedPass('show')}>
                    <Image src={icons(iconName.hidePass)} />
                  </InputGroup.Text>
                )}
              </InputGroup>
            </Col>
            <Col sm="1">
              <div />
            </Col>
            <Col sm="4">
              <Button
                type="submit"
                size="md"
                block
                // disabled={appIsLoading || !this.state.agree}
                disabled={appIsLoading}
                onClick={this.doPayment}
                className="btn-custom clear-margin btn-custom-reset-password fontSize-17"
              >
                {translate('withdrawButton', this.props.languange, buttonResource)}
                <Image src={icons(iconName.arrowWhite)} className="ml-3" />
              </Button>
            </Col>
          </Row>
        </Form.Group>
      </div>
    )
  }

  setActive = (data) => {
    // eslint-disable-next-line prefer-const
    let { nominalByPercent, nominal, balance } = this.state
    balance = balance.replace(/\./g, '')
    // eslint-disable-next-line no-restricted-syntax
    for (const item of nominalByPercent) {
      if (item.value === data.value) {
        item.active = true
        nominal = (parseInt(balance) * parseInt(item.value)) / 100
      } else {
        item.active = false
      }
    }

    this.setState({
      nominalByPercent: nominalByPercent,
      nominal: Formatter.price(parseInt(nominal)),
    })
  }

  copyText = (copyText) => {
    navigator.clipboard.writeText(copyText)

    /* Alert the copied text */
    alert(`Copied the text: ${copyText}`)
  }

  renderInquiry() {
    const { appIsLoading, appUserEmail } = this.props
    const {
      listcountry,
      listbank,
      nominal,
      password,
      balance,
      nominaldestination,
      accountNumber,
      anrekening,
      city,
      address,
      idnumberreceiver,
      swiftCode,
      phoneNumberReceiver,
      selectedOptionCountry,
      selectedOptionCurrencyCountry,
      selectedOptionBank,
      listPurpose,
      selectedOptionPurpose,
      currency,
      ifsCode,
      listcurrencycountry,
      nationality,
    } = this.state
    const optionsCountry = []
    const optionsCurrencyCountry = []
    const optionsBank = []
    const optionsPurpose = []
    // eslint-disable-next-line
    for (let i = 0; i < listcurrencycountry.length; i++) {
      // eslint-disable-next-line
      const options = { value: listcurrencycountry[i].id_country, label: listcurrencycountry[i].country_name+' - '+listcurrencycountry[i].currency }
      optionsCurrencyCountry.push(options)
    }
    for (let i = 0; i < listcountry.length; i++) {
      // eslint-disable-next-line
      const options = { value: listcountry[i].id_country, label: listcountry[i].country_name }
      optionsCountry.push(options)
    }
    // eslint-disable-next-line
    for (let i = 0; i < listbank.length; i++) {
      // eslint-disable-next-line
      const options = { value: listbank[i].id, label: listbank[i].namaBank }
      optionsBank.push(options)
    }
    for (let i = 0; i < listPurpose.length; i++) {
      // eslint-disable-next-line
      const options = { value: listPurpose[i].id, label: listPurpose[i].name }
      optionsPurpose.push(options)
    }

    let showPhoneNumberReceiver = false
    if (currency === 'CNY' || currency === 'HKD' || currency === 'SGD') {
      showPhoneNumberReceiver = true
    }

    let showIfsCode = false
    if (currency === 'INR') {
      showIfsCode = true
    }

    let showNationality = false
    if (currency === 'MYR' || currency === 'SGD') {
      showNationality = true
    }

    return (
      <div className="pb-5 container-transfer-saldo">
        <div className="total-balance">
          <div className="fontSize-18">
            {translate('availableBalance', this.props.languange, titleSentence)}
          </div>
          <div className="fontSize-26">Rp. {balance}</div>
        </div>
        <Row>
          <Col sm="6" xs="12">
            <label>{translate('inputCountry', this.props.languange, placeholderResource)}</label>
            <Select
              options={optionsCurrencyCountry}
              onChange={this.handleSelect}
              value={selectedOptionCurrencyCountry}
              placeholder={translate('select', this.props.languange, placeholderResource)}
              className="custom-input"
              name="currencycountry"
            />
          </Col>
          <Col sm="6" xs="12">
            <Form.Group className="mb-3 form-item">
              <Form.Label className="color-grey fontSize-16-SemiBold">
                {translate('nominalTransfer', this.props.languange, placeholderResource)} IDR
              </Form.Label>
              <Form.Control
                type="text"
                name="nominal"
                placeholder={translate(
                  'inputNominalIdr',
                  this.props.languange,
                  placeholderResource
                )}
                value={nominal}
                onChange={this.handleChange}
                className="custom-input fontSize-16"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm="6" xs="12">
            <label>{translate('chooseBank', this.props.languange, placeholderResource)}</label>
            <Select
              options={optionsBank}
              onChange={this.handleSelect}
              value={selectedOptionBank}
              placeholder={translate('select', this.props.languange, placeholderResource)}
              className="custom-input mb-3 mt-3"
              name="bank"
            />
          </Col>
          <Col sm="6" xs="12">
            <Form.Group className="mb-3 mt-3">
              <Form.Label className="color-grey fontSize-16-SemiBold">
                {`${translate('or', this.props.languange, placeholderResource)} ${currency}`}
              </Form.Label>
              <Form.Control
                type="text"
                name="nominaldestination"
                value={nominaldestination}
                onChange={this.handleChange}
                className="custom-input fontSize-16"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm="6" xs="12">
            <Form.Group className="mb-3 mt-3">
              <Form.Label className="color-grey fontSize-16-SemiBold">
                {translate('accountNumber2', this.props.languange, placeholderResource)}
              </Form.Label>
              <Form.Control
                type="text"
                name="accountNumber"
                value={accountNumber}
                onChange={this.handleChange}
                className="custom-input fontSize-16"
              />
            </Form.Group>
          </Col>
          <Col sm="6" xs="12">
            <Form.Group className="mb-3 mt-3">
              <Form.Label className="color-grey fontSize-16-SemiBold">
                {translate('inputNameDestination', this.props.languange, placeholderResource)}
              </Form.Label>
              <Form.Control
                type="text"
                name="anrekening"
                value={anrekening}
                onChange={this.handleChange}
                className="custom-input fontSize-16"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm="6" xs="12">
            <Form.Group className="mb-3 mt-3">
              <Form.Label className="color-grey fontSize-16-SemiBold">
                {translate('inputCity', this.props.languange, placeholderResource)}
              </Form.Label>
              <Form.Control
                type="text"
                name="city"
                value={city}
                onChange={this.handleChange}
                className="custom-input fontSize-16"
              />
            </Form.Group>
          </Col>
          <Col sm="6" xs="12">
            <Form.Group className="mb-3 mt-3">
              <Form.Label className="color-grey fontSize-16-SemiBold">
                {translate('inputAddress', this.props.languange, placeholderResource)}
              </Form.Label>
              <Form.Control
                type="text"
                name="address"
                value={address}
                onChange={this.handleChange}
                className="custom-input fontSize-16"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm="6" xs="12">
            <Form.Group className="mb-3 mt-3">
              <Form.Label className="color-grey fontSize-16-SemiBold">
                {translate('inputIDNumberReceived', this.props.languange, placeholderResource)}
              </Form.Label>
              <Form.Control
                type="text"
                name="idnumberreceiver"
                value={idnumberreceiver}
                onChange={this.handleChange}
                className="custom-input fontSize-16"
              />
            </Form.Group>
          </Col>
          <Col sm="6" xs="12">
            <Form.Group className=" mt-3">
              <Form.Label className="color-grey fontSize-16-SemiBold">
                {translate('inputSwiftCode', this.props.languange, placeholderResource)}
              </Form.Label>
              <Form.Control
                type="text"
                name="swiftCode"
                value={swiftCode}
                onChange={this.handleChange}
                className="custom-input fontSize-16"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm="6" xs="12">
            <Form.Group controlId="formBasicPassword" className="form-password mt-3">
              <Form.Label className="color-grey fontSize-16-SemiBold">
                {translate('accountPass', this.props.languange, placeholderResource)}
              </Form.Label>
              <InputGroup>
                <Form.Control
                  type={this.state.showPass ? 'text' : 'password'}
                  maxLength="64"
                  name="password"
                  placeholder={translate('inputPass', this.props.languange, placeholderResource)}
                  value={password}
                  onChange={this.handleChange}
                  className="custom-input fontSize-16"
                />
                {this.state.showPass ? (
                  <InputGroup.Text onClick={() => this.handleShowedPass('hidden')}>
                    <Image src={icons(iconName.showPass)} />
                  </InputGroup.Text>
                ) : (
                  <InputGroup.Text onClick={() => this.handleShowedPass('show')}>
                    <Image src={icons(iconName.hidePass)} />
                  </InputGroup.Text>
                )}
              </InputGroup>
            </Form.Group>
          </Col>
          <Col sm="6" xs="12">
            <label>{translate('senderGoal', this.props.languange, placeholderResource)}</label>
            <Select
              options={optionsPurpose}
              onChange={this.handleSelect}
              value={selectedOptionPurpose}
              placeholder={translate('select', this.props.languange, placeholderResource)}
              className="custom-input mb-3 mt-3"
              name="purpose"
            />
          </Col>
        </Row>
        <Row>
          {showPhoneNumberReceiver && (
            <Col sm="6" xs="12">
              <Form.Group className=" mt-3">
                <Form.Label className="color-grey fontSize-16-SemiBold">
                  {translate('inputPhoneNumberReceived', this.props.languange, placeholderResource)}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="phoneNumberReceiver"
                  value={phoneNumberReceiver}
                  onChange={this.handleChange}
                  className="custom-input fontSize-16"
                />
              </Form.Group>
            </Col>
          )}
          {showIfsCode && (
            <Col sm="6" xs="12">
              <Form.Group className=" mt-3">
                <Form.Label className="color-grey fontSize-16-SemiBold">
                  {translate('inputIfsCode', this.props.languange, placeholderResource)}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="ifsCode"
                  value={ifsCode}
                  onChange={this.handleChange}
                  className="custom-input fontSize-16"
                />
              </Form.Group>
            </Col>
          )}
          {showNationality && (
            <Col sm="6" xs="12">
              {/* <Form.Group className=" mt-3">
                <Form.Label className="color-grey fontSize-16-SemiBold">
                  {translate('inputNationality', this.props.languange, placeholderResource)}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="nationality"
                  value={nationality}
                  onChange={this.handleChange}
                  className="custom-input fontSize-16"
                />
              </Form.Group> */}
              <label>
                {translate('inputNationality', this.props.languange, placeholderResource)}
              </label>
              <Select
                options={optionsCountry}
                onChange={this.handleSelect}
                value={selectedOptionCountry}
                placeholder={translate('select', this.props.languange, placeholderResource)}
                className="custom-input"
                name="country"
              />
            </Col>
          )}
          <Col sm="6" xs="12">
            <Button
              type="submit"
              size="md"
              variant="primary"
              block
              disabled={appIsLoading}
              onClick={this.doSubmit}
              className="btn-custom btn-custom-reset-password fontSize-17"
            >
              <Image src={icons(iconName.checkSquare)} className="mr-2" />
              {translate('checkSecond', this.props.languange, buttonResource)}
            </Button>
          </Col>
          <Col sm="6" xs="12">
            <div />
          </Col>
        </Row>
      </div>
    )
  }

  closeModal = () => {
    this.setState({ showModal: false })
  }

  render() {
    const { appIsLoading, appUserData } = this.props
    const { action, alertError } = this.state

    const isParent = appUserData?.id_user_parent == null

    return (
      <>
        {!this.readAccess() ? (
          <AccessDenied />
        ) : (
          <>
            <Container fluid className="main-form">
              {action !== 'inquiry' ? (
                <div className="mb-3 container-flex-center" onClick={() => this.resetState()}>
                  <button type="button" className="btn btn-outline-primary back-button">
                    <Image src={icons(iconName.backButton)} className="" />
                  </button>
                </div>
              ) : (
                <></>
              )}
              {isParent ? (
                <>
                  {appIsLoading && (
                    <ProgressBar
                      style={{ width: '100%' }}
                      className="mt-3 mb-3"
                      variant="info"
                      label="Please Wait..."
                      animated
                      now={100}
                    />
                  )}
                  {action === 'inquiry' && this.renderInquiry()}
                  {action === 'result-inquiry' && this.resultInquiry()}
                  {action === 'transfer-payment' && this.transferPayment()}
                </>
              ) : (
                <AccessDenied />
              )}
            </Container>
          </>
        )}
        <AlertDialog
          show={!!alertError}
          onHide={this.closeError}
          onConfirm={this.closeError}
          type="warning"
          messageTitle={alertError}
        />
        <Modal show={this.state.showModal} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>FAQ</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* eslint-disable-next-line */}
            <iframe src="https://www.linkqu.id/faq-produk-dan-layanan/" />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" className="d-none">
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

InternationalScreen.defaultProps = {
  appIsLoading: false,
}

InternationalScreen.propTypes = {
  appIsLoading: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
    appUserPass: state.Auth[authConstant.USER_PASS],
    appUserData: state.Auth[authConstant.USER_DATA],
    appUserEmail: state.Auth[authConstant.USER_EMAIL],
    languange: state.Global[globalConstant.LANGUAGE],
    appEnableAPI: state.Auth[authConstant.ENABLE_API],
    appAgentUser: state.Auth[authConstant.AGENT_USER],
    appUserAccess: state.RoleAccess[roleAccess.ACCESS],
  }),
  (dispatch) => ({ dispatch })
)(withParams(InternationalScreen))
