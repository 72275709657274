/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */
import React from 'react'
import { Badge, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfo } from '@fortawesome/free-solid-svg-icons'
import Formatter from '../../../helper/formatter'
import translate from '../../../helper/translate'
import { columnResource, placeholderResource } from '../../../helper/translatePage/languageResource'
import dataType from '../../../helper/constant/dataType'
import DateTime from '../../../helper/datetime'

const TransferColumn = (actionFunction, languange) => ({
  key: 'dp.id',
  columns: [
    {
      name: 'ID Trx',
      selector: 'id',
      sortable: true,
      type: dataType.INTEGER,
      showFilter: true,
    },
    {
      name: translate('time', languange, columnResource),
      selector: 'time_created',
      sortable: true,
      type: dataType.DATERANGE,
      default: true,
      showFilter: true,
    },
    {
      name: translate('accName', languange, columnResource),
      selector: 'account_name',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('accNumber', languange, columnResource),
      selector: 'account_number',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('amount', languange, columnResource),
      selector: 'amount',
      sortable: true,
      type: dataType.INTEGER,
      showFilter: true,
    },
    {
      name: translate('expDate', languange, columnResource),
      selector: 'time_expired',
      sortable: true,
      type: dataType.DATERANGE,
      showFilter: true,
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      cell: (row) => {
        let word
        let color
        const isExpired = new Date(row.time_expired) < Date.now()
        switch (`${row.status}`) {
          case 'NEW':
            word = 'NEW'
            color = 'warning'
            break
          case 'PAID':
            word = translate('donePay', languange, placeholderResource)
            color = 'primary'
            break
          case 'PROCESS_COMPLETED':
            word = translate('processTf', languange, placeholderResource)
            color = 'warning'
            break
          case 'COMPLETED':
            word = translate('success', languange, placeholderResource)
            color = 'success'
            break
          case 'FAILED':
            word = translate('fail', languange, placeholderResource)
            color = 'danger'
            break
          default:
            word = 'other'
            color = 'secondary'
            break
        }
        return (
          <Badge variant={color}>
            <div>
              {row.status === 'NEW' && isExpired ? (
                <b variant={color}>EXPIRED</b>
              ) : (
                <b variant={color}>{word}</b>
              )}
              <br />
            </div>
          </Badge>
        )
      },
      type: dataType.ENUM,
      default_value: [
        {
          id: 'NEW',
          name: 'NEW',
        },
        {
          id: 'EXPIRED',
          name: 'EXPIRED',
        },
        {
          id: 'PAID',
          name: 'PAID',
        },
        {
          id: 'PROCESS_COMPLETED',
          name: 'PROCESS_COMPLETED',
        },
        {
          id: 'COMPLETED',
          name: 'COMPLETED',
        },
        {
          id: 'FAILED',
          name: 'FAILED',
        },
      ],
      showFilter: true,
    },
    {
      name: translate('action', languange, columnResource),
      cell: (row) => {
        return (
          <div style={{ flexDirection: 'column' }}>
            <div>
              <Button
                size="sm"
                variant="outline-info"
                className="mr-1"
                data-action="transfer_detail"
                onClick={(e) => actionFunction(row, e)}
              >
                <FontAwesomeIcon fixedWidth icon={faInfo} />
              </Button>
            </div>
          </div>
        )
      },
      showFilter: false,
    },
  ],
})

export default TransferColumn
