/* eslint-disable no-unused-vars */
import React from 'react'
import { Badge, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import translate from '../../../helper/translate'
import Formatter from '../../../helper/formatter'
import { columnResource } from '../../../helper/translatePage/languageResource'
import dataType from '../../../helper/constant/dataType'

const aggregatorFeeMonthly = (action, languange) => ({
  key: 'id',
  columns: [
    {
      name: translate('reportMonth', languange, columnResource),
      selector: 'bulan',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('trxVa', languange, columnResource),
      selector: 'trxva',
      sortable: true,
      type: dataType.INTEGER,
      showFilter: true,
    },
    {
      name: translate('feeVa', languange, columnResource),
      selector: 'feeva',
      sortable: true,
      type: dataType.INTEGER,
      showFilter: true,
    },
    {
      name: translate('trxTrf', languange, columnResource),
      selector: 'trxtrf',
      sortable: true,
      type: dataType.INTEGER,
      showFilter: true,
    },
    {
      name: translate('feeTrf', languange, columnResource),
      selector: 'feetrf',
      sortable: true,
      type: dataType.INTEGER,
      showFilter: true,
    },
    {
      name: translate('totalTrx', languange, columnResource),
      selector: 'totaltrx',
      sortable: true,
      type: dataType.INTEGER,
      showFilter: true,
    },
    {
      name: translate('totalFee', languange, columnResource),
      selector: 'totalfee',
      sortable: true,
      type: dataType.INTEGER,
      showFilter: true,
    },
  ],
})

export default aggregatorFeeMonthly
