const Downloader = {
  format(s, c) {
    return s.replace(/{(\w+)}/g, (m, p) => c[p])
  },

  tableToExcel(tableRef, fileName, sheetName) {
    const table = tableRef.outerHTML

    const uri = 'data:application/vnd.ms-excel;base64,'
    const template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-mic' +
      'rosoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta cha' +
      'rset="UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:Exce' +
      'lWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/>' +
      '</x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></' +
      'xml><![endif]--></head><body>{table}</body></html>'

    const context = {
      worksheet: sheetName || 'Worksheet',
      table,
    }

    // If IE11
    if (window.navigator.msSaveOrOpenBlob) {
      const fileData = [
        `${
          '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-mic' +
          'rosoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta cha' +
          'rset="UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:Exce' +
          'lWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/>' +
          '</x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></' +
          'xml><![endif]--></head><body>'
        }${table}</body></html>`,
      ]
      const blobObject = new Blob(fileData)
      document.getElementById('react-html-table-to-excel').click()(() => {
        window.navigator.msSaveOrOpenBlob(blobObject, `${fileName}.xls`)
      })

      return true
    }

    const element = window.document.createElement('a')
    element.href =
      uri + window.btoa(unescape(encodeURIComponent(Downloader.format(template, context))))
    element.download = `${fileName}.xls`
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)

    return true
  },
}

export default Downloader
