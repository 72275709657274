import roleAccess from '../constant/roleAccess'

const initial = {
  [roleAccess.ROLE]: null,
  [roleAccess.ACCESS]: null,
}

export default (state = initial, action) => {
  switch (action.type) {
    case roleAccess.ROLE:
    case roleAccess.ACCESS:
      return {
        ...state,
        [action.type]: action.payload,
      }
    case roleAccess.SET_ROLE_ACCESS:
      return {
        ...state,
        ...action.payload,
      }
    case roleAccess.CLEAR_ROLE_ACCESS:
      return initial

    default:
      return state
  }
}
