/* eslint-disable react/react-in-jsx-scope */
import { connect } from 'react-redux'
import {
  Container,
  Row,
  Col,
  Card,
  Spinner,
  Form,
  Button,
  InputGroup,
  Alert,
  Image,
} from 'react-bootstrap'
import PropTypes from 'prop-types'
import PasswordStrengthBar from 'react-password-strength-bar'

import './resetPassword.scss'
import BaseScreen from '../base'
import globalConstant from '../../store/constant/global'
import authConstant from '../../store/constant/auth'
import { updateAccount } from '../../api/action/dataAccount'
import AlertDialog from '../dialog/alert'
import { resetPasswordLangResource } from '../../helper/translatePage/languageResource'
import roleAccess from '../../store/constant/roleAccess'
import checkAccess from '../../helper/access'
import AccessDenied from '../component/AccessDenied'
import icons from '../../helper/icons'
import iconName from '../../helper/constant/iconName'
import getCsrf from '../../api/action/getCsrf'
import logout from '../../api/action/logout'

class ResetPasswordScreen extends BaseScreen {
  constructor(props) {
    super(props, ['page--account'])
    this.state = {
      isOpen: false,
      userId: '',
      dataCust: [],
      loading: false,
      alertError: '',
      balanceFresh: false,
      userPassword: '',
      userPassword2: '',
      newPassword: '',
      newPassword2: '',
      passwordErr: false,
      minChar: false,
      sending: false,
      showOldPass: false,
      showNewPass: false,
      showNewPass2: false,
    }
  }

  showError = (alertError) => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ alertError })
  }

  closeError = () => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ alertError: '' })
  }

  componentDidMount() {
    super.componentDidMount()
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
    if (event.target.name === 'userPassword2') {
      if (this.state.userPassword !== event.target.value) {
        this.setState({ passwordErr: true, sending: true })
      } else {
        this.setState({ passwordErr: false, sending: false })
      }
    }

    if (event.target.name === 'newPassword2') {
      if (this.state.newPassword !== event.target.value) {
        this.setState({ passwordErr: true, sending: true })
      } else {
        this.setState({ passwordErr: false, sending: false })
      }
    }

    if (event.target.name === 'newPassword') {
      if (event.target.value.length < 12) {
        this.setState({ minChar: true, sending: true })
      } else {
        this.setState({ minChar: false, sending: false })
      }
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault()
    this.setState({ sending: true })
    const { userPassword, newPassword, newPassword2 } = this.state
    if (newPassword !== newPassword2) {
      this.setState({ passwordErr: true, sending: false })
    } else {
      const { dispatch, appUserData } = this.props
      let response = await dispatch(getCsrf())
      if (response.isOK()) {
        response = await dispatch(
          // eslint-disable-next-line
          updateAccount(appUserData.id, appUserData.username, appUserData.username_agen, newPassword, userPassword)
        )

        if (response.getRC() === '00') {
          this.showError('Account password successfully updated')
          this.setState({
            userPassword: '',
            newPassword: '',
            newPassword2: '',
            accountPassword: '',
            sending: false,
            modalEdit: false,
            userId: '',
          })
        } else {
          this.showError(response.getRD())
          this.setState({ sending: false })
        }
      } else {
        dispatch(logout())
      }
    }
  }

  translate = (key) => {
    const { language } = this.props

    let result = ''
    if (language === 'id') {
      // eslint-disable-next-line
      result = resetPasswordLangResource.id[key]
    } else {
      // eslint-disable-next-line
      result = resetPasswordLangResource.en[key]
    }

    return result !== undefined ? result : 'Language not found'
  }

  handleShowedPass = (action, name) => {
    switch (action) {
      case 'show':
        this.setState({ [name]: true })
        break
      case 'hidden':
        this.setState({ [name]: false })
        break

      default:
        this.setState({ [name]: false })
        break
    }
  }

  updateAccess = () => {
    return checkAccess(this.props.appUserAccess, 'reset_password', 'UPDATE')
  }

  render() {
    // eslint-disable-next-line
    const { appAgentName, appIsLoading, appUserData } = this.props
    // eslint-disable-next-line
    const { loading, alertError, balanceFresh, userPassword, newPassword, newPassword2, passwordErr, sending, language } = this.state
    return (
      <>
        <Container fluid className="p-3">
          {!this.updateAccess() ? (
            <AccessDenied />
          ) : (
            <>
              {loading ? (
                <>
                  <Spinner size="xs" className="mr-1" animation="border" variant="primary" />
                </>
              ) : (
                <>
                  {this.props.environment === 'sandbox' ? (
                    <Alert variant="warning" className="mb-3">
                      Anda di dalam Mode Sandbox. Fitur ini hanya bisa dijalankan di dalam Mode
                      Production.
                    </Alert>
                  ) : (
                    <Row>
                      <Col>
                        <Card className="mb-3 container-card">
                          <Card.Body className="container-body">
                            <Form onSubmit={this.handleSubmit}>
                              <Form.Group
                                controlId="formBasicPassword"
                                className="form-group-password"
                              >
                                <Form.Label className="fontSize-16-Medium">
                                  {this.translate('labelOld')}
                                </Form.Label>
                                <InputGroup>
                                  <Form.Control
                                    type={this.state.showOldPass ? 'text' : 'password'}
                                    placeholder={this.translate('placeOld')}
                                    name="userPassword"
                                    value={userPassword}
                                    onChange={this.handleChange}
                                    required
                                    className="custom-input"
                                  />
                                  {this.state.showOldPass ? (
                                    <InputGroup.Text
                                      onClick={() => this.handleShowedPass('hidden', 'showOldPass')}
                                    >
                                      <Image src={icons(iconName.showPass)} />
                                    </InputGroup.Text>
                                  ) : (
                                    <InputGroup.Text
                                      onClick={() => this.handleShowedPass('show', 'showOldPass')}
                                    >
                                      <Image src={icons(iconName.hidePass)} />
                                    </InputGroup.Text>
                                  )}
                                </InputGroup>
                              </Form.Group>

                              <Form.Group
                                controlId="formBasicPassword"
                                className="form-group-password"
                              >
                                <Form.Label className="fontSize-16-Medium">
                                  {this.translate('labelNew')}
                                </Form.Label>
                                <InputGroup>
                                  <Form.Control
                                    type={this.state.showNewPass ? 'text' : 'password'}
                                    placeholder={this.translate('placeNew')}
                                    name="newPassword"
                                    value={newPassword}
                                    onChange={this.handleChange}
                                    minLength={12}
                                    required
                                    className="custom-input"
                                  />
                                  {this.state.showNewPass ? (
                                    <InputGroup.Text
                                      onClick={() => this.handleShowedPass('hidden', 'showNewPass')}
                                    >
                                      <Image src={icons(iconName.showPass)} />
                                    </InputGroup.Text>
                                  ) : (
                                    <InputGroup.Text
                                      onClick={() => this.handleShowedPass('show', 'showNewPass')}
                                    >
                                      <Image src={icons(iconName.hidePass)} />
                                    </InputGroup.Text>
                                  )}
                                </InputGroup>
                                <Form.Text
                                  className={this.state.minChar ? 'text-danger' : 'text-muted'}
                                >
                                  {newPassword &&
                                    this.state.minChar &&
                                    'password must be at least 12 characters'}
                                </Form.Text>
                                <Form.Text className={passwordErr ? 'text-danger' : 'text-muted'}>
                                  {newPassword && passwordErr && "confirm password doesn't match"}
                                </Form.Text>
                                <PasswordStrengthBar
                                  password={newPassword}
                                  scoreWords={['Very Weak', 'Weak', 'Okay', 'Good', 'Strong']}
                                  shortScoreWord={' '}
                                />
                              </Form.Group>

                              <Form.Group
                                controlId="formBasicPassword"
                                className="form-group-password"
                              >
                                <Form.Label className="fontSize-16-Medium">
                                  {this.translate('labelConfirm')}
                                </Form.Label>
                                <InputGroup>
                                  <Form.Control
                                    type={this.state.showNewPass2 ? 'text' : 'password'}
                                    placeholder={this.translate('placeConfirm')}
                                    name="newPassword2"
                                    minLength={12}
                                    value={newPassword2}
                                    onChange={this.handleChange}
                                    className="custom-input"
                                  />
                                  {this.state.showNewPass2 ? (
                                    <InputGroup.Text
                                      onClick={() =>
                                        this.handleShowedPass('hidden', 'showNewPass2')
                                      }
                                    >
                                      <Image src={icons(iconName.showPass)} />
                                    </InputGroup.Text>
                                  ) : (
                                    <InputGroup.Text
                                      onClick={() => this.handleShowedPass('show', 'showNewPass2')}
                                    >
                                      <Image src={icons(iconName.hidePass)} />
                                    </InputGroup.Text>
                                  )}
                                </InputGroup>
                                <Form.Text className={passwordErr ? 'text-danger' : 'text-muted'}>
                                  {newPassword2 && passwordErr && "confirm password doesn't match"}
                                </Form.Text>
                              </Form.Group>

                              <Button
                                className="btn-custom btn-custom-reset-password fontSize-17 reset-password-page"
                                variant="primary"
                                type="submit"
                                disabled={sending}
                              >
                                <Image src={icons(iconName.iconSaveWhite)} className="mr-2" />
                                Submit
                              </Button>
                            </Form>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </>
          )}
        </Container>
        <AlertDialog
          show={!!alertError}
          onHide={this.closeError}
          onConfirm={this.closeError}
          title="Information"
        >
          {alertError}
        </AlertDialog>
      </>
    )
  }
}

ResetPasswordScreen.defaultProps = {
  appIsLoading: false,
  appUserId: '',
  appUserName: '',
  appAgentName: '',
  appUserGroup: '',
  appUserData: '',
}

ResetPasswordScreen.propTypes = {
  appIsLoading: PropTypes.bool,
  appUserId: PropTypes.string,
  appUserName: PropTypes.string,
  appAgentName: PropTypes.string,
  appUserGroup: PropTypes.string,
  appUserData: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
    appUserId: state.Auth[authConstant.USER_ID],
    appUserName: state.Auth[authConstant.USER_NAME],
    appAgentName: state.Auth[authConstant.AGENT_NAME],
    appUserGroup: state.Auth[authConstant.USER_GROUP],
    appUserData: state.Auth[authConstant.USER_DATA],
    language: state.Global[globalConstant.LANGUAGE],
    appUserAccess: state.RoleAccess[roleAccess.ACCESS],
    environment: state.Global[globalConstant.ENVIRONMENT],
  }),
  (dispatch) => ({ dispatch })
)(ResetPasswordScreen)
