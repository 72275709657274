/* eslint-disable no-unused-vars */
import React from 'react'
import { Badge, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Formatter from '../../../helper/formatter'
import translate from '../../../helper/translate'
import { columnResource } from '../../../helper/translatePage/languageResource'
import dataType from '../../../helper/constant/dataType'

const TransactionColumn = (actionFunction, languange) => ({
  key: 'id_transaksi',
  columns: [
    {
      name: 'ID Trx',
      selector: 'id_transaksi',
      sortable: true,
      type: dataType.INTEGER,
      showFilter: true,
    },
    {
      name: translate('time', languange, columnResource),
      selector: 'time_request',
      sortable: true,
      type: dataType.DATERANGE,
      showFilter: false,
    },
    {
      name: translate('dateTrx', languange, columnResource),
      selector: 'transaction_date',
      sortable: true,
      type: dataType.DATERANGE,
      default: true,
      showFilter: true,
    },
    {
      name: 'ID Trx Biller',
      selector: 'invoicenumberext',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('subAccountName', languange, columnResource),
      selector: 'addinfo21',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: 'Partner Reff VA',
      selector: 'partner_reff_va',
      sortable: true,
      cell: (row) => (row.jenis_trx === 'CASH IN' ? row.partner_reff_va : ''),
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: 'Partner Reff',
      selector: 'nopelanggan3',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('trxType', languange, columnResource),
      selector: 'jenis_transaksi',
      sortable: true,
      cell: (row) => {
        let word
        let color
        switch (`${row.jenis_transaksi}`) {
          case '0':
            word = 'Inq'
            color = 'warning'
            break
          case '1':
            word = 'Pay'
            color = 'primary'
            break
          default:
            word = 'Other'
            color = 'secondary'
            break
        }
        return <Badge variant={color}>{word}</Badge>
      },
      type: dataType.ENUM,
      default_value: [
        {
          id: '0',
          name: 'Inq',
        },
        {
          id: '1',
          name: 'Pay',
        },
      ],
      showFilter: true,
    },
    {
      name: translate('kindTrx', languange, columnResource),
      selector: 'jenis_trx',
      sortable: true,
      cell: (row) => {
        let word
        let color
        switch (`${row.jenis_trx}`) {
          case 'CASH OUT':
            word = 'CASH OUT'
            color = 'warning'
            break
          case 'CASH IN':
            word = 'CASH IN'
            color = 'primary'
            break
          default:
            word = ''
            color = 'secondary'
            break
        }
        return <Badge variant={color}>{word}</Badge>
      },
      type: dataType.ENUM,
      default_value: [
        {
          id: 'CASH OUT',
          name: 'CASH OUT',
        },
        {
          id: 'CASH IN',
          name: 'CASH IN',
        },
      ],
      showFilter: true,
    },
    {
      name: 'Status',
      selector: 'status_trx',
      sortable: true,
      cell: (row) => {
        let word
        let color
        switch (row.status_trx) {
          case 'SUCCESS':
            word = row.status_trx
            color = 'success'
            break
          case 'FAILED':
            word = row.status_trx
            color = 'danger'
            break
          default:
            word = 'PROCESS'
            color = 'warning'
            break
        }
        return <Badge variant={color}>{word}</Badge>
      },
      type: dataType.ENUM,
      default_value: [
        {
          id: 'SUCCESS',
          name: 'SUCCESS',
        },
        {
          id: 'FAILED',
          name: 'FAILED',
        },
        {
          id: 'PROCESS',
          name: 'PROCESS',
        },
      ],
      showFilter: true,
    },
    {
      name: 'RC',
      selector: 'response_code',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: 'Response Biller',
      selector: 'responsebiller',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('remark', languange, columnResource),
      selector: 'keterangan',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('productName', languange, columnResource),
      selector: 'nama_produk',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('addInfo', languange, columnResource),
      selector: 'nopelanggan1',
      sortable: true,
      cell: (row) => `${row.nopelanggan1}, ${row.nopelanggan2}`,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('bankName', languange, columnResource),
      selector: 'kodebank',
      sortable: true,
      cell: (row) =>
        row.kodebank && row.namabank ? `[${row.kodebank}] ${row.namabank}` : row.nama_produk,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('custName', languange, columnResource),
      selector: 'customername',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: 'Nominal',
      selector: 'nominal_origin',
      sortable: true,
      cell: (row) => Formatter.price(row.nominal_origin),
      type: dataType.INTEGER,
      showFilter: true,
    },
    {
      name: translate('adminFee', languange, columnResource),
      selector: 'nominal_admin',
      sortable: true,
      cell: (row) => Formatter.price(row.nominal_admin),
      type: dataType.INTEGER,
      showFilter: true,
    },
    {
      name: translate('statBalance', languange, columnResource),
      selector: 'is_void',
      sortable: true,
      cell: (row) => {
        let word
        let color
        if (row.jenis_transaksi !== 0) {
          if (row.is_void) {
            word = 'Refunded'
            color = 'warning'
          } else {
            word = 'Debited'
            color = 'primary'
          }
        } else {
          word = '-'
          color = ''
        }
        return <Badge variant={color}>{word}</Badge>
      },
      type: dataType.ENUM,
      default_value: [
        {
          id: 't',
          name: 'Refunded',
        },
        {
          id: 'f',
          name: 'Debited',
        },
      ],
      showFilter: true,
    },
    {
      name: translate('action', languange, columnResource),
      cell: (row) => {
        return (
          <div style={{ flexDirection: 'column' }}>
            <div>
              <Button
                size="sm"
                variant="outline-info"
                className="mr-1"
                data-action="callback"
                onClick={(e) => actionFunction(row, e)}
              >
                <FontAwesomeIcon fixedWidth icon="paper-plane" />
              </Button>
              {`${row.jenis_transaksi}` === '1' &&
                row.response_code === '00' &&
                row.status_trx === 'SUCCESS' && (
                  <Button
                    size="sm"
                    variant="outline-success"
                    data-action="print"
                    onClick={(e) => actionFunction(row, e)}
                  >
                    <FontAwesomeIcon fixedWidth icon="print" />
                  </Button>
                )}
            </div>
          </div>
        )
      },
      showFilter: false,
    },
  ],
})

export default TransactionColumn
