/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { connect } from 'react-redux'
import { Container, Row, Col, Spinner, Image } from 'react-bootstrap'
import PropTypes from 'prop-types'

import './account.scss'
import swal from 'sweetalert'
import BaseScreen from '../base'
import globalConstant from '../../store/constant/global'
import authConstant from '../../store/constant/auth'
import dataUser from '../../api/action/dataUser'
import dataReg from '../../api/action/dataReg'
// eslint-disable-next-line
import AlertDialog from '../dialog/alert'
import {
  accountLangResource,
  columnResource,
  sidebarLangResource,
} from '../../helper/translatePage/languageResource'
import roleAccess from '../../store/constant/roleAccess'
import checkAccess from '../../helper/access'
import AccessDenied from '../component/AccessDenied'
import icons from '../../helper/icons'
import iconName from '../../helper/constant/iconName'
import ResetPassword from './resetPassword'
import AccountManagementScreen from '../accountManagement/accountManagement'
import translate from '../../helper/translate'
import Formatter from '../../helper/formatter'

class AccountScreen extends BaseScreen {
  constructor(props) {
    super(props, ['page--account'])
    this.state = {
      isOpen: false,
      userId: '',
      dataCust: [],
      loading: false,
      alertError: '',
      balanceFresh: false,
      authToken: '',
      path: '',
      cardSubHeaderPicker: [
        {
          title: this.translate('myData'),
          active: true,
          link: 'account',
          show: true,
        },
        {
          title: this.translate('changePassword'),
          active: false,
          link: 'reset_password',
          show: this.passwordAccess(),
        },
        {
          title: this.translate('accountManagement'),
          active: false,
          link: 'account_management',
          show: this.readAccessAccountManagement(),
        },
      ],
      active: 'account',
    }
  }

  getData = async () => {
    const { dispatch } = this.props
    this.setState({ loading: true })

    const response = await dispatch(dataUser())
    const response2 = await dispatch(dataReg())

    if (response) {
      // eslint-disable-next-line
      this.setState({ dataCust: response, authToken: response2, balanceFresh: true, loading: false })
    } else {
      this.setState({ dataCust: 404, loading: false })
    }
    this.setState({ balanceFresh: false })
  }

  doUpgrade = () => {
    // eslint-disable-next-line
    const { appStatusReg } = this.props
    // eslint-disable-next-line
    window.location.href=process.env.REACT_APP_URL_LINKQU_REG+'/activationuser/'+appStatusReg.auth_token.auth_token.bisnis
  }

  showError = (alertError) => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ alertError })
  }

  closeError = () => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ alertError: '' })
  }

  componentDidMount() {
    super.componentDidMount()
    this.setState({ path: this.props.match.path })

    this.getData()
  }

  translate = (key) => {
    const { language } = this.props

    let result = ''
    if (language === 'id') {
      // eslint-disable-next-line
      result = accountLangResource.id[key]
    } else {
      // eslint-disable-next-line
      result = accountLangResource.en[key]
    }

    return result !== undefined ? result : 'Language not found'
  }

  readAccess = () => {
    return checkAccess(this.props.appUserAccess, 'account', 'READ')
  }

  upgradeAccess = () => {
    return checkAccess(this.props.appUserAccess, 'account', 'UPGRADE')
  }

  passwordAccess = () => {
    return checkAccess(this.props.appUserAccess, 'reset_password', 'UPDATE')
  }

  readAccessAccountManagement = () => {
    return (
      checkAccess(this.props.appUserAccess, 'account_management', 'READ') &&
      this.props.appUserType !== 'Personal END USER'
    )
  }

  setActive = (data) => {
    // eslint-disable-next-line prefer-const
    let { cardSubHeaderPicker } = this.state

    // eslint-disable-next-line no-restricted-syntax
    for (const item of cardSubHeaderPicker) {
      if (item.link === data.link) {
        item.active = true
        this.setState({ active: data.link })
      } else {
        item.active = false
      }
    }

    this.setState({ cardSubHeaderPicker: cardSubHeaderPicker })
  }

  copyText = (text, data) => {
    navigator.clipboard.writeText(data)
    swal('Sukses', `${text}`, 'success')
  }

  render() {
    this.props.dispatch({
      type: globalConstant.HEADER_TITLE,
      payload: translate('account', this.props.language, sidebarLangResource),
    })

    const { dataCust, loading, alertError, cardSubHeaderPicker, active } = this.state
    const { appUserId, appBalance, appHoldBalance, appRuleShowBalance, appEnableAPI } = this.props
    const dataPersonal = dataCust.data_personal
    let dataBisnis = dataCust.data_bisnis
    const benefitUpgrade = [
      {
        text: this.translate('benefit1'),
      },
      {
        text: this.translate('benefit2'),
      },
      {
        text: this.translate('benefit3'),
      },
      {
        text: this.translate('benefit4'),
      },
      {
        text: this.translate('benefit5'),
      },
    ]
    let tipeAccount
    let accountCompanyInfo = []
    let accountOwnerCompanyInfo = []
    let accountRepresnetativeCompanyInfo = []
    let accountBankInfo = []
    if (dataBisnis !== undefined && dataBisnis == null) {
      if (this.props.appUserType === 'Personal END USER') {
        tipeAccount = 'Personal'
      } else {
        tipeAccount = 'Personal H2H'
      }
    } else {
      tipeAccount = 'Bisnis'
      if (!dataBisnis) {
        dataBisnis = {}
      }

      accountCompanyInfo = [
        {
          key: this.translate('titleCompany'),
          title: true,
        },
        {
          key: this.translate('bankName'),
          value: dataBisnis.nama_perusahaan ? dataBisnis.nama_perusahaan : '',
        },
        {
          key: this.translate('personalAddress'),
          value: dataBisnis.alamat_perusahaan ? dataBisnis.alamat_perusahaan : '',
        },
        {
          key: this.translate('companyPhone'),
          value: dataBisnis.telp_perusahaan ? dataBisnis.telp_perusahaan : '',
        },
        {
          key: 'E-mail',
          value: dataBisnis.email_perusahaan ? dataBisnis.email_perusahaan : '',
        },
        {
          key: 'NPWP',
          value: dataBisnis.npwp_perusahaan ? dataBisnis.npwp_perusahaan : '',
        },
        {
          key: this.translate('website'),
          value: dataBisnis.website_produk ? dataBisnis.website_produk : '',
        },
      ]
      accountOwnerCompanyInfo = [
        {
          key: this.translate('ownerCompany'),
          title: true,
        },
        {
          key: this.translate('bankName'),
          value: dataBisnis.nama_perusahaan ? dataBisnis.nama_perusahaan : '',
        },
        {
          key: this.translate('companyPhone'),
          value: dataBisnis.telp_perusahaan ? dataBisnis.telp_perusahaan : '',
        },
        {
          key: 'NIK',
          value: dataBisnis.no_ktp_pemilik ? dataBisnis.no_ktp_pemilik : '',
        },
        {
          key: 'NPWP',
          value: dataBisnis.npwp_pemilik ? dataBisnis.npwp_pemilik : '',
        },
      ]
      accountRepresnetativeCompanyInfo = [
        {
          key: this.translate('representativeCompany'),
          title: true,
        },
        {
          key: this.translate('bankName'),
          value: dataBisnis.nama_cp ? dataBisnis.nama_cp : '',
        },
        {
          key: this.translate('cpPosition'),
          value: dataBisnis.jabatan_cp ? dataBisnis.jabatan_cp : '',
        },
        {
          key: this.translate('companyPhone'),
          value: dataBisnis.no_telp_cp ? dataBisnis.no_telp_cp : '',
        },
        {
          key: 'E-mail',
          value: dataBisnis.email_cp ? dataBisnis.email_cp : '',
        },
      ]
      accountBankInfo = [
        {
          key: this.translate('titleBank'),
          title: true,
        },
        {
          key: this.translate('bankName'),
          value: dataBisnis.nama_bank ? dataBisnis.nama_bank : '',
        },
        {
          key: this.translate('cpPosition'),
          value: dataBisnis.cabang_bank ? dataBisnis.cabang_bank : '',
        },
        {
          key: this.translate('bankAccName'),
          value: dataBisnis.nama_rek_bank ? dataBisnis.nama_rek_bank : '',
        },
        {
          key: this.translate('bankAccNumb'),
          value: dataBisnis.no_rek_bank ? dataBisnis.no_rek_bank : '',
        },
      ]
    }
    let accountInfo = []
    let referral = []
    if (dataPersonal !== undefined) {
      accountInfo = [
        {
          key: translate('username', this.props.language, columnResource),
          value: appUserId,
        },
        {
          key: this.translate('bankName'),
          value: dataPersonal.nama_customer,
        },
        {
          key: 'E-mail:',
          value: dataPersonal.email,
        },
        {
          key: this.translate('companyPhone'),
          value: dataPersonal.no_hp,
        },
        {
          key: this.translate('personalBirth'),
          value: dataPersonal.tgl_lahir,
        },
        {
          key: this.translate('personalAddress'),
          value: dataPersonal.alamat_lengkap,
        },
        {
          key: 'Max Limit:',
          value: Formatter.price(dataPersonal.max_limit),
        },
      ]
      if (appRuleShowBalance === 2 && appEnableAPI) {
        if (tipeAccount !== 'Personal') {
          accountInfo.push({
            key: 'Saldo Ditahan',
            value: appHoldBalance,
          })
        }
        accountInfo.push({
          key: tipeAccount === 'Personal' ? 'Sisa Dana Nominal Unik: ' : 'Saldo Simpanan',
          value: appBalance,
        })
      }

      dataPersonal.mgm_ref_code = 'JBKNK'
      referral = [
        {
          key: this.translate('referralTitle'),
          value: dataPersonal.mgm_ref_code,
          textSuccess: 'Kode referral berhasil disalin',
        },
        {
          key: this.translate('affiliationTitle'),
          value: dataPersonal.mgm_ref_code
            ? `https://signup.linkqu.id/ref/${dataPersonal.mgm_ref_code}`
            : '',
          textSuccess: 'URL afiliasi web berhasil disalin',
        },
        {
          key: this.translate('affiliationAndroid'),
          value: dataPersonal.mgm_ref_code
            ? `https://play.google.com/store/apps/details?id=com.linkqu.mobile&referrer=${dataPersonal.mgm_ref_code}`
            : '',
          textSuccess: 'URL afiliasi android berhasil disalin',
        },
      ]
    }

    return (
      <>
        <Container fluid className="p-3 mt-3">
          {!this.readAccess() ? (
            <AccessDenied />
          ) : (
            <>
              {loading ? (
                <>
                  <Spinner size="xs" className="mr-1 text-active" animation="border" />
                </>
              ) : (
                <>
                  {dataCust === 404 ? (
                    <h4>Data Not Found</h4>
                  ) : (
                    <>
                      <Row>
                        {cardSubHeaderPicker.map((item) => (
                          <Col md="3" sm="4" xs="12" className={item.show ? 'mb-2' : 'd-none'}>
                            <div
                              className={
                                item.active
                                  ? 'card-subpicker card-subpicker-active'
                                  : 'card-subpicker'
                              }
                              onClick={() => this.setActive(item)}
                            >
                              <div className="d-inline-block">
                                <div
                                  className={
                                    item.active
                                      ? 'color-white fontSize-17 text-center'
                                      : 'text-active fontSize-17 text-center'
                                  }
                                >
                                  {item.title}
                                </div>
                              </div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                      <h4 className="mt-3 ml-3">
                        {active === 'account'
                          ? this.translate('myData')
                          : active === 'account_management'
                          ? this.translate('accountManagement')
                          : this.translate('changePassword')}
                      </h4>
                      {active !== 'account' ? (
                        <></>
                      ) : (
                        <div className="container-data">
                          {tipeAccount === 'Personal' || tipeAccount === 'Personal H2H' ? (
                            <div className="upgrade-business">
                              <div className="text-upgrade fontSize-16-Medium">
                                {this.translate('textUpgrade')}
                              </div>
                              <div
                                className="button-upgrade fontSize-17-Medium"
                                onClick={() => this.doUpgrade()}
                              >
                                {this.translate('buttonUpgrade')}
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                          <div
                            className={
                              tipeAccount === 'Bisnis'
                                ? 'account-basic'
                                : 'account-basic with-upgrade'
                            }
                          >
                            <div className="account-type">
                              <div className="fontSize-16">Jenis Akun</div>
                              <div
                                className={
                                  tipeAccount === 'Bisnis'
                                    ? 'account-type-business fontSize-16-SemiBold'
                                    : 'account-type-personal fontSize-16-SemiBold'
                                }
                              >
                                {tipeAccount}
                              </div>
                            </div>
                            <div className="account-info">
                              <Image
                                src={icons(iconName.avatar, process.env.REACT_APP_BRAND_NAME)}
                              />
                              <div className="account-info-detail">
                                {accountInfo.map((item) => (
                                  <div className="container-account-info-detail">
                                    <div className="fontSize-16-SemiBold d-inline-block">
                                      {item.key}
                                    </div>
                                    <div className="fontSize-16 color-grey d-inline-block ">
                                      {item.value}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                            <div className="referral">
                              {referral.map((item) => (
                                <div className="mt-3">
                                  <div className="fontSize-16-SemiBold">{item.key}</div>
                                  <div className="account-referral">
                                    <div className="fontSize-16 color-grey text">{item.value}</div>
                                    <div
                                      className="button-copy"
                                      onClick={() => this.copyText(item.textSuccess, item.value)}
                                    >
                                      <Image src={icons(iconName.copy)} className="" />
                                      <div className="ml-2 button-copy-text fontSize-16-Medium color-white">
                                        Salin
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                            {tipeAccount === 'Personal' || tipeAccount === 'Personal H2H' ? (
                              <div className="benefit">
                                <div className="fontSize-18-Bold title">
                                  Fitur LinkQu Bisnis setelah kamu melakukan verifikasi data
                                </div>
                                {benefitUpgrade.map((item) => (
                                  <div className="container-benefit">
                                    <Image
                                      className="d-inline mr-3"
                                      src={icons(iconName.checksuccess)}
                                    />
                                    <div className="fontSize-16 d-inline">{item.text}</div>
                                  </div>
                                ))}
                                <div
                                  className="button-upgrade-reverse fontSize-17-Medium"
                                  onClick={() => this.doUpgrade()}
                                >
                                  {this.translate('buttonUpgrade')}
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                            {tipeAccount === 'Bisnis' ? (
                              <div className="info-business mt-3">
                                {accountCompanyInfo.map((item) => (
                                  <div className="container-account-info-detail">
                                    <div
                                      className={
                                        item.title
                                          ? 'fontSize-16-SemiBold d-inline-block text-active'
                                          : 'fontSize-16-SemiBold d-inline-block'
                                      }
                                    >
                                      {item.key}
                                    </div>
                                    <div className="fontSize-16 color-grey d-inline-block ">
                                      {item.value}
                                    </div>
                                  </div>
                                ))}
                                <hr />
                                {accountOwnerCompanyInfo.map((item) => (
                                  <div className="container-account-info-detail">
                                    <div
                                      className={
                                        item.title
                                          ? 'fontSize-16-SemiBold d-inline-block text-active'
                                          : 'fontSize-16-SemiBold d-inline-block'
                                      }
                                    >
                                      {item.key}
                                    </div>
                                    <div className="fontSize-16 color-grey d-inline-block ">
                                      {item.value}
                                    </div>
                                  </div>
                                ))}
                                <hr />
                                {accountRepresnetativeCompanyInfo.map((item) => (
                                  <div className="container-account-info-detail">
                                    <div
                                      className={
                                        item.title
                                          ? 'fontSize-16-SemiBold d-inline-block text-active'
                                          : 'fontSize-16-SemiBold d-inline-block'
                                      }
                                    >
                                      {item.key}
                                    </div>
                                    <div className="fontSize-16 color-grey d-inline-block ">
                                      {item.value}
                                    </div>
                                  </div>
                                ))}
                                <hr />
                                {accountBankInfo.map((item) => (
                                  <div className="container-account-info-detail">
                                    <div
                                      className={
                                        item.title
                                          ? 'fontSize-16-SemiBold d-inline-block text-active'
                                          : 'fontSize-16-SemiBold d-inline-block'
                                      }
                                    >
                                      {item.key}
                                    </div>
                                    <div className="fontSize-16 color-grey d-inline-block ">
                                      {item.value}
                                    </div>
                                  </div>
                                ))}
                                <hr />
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      )}
                      {active !== 'reset_password' ? <></> : <ResetPassword />}
                      {active !== 'account_management' ? <></> : <AccountManagementScreen />}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Container>
        <AlertDialog
          show={!!alertError}
          onHide={this.closeError}
          onConfirm={this.closeError}
          type="warning"
          messageTitle={alertError}
        />
      </>
    )
  }
}

AccountScreen.defaultProps = {
  appIsLoading: false,
  appUserId: '',
  appUserName: '',
  appAgentName: '',
  appUserGroup: '',
  appStatusReg: '',
  appUserData: '',
}

AccountScreen.propTypes = {
  appIsLoading: PropTypes.bool,
  appUserId: PropTypes.string,
  appUserName: PropTypes.string,
  appAgentName: PropTypes.string,
  appUserGroup: PropTypes.string,
  appUserData: PropTypes.string,
  appStatusReg: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
    appUserId: state.Auth[authConstant.USER_ID],
    appUserName: state.Auth[authConstant.USER_NAME],
    appAgentName: state.Auth[authConstant.AGENT_NAME],
    appUserGroup: state.Auth[authConstant.USER_GROUP],
    appUserData: state.Auth[authConstant.USER_DATA],
    appUserType: state.Auth[authConstant.USER_TYPE],
    appStatusReg: state.Auth[authConstant.STATUS_REG],
    language: state.Global[globalConstant.LANGUAGE],
    appUserAccess: state.RoleAccess[roleAccess.ACCESS],
    environment: state.Global[globalConstant.ENVIRONMENT],
    appBalance: state.Auth[authConstant.USER_BALANCE],
    appHoldBalance: state.Auth[authConstant.USER_HOLDBALANCE],
    appRuleShowBalance: state.Global[globalConstant.RULE_SHOW_BALANCE],
    appEnableAPI: state.Auth[authConstant.ENABLE_API],
  }),
  (dispatch) => ({ dispatch })
)(AccountScreen)
