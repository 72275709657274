export default {
  account: 'account',
  accessDenied: 'accessDenied',
  accessDeniedFusindo: 'accessDeniedFusindo',
  alert: 'alert',
  alertGrey: 'alertGrey',
  avatar: 'avatar',
  avatarFusindo: 'avatarFusindo',
  api: 'api',
  apiActive: 'apiActive',
  apiFusindo: 'apiFusindo',
  arrowRight: 'arrowRight',
  arrowRightNew: 'arrowRightNew',
  arrowRightBlack: 'arrowRightBlack',
  fullArrowRightActive: 'fullArrowRightActive',
  fullArrowRightActiveFusindo: 'fullArrowRightActiveFusindo',
  arrowRightActive: 'arrowRightActive',
  arrowRightActiveFusindo: 'arrowRightActiveFusindo',
  arrowWhite: 'arrowWhite',
  arrowLeft: 'arrowLeft',
  arrowLeftNew: 'arrowLeftNew',
  arrowDown: 'arrowDown',
  arrowDownGrey: 'arrowDownGrey',
  arrowUpGrey: 'arrowUpGrey',
  arrowUpNew: 'arrowUpNew',
  backButton: 'backButton',
  balance: 'balance',
  balanceFusindo: 'balanceFusindo',
  bankCard: 'bankCard',
  bankCardFusindo: 'bankCardFusindo',
  bankCardWhite: 'bankCardWhite',
  business: 'business',
  bulkTransfer: 'bulkTransfer',
  bulkTransferActive: 'bulkTransferActive',
  bulkTransferActiveFusindo: 'bulkTransferActiveFusindo',
  carbonPending: 'carbonPending',
  carbonPendingFusindo: 'carbonPendingFusindo',
  check: 'check',
  checkFusindo: 'checkFusindo',
  checkRounded: 'checkRounded',
  checksuccess: 'checksuccess',
  checklist: 'checklist',
  checkSquare: 'checkSquare',
  clipPath: 'clipPath',
  copy: 'copy',
  cursorInput: 'cursorInput',
  cursorInputActiveFusindo: 'cursorInputActiveFusindo',
  cursorInputActive: 'cursorInputActive',
  dataTransferInactive: 'dataTransferInactive',
  deposit: 'deposit',
  download: 'download',
  downloadFile: 'downloadFile',
  downloadFileFusindo: 'downloadFileFusindo',
  empty: 'empty',
  emptyFusindo: 'emptyFusindo',
  fluentPassword: 'fluentPassword',
  globe: 'globe',
  gubuk: 'gubuk',
  homeActive: 'homeActive',
  homeActiveFusindo: 'homeActiveFusindo',
  homeInactive: 'homeInactive',
  hidePass: 'hidePass',
  history: 'history',
  historyFusindo: 'historyFusindo',
  historyInactive: 'historyInactive',
  idCard: 'idCard',
  iconSaveWhite: 'iconSaveWhite',
  icPersonal: 'icPersonal',
  icPersonalFusindo: 'icPersonalFusindo',
  laporanActiveFusindo: 'laporanActiveFusindo',
  laporanActive: 'laporanActive',
  laporanInactive: 'laporanInactive',
  danatalanganActive: 'danatalanganActive',
  danatalanganInactive: 'danatalanganInactive',
  unduhActive: 'unduhActive',
  unduhInactive: 'unduhInactive',
  limitLogo: 'limitLogo',
  limitLogoFusindo: 'limitLogoFusindo',
  logout: 'logout',
  logoutFusindo: 'logoutFusindo',
  merchantActiveFusindo: 'merchantActiveFusindo',
  merchantActive: 'merchantActive',
  merchantInactive: 'merchantInactive',
  pencil: 'pencil',
  personal: 'personal',
  plus: 'plus',
  print: 'print',
  profil: 'profil',
  requestPayment: 'requestPayment',
  saldo: 'saldo',
  saldoFusindo: 'saldoFusindo',
  saldoRed: 'saldoRed',
  save: 'save',
  saveFusindo: 'saveFusindo',
  search: 'search',
  sendFeedback: 'sendFeedback',
  settingActive: 'settingActive',
  settingActiveFusindo: 'settingActiveFusindo',
  settingInactive: 'settingInactive',
  settlementActiveFusindo: 'settlementActiveFusindo',
  settlementActive: 'settlementActive',
  settlementInactive: 'settlementInactive',
  showPass: 'showPass',
  store: 'store',
  storeFusindo: 'storeFusindo',
  storeWhite: 'storeWhite',
  successDialog: 'successDialog',
  support: 'support',
  terimauangActive: 'terimauangActive',
  terimauangActiveFusindo: 'terimauangActiveFusindo',
  terimauangInactive: 'terimauangInactive',
  timeRollback: 'timeRollback',
  timeRollbackFusindo: 'timeRollbackFusindo',
  transactionActive: 'transactionActive',
  transactionInactive: 'transactionInactive',
  transferActive: 'transferActive',
  transferActiveFusindo: 'transferActiveFusindo',
  transferInactive: 'transferInactive',
  vaActive: 'vaActive',
  vaActiveFusindo: 'vaActiveFusindo',
  vaInactive: 'vaInactive',
  voucher: 'voucher',
  voucherFusindo: 'voucherFusindo',
  withdraw: 'withdraw',
  wallet: 'wallet',
  walletFusindo: 'walletFusindo',
  walletWhite: 'walletWhite',
  warningDialog: 'warningDialog',
}
