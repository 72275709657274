import MessageSender from '../sender'
import BaseMessage from '../message'

export default (path, key, date, args) => async () => {
  const msg = new BaseMessage()
  msg.setPath(`resume/${path}`)
  msg.setItem('key', key)
  msg.setItem('date', date)
  if (args !== null && typeof args === 'object') {
    Object.keys(args).forEach((k) => msg.setItem(k, args[k]))
  }

  const response = await new MessageSender().doGet(msg)

  if (response.isOK()) {
    return response.getResultAsObject()
  }

  return { key, value: 'n/a' }
}
