/* eslint-disable no-unused-vars */
import translate from '../../../helper/translate'
import { columnResource } from '../../../helper/translatePage/languageResource'
import dataType from '../../../helper/constant/dataType'

const aggregatorFeeDaily = (action, languange) => ({
  key: 'id',
  columns: [
    {
      name: translate('reportDate', languange, columnResource),
      selector: 'tanggal',
      sortable: true,
      type: dataType.DATERANGE,
      showFilter: true,
      default: true,
    },
    {
      name: translate('trxVa', languange, columnResource),
      selector: 'trxva',
      sortable: true,
      type: dataType.INTEGER,
      showFilter: true,
    },
    {
      name: translate('feeVa', languange, columnResource),
      selector: 'feeva',
      sortable: true,
      type: dataType.INTEGER,
      showFilter: true,
    },
    {
      name: translate('trxTrf', languange, columnResource),
      selector: 'trxtrf',
      sortable: true,
      type: dataType.INTEGER,
      showFilter: true,
    },
    {
      name: translate('feeTrf', languange, columnResource),
      selector: 'feetrf',
      sortable: true,
      type: dataType.INTEGER,
      showFilter: true,
    },
    {
      name: translate('totalTrx', languange, columnResource),
      selector: 'totaltrx',
      sortable: true,
      type: dataType.INTEGER,
      showFilter: true,
    },
    {
      name: translate('totalFee', languange, columnResource),
      selector: 'totalfee',
      sortable: true,
      type: dataType.INTEGER,
      showFilter: true,
    },
  ],
})

export default aggregatorFeeDaily
