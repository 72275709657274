/* eslint-disable no-unused-vars */
import React from 'react'
import { Badge, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import translate from '../../../helper/translate'
import Formatter from '../../../helper/formatter'
import { columnResource } from '../../../helper/translatePage/languageResource'
import dataType from '../../../helper/constant/dataType'

const MerchantMember = (action, languange) => ({
  key: 'id',
  columns: [
    {
      name: translate('idAgen', languange, columnResource),
      selector: 'id_agen',
      sortable: true,
      type: dataType.INTEGER,
      showFilter: true,
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      cell: (row) => {
        let word
        let color
        switch (`${row.status}`) {
          case '0':
            word = translate('notActive', languange, columnResource)
            color = 'danger'
            break
          case '1':
            word = translate('active', languange, columnResource)
            color = 'success'
            break
          case '2':
            word = translate('failLogin', languange, columnResource)
            color = 'primary'
            break
          default:
            word = translate('unknown', languange, columnResource)
            color = 'secondary'
            break
        }
        return <Badge variant={color}>{word}</Badge>
      },
      type: dataType.ENUM,
      showFilter: true,
      default_value: [
        {
          id: '0',
          name: translate('notActive', languange, columnResource),
        },
        {
          id: '1',
          name: translate('active', languange, columnResource),
        },
        {
          id: '2',
          name: translate('failLogin', languange, columnResource),
        },
      ],
    },
    {
      name: translate('isBlocked', languange, columnResource),
      selector: 'is_blocked',
      sortable: true,
      cell: (row) => {
        let word
        let color
        switch (row.is_blocked) {
          case true:
            word = translate('true', languange, columnResource)
            color = 'danger'
            break
          case false:
            word = translate('false', languange, columnResource)
            color = 'success'
            break
          default:
            word = translate('unknown', languange, columnResource)
            color = 'secondary'
            break
        }
        return <Badge variant={color}>{word}</Badge>
      },
      type: dataType.ENUM,
      showFilter: true,
      default_value: [
        {
          id: true,
          name: translate('true', languange, columnResource),
        },
        {
          id: false,
          name: translate('false', languange, columnResource),
        },
      ],
    },
    {
      name: translate('username', languange, columnResource),
      selector: 'username',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('balance', languange, columnResource),
      selector: 'balance',
      sortable: true,
      type: dataType.INTEGER,
      showFilter: true,
    },
    {
      name: translate('balanceFreeze', languange, columnResource),
      selector: 'minimum_balance',
      sortable: true,
      type: dataType.INTEGER,
      showFilter: true,
    },
    {
      name: translate('idRefferer', languange, columnResource),
      selector: 'id_refferer',
      sortable: true,
      type: dataType.INTEGER,
      showFilter: true,
    },
    {
      name: translate('name', languange, columnResource),
      selector: 'nama_pemilik',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('nickname', languange, columnResource),
      selector: 'nickname',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('initialName', languange, columnResource),
      selector: 'nama_initial',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('phone', languange, columnResource),
      selector: 'telp_pemilik',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('email', languange, columnResource),
      selector: 'email',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('province', languange, columnResource),
      selector: 'nama_propinsi',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('city', languange, columnResource),
      selector: 'nama_kota',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('grupAgen', languange, columnResource),
      selector: 'namagrupagen',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('commision', languange, columnResource),
      selector: 'namakomisi',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('activationDate', languange, columnResource),
      selector: 'tanggal_aktivasi',
      sortable: true,
      type: dataType.DATERANGE,
      showFilter: true,
    },
    {
      name: translate('registrationDate', languange, columnResource),
      selector: 'tanggal_registrasi',
      sortable: true,
      type: dataType.DATERANGE,
      showFilter: true,
    },
  ],
})

export default MerchantMember
