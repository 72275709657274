/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { Table, Form, Button, Row, Col, InputGroup, Image } from 'react-bootstrap'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Formatter from '../../../helper/formatter'
import AlertDialog from '../../dialog/alert'
import translate from '../../../helper/translate'
import { postChecksum, postStoreBulkVA } from '../../../api/action/transaction'
import {
  buttonResource,
  columnResource,
  placeholderResource,
} from '../../../helper/translatePage/languageResource'
import icons from '../../../helper/icons'
import iconName from '../../../helper/constant/iconName'

class BulkVaTable extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      ...props.data.reduce((acc, cur, index) => {
        return {
          ...acc,
          ...{
            [index]: true,
            [`kode_bank_${index}`]: cur.kode_bank || '',
            [`amount_${index}`]: cur.amount,
            [`customer_id_${index}`]: cur.customer_id || '',
            [`customer_name_${index}`]: cur.customer_name || '',
            [`customer_phone_${index}`]: cur.customer_phone || '',
            [`customer_email_${index}`]: cur.customer_email || '',
            [`expired_${index}`]: cur.expired || '',
            [`type_${index}`]: cur.type || '',
            [`send_mail_${index}`]: cur.send_mail === 'true' || false,
            [`description_${index}`]: cur.description || '',
          },
        }
      }, {}),
      allChecked: false,
      hideCheckButton: false,
      showPass: false,
    }
  }

  showError = (alertError) => {
    this.setState({ alertError })
  }

  closeError = () => {
    this.setState({ alertError: '' })
  }

  closeSuccess = () => {
    const { alertError } = this.state

    if (alertError === 'Sukses') {
      this.props.navigate.push(`/data/bulk_virtual_account`)
    }

    this.setState({ alertError: '' })
  }

  handleShowPass = (type) => {
    switch (type) {
      case 'show':
        this.setState({ showPass: true })
        break
      case 'hidden':
        this.setState({ showPass: false })
        break

      default:
        this.setState({ showPass: false })
        break
    }
  }

  handleChange = (e) => {
    const {
      target: { name, checked, value },
    } = e

    if (name === 'checkAll') {
      const { data } = this.props

      this.setState(() => {
        const x = {
          allChecked: checked,
        }

        for (let i = 0; i < data.length; i += 1) {
          x[`send_mail_${i}`] = checked
        }

        return x
      })
    } else if (name.indexOf('send_mail') === 0) {
      this.setState((state) => ({
        [name]: checked,
        allChecked:
          Object.keys(state).filter(
            (k) =>
              k.indexOf('send_mail') === 0 &&
              ((k !== name && state[k] === false) || (k === name && !checked))
          ).length === 0,
      }))
    } else if (name.indexOf('accountNumber') === 0 || name.indexOf('phone') === 0) {
      this.setState({ [name]: Formatter.serial(value, 64) })
    } else if (name.indexOf('amount') === 0) {
      this.setState({ [name]: Formatter.price(value) })
    } else {
      this.setState({ [name]: value })
    }
  }

  doSubmit = async () => {
    const { password } = this.state

    if (password) {
      const { data, dispatch, username } = this.props
      // eslint-disable-next-line no-restricted-syntax
      for (const item of data) {
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in item) {
          if (key === 'amount') {
            item[key] = Number(item[key])
          }
          item.username = username
          item.description = item.description || ''
          item.status = 0
        }
      }

      let response = await dispatch(postChecksum(password))
      if (response.isOK()) {
        response = await dispatch(postStoreBulkVA(data))
        if (response.isOK()) {
          this.showError(response.getRD())
        } else {
          this.showError(response.getRD())
        }
      } else {
        this.showError(response.getRD())
      }
    } else {
      this.showError('Input account password to continue')
    }
  }

  render() {
    const { allChecked, password, alertError, checksum, hideCheckButton, showPass } = this.state
    const { data, listBank, onReset, language } = this.props

    return (
      <>
        <Table bordered hover responsive className="disbursement-table">
          <thead>
            <tr>
              <th>No.</th>
              {checksum && <th>&nbsp;</th>}
              <th>{translate('bankName', language, columnResource)}</th>
              <th>{translate('amount', language, columnResource)}</th>
              <th>{translate('customerId', language, columnResource)}</th>
              <th>{translate('customerName', language, columnResource)}</th>
              <th>{translate('customerPhone', language, columnResource)}</th>
              <th>{translate('customerEmail', language, columnResource)}</th>
              <th>{translate('expAt', language, columnResource)}</th>
              <th>{translate('type', language, columnResource)}</th>
              <th>
                <Form.Check
                  type="checkbox"
                  name="checkAll"
                  checked={allChecked}
                  onChange={this.handleChange}
                />
                {translate('send_mail', language, columnResource)}
              </th>
              <th>{translate('description', language, columnResource)}</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {data.map((datum, index) => {
              const { state } = this
              return (
                <tr className={state[`error_${index}`] ? 'error' : ''}>
                  <td>{index + 1}</td>
                  <td>
                    <Form.Control
                      as="select"
                      custom
                      name={`kode_bank_${index}`}
                      value={state[`kode_bank_${index}`]}
                      onChange={this.handleChange}
                      className="fontSize-16"
                    >
                      {listBank.map((b) => (
                        <option key={b.kodebank} value={b.kodebank}>
                          {b.namabank}
                        </option>
                      ))}
                    </Form.Control>
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      name={`amount_${index}`}
                      value={state[`amount_${index}`]}
                      autoComplete="off"
                      onChange={this.handleChange}
                      className="fontSize-16"
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      name={`customer_id_${index}`}
                      maxLength="255"
                      value={state[`customer_id_${index}`]}
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      name={`customer_name_${index}`}
                      maxLength="255"
                      value={state[`customer_name_${index}`]}
                      autoComplete="off"
                      onChange={this.handleChange}
                      className="fontSize-16"
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      name={`customer_phone_${index}`}
                      maxLength="255"
                      value={state[`customer_phone_${index}`]}
                      autoComplete="off"
                      onChange={this.handleChange}
                      className="fontSize-16"
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      name={`customer_email_${index}`}
                      maxLength="255"
                      value={state[`customer_email_${index}`]}
                      autoComplete="off"
                      onChange={this.handleChange}
                      className="fontSize-16"
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      name={`expired_${index}`}
                      maxLength="255"
                      value={state[`expired_${index}`]}
                      autoComplete="off"
                      onChange={this.handleChange}
                      className="fontSize-16"
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      name={`type_${index}`}
                      maxLength="255"
                      value={state[`type_${index}`]}
                      autoComplete="off"
                      onChange={this.handleChange}
                      className="fontSize-16"
                    />
                  </td>
                  <td>
                    <Form.Check
                      type="checkbox"
                      name={`send_mail_${index}`}
                      checked={state[`send_mail_${index}`]}
                      onChange={this.handleChange}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      name={`description_${index}`}
                      maxLength="255"
                      value={state[`description_${index}`]}
                      autoComplete="off"
                      onChange={this.handleChange}
                      className="fontSize-16"
                    />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
        {!hideCheckButton && (
          <div className="table-input mt-3">
            <Row aria-label="Basic example">
              <Col sm="6" xs="12" className="mt-2">
                <Form.Group className="form-password mb-3">
                  <Form.Label className="fontSize-16-Medium color-grey">
                    {translate('inputPass', language, placeholderResource)}
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      type={showPass ? 'text' : 'password'}
                      name="password"
                      maxLength="64"
                      value={password}
                      onChange={this.handleChange}
                      className="custom-input"
                    />
                    {showPass ? (
                      <InputGroup.Text onClick={() => this.handleShowPass('hidden')}>
                        <Image src={icons(iconName.showPass)} />
                      </InputGroup.Text>
                    ) : (
                      <InputGroup.Text onClick={() => this.handleShowPass('show')}>
                        <Image src={icons(iconName.hidePass)} />
                      </InputGroup.Text>
                    )}
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col sm="3" xs="12">
                <Button
                  className="btn-custom-second btn-custom color-white"
                  type="button"
                  size="md"
                  block
                  onClick={onReset}
                >
                  {translate('cancel', language, buttonResource)}
                </Button>
              </Col>
              <Col sm="3" xs="12">
                <Button
                  className="btn-custom btn-custom-first"
                  type="submit"
                  size="md"
                  variant="danger"
                  block
                  onClick={this.doSubmit}
                >
                  {translate('smCheck', language, buttonResource)}
                </Button>
              </Col>
            </Row>
          </div>
        )}
        <AlertDialog
          show={!!alertError}
          onHide={this.closeError}
          onConfirm={this.closeSuccess}
          title="Perhatian"
        >
          {alertError}
        </AlertDialog>
      </>
    )
  }
}

BulkVaTable.defaultProps = {
  data: [],
  listBank: [],
  dispatch: null,
  onReset: null,
  // onPaymentFinished: null,
  language: 'in',
  username: '',
  navigate: {},
}

BulkVaTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  listBank: PropTypes.arrayOf(PropTypes.object),
  dispatch: PropTypes.func,
  onReset: PropTypes.func,
  username: PropTypes.string,
  navigate: PropTypes.object,
  // onPaymentFinished: PropTypes.func,
  language: PropTypes.string,
}

export default BulkVaTable
