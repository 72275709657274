/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/named */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
/* eslint-disable no-lonely-if */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable radix */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unknown-property */
import React from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import {
  Container,
  Form,
  Button,
  ProgressBar,
  Table,
  Row,
  Col,
  Modal,
  InputGroup,
  Image,
} from 'react-bootstrap'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { useHistory } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'

import './withdraw.scss'
import BaseScreen from '../base'
import authConstant from '../../store/constant/auth'
import globalConstant from '../../store/constant/global'
import AlertDialog from '../dialog/alert'
import {
  getBank,
  withdrawInquiry,
  withdrawPayment,
  checkStatus,
  getDetail,
  createOTP,
  getEwalletList,
  ewalletInquiry,
  ewalletPayment,
  getTransactionStore,
} from '../../api/action/transaction'
import Formatter from '../../helper/formatter'
import Browser from '../../helper/browser'
import DateTime from '../../helper/datetime'
import LogoBank from '../../helper/iconBanks'
import translate from '../../helper/translate'
import {
  buttonResource,
  headerPageResource,
  placeholderResource,
  titleSentence,
} from '../../helper/translatePage/languageResource'
import AccessDenied from '../component/AccessDenied'
import GubukLogo from '../../assets/imgs/logo/Gubuk.png'
import icons from '../../helper/icons'
import iconName from '../../helper/constant/iconName'
import checkAccess from '../../helper/access'
import roleAccess from '../../store/constant/roleAccess'
import getCsrf from '../../api/action/getCsrf'
import logout from '../../api/action/logout'

function withParams(Component) {
  return (props) => <Component {...props} navigate={useHistory()} />
}
class WithdrawScreen extends BaseScreen {
  constructor(props) {
    super(props, ['page--withdraw'])

    this.state = {
      isOpen: false,
      listBank: [],
      listEwallet: [],
      listLastTransaction: [],
      action: 'inquiry',
      tabledata: null,
      nominalbayar: 0,
      biayaadmin: 2500,
      produkname: '',
      idtransaksi: 0,
      checksum: '',
      uuidUser: '',
      balanceFresh: false,
      uniqcode: Math.floor(Math.random() * (999 - 100 + 1) + 100),
      bankChosen: '',
      bankCode: '',
      selectedOption: null,
      detail: false,
      dataPayment: null,
      showModal: false,
      agree: false,
      showPass: false,
      isVA: false,
      transferType: 'bank',
      urlImage: '',
      nominal: 0,
      nominalByPercent: [
        {
          value: 25,
          text: '25%',
          active: false,
        },
        {
          value: 50,
          text: '50%',
          active: false,
        },
        {
          value: 75,
          text: '75%',
          active: false,
        },
        {
          value: 100,
          text: '100%',
          active: false,
        },
      ],
      recaptcha: '',
    }

    this.showTransferMethod = this.showTransferMethod.bind(this)
    this.showTransferPayment = this.showTransferPayment.bind(this)
    this.handleDetail = this.handleDetail.bind(this)
    this.captchaRef = React.createRef().current
  }

  componentDidMount() {
    super.componentDidMount()

    const { dispatch } = this.props
    const { action } = this.state

    if (this.props.isTestUser) {
      this.setState({ recaptcha: process.env.REACT_APP_RECAPTCHA_TES })
    }

    if (action === 'inquiry') {
      dispatch(getBank()).then((listBank) => {
        this.setState({
          listBank,
        })
      })
      dispatch(getEwalletList()).then((list) => {
        this.setState({
          listEwallet: list,
        })
      })
      dispatch(getTransactionStore(this.props.appUserEmail, 0, 100)).then((list) => {
        this.setState({
          listLastTransaction: list,
        })
      })
    }

    if (action === 'checkstatus') {
      this.doCheckStatusInterval()
    }
  }

  componentWillUnmount() {
    if (this.checkInterval) {
      clearInterval(this.checkInterval)
    }
  }

  readAccess = () => {
    const pathname = window.location.pathname.split('/')
    return (
      checkAccess(this.props.appUserAccess, pathname[2] ? pathname[2] : '', 'READ') &&
      this.props.appEnableAPI
    )
  }

  resetState = () => {
    this.setState({
      bankCode: '',
      bankChosen: '',
      action: 'inquiry',
      transferType: 'bank',
      selectedOption: null,
      recaptcha: '',
    })
  }

  doCheckStatusInterval = () => {
    this.checkInterval = setInterval(this.doCheckStatus, 10000)
  }

  doCheckStatus = async () => {
    const { dispatch } = this.props
    const { idtransaksi, checksum, uuidUser } = this.state

    const response = await dispatch(checkStatus(uuidUser, checksum, idtransaksi))
    if (response.isOK()) {
      if (this.checkInterval) {
        clearInterval(this.checkInterval)
      }
      const { payment_reff: idpayment, id_produk: idproduk } = response.getResultAsObject()
      const response2 = await dispatch(getDetail(uuidUser, checksum, idproduk, idpayment))
      if (response2.isOK()) {
        const { tabledata, produkname } = response2.getResultAsObject()

        this.setState({
          tabledata,
          produkname,
          idpayment,
        })
      } else {
        this.setState({ alertError: response.getRD(), action: 'inquiry' })
      }
    }

    this.setState({ balanceFresh: true })
  }

  doPrint = async () => {
    const { appUserPass } = this.props
    const { idpayment } = this.state

    let host = ''
    if (process.env.REACT_APP_ENV === 'development') {
      host = 'http://localhost:3001/'
    }
    Browser.redirect(
      `${host}api/invoice?id_transaksi=${idpayment}&date=${DateTime.format(
        new Date(),
        DateTime.FMT_DATE_YMD
      )}&token=${appUserPass}`
    )
  }

  handleTransferType = (e) => {
    this.setState({ transferType: e.target.value, bankCode: '', selectedOption: null })
  }

  doPayment = async () => {
    const {
      bankNumber,
      nominal,
      idtransaksi,
      checksum,
      uuidUser,
      pin,
      bankCode,
      transferType,
    } = this.state

    if (!pin) {
      this.showError('Enter OTP')
    } else {
      const { dispatch } = this.props
      let response = await dispatch(getCsrf())
      let pay
      if (response.isOK()) {
        if (transferType === 'bank' || transferType === 'last') {
          pay = withdrawPayment
        } else if (transferType === 'ewallet') {
          pay = ewalletPayment
        }

        response = await dispatch(
          pay(bankNumber, nominal, idtransaksi, checksum, uuidUser, pin, bankCode)
        )

        if (response.isOK()) {
          const { tabledata } = response.getResultAsObject()

          const paymentData = {
            // eslint-disable-next-line
            catatan: tabledata['CATATAN'],
            // eslint-disable-next-line
            id_transaksi: tabledata['ID TRANSAKSI'],
            // eslint-disable-next-line
            keterangan: tabledata['KETERANGAN'],
            // eslint-disable-next-line
            nama_channel: tabledata['NAMA CHANNEL'],
            // eslint-disable-next-line
            nama_pelanggan: tabledata['NAMA PELANGGAN'],
            // eslint-disable-next-line
            nama_produk: tabledata['NAMA PRODUK'],
            // eslint-disable-next-line
            no_pelanggan: tabledata['NO PELANGGAN'],
            // eslint-disable-next-line
            nominal: tabledata['NOMINAL'],
            // eslint-disable-next-line
            nominal_admin: tabledata['NOMINAL ADMIN'],
            // eslint-disable-next-line
            tanggal_transaksi: tabledata['TANGGAL TRANSAKSI'],
            // eslint-disable-next-line
            token_code: tabledata['KODE PENGAMBILAN'],
            // eslint-disable-next-line
            note: tabledata['CARA BAYAR'],
          }

          this.setState({
            tabledata: paymentData,
            dataPayment: response.getResultAsObject(),
            action: 'transfer-payment',
          })
        } else {
          this.showError(response.getRD())
        }
      } else {
        dispatch(logout())
      }
    }
  }

  doSubmit = async () => {
    let { bankCode, bankNumber } = this.state
    const { transferType, nominal, password, isVA, recaptcha, selectedOption } = this.state
    let error
    if (!bankCode && transferType === 'bank') {
      error = 'Choose destination bank name'
    } else if (!bankNumber && (transferType == 'bank' || transferType == 'ewallet')) {
      error = 'Enter destination account number'
      // eslint-disable-next-line radix
    } else if (!nominal || parseInt(nominal) <= 0) {
      error = 'Enter transfer amount (must be greater than 0)'
    } else if (!password) {
      error = 'Enter account password'
    } else if (!bankCode && transferType === 'ewallet') {
      error = 'Choose E-Wallet destination'
    } else if (!recaptcha) {
      if (!this.props.isTestUser) {
        error = 'Please fill recatpcha'
      }
    }

    if (!this.props.isTestUser) {
      this.captchaRef.reset()
    }

    if (error) {
      this.showError(error)
    } else {
      const { dispatch } = this.props

      let response = await dispatch(getCsrf())
      if (response.isOK()) {
        let inq
        if (
          transferType === 'bank' ||
          (selectedOption.data && selectedOption.data.type && selectedOption.data.type === 'BANK')
        ) {
          inq = withdrawInquiry
        } else if (
          transferType === 'ewallet' ||
          (selectedOption.data &&
            selectedOption.data.type &&
            selectedOption.data.type === 'EWALLET')
        ) {
          inq = ewalletInquiry
        }

        if (transferType === 'last') {
          bankCode = selectedOption.data.kodebank
          bankNumber = selectedOption.data.accountnumber
          this.setState({ bankCode, bankNumber })
        }
        response = await dispatch(
          inq(
            bankCode,
            bankNumber.replace(/[^0-9]/g, ''),
            nominal.replace(/[^0-9]/g, ''),
            password,
            '',
            isVA,
            '',
            '',
            recaptcha
          )
        )

        if (response.isOK()) {
          const resOTP = await dispatch(createOTP(password, nominal))
          if (resOTP.isOK()) {
            const {
              tabledata,
              nominal: nominalbayar,
              produkname,
              checksum,
              uuid_user: uuidUser,
              idtransaksi,
              url_image,
            } = response.getResultAsObject()
            // eslint-disable-next-line
            const inquirydata = {
              // eslint-disable-next-line
              catatan: tabledata['CATATAN'],
              // eslint-disable-next-line
              id_transaksi: tabledata['ID TRANSAKSI'],
              // eslint-disable-next-line
              keterangan: tabledata['KETERANGAN'],
              // eslint-disable-next-line
              nama_channel: tabledata['NAMA CHANNEL'],
              // eslint-disable-next-line
              nama_pelanggan: tabledata['NAMA PELANGGAN'],
              // eslint-disable-next-line
              nama_produk: tabledata['NAMA PRODUK'],
              // eslint-disable-next-line
              no_pelanggan: tabledata['NO PELANGGAN'],
              // eslint-disable-next-line
              nominal: tabledata['NOMINAL'],
              // eslint-disable-next-line
              nominal_admin: tabledata['NOMINAL ADMIN'],
              // eslint-disable-next-line
              tanggal_transaksi: tabledata['TANGGAL TRANSAKSI'],
            }
            this.setState({
              tabledata: inquirydata,
              nominalbayar,
              produkname,
              checksum,
              uuidUser,
              idtransaksi,
              action: 'result-inquiry',
              bankChosen: '',
              urlImage: url_image,
            })
          } else {
            this.showError(resOTP.getRD())
          }
        } else {
          this.showError(response.getRD())
        }
      } else {
        dispatch(logout())
      }
    }
  }

  doReset = () => {
    this.setState({
      action: 'inquiry',
      bankCode: '',
      bankNumber: '',
      bankName: '',
      nominal: '0',
      password: '',
      pin: '',
      selectedOption: null,
      transferType: 'bank',
    })
  }

  toggleSidebar = () => {
    const { isOpen } = this.state

    this.setState({ isOpen: !isOpen })
  }

  handleChange = (e) => {
    let value

    if (e.target.name === 'bankNumber') {
      value = Formatter.serial(e.target.value, 24)
    } else if (e.target.name === 'nominal') {
      value = Formatter.price(e.target.value)
    } else {
      value = e.target.value
    }
    this.setState({ [e.target.name]: value })
  }

  handleSelect = (selectedOption) => {
    this.setState({
      bankCode: selectedOption.value == 'IDMTARIKTUNAI' ? 'INDOMARET' : selectedOption.value,
      selectedOption,
    })
  }

  showTransferMethod() {
    this.setState({ action: 'transfer-method' })
  }

  showTransferPayment(bank) {
    this.setState({ action: 'transfer-payment', bankChosen: bank })
  }

  // eslint-disable-next-line class-methods-use-this
  backInquiry() {
    this.resetState()
  }

  renderStatus() {
    const { tabledata, nominalbayar, nominaladmin, produkname } = this.state

    if (tabledata !== null && typeof tabledata === 'object') {
      return (
        <>
          <Table hover>
            <thead>
              <tr>
                <th colSpan="2">{produkname}</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(tabledata).map((key) => (
                <tr>
                  <td>{key}</td>
                  <td>{tabledata[key]}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th>ADMIN FEE</th>
                <th>Rp {Formatter.price(nominaladmin)}</th>
              </tr>
              <tr>
                <th>TOTAL PAYMENT</th>
                <th>
                  <strong>Rp {Formatter.price(nominalbayar)}</strong>
                </th>
              </tr>
            </tfoot>
          </Table>
          <Form.Group>
            <Button type="button" size="md" variant="info" block onClick={this.doPrint}>
              Show Invoice
            </Button>
          </Form.Group>
          <Form.Group>
            <Button
              type="button"
              size="md"
              variant="outline-secondary"
              block
              onClick={this.doReset}
            >
              Reset
            </Button>
          </Form.Group>
        </>
      )
    }
    return (
      <ProgressBar
        style={{ width: '100%' }}
        className="mt-3 mb-3"
        variant="info"
        label="Please Wait..."
        animated
        now={100}
      />
    )
  }

  // used for insert pin transaction
  renderPayment() {
    const { appIsLoading } = this.props
    const { tabledata, nominalbayar, nominaladmin, produkname, pin } = this.state

    return (
      <div className="pl-5 pr-5 pb-5">
        <Table hover>
          <thead>
            <tr>
              <th colSpan="2">{produkname}</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(tabledata).map((key) => (
              <tr>
                <td>{key}</td>
                <td>{tabledata[key]}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th>ADMIN FEE AAA</th>
              <th>Rp {Formatter.price(nominaladmin)}</th>
            </tr>
            <tr>
              <th>TOTAL PAYMENT</th>
              <th>
                <strong>Rp {Formatter.price(nominalbayar)}</strong>
              </th>
            </tr>
          </tfoot>
        </Table>
        <Form.Group className="mb-3 mt-3 payment-form">
          <Form.Label className="color-grey fontSize-16-SemiBold">
            {translate('pinTrx', this.props.languange, titleSentence)}
          </Form.Label>
          <Form.Control
            type="password"
            maxLength="64"
            name="pin"
            value={pin}
            onChange={this.handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Button
            type="submit"
            size="md"
            variant="danger"
            block
            disabled={appIsLoading}
            onClick={this.doPayment}
          >
            Transfer
          </Button>
        </Form.Group>
        <Form.Group>
          <Button
            type="button"
            size="md"
            variant="outline-secondary"
            block
            disabled={appIsLoading}
            onClick={this.doReset}
          >
            {translate('reset', this.props.languange, buttonResource)}
          </Button>
        </Form.Group>
      </div>
    )
  }

  handleDetail() {
    this.setState({ detail: !this.state.detail })
  }

  // used for show detail payment by balance
  transferPayment() {
    const { appIsLoading } = this.props
    const { tabledata, selectedOption, urlImage } = this.state
    // eslint-disable-next-line
    const total = parseInt(tabledata.nominal) + parseInt(tabledata.nominal_admin)
    return (
      <Container fluid className="p-3 container-success-topup">
        <div className="subcontainer-success-topup">
          <div className="container-flex-space-between">
            <div className="box-info">
              <div className="text text-active fontSize-17">ORDER ID #{tabledata.id_transaksi}</div>
              <div className="color-grey fontSize-16">{tabledata.tanggal_transaksi} WIB</div>
            </div>
          </div>
          <div className="container-bank-info">
            <Image src={urlImage != '' ? urlImage : GubukLogo} />
            <div className="bank-info ml-3">
              <div className="fontSize-19">{tabledata.nama_channel}</div>
              <div className="color-grey fontSize-19">{tabledata.nama_pelanggan}</div>
            </div>
          </div>
          <div className="container-copy-data mb-3">
            {/* eslint-disable-next-line */}
            <div className="fontSize-19-Bold copy-data">
              <div className="text">{tabledata.no_pelanggan}</div>
              <div className="button-copy" onClick={() => this.copyText(tabledata.no_pelanggan)}>
                <Image src={icons(iconName.copy)} className="" />
                <div className="ml-2 button-copy-text fontSize-16-Medium color-white">
                  {translate('copy', this.props.language, buttonResource)}
                </div>
              </div>
            </div>
          </div>
          <div className="container-flex-space-between mb-2">
            <div className="color-grey fontSize-16 ">
              {translate('nominalTransfer', this.props.language, placeholderResource)}
            </div>
            <div className="color-grey fontSize-16 ">Rp. {Formatter.price(tabledata.nominal)}</div>
          </div>
          <div className="container-flex-space-between mb-2">
            <div className="color-grey fontSize-16">
              {translate('adminFee', this.props.language, titleSentence)}
            </div>
            <div className="color-grey fontSize-16">
              Rp. {Formatter.price(tabledata.nominal_admin)}
            </div>
          </div>
          <div className="container-copy-data mb-3">
            {/* eslint-disable-next-line */}
            <div className="fontSize-19-Bold copy-data">
              <div className="text">
                Rp {parseInt(tabledata.nominal) + parseInt(tabledata.nominal_admin)}
              </div>
              <div
                className="button-copy"
                onClick={() =>
                  this.copyText(parseInt(tabledata.nominal) + parseInt(tabledata.nominal_admin))
                }
              >
                <Image src={icons(iconName.copy)} className="" />
                <div className="ml-2 button-copy-text fontSize-16-Medium color-white">
                  {translate('copy', this.props.language, buttonResource)}
                </div>
              </div>
            </div>
          </div>
          {tabledata.token_code && (
            <Form.Group>
              <div className="footer-title">
                <span>KODE PENGAMBILAN</span>
              </div>
              <Row className="copy-wrapper">
                <div className="col" style={{ textAlign: 'center' }}>
                  {tabledata.token_code}
                </div>
              </Row>
              <div>{tabledata.note}</div>
            </Form.Group>
          )}
        </div>
      </Container>
    )
  }

  transferMethod() {
    // const { appIsLoading } = this.props
    return (
      <div className="pl-5 pr-5 pb-5">
        Dengan memilih metode transfer, saya telah setuju dengan syarat & ketentuan transaksi LinkQu
        <div className="p-3 payment-method mb-3">
          <div className="method-list">
            <button
              type="button"
              className="list-wrapper"
              name="mandiri"
              onClick={() => this.showTransferPayment('mandiri')}
            >
              <div className="bank">
                <img src={LogoBank('mandiri')} alt="" className="bank-logo" />
                <span>Mandiri</span>
              </div>
              <div className="arrow text-primary" style={{ textAlign: 'right' }}>
                <FontAwesomeIcon icon={faChevronRight} size="2x" />
              </div>
            </button>
            <button
              type="button"
              className="list-wrapper"
              name="bni"
              onClick={() => this.showTransferPayment('bank bni')}
            >
              <div className="bank">
                <img src={LogoBank('bank bni')} alt="" className="bank-logo" />
                <span>BNI</span>
              </div>
              <div className="arrow text-primary" style={{ textAlign: 'right' }}>
                <FontAwesomeIcon icon={faChevronRight} size="2x" />
              </div>
            </button>
            <button
              type="button"
              className="list-wrapper"
              name="permata"
              onClick={() => this.showTransferPayment('permata bank')}
            >
              <div className="bank">
                <img src={LogoBank('permata bank')} alt="" className="bank-logo" />
                <span>Permata</span>
              </div>
              <div className="arrow text-primary" style={{ textAlign: 'right' }}>
                <FontAwesomeIcon icon={faChevronRight} size="2x" />
              </div>
            </button>
            <button
              type="button"
              className="list-wrapper"
              name="bri"
              onClick={() => this.showTransferPayment('bank bri')}
            >
              <div className="bank">
                <img src={LogoBank('bank bri')} alt="" className="bank-logo" />
                <span>bri</span>
              </div>
              <div className="arrow text-primary" style={{ textAlign: 'right' }}>
                <FontAwesomeIcon icon={faChevronRight} size="2x" />
              </div>
            </button>
          </div>
        </div>
      </div>
    )
  }

  handleShowedPass = (action) => {
    switch (action) {
      case 'show':
        this.setState({ showPass: true })
        break
      case 'hidden':
        this.setState({ showPass: false })
        break

      default:
        this.setState({ showPass: false })
        break
    }
  }

  resultInquiry() {
    const { appIsLoading } = this.props
    const { tabledata, selectedOption, urlImage } = this.state
    // eslint-disable-next-line
    let bankName = 'Mandiri'
    if (tabledata !== null) {
      bankName = tabledata.nama_channel
    }
    const total = parseInt(tabledata.nominal) + parseInt(tabledata.nominal_admin)
    return (
      <div className="container-result-withdraw">
        <div className="p-3 result-inquiry-withdraw card mb-3">
          <div className="header-card d-flex align-items-center">
            <img
              src={urlImage != '' ? urlImage : GubukLogo}
              alt={selectedOption.label}
              className="bank-logo mr-3"
            />
          </div>
          <Row className="mb-3">
            <Col sm="7">
              <span className="props fontSize-16-SemiBold text-active">
                {translate('accountNumber', this.props.languange, titleSentence)}
              </span>
              <br />
              <span className="fontSize-16-SemiBold">{tabledata.no_pelanggan}</span>
              <br />
              <span className="text-uppercase fontSize-16-SemiBold ">
                {tabledata.nama_pelanggan}
              </span>
            </Col>
            <Col sm="5">
              <span className="props fontSize-16-SemiBold text-active">
                {translate('amount', this.props.languange, titleSentence)}
              </span>
              <br />
              <span className="fontSize-16-SemiBold">Rp {Formatter.price(tabledata.nominal)}</span>
            </Col>
          </Row>
          <Row>
            <Col sm="7">
              <div className="container-total">
                <span className="props fontSize-16-SemiBold ">Total</span>
                <br />
                <span className="fontSize-16-Medium">Rp {Formatter.price(total)}</span>
              </div>
            </Col>
            <Col sm="5">
              <span className="props fontSize-16-SemiBold text-active">
                {translate('adminFee', this.props.languange, titleSentence)}
              </span>
              <br />
              <span className="fontSize-16-SemiBold">
                Rp {Formatter.price(tabledata.nominal_admin)}
              </span>
            </Col>
          </Row>
        </div>
        <Form.Group className="mt-5 payment-form">
          <div className="mb-3">
            <Image src={icons(iconName.alert)} className="" />
            <div className="color-pink-dark fontSize-16 d-inline ml-4">
              {translate('withdrawInfoOtp', this.props.language, titleSentence)}
            </div>
          </div>
          <Row>
            <Col sm="7">
              <InputGroup className="form-password">
                <Form.Control
                  type={this.state.showPass ? 'text' : 'password'}
                  maxLength="64"
                  name="pin"
                  value={this.state.pin}
                  onChange={this.handleChange}
                  className="custom-input fontSize-16"
                />
                {this.state.showPass ? (
                  <InputGroup.Text onClick={() => this.handleShowedPass('hidden')}>
                    <Image src={icons(iconName.showPass)} />
                  </InputGroup.Text>
                ) : (
                  <InputGroup.Text onClick={() => this.handleShowedPass('show')}>
                    <Image src={icons(iconName.hidePass)} />
                  </InputGroup.Text>
                )}
              </InputGroup>
            </Col>
            <Col sm="1">
              <div />
            </Col>
            <Col sm="4">
              <Button
                type="submit"
                size="md"
                block
                // disabled={appIsLoading || !this.state.agree}
                disabled={appIsLoading}
                onClick={this.doPayment}
                className="btn-custom clear-margin btn-custom-reset-password fontSize-17"
              >
                {translate('withdrawButton', this.props.language, buttonResource)}
                <Image src={icons(iconName.arrowWhite)} className="ml-3" />
              </Button>
            </Col>
          </Row>
        </Form.Group>
      </div>
    )
  }

  handleTransferNumber(e) {
    if (e.target.value === 'is_va') {
      this.setState({ isVA: true })
    } else {
      this.setState({ isVA: false })
    }
  }

  setActive = (data) => {
    // eslint-disable-next-line prefer-const
    let { nominalByPercent, nominal } = this.state
    let balance = this.props.userBalance !== 'n/a' ? this.props.userBalance : 0
    balance = balance.replace(/\./g, '')
    // eslint-disable-next-line no-restricted-syntax
    for (const item of nominalByPercent) {
      if (item.value === data.value) {
        item.active = true
        nominal = (parseInt(balance) * parseInt(item.value)) / 100
      } else {
        item.active = false
      }
    }

    this.setState({
      nominalByPercent: nominalByPercent,
      nominal: Formatter.price(parseInt(nominal)),
    })
  }

  copyText = (copyText) => {
    navigator.clipboard.writeText(copyText)

    /* Alert the copied text */
    alert(`Copied the text: ${copyText}`)
  }

  onChangeRecaptcha = (value) => {
    this.setState({ recaptcha: value })
  }

  renderInquiry() {
    const { appIsLoading, appUserEmail, appRuleShowBalance } = this.props
    const {
      listBank,
      listEwallet,
      listLastTransaction,
      transferType,
      bankNumber,
      nominal,
      password,
      bankCode,
      nominalByPercent,
    } = this.state

    const options = []
    // eslint-disable-next-line
    for (let i = 0; i < listBank.length; i++) {
      // eslint-disable-next-line
      const option = { value: listBank[i].kodebank, label: listBank[i].namabank }
      options.push(option)
    }

    const ewalletOptions = []
    for (let i = 0; i < listEwallet.length; i++) {
      // eslint-disable-next-line
      const option = { value: listEwallet[i].id_produk, label: listEwallet[i].nama_produk }
      ewalletOptions.push(option)
    }

    const lastTransactionOptions = []
    for (let i = 0; i < listLastTransaction.length; i++) {
      // eslint-disable-next-line
      const option = { value: listLastTransaction[i].kodebank, label: listLastTransaction[i].namabank + ' - ' + listLastTransaction[i].accountnumber + ' - ' + listLastTransaction[i].accountname, data: listLastTransaction[i] }
      lastTransactionOptions.push(option)
    }

    // eslint-disable-next-line no-nested-ternary
    const label = this.state.isVA
      ? 'Virtual Account'
      : this.state.bankCode == 'INDOMARET'
      ? 'Nomor Pelanggan'
      : translate('accountNumber', this.props.languange, placeholderResource)

    return (
      <div className="pb-5 container-transfer-saldo">
        {appRuleShowBalance === 1 ? (
          <div className="total-balance">
            <div className="fontSize-18">
              {translate('availableBalance', this.props.languange, titleSentence)}
            </div>
            <div className="fontSize-26">
              Rp. {this.props.userBalance !== 'n/a' ? this.props.userBalance : 0}
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="mb-3 mt-3">
          <Form.Check
            inline
            type="radio"
            label="Transfer Bank"
            id="radio-transfer"
            value="bank"
            checked={this.state.transferType === 'bank'}
            onChange={(e) => this.handleTransferType(e)}
            className="fontSize-16"
          />
          <Form.Check
            inline
            type="radio"
            label="Transfer E-Money"
            id="radio-ewallet"
            value="ewallet"
            checked={this.state.transferType === 'ewallet'}
            onChange={(e) => this.handleTransferType(e)}
            className="fontSize-16"
          />
          <Form.Check
            inline
            type="radio"
            label="Rekening Favorit"
            id="radio-ewallet"
            value="last"
            checked={this.state.transferType === 'last'}
            onChange={(e) => this.handleTransferType(e)}
            className="fontSize-16"
          />
        </div>
        {transferType === 'bank' && (
          <>
            <label>Bank</label>
            <Select
              options={options}
              onChange={this.handleSelect}
              value={this.state.selectedOption}
              placeholder={translate('select', this.props.languange, placeholderResource)}
              className="custom-input"
            />
          </>
        )}
        {transferType === 'ewallet' && (
          <>
            <label>E-Money</label>
            <Select
              options={ewalletOptions}
              onChange={this.handleSelect}
              value={this.state.selectedOption}
              placeholder={translate('select', this.props.languange, placeholderResource)}
              className="custom-input fontSize-16"
            />
          </>
        )}
        {transferType === 'last' && (
          <>
            <label>Rekening Favorit</label>
            <Select
              options={lastTransactionOptions}
              onChange={this.handleSelect}
              value={this.state.selectedOption}
              placeholder={translate('select', this.props.languange, placeholderResource)}
              className="custom-input fontSize-16"
            />
          </>
        )}
        {transferType === 'bank' && bankCode == '011' && (
          <div className="mb-3 mt-3">
            <Form.Check
              inline
              type="radio"
              label="Transfer"
              id="radio-va"
              value="non_va"
              checked={!this.state.isVA}
              onChange={(e) => this.handleTransferNumber(e)}
              className="fontSize-16"
            />
            <Form.Check
              inline
              type="radio"
              label="Virtual Account"
              id="radio-non-va"
              value="is_va"
              checked={this.state.isVA}
              onChange={(e) => this.handleTransferNumber(e)}
              className="fontSize-16"
            />
          </div>
        )}
        {transferType !== 'last' && (
          <Form.Group className="mb-3 mt-3">
            <Form.Label className="color-grey fontSize-16-SemiBold">{label}</Form.Label>
            <Form.Control
              type="text"
              name="bankNumber"
              maxLength="24"
              placeholder={
                this.state.isVA
                  ? 'Virtual Account'
                  : translate('inputAccountNumber', this.props.languange, placeholderResource)
              }
              value={bankNumber}
              onChange={this.handleChange}
              className="custom-input fontSize-16"
            />
          </Form.Group>
        )}
        <div className={appRuleShowBalance === 1 ? 'container-split-form-balance' : ''}>
          {appRuleShowBalance === 1 ? (
            <div className="mb-3">
              <label>{translate('nominalOther', this.props.languange, placeholderResource)}</label>
              <div className="custom-form-input-choice">
                <Row>
                  {nominalByPercent.map((item) => (
                    <Col>
                      <div
                        className={
                          item.active ? 'card-subpicker card-subpicker-active' : 'card-subpicker'
                        }
                        onClick={() => this.setActive(item)}
                      >
                        <div className="d-inline-block">
                          <div
                            className={
                              item.active
                                ? 'color-white fontSize-17 text-center'
                                : 'text-active fontSize-17 text-center'
                            }
                          >
                            {item.text}
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
          ) : (
            <></>
          )}
          <Form.Group className="mb-3 form-item">
            <Form.Label className="color-grey fontSize-16-SemiBold">
              {translate(
                appRuleShowBalance === 1 ? 'nominalOther' : 'nominalTransfer',
                this.props.languange,
                placeholderResource
              )}
            </Form.Label>
            <Form.Control
              type="text"
              maxLength="15"
              name="nominal"
              placeholder={translate('inputNominal', this.props.languange, placeholderResource)}
              value={nominal}
              onChange={this.handleChange}
              className="custom-input fontSize-16"
            />
          </Form.Group>
        </div>
        <Form.Group controlId="formBasicPassword" className="form-password mb-3">
          <Form.Label className="color-grey fontSize-16-SemiBold">
            {translate('accountPass', this.props.languange, placeholderResource)}
          </Form.Label>
          <InputGroup>
            <Form.Control
              type={this.state.showPass ? 'text' : 'password'}
              maxLength="64"
              name="password"
              placeholder={translate('inputPass', this.props.languange, placeholderResource)}
              value={password}
              onChange={this.handleChange}
              className="custom-input fontSize-16"
            />
            {this.state.showPass ? (
              <InputGroup.Text onClick={() => this.handleShowedPass('hidden')}>
                <Image src={icons(iconName.showPass)} />
              </InputGroup.Text>
            ) : (
              <InputGroup.Text onClick={() => this.handleShowedPass('show')}>
                <Image src={icons(iconName.hidePass)} />
              </InputGroup.Text>
            )}
          </InputGroup>
        </Form.Group>
        <Row>
          <Col sm="6" xs="12">
            {!this.props.isTestUser && (
              <ReCAPTCHA
                ref={(ref) => {
                  this.captchaRef = ref
                }}
                className="mt-4"
                sitekey={process.env.REACT_APP_GRECAPTCHA_KEY}
                onChange={this.onChangeRecaptcha}
              />
            )}
          </Col>
          <Col sm="6" xs="12">
            <Button
              type="submit"
              size="md"
              block
              disabled={appIsLoading}
              onClick={this.doSubmit}
              className="btn-custom btn-custom-reset-password fontSize-17"
            >
              <Image src={icons(iconName.checkSquare)} className="mr-2" />
              {translate('checkSecond', this.props.languange, buttonResource)}
            </Button>
          </Col>
        </Row>
      </div>
    )
  }

  closeModal = () => {
    this.setState({ showModal: false })
  }

  render() {
    const { appIsLoading, appUserData } = this.props
    const { action, alertError } = this.state

    const isParent = appUserData?.id_user_parent == null

    return (
      <>
        {!this.readAccess() ? (
          <AccessDenied />
        ) : (
          <>
            <Container fluid className="main-form">
              <div
                className="mb-3 container-flex-center"
                onClick={() => this.props.history.goBack()}
              >
                <button type="button" className="btn btn-outline-primary back-button">
                  <Image src={icons(iconName.backButton)} className="" />
                </button>
                {action === 'inquiry' ? (
                  <span className="ml-2 fontSize-20-Bold">
                    {translate('withdraw', this.props.language, titleSentence)}
                  </span>
                ) : (
                  <></>
                )}
                {action === 'result-inquiry' ? (
                  <span className="ml-2">
                    <div className="fontSize-16">
                      {translate('withdrawInfo1', this.props.language, titleSentence)}
                    </div>
                    <div className="fontSize-16">
                      {translate('withdrawInfo2', this.props.language, titleSentence)}
                    </div>
                  </span>
                ) : (
                  <></>
                )}
              </div>
              {isParent ? (
                <>
                  {appIsLoading && (
                    <ProgressBar
                      style={{ width: '100%' }}
                      className="mt-3 mb-3"
                      variant="info"
                      label="Please Wait..."
                      animated
                      now={100}
                    />
                  )}
                  {action === 'inquiry' && this.renderInquiry()}
                  {action === 'result-inquiry' && this.resultInquiry()}
                  {action === 'transfer-payment' && this.transferPayment()}
                </>
              ) : (
                <AccessDenied />
              )}
            </Container>
          </>
        )}
        <AlertDialog
          show={!!alertError}
          onHide={this.closeError}
          onConfirm={this.closeError}
          type="warning"
          messageTitle={alertError}
        />
        <Modal show={this.state.showModal} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>FAQ</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* eslint-disable-next-line */}
            <iframe src="https://www.linkqu.id/faq-produk-dan-layanan/" />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" className="d-none">
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

WithdrawScreen.defaultProps = {
  appIsLoading: false,
}

WithdrawScreen.propTypes = {
  appIsLoading: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
    appUserPass: state.Auth[authConstant.USER_PASS],
    appUserData: state.Auth[authConstant.USER_DATA],
    appUserEmail: state.Auth[authConstant.USER_EMAIL],
    languange: state.Global[globalConstant.LANGUAGE],
    appEnableAPI: state.Auth[authConstant.ENABLE_API],
    appUserAccess: state.RoleAccess[roleAccess.ACCESS],
    userBalance: state.Auth[authConstant.USER_BALANCE],
    appRuleShowBalance: state.Global[global.RULE_SHOW_BALANCE],
    isTestUser: state.Global[globalConstant.IS_TEST_USER],
  }),
  (dispatch) => ({ dispatch })
)(withParams(WithdrawScreen))
