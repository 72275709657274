/* eslint-disable camelcase */
import MessageSender from '../sender'
import BaseMessage from '../message'

export default (idUser) => async () => {
  const msg = new BaseMessage()

  msg.setPath('/data/modul_access_user')
  msg.setItem('id_user', idUser)

  const response = await new MessageSender().doGet(msg)

  return response
}
