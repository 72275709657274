/* eslint-disable no-nested-ternary */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-lone-blocks */
/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable eqeqeq */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import DataTable from 'react-data-table-component'
import DataTableExtensions from 'react-data-table-component-extensions'
import Select from 'react-select'
// import CsvDownload from 'react-csv-downloader'
import {
  Button,
  ProgressBar,
  Container,
  Row,
  Col,
  InputGroup,
  FormControl,
  Dropdown,
  DropdownButton,
  Nav,
  Image,
} from 'react-bootstrap'
import DatePicker from 'react-date-picker'
import 'react-data-table-component-extensions/dist/index.css'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import qs from 'qs'
import { Link, useParams, useHistory, useLocation } from 'react-router-dom'

import './data.scss'
import CsvDownload from 'react-csv-downloader'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import BaseScreen from '../base'
import globalConstant from '../../store/constant/global'
import authConstant from '../../store/constant/auth'
import getData from '../../api/action/data'
import AlertDialog from '../dialog/alert'
import TransactionColumn from '../component/column/transaction'
import BalanceColumn from '../component/column/balance'
import VirtualAccountColumn from '../component/column/va_column'
import CallbackColumn from '../component/column/callback'
import DateTime from '../../helper/datetime'
import retryCallback from '../../api/action/retryCallback'
import Browser from '../../helper/browser'
import DepositColumn from '../component/column/deposit'
import DisbursementColumn from '../component/column/disbursement'
import TransactionBulkVaColumn from '../component/column/transaction_bulk_va'
import DisbursementDetailColumn from '../component/column/disbursement_detail'
import dataListVA from '../../api/action/dataListVA'
import dataVA from '../../api/action/dataVA'
import roleAccess from '../../store/constant/roleAccess'
import checkAccess from '../../helper/access'
import AccessDenied from '../component/AccessDenied'
import {
  buttonResource,
  headerPageResource,
  placeholderResource,
  sidebarLangResource,
  titleSentence,
} from '../../helper/translatePage/languageResource'
import translate from '../../helper/translate'
import VirtualAccountOpenColumn from '../component/column/va_open_column'
import MonitorHoldBalanceColumn from '../component/column/monitor_hold_balance'
import SettlementMerchant from '../component/column/settlement_merchant'
import SettlementMerchantProcess from '../component/column/settlement_merchant_process'
import DanaTalangan from '../component/column/dana_talangan'
import MerchantMember from '../component/column/merchant_member'
import aggregatorFeeDaily from '../component/column/aggregator_fee_daily'
import aggregatorFeeMonthly from '../component/column/aggregator_fee_monthly'
import resumeShareProfitByMitraDaily from '../component/column/resume_share_profit_by_mitra_daily'
import resumeShareProfitByMitraMonthly from '../component/column/resume_share_profit_by_mitra_monthly'

// eslint-disable-next-line import/no-unresolved
import '../../bootstrap.min.css'
// eslint-disable-next-line import/no-unresolved
import '../../daterangepicker.css'
import operator from '../../helper/constant/operator'
import dataType from '../../helper/constant/dataType'
import icons from '../../helper/icons'
import iconName from '../../helper/constant/iconName'
import TransferColumn from '../component/column/transfer'
import isMartinGrup from '../../helper/isMartinGroup'
import BalanceDetailColumn from '../component/column/balance_detail'
import ExportDataColumn from '../component/column/export_data'
import MessageSender from '../../api/sender'
import settingAccountFavourite from '../component/column/setting_account_favourite'
import setChangeStatusAccountFavourite from '../../api/action/setChangeStatusAccountFavourite'
import { danaTalangan } from '../../api/action/transaction'
import deleteDanaTalangan from '../../api/action/deleteDanaTalangan'

const customStyles = {
  rows: {
    style: {
      minHeight: '72px', // override the row height
      cursor: 'pointer',
    },
  },
  headCells: {
    style: {
      paddingLeft: '8px', // override the cell padding for head cells
      paddingRight: '8px',
    },
  },
  cells: {
    style: {
      paddingLeft: '8px', // override the cell padding for data cells
      paddingRight: '8px',
    },
  },
}

const customSelectStyles = {
  container: (styles) => ({ ...styles, width: '250px' }),
  menu: (styles) => ({ ...styles, zIndex: '99' }),
}

function withParams(Component) {
  return (props) => (
    <Component {...props} params={useParams()} navigate={useHistory()} location={useLocation()} />
  )
}

class DataScreen extends BaseScreen {
  constructor(props) {
    super(props, ['page--data'])

    const { path } = props.match.params
    const { key, value, date } = qs.parse(props.location.search, { ignoreQueryPrefix: true })

    this.state = {
      path,
      data: [],
      dataDownload: [],
      total: 0,
      page: 1,
      limit: 10,
      sort: '',
      isOpen: false,
      duce: [],
      alertConfirm: '',
      dataConfirm: null,
      filters: [],
      listVA: [],
      loadListVa: false,
      selectedVa: null,
      headers: [],
      queryParam: {
        value: value,
        key: key,
        date: date,
      },
      dateRangeTitleDownload: `${DateTime.format(Date(), DateTime.FMT_DATE_YMD)}&${DateTime.format(
        Date(),
        DateTime.FMT_DATE_YMD
      )}`,
    }

    let selectedColumn
    if (path === 'transaction') {
      selectedColumn = TransactionColumn
    } else if (path === 'transfer') {
      selectedColumn = TransferColumn
    } else if (path === 'balance') {
      selectedColumn = BalanceColumn
    } else if (path === 'export_data') {
      selectedColumn = ExportDataColumn
    } else if (path === 'balance_detail') {
      selectedColumn = BalanceDetailColumn
    } else if (path === 'virtual_account_transaction') {
      selectedColumn = VirtualAccountColumn
    } else if (path === 'virtual_account_reserved') {
      selectedColumn = VirtualAccountOpenColumn
    } else if (path === 'callback') {
      selectedColumn = CallbackColumn
    } else if (path === 'deposit') {
      selectedColumn = DepositColumn
    } else if (path === 'disbursement') {
      selectedColumn = DisbursementColumn
    } else if (path === 'bulk_virtual_account') {
      selectedColumn = TransactionBulkVaColumn
    } else if (path === 'disbursement_detail') {
      selectedColumn = DisbursementDetailColumn
    } else if (path === 'monitor_hold_balance') {
      selectedColumn = MonitorHoldBalanceColumn
    } else if (path === 'settlement_merchant_sent') {
      selectedColumn = SettlementMerchant
    } else if (path === 'settlement_merchant_process') {
      selectedColumn = SettlementMerchantProcess
    } else if (path === 'merchant_member') {
      selectedColumn = MerchantMember
    } else if (path === 'aggregator_fee_daily') {
      selectedColumn = aggregatorFeeDaily
    } else if (path === 'aggregator_fee_monthly') {
      selectedColumn = aggregatorFeeMonthly
    } else if (path === 'resume_share_profit_by_mitra_daily') {
      selectedColumn = resumeShareProfitByMitraDaily
    } else if (path === 'resume_share_profit_by_mitra_monthly') {
      selectedColumn = resumeShareProfitByMitraMonthly
    } else if (path === 'setting_account_favourite') {
      selectedColumn = settingAccountFavourite
    } else if (path === 'dana_talangan') {
      selectedColumn = DanaTalangan
    }

    this.tableColumns = selectedColumn(this.actionFunction, this.props.languange)

    this.tableRef = React.createRef()
  }

  componentDidMount() {
    super.componentDidMount()
    this.setState({ path: this.props.match.params.path })

    if (this.props.match.params.path === 'virtual_account_transaction') {
      this.getListVa('close')
    } else if (this.props.match.params.path === 'virtual_account_reserved') {
      this.getListVa('open')
    }

    const { limit, sort, date, filterKey, filterValue, dateEnd, filters } = this.state
    const { columns } = this.tableColumns

    let filtersTemp = []
    // eslint-disable-next-line no-restricted-syntax
    let i = 0
    // eslint-disable-next-line no-restricted-syntax
    for (const col of columns) {
      if (col.default) {
        filtersTemp = [
          {
            filterTitle: col.name,
            filterColumn: col.selector,
            filterColumnNumber: i,
            filterKey: col.type,
            filterDecision: col.type == dataType.DATERANGE ? 'between' : '=',
            filterValue: `${DateTime.format(Date(), DateTime.FMT_DATE_YMD)}&${DateTime.format(
              Date(),
              DateTime.FMT_DATE_YMD
            )}`,
            filterDefault: col.default,
            filterOperator: operator[col.type],
          },
        ]

        break
      }
      // eslint-disable-next-line no-plusplus
      i++
    }

    if (filtersTemp.length == 0) {
      filtersTemp = [
        {
          filterTitle: '',
          filterColumn: '',
          filterColumnNumber: 0,
          filterKey: '',
          filterDecision: '',
          filterValue: '',
          filterDefault: false,
          filterOperator: operator.NULL,
        },
      ]
    }

    this.setState({ filters: filtersTemp })
    this.setHeaders(columns)
    if (this.state.path === this.props.match.params.path) {
      this.doReload(1, limit, sort, filtersTemp, false, this.state.queryParam)
    } else if (this.state.path == 'disbursement') {
      this.doReload(1, limit, sort, filters, false, this.state.queryParam)
    }
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    const { path, selectedVa, limit, sort, filters } = this.state
    const { columns } = this.tableColumns

    if (
      (path === 'virtual_account_reserved' || path === 'virtual_account_transaction') &&
      prevState.selectedVa !== selectedVa
    ) {
      this.getVirtualAccountPageData(1, selectedVa.value, limit, sort, filters)
    }

    if (prevState.path !== this.props.match.params.path) {
      if (
        this.props.match.params.path === 'callback' ||
        this.props.match.params.path === 'disbursement_detail'
      ) {
        const { value, date: selectedDate, key: selectedKey } = qs.parse(
          this.props.location.search,
          {
            ignoreQueryPrefix: true,
          }
        )
        this.resetTable(this.props.match.params.path)
        // this.resetState(value, selectedDate, selectedKey)
        this.componentDidMount()
      } else {
        this.resetTable(this.props.match.params.path)
        // this.resetState('', '', '')
        this.componentDidMount()
      }
    } else if (prevProps.languange !== this.props.languange) {
      this.resetTable(this.props.match.params.path)
      this.componentDidMount()
    }
  }

  setHeaders = (columns) => {
    if (!Array.isArray(columns)) {
      return []
    }
    const header = columns.map((column) => {
      if (column.name === 'Action') {
        return { id: '', displayName: column.name }
      }
      return { id: column.selector, displayName: column.name }
    })

    this.setState({ headers: header })
  }

  resetState = (filterValue, date, key) => {
    this.setState({
      page: 1,
      limit: 10,
      sort: '',
      duce: [],
      filters: [],
      listVA: [],
      loadListVa: false,
      selectedVa: null,
    })
  }

  resetTable = (path) => {
    let selectedColumn
    if (path === 'transaction') {
      selectedColumn = TransactionColumn
    } else if (path === 'transfer') {
      selectedColumn = TransferColumn
    } else if (path === 'balance') {
      selectedColumn = BalanceColumn
    } else if (path === 'export_data') {
      selectedColumn = ExportDataColumn
    } else if (path === 'balance_detail') {
      selectedColumn = BalanceDetailColumn
    } else if (path === 'virtual_account_transaction') {
      selectedColumn = VirtualAccountColumn
    } else if (path === 'virtual_account_reserved') {
      selectedColumn = VirtualAccountOpenColumn
    } else if (path === 'callback') {
      selectedColumn = CallbackColumn
    } else if (path === 'deposit') {
      selectedColumn = DepositColumn
    } else if (path === 'disbursement') {
      selectedColumn = DisbursementColumn
    } else if (path === 'bulk_virtual_account') {
      selectedColumn = TransactionBulkVaColumn
    } else if (path === 'disbursement_detail') {
      selectedColumn = DisbursementDetailColumn
    } else if (path === 'monitor_hold_balance') {
      selectedColumn = MonitorHoldBalanceColumn
    } else if (path === 'settlement_merchant_sent') {
      selectedColumn = SettlementMerchant
    } else if (path === 'settlement_merchant_process') {
      selectedColumn = SettlementMerchantProcess
    } else if (path === 'merchant_member') {
      selectedColumn = MerchantMember
    } else if (path === 'aggregator_fee_daily') {
      selectedColumn = aggregatorFeeDaily
    } else if (path === 'aggregator_fee_monthly') {
      selectedColumn = aggregatorFeeMonthly
    } else if (path === 'resume_share_profit_by_mitra_daily') {
      selectedColumn = resumeShareProfitByMitraDaily
    } else if (path === 'resume_share_profit_by_mitra_monthly') {
      selectedColumn = resumeShareProfitByMitraMonthly
    } else if (path === 'setting_account_favourite') {
      selectedColumn = settingAccountFavourite
    } else if (path === 'dana_talangan') {
      selectedColumn = DanaTalangan
    }

    this.tableColumns = selectedColumn(this.actionFunction, this.props.languange)
  }

  getListVa = async (type) => {
    this.setState({ loadListVa: true })

    const { dispatch } = this.props

    try {
      const listVa = await dispatch(dataListVA(type))
      const filteredVa = listVa.data.filter((filterVa) => filterVa.kodeBank != 523)

      const mappedValueOptions = filteredVa.map((VA) => {
        return { value: VA.kodeBank, label: VA.namaBank }
      })
      this.setState({ listVA: mappedValueOptions })
      this.setState({ selectedVa: mappedValueOptions[0] })
      this.setState({ loadListVa: false })
    } catch (error) {
      this.setState({ loadListVa: false })
    }
  }

  doReload = async (page, limit, sort, filters, _isDownload = false, queryParam) => {
    const { dispatch, appAgentUser } = this.props
    const { path } = this.state

    if (_isDownload) {
      const response = await dispatch(getData(path, page, sort, limit, filters, queryParam, 1))
      if (response.isOK()) {
        this.setState({
          alertConfirm: response.getRD(),
          dataConfirm: {
            action: null,
            row: null,
          },
        })
      } else {
        this.setState({ alertError: response.getRD() })
      }
    } else {
      const { total, data } = await dispatch(getData(path, page, sort, limit, filters, queryParam))
      this.setState({ total, data, page, limit, sort })
    }
  }

  getVirtualAccountPageData = async (
    page,
    selectedVA,
    limit,
    sort,
    filters,
    _isDownload = false
  ) => {
    const { dispatch, appAgentUser } = this.props
    const { path } = this.state

    const { total, data } = await dispatch(dataVA(path, selectedVA, page, sort, limit, filters))
    if (_isDownload) {
      const response = await dispatch(dataVA(path, selectedVA, page, sort, limit, filters, 1))
      if (response.isOK()) {
        this.setState({
          alertConfirm: response.getRD(),
          dataConfirm: {
            action: null,
            row: null,
          },
        })
      } else {
        this.setState({ alertError: response.getRD() })
      }
    } else {
      this.setState({ total, data, page, limit, sort })
    }
  }

  setFiltered = () => {
    const { path, selectedVa, limit, sort, filters } = this.state
    const { columns } = this.tableColumns

    if (path === 'virtual_account_reserved' || path === 'virtual_account_transaction') {
      this.getVirtualAccountPageData(1, selectedVa.value, limit, sort, filters)
    } else {
      this.doReload(1, limit, sort, filters, false, this.state.queryParam)
    }
  }

  setPage = (page) => {
    const { path, selectedVa, limit, sort, filters } = this.state
    const { columns } = this.tableColumns

    if (path === 'virtual_account_reserved' || path === 'virtual_account_transaction') {
      this.getVirtualAccountPageData(page, selectedVa.value, limit, sort, filters)
    } else {
      this.doReload(page, limit, sort, filters, false, this.state.queryParam)
    }
  }

  setLimit = (limit) => {
    const { path, selectedVa, sort, filters } = this.state
    const { columns } = this.tableColumns

    if (path === 'virtual_account_reserved' || path === 'virtual_account_transaction') {
      this.getVirtualAccountPageData(1, selectedVa.value, limit, sort, filters)
    } else {
      this.doReload(1, limit, sort, filters, false, this.state.queryParam)
    }
  }

  setSorting = (column, dir) => {
    const { path, selectedVa, limit, filters } = this.state
    const { columns } = this.tableColumns
    const { selector } = column

    if (path === 'virtual_account_reserved' || path === 'virtual_account_transaction') {
      this.getVirtualAccountPageData(1, selectedVa.value, limit, `${selector} ${dir}`, filters)
    } else {
      this.doReload(1, limit, `${selector} ${dir}`, filters, false, this.state.queryParam)
    }
  }

  setDateStart = (date) => {
    const { path, selectedVa, limit, sort, filters } = this.state
    const { columns } = this.tableColumns

    if (path === 'virtual_account_reserved' || path === 'virtual_account_transaction') {
      this.getVirtualAccountPageData(1, selectedVa.value, limit, sort, filters)
    } else {
      this.doReload(1, limit, sort, filters, false, this.state.queryParam)
    }
  }

  setDateEnd = (dateEnd) => {
    const { path, selectedVa, limit, sort, filters } = this.state
    const { columns } = this.tableColumns

    if (path === 'virtual_account_reserved' || path === 'virtual_account_transaction') {
      this.getVirtualAccountPageData(1, selectedVa.value, limit, sort, filters)
    } else {
      this.doReload(1, limit, sort, filters, false, this.state.queryParam)
    }
  }

  setFilterKey = (e) => {
    const {
      dataset: { index, type },
    } = e.target

    this.setState({ filterKey: index })
    const { id } = e.target

    if (id) {
      const splitId = id.split('-')
      // eslint-disable-next-line
      const idx = parseInt(splitId[1])

      const { filters } = this.state
      const { columns } = this.tableColumns
      filters[idx].filterTitle = columns[index].name
      filters[idx].filterColumn = columns[index].selector
      filters[idx].filterColumnNumber = index
      filters[idx].filterKey = type
      filters[idx].filterDecision = ''
      filters[idx].filterValue = ''
      filters[idx].valueTemporary = ''
      filters[idx].filterOperator = operator[type]
      this.setState({ filters: filters })
    }
  }

  setFilterDecision = (e) => {
    // eslint-disable-next-line
    const {
      dataset: { id, value },
    } = e.target

    if (id) {
      const { filters } = this.state
      filters[id].filterDecision = value
      this.setState({ filters: filters })
    }
  }

  setFilterValueEnum = (e) => {
    // eslint-disable-next-line
    const {
      dataset: { id, value, index },
    } = e.target

    if (id) {
      const { filters } = this.state
      filters[id].valueTemporary = value
      filters[id].filterValue = index
      this.setState({ filters: filters })
    }
  }

  setFilterValueDateRange = (e, picker) => {
    const { id } = e.target
    const splitId = id.split('-')
    // eslint-disable-next-line
    const idx = parseInt(splitId[1])
    const { filters } = this.state
    filters[idx].filterValue = `${picker.startDate.format('YYYY-MM-DD')}&${picker.endDate.format(
      'YYYY-MM-DD'
    )}`
    filters[idx].valueTemporary = `${picker.startDate.format(
      'DD/MM/YYYY'
    )} - ${picker.endDate.format('DD/MM/YYYY')}`
    this.setState({ filters: filters, dateRangeTitleDownload: filters[idx].valueTemporary })
  }

  setFilterValue = (e) => {
    const { id } = e.target
    const splitId = id.split('-')
    // eslint-disable-next-line
    const idx = parseInt(splitId[1])
    const { filters } = this.state
    filters[idx].filterValue =
      // eslint-disable-next-line radix
      filters[idx].filterkey == dataType.INTEGER ? parseInt(e.target.value) : e.target.value
    this.setState({ filters: filters })
  }

  closeDuce = () => {
    this.setState({ duce: [] })
  }

  closeConfirm = () => {
    this.setState({ alertConfirm: '', dataConfirm: null })
  }

  doConfirm = async () => {
    const {
      dataConfirm: { action, row },
    } = this.state
    const { dispatch } = this.props
    const { limit, total, filters, sort, queryParam, date, dateEnd } = this.state

    switch (action) {
      case 'retry':
        {
          const response = await dispatch(retryCallback(row.id_callback))
          if (response.isOK()) {
            this.setState({ filterKey: 0, filterValue: row.id_callback })

            this.doReload(1, limit, sort, date, 'id_callback', row.id_callback, dateEnd)
          } else {
            this.setState({ alertError: response.getRD() })
          }
        }
        break
      case 'reload':
        {
          this.doReload(1, total, sort, filters, false, queryParam)
        }
        break
      default:
        break
    }

    this.closeConfirm()
  }

  actionFunction = (row, e) => {
    const {
      dataset: { action },
    } = e.currentTarget

    switch (action) {
      case 'retry':
        this.setState({
          alertConfirm: translate(
            'retryCallbackConfirm',
            this.props.languange,
            placeholderResource
          ),
          dataConfirm: { action, row },
        })
        break
      case 'print':
        {
          Browser.openInNewTab(
            `${Browser.baseURL()}invoice/${row.id_transaksi}/${DateTime.convert(
              Browser.getCurrentPath().includes('disbursement_detail')
                ? row.created_date
                : row.time_request,
              DateTime.FMT_DATE_TIME_YMDHMSU,
              DateTime.FMT_DATE_YMD
            )}`
          )
        }
        break
      case 'download':
        {
          this.downloadData(row.id)
        }
        break
      case 'edit-status':
        {
          this.changeStatus(row.id_accounts)
        }
        break
      case 'hapus':
        {
          this.hapus(row.id)
        }
        break
      case 'callback':
        this.props.navigate.push(
          `/data/callback?key=1&value=${row.id_transaksi}&date=${row.time_request}`
        )
        break
      case 'balance':
        Browser.redirect(`data/balance?key=0&value=${row.id_transaksi}&date=${row.time_request}`)
        break
      case 'disbursement_detail':
        this.setState({ queryParam: { value: row.id } })
        this.props.navigate.push(
          `/data/disbursement_detail?key=0&value=${row.id}&date=${row.created_date}`
        )
        break
      case 'transfer_detail':
        this.props.navigate.push(`/transaction/transfer/detail/${row.id}`)
        break
      default:
        {
          const { columns } = this.tableColumns
          const duce = Object.keys(row).reduce((acc, key) => {
            const [y] = columns.filter((x) => x.selector === key)
            if (y) {
              acc.push({
                label: y.name,
                value: y.cell !== undefined ? y.cell(row) : row[key],
                preFormatted: y.preFormatted,
              })
            }

            return acc
          }, [])

          this.setState({ duce })
        }
        break
    }
  }

  appendFilter = () => {
    const { columns } = this.tableColumns
    const { filters } = this.state
    const max = columns.length - 1
    if (filters.length < max) {
      this.setState({
        filters: [
          ...filters,
          {
            filterTitle: '',
            filterColumn: '',
            filterKey: '',
            filterDecision: '',
            filterValue: '',
            filterDefault: false,
            filterOperator: operator.NULL,
          },
        ],
      })
    }
  }

  dicreaseFilter = (i) => {
    const filters = [...this.state.filters]
    filters.splice(i, 1)
    this.setState({ filters: filters })
  }

  handleVA = (evt) => {
    this.setState({ selectedVa: evt })
  }

  readAccess = () => {
    const { appUserType, appEnableAPI } = this.props
    if (
      (this.state.path === 'deposit' ||
        this.state.path === 'settlement_merchant_sent' ||
        this.state.path === 'settlement_merchant_process' ||
        this.state.path === 'balance' ||
        this.state.path === 'balance_detail' ||
        this.state.path === 'bulk_virtual_account' ||
        this.state.path === 'setting_account_favourite' ||
        this.state.path === 'monitor_hold_balance' ||
        this.state.path === 'dana_talangan') &&
      !appEnableAPI
    ) {
      return false
    }

    if (
      (this.state.path === 'transaction' || this.state.path === 'bulk_virtual_account') &&
      appUserType === 'Personal END USER'
    ) {
      return false
    }

    return checkAccess(this.props.appUserAccess, `data_${this.state.path}`, 'READ')
  }

  noData = () => {
    return (
      <p className="mt-4 fontSize-16-Medium">
        {translate('noData', this.props.languange, placeholderResource)}
      </p>
    )
  }

  downloadData = async (id) => {
    const { path, selectedVa, total, sort, filters } = this.state
    const { columns } = this.tableColumns
    const { appAgentUser, dispatch } = this.props

    this.setHeaders(columns)
    let datas = []

    if (path === 'virtual_account_reserved' || path === 'virtual_account_transaction') {
      datas = await this.getVirtualAccountPageData(1, selectedVa.value, total, sort, filters, true)
    } else if (path === 'export_data') {
      Browser.openInNewTab(
        `${
          process.env.REACT_APP_API_URL
        }api/publics/download_csv?id=${id}&token=${MessageSender.checkToken()}`
      )
    } else {
      datas = await this.doReload(1, total, sort, filters, true, false, this.state.queryParam)
    }

    return datas
  }

  changeStatus = async (id) => {
    const { dispatch } = this.props
    const { total, sort, filters, queryParam } = this.state

    const response = await dispatch(setChangeStatusAccountFavourite(id))
    console.log('check response => ', response)
    if (response.isOK()) {
      this.setState({
        alertConfirm: response.getRD(),
        dataConfirm: {
          action: 'reload',
          row: null,
        },
      })
    } else {
      this.setState({ alertError: response.getRD() })
    }
  }

  hapus = async (id) => {
    const { dispatch } = this.props
    const { total, sort, filters, queryParam } = this.state

    const response = await dispatch(deleteDanaTalangan(id))
    console.log('check response => ', response)
    if (response.isOK()) {
      this.setState({
        alertConfirm: response.getRD(),
        dataConfirm: {
          action: 'reload',
          row: null,
        },
      })
    } else {
      this.setState({ alertError: response.getRD() })
    }
  }

  render() {
    const {
      appIsLoading,
      appUserType,
      appUserData,
      appEnableAPI,
      appAgentUser,
      appRuleShowBalance,
    } = this.props
    const { path, data, total, limit, duce, alertError, alertConfirm, filters, listVA } = this.state

    const isParent = appUserData?.id_user_parent == null

    const { key, columns } = this.tableColumns
    let title
    let cardHeaderPicker = []
    let cardSubHeaderPicker = []
    let cardHeader = false
    let isVa = false
    if (path === 'transaction') {
      title = translate('historyTransaction', this.props.languange, sidebarLangResource)
      cardSubHeaderPicker = [
        {
          icon: icons(iconName.api),
          title: translate('api', this.props.languange, titleSentence),
          active: true,
          link: '/data/transaction',
          show: appUserType === 'Personal END USER' ? false : true,
        },
        {
          icon: icons(iconName.cursorInputActive, process.env.REACT_APP_BRAND_NAME),
          title: translate('manualInput', this.props.languange, titleSentence),
          active: false,
          link: '/data/transfer',
          show: true,
        },
        {
          icon: icons(iconName.bulkTransferActive, process.env.REACT_APP_BRAND_NAME),
          title: translate('bulkTransfer', this.props.languange, titleSentence),
          active: false,
          link: '/data/disbursement',
          show: true,
        },
        {
          icon: icons(iconName.bulkTransferActive, process.env.REACT_APP_BRAND_NAME),
          title: translate('bulkCreateVa', this.props.languange, titleSentence),
          active: false,
          link: '/data/bulk_virtual_account',
          show: appEnableAPI && (appUserType === 'Personal END USER' ? false : true),
        },
      ]
    } else if (path === 'transfer') {
      title = translate('manualInput', this.props.languange, titleSentence)
      cardSubHeaderPicker = [
        {
          icon: icons(iconName.apiActive, process.env.REACT_APP_BRAND_NAME),
          title: translate('api', this.props.languange, titleSentence),
          active: false,
          link: '/data/transaction',
          show: appUserType === 'Personal END USER' ? false : true,
        },
        {
          icon: icons(iconName.cursorInput),
          title: translate('manualInput', this.props.languange, titleSentence),
          active: true,
          link: '/data/transfer',
          show: true,
        },
        {
          icon: icons(iconName.bulkTransferActive, process.env.REACT_APP_BRAND_NAME),
          title: translate('bulkTransfer', this.props.languange, titleSentence),
          active: false,
          link: '/data/disbursement',
          show: true,
        },
        {
          icon: icons(iconName.bulkTransferActive, process.env.REACT_APP_BRAND_NAME),
          title: translate('bulkCreateVa', this.props.languange, titleSentence),
          active: false,
          link: '/data/bulk_virtual_account',
          show: appEnableAPI && (appUserType === 'Personal END USER' ? false : true),
        },
      ]
    } else if (path === 'balance') {
      cardHeader = this.readAccess()
      title = translate('accountStatement', this.props.languange, headerPageResource)
      cardSubHeaderPicker = [
        {
          icon: icons(iconName.bulkTransfer, process.env.REACT_APP_BRAND_NAME),
          title: translate('mutation', this.props.languange, titleSentence),
          active: true,
          link: '/data/balance',
          show: appEnableAPI && (appUserType === 'Personal END USER' ? false : true),
        },
        {
          icon: icons(iconName.bulkTransferActive, process.env.REACT_APP_BRAND_NAME),
          title: translate('mutationDetail', this.props.languange, titleSentence),
          active: false,
          link: '/data/balance_detail',
          show: appEnableAPI && (appUserType === 'Personal END USER' ? false : true),
        },
      ]
    } else if (path === 'balance_detail') {
      cardHeader = this.readAccess()
      title = translate('accountStatement', this.props.languange, headerPageResource)
      cardSubHeaderPicker = [
        {
          icon: icons(iconName.bulkTransferActive, process.env.REACT_APP_BRAND_NAME),
          title: translate('mutation', this.props.languange, titleSentence),
          active: false,
          link: '/data/balance',
          show: appEnableAPI && (appUserType === 'Personal END USER' ? false : true),
        },
        {
          icon: icons(iconName.bulkTransfer, process.env.REACT_APP_BRAND_NAME),
          title: translate('mutationDetail', this.props.languange, titleSentence),
          active: true,
          link: '/data/balance_detail',
          show: appEnableAPI && (appUserType === 'Personal END USER' ? false : true),
        },
      ]
    } else if (path === 'callback') {
      title = translate('Report', this.props.languange, sidebarLangResource)
      cardSubHeaderPicker = [
        {
          title: translate('monitorHoldBalance', this.props.languange, sidebarLangResource),
          active: false,
          link: '/data/monitor_hold_balance',
          show: appEnableAPI,
        },
        {
          title: 'Monitoring Callback',
          active: true,
          link: '/data/callback',
          show: appEnableAPI,
        },
      ]
    } else if (path === 'deposit') {
      title = 'Balance Topup'
    } else if (path === 'setting_account_favourite') {
      title = translate('settingACcountFavourite', this.props.languange, sidebarLangResource)
    } else if (path === 'disbursement') {
      title = translate('bulkTransferData', this.props.languange, sidebarLangResource)
      cardSubHeaderPicker = [
        {
          icon: icons(iconName.apiActive, process.env.REACT_APP_BRAND_NAME),
          title: translate('api', this.props.languange, titleSentence),
          active: false,
          link: '/data/transaction',
          show: appUserType === 'Personal END USER' ? false : true,
        },
        {
          icon: icons(iconName.cursorInputActive, process.env.REACT_APP_BRAND_NAME),
          title: translate('manualInput', this.props.languange, titleSentence),
          active: false,
          link: '/data/transfer',
          show: true,
        },
        {
          icon: icons(iconName.bulkTransfer, process.env.REACT_APP_BRAND_NAME),
          title: translate('bulkTransfer', this.props.languange, titleSentence),
          active: true,
          link: '/data/disbursement',
          show: true,
        },
        {
          icon: icons(iconName.bulkTransferActive, process.env.REACT_APP_BRAND_NAME),
          title: translate('bulkCreateVa', this.props.languange, titleSentence),
          active: false,
          link: '/data/bulk_virtual_account',
          show: appEnableAPI && (appUserType === 'Personal END USER' ? false : true),
        },
      ]
    } else if (path === 'bulk_virtual_account') {
      title = translate('bulkCreateVa', this.props.languange, sidebarLangResource)
      cardSubHeaderPicker = [
        {
          icon: icons(iconName.apiActive, process.env.REACT_APP_BRAND_NAME),
          title: translate('api', this.props.languange, titleSentence),
          active: false,
          link: '/data/transaction',
          show: appUserType === 'Personal END USER' ? false : true,
        },
        {
          icon: icons(iconName.cursorInputActive, process.env.REACT_APP_BRAND_NAME),
          title: translate('manualInput', this.props.languange, titleSentence),
          active: false,
          link: '/data/transfer',
          show: true,
        },
        {
          icon: icons(iconName.bulkTransferActive, process.env.REACT_APP_BRAND_NAME),
          title: translate('bulkTransfer', this.props.languange, titleSentence),
          active: false,
          link: '/data/disbursement',
          show: true,
        },
        {
          icon: icons(iconName.bulkTransfer, process.env.REACT_APP_BRAND_NAME),
          title: translate('bulkCreateVa', this.props.languange, titleSentence),
          active: true,
          link: '/data/bulk_virtual_account',
          show: appEnableAPI && (appUserType === 'Personal END USER' ? false : true),
        },
      ]
    } else if (path === 'disbursement_detail') {
      title = translate('bulkTrxList', this.props.languange, headerPageResource)
    } else if (path === 'merchant_member') {
      title = translate('merchantMember', this.props.languange, headerPageResource)
    } else if (path === 'aggregator_fee_daily') {
      title = translate('Report', this.props.languange, sidebarLangResource)
      cardSubHeaderPicker = [
        {
          title: translate('aggregatorFeeDaily', this.props.languange, headerPageResource),
          active: true,
          link: '/data/aggregator_fee_daily',
          show: true,
        },
        {
          title: translate('aggregatorFeeMonthly', this.props.languange, sidebarLangResource),
          active: false,
          link: '/data/aggregator_fee_monthly',
          show: true,
        },
        {
          title: translate(
            'resumeShareProfitByMitraDaily',
            this.props.languange,
            sidebarLangResource
          ),
          active: false,
          link: '/data/resume_share_profit_by_mitra_daily',
          show: true,
        },
        {
          title: translate(
            'resumeShareProfitByMitraMonthly',
            this.props.languange,
            sidebarLangResource
          ),
          active: false,
          link: '/data/resume_share_profit_by_mitra_monthly',
          show: true,
        },
      ]
    } else if (path === 'aggregator_fee_monthly') {
      title = translate('Report', this.props.languange, sidebarLangResource)
      cardSubHeaderPicker = [
        {
          title: translate('aggregatorFeeDaily', this.props.languange, headerPageResource),
          active: false,
          link: '/data/aggregator_fee_daily',
          show: true,
        },
        {
          title: translate('aggregatorFeeMonthly', this.props.languange, sidebarLangResource),
          active: true,
          link: '/data/aggregator_fee_monthly',
          show: true,
        },
        {
          title: translate(
            'resumeShareProfitByMitraDaily',
            this.props.languange,
            sidebarLangResource
          ),
          active: false,
          link: '/data/resume_share_profit_by_mitra_daily',
          show: true,
        },
        {
          title: translate(
            'resumeShareProfitByMitraMonthly',
            this.props.languange,
            sidebarLangResource
          ),
          active: false,
          link: '/data/resume_share_profit_by_mitra_monthly',
          show: true,
        },
      ]
    } else if (path === 'resume_share_profit_by_mitra_daily') {
      title = translate('Report', this.props.languange, sidebarLangResource)
      cardSubHeaderPicker = [
        {
          title: translate('aggregatorFeeDaily', this.props.languange, headerPageResource),
          active: false,
          link: '/data/aggregator_fee_daily',
          show: true,
        },
        {
          title: translate('aggregatorFeeMonthly', this.props.languange, sidebarLangResource),
          active: false,
          link: '/data/aggregator_fee_monthly',
          show: true,
        },
        {
          title: translate(
            'resumeShareProfitByMitraDaily',
            this.props.languange,
            sidebarLangResource
          ),
          active: true,
          link: '/data/resume_share_profit_by_mitra_daily',
          show: true,
        },
        {
          title: translate(
            'resumeShareProfitByMitraMonthly',
            this.props.languange,
            sidebarLangResource
          ),
          active: false,
          link: '/data/resume_share_profit_by_mitra_monthly',
          show: true,
        },
      ]
    } else if (path === 'settlement_merchant_sent') {
      title = translate('settlement', this.props.languange, sidebarLangResource)
      cardHeaderPicker = [
        {
          icon: icons(iconName.check, process.env.REACT_APP_BRAND_NAME),
          title: translate('settlementSent', this.props.languange, sidebarLangResource),
          description: translate(
            'settlementDescriptionSent',
            this.props.languange,
            sidebarLangResource
          ),
          active: true,
          link: '/data/settlement_merchant_sent',
        },
        {
          icon: icons(iconName.carbonPending, process.env.REACT_APP_BRAND_NAME),
          title: translate('settlementProcess', this.props.languange, sidebarLangResource),
          description: translate(
            'settlementDescriptionProcess',
            this.props.languange,
            sidebarLangResource
          ),
          active: false,
          link: '/data/settlement_merchant_process',
        },
      ]
    } else if (path === 'settlement_merchant_process') {
      title = translate('settlement', this.props.languange, sidebarLangResource)
      cardHeaderPicker = [
        {
          icon: icons(iconName.check, process.env.REACT_APP_BRAND_NAME),
          title: translate('settlementSent', this.props.languange, sidebarLangResource),
          description: translate(
            'settlementDescriptionSent',
            this.props.languange,
            sidebarLangResource
          ),
          active: false,
          link: '/data/settlement_merchant_sent',
        },
        {
          icon: icons(iconName.carbonPending, process.env.REACT_APP_BRAND_NAME),
          title: translate('settlementProcess', this.props.languange, sidebarLangResource),
          description: translate(
            'settlementDescriptionProcess',
            this.props.languange,
            sidebarLangResource
          ),
          active: true,
          link: '/data/settlement_merchant_process',
        },
      ]
    } else if (path === 'monitor_hold_balance') {
      title = translate('Report', this.props.languange, sidebarLangResource)
      cardSubHeaderPicker = [
        {
          title: translate('monitorHoldBalance', this.props.languange, sidebarLangResource),
          active: true,
          link: '/data/monitor_hold_balance',
          show: appEnableAPI,
        },
        {
          title: 'Monitoring Callback',
          active: false,
          link: '/data/callback',
          show: appEnableAPI,
        },
      ]
    } else if (path === 'resume_share_profit_by_mitra_monthly') {
      title = translate('Report', this.props.languange, sidebarLangResource)
      cardSubHeaderPicker = [
        {
          title: translate('aggregatorFeeDaily', this.props.languange, headerPageResource),
          active: false,
          link: '/data/aggregator_fee_daily',
          show: true,
        },
        {
          title: translate('aggregatorFeeMonthly', this.props.languange, sidebarLangResource),
          active: false,
          link: '/data/aggregator_fee_monthly',
          show: true,
        },
        {
          title: translate(
            'resumeShareProfitByMitraDaily',
            this.props.languange,
            sidebarLangResource
          ),
          active: false,
          link: '/data/resume_share_profit_by_mitra_daily',
          show: true,
        },
        {
          title: translate(
            'resumeShareProfitByMitraMonthly',
            this.props.languange,
            sidebarLangResource
          ),
          active: true,
          link: '/data/resume_share_profit_by_mitra_monthly',
          show: true,
        },
      ]
    } else if (path === 'virtual_account_reserved') {
      title = `${translate('virtalAccount', this.props.languange, sidebarLangResource)} ${
        this.state.selectedVa === null ? '' : this.state.selectedVa.label
      }`
      isVa = true
      cardHeaderPicker = [
        {
          icon: icons(iconName.save, process.env.REACT_APP_BRAND_NAME),
          title: translate('transferAPI', this.props.languange, sidebarLangResource),
          description: translate(
            'vaDescriptionTransaction',
            this.props.languange,
            sidebarLangResource
          ),
          active: false,
          link: '/data/virtual_account_transaction',
        },
        {
          icon: icons(iconName.timeRollback, process.env.REACT_APP_BRAND_NAME),
          title: translate('vaReserved', this.props.languange, sidebarLangResource),
          description: translate(
            'vaDescriptionReserved',
            this.props.languange,
            sidebarLangResource
          ),
          active: true,
          link: '/data/virtual_account_reserved',
        },
      ]
    } else if (path === 'virtual_account_transaction') {
      title = `${translate('virtalAccount', this.props.languange, sidebarLangResource)} ${
        this.state.selectedVa === null ? '' : this.state.selectedVa.label
      }`
      isVa = true
      cardHeaderPicker = [
        {
          icon: icons(iconName.save, process.env.REACT_APP_BRAND_NAME),
          title: translate('transferAPI', this.props.languange, sidebarLangResource),
          description: translate(
            'vaDescriptionTransaction',
            this.props.languange,
            sidebarLangResource
          ),
          active: true,
          link: '/data/virtual_account_transaction',
        },
        {
          icon: icons(iconName.timeRollback, process.env.REACT_APP_BRAND_NAME),
          title: translate('vaReserved', this.props.languange, sidebarLangResource),
          description: translate(
            'vaDescriptionReserved',
            this.props.languange,
            sidebarLangResource
          ),
          active: false,
          link: '/data/virtual_account_reserved',
        },
      ]
    }
    this.props.dispatch({ type: globalConstant.HEADER_TITLE, payload: title })
    return (
      <>
        <div className="p-3">
          <Row>
            {path === 'disbursement_detail' && (
              <Col md="1" className="mb-3">
                {/* <Button as={Link} variant="outline-primary" to="/data/disbursement">
                  <FontAwesomeIcon fixedWidth icon={faArrowLeft} />
                </Button> */}
                {/* <button type="button" className="btn btn-outline-primary back-button"> */}
                <Button
                  as={Link}
                  variant="outline-primary"
                  className="back-button"
                  to="/data/disbursement"
                >
                  <Image src={icons(iconName.backButton)} className="mt-2" />
                </Button>
              </Col>
            )}
          </Row>
          {!cardHeader ? (
            <></>
          ) : (
            <div className="card-header-full mb-3">
              <div className="card-top">
                <div className="card-title">
                  <div className="image">
                    <Image
                      src={icons(iconName.saldoRed, process.env.REACT_APP_BRAND)}
                      className="d-inline-block icon-button"
                    />
                  </div>
                  <div className="title ml-4 fontSize-26">
                    {translate('accountStatement', this.props.languange, sidebarLangResource)}
                  </div>
                </div>
                {appRuleShowBalance === 1 ? (
                  <Row>
                    <Col sm="6" xs="12">
                      <div className="card-item">
                        <div className="title fontSize-18-Bold">
                          {translate('availableBalance', this.props.languange, titleSentence)}
                        </div>
                        <div className="value fontSize-26">
                          {this.props.userBalance !== 'n/a' ? this.props.userBalance : 0}
                        </div>
                      </div>
                    </Col>
                    <Col sm="6" xs="12">
                      <div className="card-item">
                        <div className="title fontSize-18-Bold">
                          {translate('holdBalance', this.props.languange, titleSentence)}
                        </div>
                        <div className="value fontSize-26">
                          {this.props.holdBalance !== 'n/a' ? this.props.holdBalance : 0}
                        </div>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}
              </div>
              <div className="card-bottom">
                <Row>
                  <Col>
                    <Link to="/transaction/single_disbursement">
                      <div className="card-item">
                        <div className="image">
                          <Image
                            src={icons(iconName.withdraw)}
                            className="d-inline-block icon-button"
                          />
                        </div>
                        <div className="title ml-3 fontSize-17 color-black">
                          {translate('withdraw', this.props.languange, titleSentence)}
                        </div>
                      </div>
                    </Link>
                  </Col>
                  {!isMartinGrup(this.props.appAgentGroup) && (
                    <Col>
                      <Link to="/topup">
                        <div className="card-item">
                          <div className="image">
                            <Image
                              src={icons(iconName.deposit)}
                              className="d-inline-block icon-button"
                            />
                          </div>
                          <div className="title ml-3 fontSize-17 color-black">
                            {translate('deposit', this.props.languange, titleSentence)}
                          </div>
                        </div>
                      </Link>
                    </Col>
                  )}
                </Row>
              </div>
            </div>
          )}
          <Row>
            {this.readAccess() &&
              cardHeaderPicker.map((item, i) => (
                <Col md="6" sm="12" className="mb-2">
                  <Link to={item.link}>
                    <div className={item.active ? 'card-picker card-picker-active' : 'card-picker'}>
                      <div className="d-inline-block container-icon">
                        <Image src={item.icon} className="d-inline-block icon-button" />
                      </div>
                      <div className="d-inline-block">
                        <div className="title fontSize-18-Bold text-active">{item.title}</div>
                        <div className="description fontSize-16-Medium color-grey">
                          {item.description}
                        </div>
                      </div>
                      <Image
                        src={
                          item.active
                            ? icons(iconName.fullArrowRightActive, process.env.REACT_APP_BRAND_NAME)
                            : icons(iconName.arrowRight)
                        }
                        className="d-inline-block icon-button"
                      />
                    </div>
                  </Link>
                </Col>
              ))}
            {cardSubHeaderPicker.map((item, i) => {
              if (item.show && this.readAccess()) {
                return (
                  <Col md="3" sm="6" xs="12" className="mb-2">
                    <Link to={item.link}>
                      <div
                        className={
                          item.active ? 'card-subpicker card-subpicker-active' : 'card-subpicker'
                        }
                      >
                        <Image src={item.icon} className="d-inline-block icon-button mr-2" />
                        <div className="d-inline-block text-center">
                          <div
                            className={
                              item.active ? 'color-white fontSize-17' : 'text-active fontSize-17'
                            }
                          >
                            {item.title}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </Col>
                )
              }

              return <></>
            })}
          </Row>
        </div>
        {!this.readAccess() && path !== 'disbursement_detail' ? (
          <AccessDenied />
        ) : (
          <>
            <Container fluid className="main-table">
              {(path === 'virtual_account_reserved' || path === 'virtual_account_transaction') &&
              this.state.selectedVa === null ? (
                ''
              ) : (
                <DataTableExtensions
                  data={data}
                  columns={columns}
                  exportHeaders
                  filter={false}
                  export={false}
                  print={false}
                >
                  <DataTable
                    keyField={key}
                    customStyles={customStyles}
                    highlightOnHover
                    fixedHeader
                    // fixedHeaderScrollHeight="80vh"
                    noDataComponent={this.noData()}
                    pagination
                    paginationServer
                    paginationTotalRows={total}
                    paginationPerPage={limit}
                    paginationRowsPerPageOptions={[10, 50, 100, 200, total > 300 ? total : 300]}
                    persistTableHead
                    progressPending={appIsLoading}
                    progressComponent={
                      <ProgressBar
                        style={{ width: '100%' }}
                        className="mt-3 mb-3 fontSize-16-Medium"
                        variant="info"
                        label="Please Wait..."
                        animated
                        now={100}
                      />
                    }
                    onChangePage={this.setPage}
                    onChangeRowsPerPage={this.setLimit}
                    onSort={this.setSorting}
                    onRowClicked={this.actionFunction}
                    sortServer
                    subHeader
                    subHeaderComponent={
                      <Row className="mb-3">
                        <div className="pl-3 mt-1">
                          {isVa &&
                            (this.state.loadListVa ? (
                              'Loading...'
                            ) : (
                              <Row>
                                <Col>
                                  {/* eslint-disable-next-line */}
                                  <label className="fontSize-17" >{translate('chooseVa', this.props.languange, titleSentence)}</label>
                                  <br />
                                  <Select
                                    options={listVA}
                                    value={this.state.selectedVa}
                                    onChange={this.handleVA}
                                    styles={customSelectStyles}
                                    className="custom-input"
                                  />
                                </Col>
                              </Row>
                            ))}
                        </div>
                        <Row className="container-filter">
                          <Col xs="12" md="8">
                            {/* eslint-disable-next-line */}
                            {filters.map((filter, i) => {
                              let titleDecision = ''
                              const filterDecision =
                                filter.filterDecision == ''
                                  ? translate('choose', this.props.languange, placeholderResource)
                                  : filter.filterOperator
                              if (typeof filterDecision == 'string') {
                                titleDecision = filterDecision
                              } else {
                                const items = filter.filterOperator.find(
                                  (item) => item.id === filter.filterDecision
                                )
                                titleDecision = items.name
                              }
                              return (
                                // eslint-disable-next-line react/no-array-index-key
                                <InputGroup className="data-form" key={i}>
                                  <DropdownButton
                                    as={InputGroup.Prepend}
                                    variant="outline-secondary"
                                    title={
                                      filter.filterTitle
                                        ? filter.filterTitle
                                        : translate(
                                            'choose',
                                            this.props.languange,
                                            placeholderResource
                                          )
                                    }
                                    onClick={this.setFilterKey}
                                    disabled={filter.filterDefault}
                                    className="filter fontSize-16-Medium"
                                  >
                                    {columns.map((col, index) => {
                                      if (col.showFilter) {
                                        return (
                                          <Dropdown.Item
                                            id={`fil-${i}`}
                                            key={col.selector}
                                            data-index={index}
                                            data-type={col.type}
                                            className="fontSize-16-Medium"
                                          >
                                            {col.name}
                                          </Dropdown.Item>
                                        )
                                      } else {
                                        return null
                                      }
                                    })}
                                  </DropdownButton>
                                  {/* eslint-disable-next-line */}
                                  <DropdownButton
                                    variant="outline-secondary"
                                    title={titleDecision}
                                    onClick={this.setFilterDecision}
                                    className="filter fontSize-16-Medium"
                                  >
                                    {filter.filterOperator.map((sort) => (
                                      <Dropdown.Item
                                        data-id={i}
                                        key={sort.id}
                                        data-value={sort.id}
                                        className="fontSize-16-Medium"
                                      >
                                        {sort.name}
                                      </Dropdown.Item>
                                    ))}
                                  </DropdownButton>
                                  {filter.filterKey == dataType.INTEGER ||
                                  filter.filterKey == dataType.STRING ||
                                  filter.filterKey == dataType.NULL ||
                                  filter.filterKey == '' ? (
                                    <FormControl
                                      placeholder={translate(
                                        'search',
                                        this.props.languange,
                                        placeholderResource
                                      )}
                                      id={`fil-${i}`}
                                      onChange={this.setFilterValue}
                                      value={filter.filterValue}
                                      className="filter fontSize-16-Medium"
                                    />
                                  ) : null}
                                  {filter.filterKey == dataType.DATE ? (
                                    <DatePicker
                                      className="mb-3 filter fontSize-16-Medium"
                                      onChange={this.setFilterValue}
                                      value={filter.filterValue}
                                      disableClock
                                      format="yyyy-MM-dd"
                                      maxDate={new Date()}
                                      clearIcon={null}
                                      calendarIcon={
                                        <FontAwesomeIcon icon="calendar-alt" color="#eb6864" />
                                      }
                                    />
                                  ) : null}
                                  {filter.filterKey == dataType.DATERANGE ? (
                                    <DateRangePicker
                                      initialSettings={(new Date(), new Date())}
                                      onEvent={this.setFilterValueDateRange}
                                    >
                                      <FormControl
                                        placeholder={translate(
                                          'search',
                                          this.props.languange,
                                          placeholderResource
                                        )}
                                        id={`fil-${i}`}
                                        value={filter.valueTemporary}
                                        className="filter fontSize-16-Medium"
                                      />
                                    </DateRangePicker>
                                  ) : null}
                                  {filter.filterKey == dataType.ENUM ? (
                                    <DropdownButton
                                      as={InputGroup.Prepend}
                                      variant="outline-secondary"
                                      title={
                                        filter.valueTemporary
                                          ? filter.valueTemporary
                                          : translate(
                                              'choose',
                                              this.props.languange,
                                              placeholderResource
                                            )
                                      }
                                      onClick={this.setFilterValueEnum}
                                      className="filter fontSize-16-Medium"
                                    >
                                      {columns[filter.filterColumnNumber].default_value
                                        ? columns[filter.filterColumnNumber].default_value.map(
                                            (col, index) => (
                                              <Dropdown.Item
                                                data-id={i}
                                                key={col.selector}
                                                data-index={col.id}
                                                data-value={col.name}
                                                className="fontSize-16-Medium"
                                              >
                                                {col.name}
                                              </Dropdown.Item>
                                            )
                                          )
                                        : null}
                                    </DropdownButton>
                                  ) : null}
                                  {i === 0 ? (
                                    <InputGroup.Append>
                                      <Button
                                        variant="outline-info"
                                        onClick={this.appendFilter}
                                        className="button-filter-radius"
                                      >
                                        <FontAwesomeIcon icon="plus" />
                                      </Button>
                                    </InputGroup.Append>
                                  ) : (
                                    <InputGroup.Append>
                                      <Button
                                        variant="outline-primary"
                                        onClick={() => this.dicreaseFilter(i)}
                                        className="button-filter-radius"
                                      >
                                        <FontAwesomeIcon icon="times" />
                                      </Button>
                                    </InputGroup.Append>
                                  )}
                                </InputGroup>
                              )
                            })}
                          </Col>
                          <Col className="filter-action">
                            <Button
                              className="filter"
                              variant="outline-info"
                              onClick={this.setFiltered}
                            >
                              <FontAwesomeIcon icon="search" />
                            </Button>
                            {path === 'transaction' ||
                            path === 'balance' ||
                            path === 'balance_detail' ||
                            path === 'transfer' ||
                            path === 'disbursement' ||
                            path === 'deposit' ||
                            path === 'virtual_account_transaction' ||
                            path === 'virtual_account_reserved' ||
                            path === 'monitor_hold_balance' ||
                            path === 'callback' ||
                            path === 'settlement_merchant_sent' ||
                            path === 'settlement_merchant_process' ||
                            path === 'dana_talangan' ? (
                              <Button
                                className="d-inline button-download"
                                onClick={this.downloadData}
                              >
                                <Image src={icons(iconName.download)} />
                                {translate('download2', this.props.languange, buttonResource)}
                              </Button>
                            ) : path != 'export_data' ? (
                              <CsvDownload
                                columns={this.state.headers}
                                datas={this.downloadData}
                                extension=".csv"
                                filename={`${title} ${this.state.dateRangeTitleDownload}`}
                                className="d-inline button-download"
                                separator=";"
                              >
                                <Image src={icons(iconName.download)} />
                                {translate('download2', this.props.languange, buttonResource)}
                              </CsvDownload>
                            ) : (
                              <></>
                            )}
                            {path === 'dana_talangan' ? (
                              <Link to="/add-item">
                                <Button
                                  className="d-inline button-download"
                                  style={{
                                    marginLeft: '5px',
                                    backgroundColor: '#FF0000',
                                    color: '#FFFF',
                                  }}
                                >
                                  {/* <Image src={icons(iconName.plus)} /> */}
                                  Tambah
                                </Button>
                              </Link>
                            ) : (
                              <></>
                            )}
                          </Col>
                        </Row>
                      </Row>
                    }
                  />
                </DataTableExtensions>
              )}
            </Container>
          </>
        )}
        <AlertDialog
          show={!!alertError}
          onHide={this.closeError}
          onConfirm={this.closeError}
          type="warning"
          messageTitle={alertError}
        />
        <AlertDialog
          show={!!alertConfirm}
          onHide={this.closeConfirm}
          onConfirm={this.doConfirm}
          type="success"
          messageTitle={alertConfirm}
        />
        <AlertDialog
          show={duce.length > 0}
          onHide={this.closeDuce}
          onConfirm={this.closeDuce}
          title="Perhatian"
        >
          {duce.map((d) => (
            <Row key={d.label}>
              <Col xs="3" className="text-left fontSize-16">
                {d.label}
              </Col>
              <Col xs="1" className="fontSize-16">
                :
              </Col>
              <Col
                xs="8"
                className={classNames(
                  'detail',
                  d.preFormatted ? 'pre text-left fontSize-16' : 'text-left fontSize-16'
                )}
              >
                {d.value || '-'}
              </Col>
            </Row>
          ))}
        </AlertDialog>
      </>
    )
  }
}

DataScreen.defaultProps = {
  appIsLoading: false,
}

DataScreen.propTypes = {
  appIsLoading: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
    appUserPass: state.Auth[authConstant.USER_PASS],
    appUserAccess: state.RoleAccess[roleAccess.ACCESS],
    appUserType: state.Auth[authConstant.USER_TYPE],
    appUserData: state.Auth[authConstant.USER_DATA],
    menuChoosen: state.Global[globalConstant.MENU_CHOOSEN],
    languange: state.Global[globalConstant.LANGUAGE],
    appEnableAPI: state.Auth[authConstant.ENABLE_API],
    appAgentGroup: state.Auth[authConstant.AGENT_GROUP],
    appAgentUser: state.Auth[authConstant.AGENT_USER],
    userBalance: state.Auth[authConstant.USER_BALANCE],
    holdBalance: state.Auth[authConstant.USER_HOLDBALANCE],
    appRuleShowBalance: state.Global[globalConstant.RULE_SHOW_BALANCE],
  }),
  (dispatch) => ({ dispatch })
)(withParams(DataScreen))
