/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/prop-types */
/* eslint-disable eqeqeq */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/react-in-jsx-scope */
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Container, Form, Image, InputGroup, ProgressBar } from 'react-bootstrap'
import DataTable from 'react-data-table-component'

import dataRequestPaymentList from '../../api/action/dataRequestPaymentList'
import requestPaymentListCol from '../component/column/request_payment_list'
import checkAccess from '../../helper/access'
import translate from '../../helper/translate'
import { buttonResource, placeholderResource } from '../../helper/translatePage/languageResource'
import './requestPayment.scss'
import icons from '../../helper/icons'
import iconName from '../../helper/constant/iconName'

// eslint-disable-next-line react/prop-types
function RequestPaymentList({
  contentChange,
  agentId,
  dispatch,
  loading,
  appUserAccess,
  languange,
}) {
  const customStyles = {
    rows: {
      style: {
        minHeight: '72px', // override the row height
        cursor: 'pointer',
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
      },
    },
  }

  // eslint-disable-next-line no-unused-vars
  const [initialData, setDataChange] = useState({
    data: [],
    filter: '',
    limit: 10,
    page: 1,
    total: 0,
  })

  const getData = async (idAgent, filter, limit, page) => {
    const response = await dispatch(dataRequestPaymentList(idAgent, filter, limit, page))

    if (response.data.data === undefined) {
      setDataChange({
        ...initialData,
        data: [],
        total: 0,
      })
    } else {
      setDataChange({
        ...initialData,
        data: response.data.data.data,
        total: response.data.data.total,
      })
    }
  }

  useEffect(() => {
    getData(agentId, initialData.filter, initialData.limit, initialData.page)
  }, [])

  const accessCreate = () => {
    return checkAccess(appUserAccess, 'request_payment', 'ADD')
  }

  const setPage = (page) => {
    setDataChange({ ...initialData, page })
    getData(agentId, initialData.filter, initialData.limit, page)
  }

  const setLimit = (limit) => {
    setDataChange({ ...initialData, limit })
    getData(agentId, initialData.filter, limit, 1)
  }

  const onChangeFilter = (e) => {
    const { value } = e.target

    setDataChange({ ...initialData, filter: value })
  }

  const search = () => {
    getData(agentId, initialData.filter, initialData.limit, initialData.page)
  }

  const detail = (row) => {
    contentChange('detail_request_payment', row)
  }

  const noData = () => {
    return <p className="mt-4">{translate('noData', languange, placeholderResource)}</p>
  }

  const handleKeyPress = (target) => {
    if (target.charCode == 13) {
      search()
    }
  }

  return (
    <>
      <Container fluid className="main-table">
        <DataTable
          data={initialData.data}
          columns={requestPaymentListCol(languange).column}
          highlightOnHover
          fixedHeader
          persistTableHead
          pagination
          paginationServer
          paginationTotalRows={initialData.total}
          paginationPerPage={initialData.limit}
          paginationRowsPerPageOptions={[10, 50, 100, 200]}
          onChangePage={setPage}
          onChangeRowsPerPage={setLimit}
          onRowClicked={detail}
          progressPending={loading}
          progressComponent={
            <ProgressBar
              style={{ width: '100%' }}
              className="mt-3 mb-3"
              variant="info"
              label="Please Wait..."
              animated
              now={100}
            />
          }
          customStyles={customStyles}
          noDataComponent={noData()}
          subHeader
          subHeaderComponent={
            <div className="container-request-payment-header-table">
              <div className="container-filter-request-payment mb-3">
                <InputGroup className="container-search-request-payment ml-4">
                  <Form.Control
                    placeholder={translate('searchs', languange, placeholderResource)}
                    name="filter"
                    value={initialData.filter}
                    onChange={onChangeFilter}
                    className="custom-input fontSize-16"
                    onKeyPress={handleKeyPress}
                  />
                  <button type="button" className="btn-search" onClick={search}>
                    <Image src={icons(iconName.search)} />
                  </button>
                </InputGroup>
              </div>
              {accessCreate() && (
                <button
                  type="button"
                  className="btn btn-custom btn-create-payment-request color-white fontSize-17"
                  onClick={() => contentChange('create_request_payment', null)}
                >
                  <Image src={icons(iconName.requestPayment)} className="mr-2" />
                  {translate('requestPayment', languange, buttonResource)}
                </button>
              )}
            </div>
          }
        />
      </Container>
    </>
  )
}

export default RequestPaymentList
