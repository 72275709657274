import React from 'react'
import ReactDOM from 'react-dom'
// eslint-disable-next-line import/no-extraneous-dependencies
import { CookiesProvider } from 'react-cookie'
import { Provider } from 'react-redux'
import store from './store'

import './bootstrap.min.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
