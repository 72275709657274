/* eslint-disable no-useless-concat */
import moment from 'moment'
import 'moment/locale/id'

moment.locale('id')

const DateTime = {
  FMT_DAY_E: 'dddd',
  FMT_DATE_D: 'DD',
  FMT_DATE_M: 'MM',
  FMT_DATE_Y: 'YYYY',
  FMT_DATE_MY_LONG: 'MMMM YYYY',
  FMT_DATE_YMD: 'YYYY-MM-DD',
  FMT_TIME_HMS: 'HH:mm:ss',
  FMT_DATE_TIME_YMDHMS_NS: 'YYYYMMDDHHmmss',
  FMT_DATE_TIME_YMDHMS: 'YYYY-MM-DD HH:mm:ss',
  FMT_DATE_TIME_YMDHMSU: 'YYYY-MM-DD HH:mm:ss.SSS',
  FMT_DATE_TIME_YMDHMS2: 'DD MMMM YYYY (HH:mm',
  FMT_DATE_TIME_YMDHMS3: 'DD MMMM YYYY (HH:mm)',
  FMT_DATE_TIME_DMY: 'DD MMMM YYYY',

  addHour(hour, format) {
    return moment()
      .add(hour, 'hour')
      .format(format || DateTime.FMT_DATE_TIME_YMDHMS)
  },

  today(format) {
    return moment().format(format)
  },

  parse(str, format) {
    const result = moment(str, format)
    if (!result.isValid()) {
      return moment()
    }

    return result
  },

  format(date, format) {
    return moment(date).format(format)
  },

  convert(str, format, newFormat) {
    return DateTime.parse(str, format).format(newFormat)
  },
}

export default DateTime
