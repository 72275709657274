/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-unused-vars */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Button } from 'react-bootstrap'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import swal from 'sweetalert'
import DateTime from '../../../helper/datetime'
import translate from '../../../helper/translate'
import { accountLangResource, columnResource } from '../../../helper/translatePage/languageResource'
// eslint-disable-next-line import/no-unresolved
import dataType from '../../../helper/constant/dataType'
import MessageSender from '../../../api/sender'

const ExportDataColumn = (actionFunction, language) => ({
  key: 'id',
  columns: [
    {
      name: 'ID',
      selector: 'id',
      sortable: true,
      type: dataType.INTEGER,
      showFilter: true,
    },
    {
      name: translate('createDate', language, columnResource),
      selector: 'tanggal_waktu_dibuat',
      sortable: true,
      cell: (row) => DateTime.format(row.tanggal_waktu_dibuat, DateTime.FMT_DATE_TIME_YMDHMS3),
      type: dataType.DATERANGE,
      default: true,
      showFilter: true,
    },
    {
      name: translate('menu', language, columnResource),
      selector: 'nama_menu',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      cell: (row) => {
        let word
        let color
        switch (row.status) {
          case 0:
            word = 'New'
            color = 'primary'
            break
          case 1:
            word = 'Queue'
            color = 'warning'
            break
          case 2:
            word = 'On Process'
            color = 'info'
            break
          case 3:
            word = 'Success'
            color = 'success'
            break
          case 4:
          default:
            word = 'Failed'
            color = 'danger'
            break
        }
        // eslint-disable-next-line react/react-in-jsx-scope
        return <Badge variant={color}>{word}</Badge>
      },
      showFilter: true,
      type: dataType.ENUM,
      default_value: [
        {
          id: '0',
          name: 'New',
        },
        {
          id: '1',
          name: 'Queue',
        },
        {
          id: '2',
          name: 'On Process',
        },
        {
          id: '3',
          name: 'Success',
        },
        {
          id: '4',
          name: 'Failed',
        },
      ],
    },
    {
      name: translate('finishDate', language, columnResource),
      selector: 'tanggal_waktu_selesai',
      sortable: true,
      cell: (row) =>
        row.tanggal_waktu_selesai
          ? DateTime.format(row.tanggal_waktu_selesai, DateTime.FMT_DATE_TIME_YMDHMS3)
          : '-',
      type: dataType.DATERANGE,
      default: true,
      showFilter: true,
    },
    {
      name: translate('action', language, columnResource),
      cell: (row) => (
        <div style={{ flexDirection: 'column' }}>
          <div>
            {row.lokasi_file !== '' && row.status === 3 && (
              <>
                <Button
                  size="sm"
                  variant="outline-success"
                  data-action="download"
                  onClick={(e) => actionFunction(row, e)}
                >
                  Unduh
                </Button>
                <CopyToClipboard
                  text={`${process.env.REACT_APP_API_URL}api/publics/download_csv?id=${
                    row.id
                  }&token=${MessageSender.checkToken()}`}
                  onCopy={() => swal('Sukses', 'URL berhasil disalin', 'success')}
                >
                  <span
                    className="badge badge-primary text-white ml-1 p-2"
                    style={{ cursor: 'pointer' }}
                  >
                    {translate('affiliationCopy', language, accountLangResource)}
                  </span>
                </CopyToClipboard>
              </>
            )}
          </div>
        </div>
      ),
      showFilter: false,
    },
  ],
})

export default ExportDataColumn
