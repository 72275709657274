/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { connect } from 'react-redux'
import { Container, ProgressBar, Alert, Form, Row, Col, Button, Table } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import { Chart, LineAdvance } from 'bizcharts'
import { Link, useParams } from 'react-router-dom'

import moment from 'moment'
import './data.scss'
import BaseScreen from '../base'
import globalConstant from '../../store/constant/global'
import authConstant from '../../store/constant/auth'
import Linkqu from '../../helper/linkqu'
import DateTime from '../../helper/datetime'
import report from '../../api/action/report'
import Formatter from '../../helper/formatter'
import Downloader from '../../helper/download'
import checkAccess from '../../helper/access'
import roleAccess from '../../store/constant/roleAccess'
import AccessDenied from '../component/AccessDenied'
import {
  columnResource,
  sidebarLangResource,
  titleSentence,
} from '../../helper/translatePage/languageResource'
import translate from '../../helper/translate'

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />
}
class ReportScreen extends BaseScreen {
  constructor(props) {
    super(props, ['page--report'])

    const { path } = props.match.params

    this.state = {
      path,
      month: DateTime.today(DateTime.FMT_DATE_M),
      year: DateTime.today(DateTime.FMT_DATE_Y),
      isOpen: false,
      chartData: [],
      balance: null,
      urlName: path,
      selectedDate: moment(new Date()).format('DD MMM YYYY'),
      scheduled: null,
      cardHeaderPicker: Linkqu.getChartMenu(
        this.props.appUserGroup,
        this.translate,
        this.accessCheck,
        this.props.appEnableAPI
      ),
    }

    const chartMenu = Linkqu.getChartMenu(props.appUserGroup, this.translate, this.accessCheck)
    this.chartObject = chartMenu[path]
    if (this.chartObject === undefined) {
      this.chartObject = {}
    }
    this.chartObject.key = path

    this.tableRef = React.createRef()
  }

  translate = (key) => {
    const { language } = this.props

    let result = ''
    if (language === 'id') {
      // eslint-disable-next-line
      result = sidebarLangResource.id[key]
    } else {
      // eslint-disable-next-line
      result = sidebarLangResource.en[key]
    }

    return result !== undefined ? result : 'Language not found'
  }

  accessCheck = (moduleName, accessName) => {
    return checkAccess(this.props.appUserAccess, moduleName, accessName)
  }

  toggleSidebar = () => {
    const { isOpen } = this.state

    this.setState({ isOpen: !isOpen })
  }

  setDate = (e, picker) => {
    this.setState({ selectedDate: picker.startDate.format('DD MMM YYYY') })
  }

  doChangeDate = async () => {
    const { dispatch } = this.props
    const { month, year } = this.state
    const { type } = this.props.match.params

    const chartData = await dispatch(
      report(this.chartObject.key, `${year}${`${month}`.padStart(2, '0')}`, type)
    )

    let chartDataQris = []
    if (type) {
      // eslint-disable-next-line
      chartData.map((data) => {
        // eslint-disable-next-line
        if (data.group === 'OTHER' || data.group === 'QRIS') {
          chartDataQris = [data, ...chartDataQris]
        }
      })

      this.setState({ chartDataQris })
    } else {
      this.setState({ chartData })
    }
  }

  doExport = async () => {
    if (this.tableRef) {
      Downloader.tableToExcel(this.tableRef, this.chartObject.key, 'Main')
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  componentDidMount() {
    super.componentDidMount()
    this.setState({ path: this.props.match.params.path })

    if (this.state.path === this.props.match.params.path) {
      this.doChangeDate()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.path !== this.props.match.params.path) {
      this.setNewChart(this.props.match.params.path)
      this.resetDataChart()
      this.componentDidMount()
    }
  }

  resetDataChart = () => {
    this.setState({ chartData: [] })
  }

  setNewChart = (newPath) => {
    const chartMenu = Linkqu.getChartMenu(this.props.appUserGroup, this.translate, this.accessCheck)
    this.chartObject = chartMenu[newPath]

    if (this.chartObject === undefined) {
      this.chartObject = {}
    }
    this.chartObject.key = newPath
  }

  readAccess = () => {
    if (this.state.path === 'daily_callback' && !this.props.appEnableAPI) {
      return false
    }
    return checkAccess(this.props.appUserAccess, this.state.urlName, 'READ')
  }

  renderGraphic() {
    const { chartData, path } = this.state
    return (
      <Container fluid className="container-report">
        <Row>
          <Col md={8}>
            <div className="fontSize-21-Semibold">{this.translate(path)}</div>
            <Chart
              className="mb-5"
              padding={[10, 20, 50, 40]}
              autoFit
              height={440}
              data={chartData}
            >
              <LineAdvance shape="smooth" point area position="date*amount" color="group" />
            </Chart>
          </Col>
          <Col md={4}>
            <Table
              hover
              ref={(ref) => {
                this.tableRef = ref
              }}
              className="table-custom-report"
            >
              <thead>
                <tr>
                  <th className="fontSize-16-SemiBold text-center">
                    {translate('dateTrx', this.props.language, columnResource)}
                  </th>
                  <th className="fontSize-16-SemiBold text-center">
                    {translate('grupAgen', this.props.language, columnResource)}
                  </th>
                  <th className="fontSize-16-SemiBold text-center">
                    {translate('amount', this.props.language, titleSentence)}
                  </th>
                </tr>
              </thead>
              <tbody>
                {chartData.map((row) => (
                  <tr>
                    <td className="text-center fontSize-16-SemiBold color-grey">{row.date}</td>
                    <td className="text-center fontSize-16-SemiBold color-grey">{row.group}</td>
                    <td className="text-right fontSize-16-SemiBold color-grey">
                      {Formatter.price(row.fullamount || row.amount)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    )
  }

  setComponent = (item) => {
    const { cardHeaderPicker } = this.state
    let datasTemp = []
    // eslint-disable-next-line prefer-const
    let datas = []
    for (const data of cardHeaderPicker) {
      datasTemp = data
      if (datasTemp.key === item.key) {
        datasTemp.showOnMenu = true
        // activeComponent = item.component
      } else {
        datasTemp.showOnMenu = false
      }
      datas.push(datasTemp)
    }

    this.setState({ cardHeaderPicker: datas })
  }

  render() {
    const { appIsLoading } = this.props
    const { cardHeaderPicker, chartData, month, year } = this.state
    const years = []
    // eslint-disable-next-line radix
    const currentYear = parseInt(DateTime.today(DateTime.FMT_DATE_Y))
    for (let y = currentYear - 5; y <= currentYear; y += 1) {
      years.push(y.toString())
    }
    return (
      <>
        <Container fluid className="p-3">
          {!this.readAccess() ? (
            <AccessDenied />
          ) : (
            <>
              <Form.Row>
                <Col xs="12" sm="6">
                  <span />
                </Col>
                <Form.Group as={Col} xs="12" sm="2">
                  <Form.Control
                    as="select"
                    custom
                    name="month"
                    // eslint-disable-next-line radix
                    value={parseInt(month)}
                    onChange={this.handleChange}
                    className="select-monthpicker"
                  >
                    {[
                      'January',
                      'February',
                      'March',
                      'April',
                      'May',
                      'June',
                      'July',
                      'August',
                      'September',
                      'October',
                      'November',
                      'December',
                    ].map((m, i) => (
                      <option value={i + 1}>{m}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} xs="12" sm="2">
                  <Form.Control
                    as="select"
                    custom
                    name="year"
                    value={year}
                    onChange={this.handleChange}
                    className="select-monthpicker"
                  >
                    {years.map((y) => (
                      <option value={y}>{y}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} xs="6" sm="1" className="d-flex">
                  <Button
                    variant="primary"
                    block
                    onClick={this.doChangeDate}
                    className="btn-custom clear-margin btn-monthpicker-report"
                    disabled={appIsLoading}
                  >
                    <FontAwesomeIcon icon="search" />
                  </Button>
                </Form.Group>
                <Form.Group as={Col} xs="6" sm="1" className="d-flex">
                  <Button
                    variant="primary"
                    className="btn-custom clear-margin btn-monthpicker-report"
                    onClick={this.doExport}
                    disabled={appIsLoading}
                  >
                    <FontAwesomeIcon icon="download" />
                  </Button>
                </Form.Group>
              </Form.Row>
              <Row>
                {cardHeaderPicker &&
                  cardHeaderPicker.map((item) => {
                    if (item.hide) {
                      return (
                        <Col md={3} sm="4" xs="12" className="mb-2">
                          <Link to={item.path} onClick={() => this.setComponent(item)}>
                            <div
                              className={
                                item.showOnMenu
                                  ? 'card-subpicker card-subpicker-active'
                                  : 'card-subpicker'
                              }
                            >
                              <div className="d-inline-block text-center">
                                <div
                                  className={
                                    item.showOnMenu
                                      ? 'color-white fontSize-17'
                                      : 'text-active fontSize-17'
                                  }
                                >
                                  {translate(item.title, this.props.language, sidebarLangResource)}
                                </div>
                              </div>
                            </div>
                          </Link>
                        </Col>
                      )
                    }

                    return <></>
                  })}
              </Row>
              {appIsLoading && (
                <ProgressBar
                  className="mt-3"
                  animated
                  variant="info"
                  now={100}
                  label="mohon tunggu..."
                />
              )}
              {!appIsLoading && chartData.length === 0 && (
                <Alert variant="warning">
                  {translate('dataNotAvailable', this.props.language, titleSentence)}
                </Alert>
              )}
              {!appIsLoading && chartData.length > 0 && this.renderGraphic()}
            </>
          )}
        </Container>
      </>
    )
  }
}

ReportScreen.defaultProps = {
  appIsLoading: false,
  appUserId: '',
  appUserName: '',
  appAgentName: '',
  appUserGroup: '',
}

ReportScreen.propTypes = {
  appIsLoading: PropTypes.bool,
  appUserId: PropTypes.string,
  appUserName: PropTypes.string,
  appAgentName: PropTypes.string,
  appUserGroup: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
    appUserId: state.Auth[authConstant.USER_ID],
    appUserName: state.Auth[authConstant.USER_NAME],
    appUserGroup: state.Auth[authConstant.USER_GROUP],
    appAgentName: state.Auth[authConstant.AGENT_NAME],
    appUserAccess: state.RoleAccess[roleAccess.ACCESS],
    language: state.Global[globalConstant.LANGUAGE],
    menuChoosen: state.Global[globalConstant.MENU_CHOOSEN],
  }),
  (dispatch) => ({ dispatch })
)(withParams(ReportScreen))
