/* eslint-disable react/react-in-jsx-scope */
import iconName from '../../helper/constant/iconName'
import icons from '../../helper/icons'

const AccessDenied = () => {
  return (
    <>
      <div className="content-wrapper d-flex flex-column justify-content-center align-items-center container-access-denied mt-5">
        <img
          alt="Access Denied"
          src={icons(iconName.accessDenied, process.env.REACT_APP_BRAND_NAME)}
          width="50%"
          height="50%"
        />
        <h3>You Not Allowed to Access This Page</h3>
      </div>
    </>
  )
}

export default AccessDenied
