/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable prettier/prettier */
/* eslint-disable no-undef-init */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-else-return */
/* eslint-disable eqeqeq */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Form, Image, Spinner } from "react-bootstrap";
import swal from "sweetalert";

import authConstant from "../../store/constant/auth";
import roleAccess from "../../store/constant/roleAccess";
import BaseScreen from "../base";
import getModuleAccess from "../../helper/accessGroup";
import getDataModulAccess from "../../api/action/getDataModulAccess";
import AlertDialog from "../dialog/alert";
import notAllowAccess from "../../api/action/notAllowAccess";
import roleAccessUser from "../../api/action/roleAccessUser";
import global from "../../store/constant/global";
import translateLang from "../../helper/translate";
import { buttonResource, sidebarLangResource } from "../../helper/translatePage/languageResource";
import icons from "../../helper/icons";
import iconName from "../../helper/constant/iconName";
import getCsrf from "../../api/action/getCsrf";
import logout from "../../api/action/logout";

class EditUserAccess extends BaseScreen {
  constructor(props) {
    super(props, ["page--account"]);
    this.state = {
      loading: false,
      modulAccess: [],
      alertError: "",
      defaultCheck: true,
      notAllowAccess: [],
      addedNotAllowAccess: [],
      deletedNotAllowAccess: [],
      saveLoad: false,
    };
  }

  getModulAccess = async () => {
    this.setState({ loading: true });
    const { dispatch, appUserGroup } = this.props;
    const res = await dispatch(getDataModulAccess(appUserGroup));

    if (res.data.rc != "00") {
      this.setState({ loading: false });
      this.setState({ alertError: res.data.rd });
    } else {
      this.setState({ loading: false });
      this.setState({ modulAccess: res.data.data });
    }
  };

  getModulAccessUser = async () => {
    this.setState({ loading: true });
    const { dispatch, appIdUser } = this.props;
    const res = await dispatch(notAllowAccess(appIdUser));

    if (res.data.rc != "00") {
      this.setState({ loading: false });
      this.setState({ alertError: res.data.rd });
    } else {
      this.setState({ loading: false });
      this.setState({ notAllowAccess: res.data.data });
    }
  };

  componentDidMount() {
    this.getModulAccessUser();
    this.getModulAccess();
  }

  closeError = () => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ alertError: "" });
  };

  translate = (key) => {
    const { language } = this.props;

    let result = "";
    if (language === "id") {
      // eslint-disable-next-line
      result = sidebarLangResource.id[key];
    } else {
      // eslint-disable-next-line
      result = sidebarLangResource.en[key];
    }

    return result !== undefined ? result : "Language not found";
  };

  notAllowedAccess = (idModul, access) => {
    const checkAccess = this.state.notAllowAccess.filter(
      (acc) =>
        acc.id_modul == idModul && acc.hak_akses_dilarang.includes(access)
    );

    if (checkAccess.length < 1) {
      return true;
    } else {
      return false;
    }
  };

  onSubmit = async () => {
    const { dispatch, appIdUser, appUserData } = this.props;

    this.setState({ saveLoad: true });
    let res = await dispatch(getCsrf())
    if (res.isOK()) {
      res = await dispatch(
        roleAccessUser(
          appIdUser,
          appUserData.id,
          this.state.addedNotAllowAccess,
          this.state.deletedNotAllowAccess
        )
      );

      if (res.data.rc == "00") {
        this.setState({ saveLoad: false });
        swal("Success", res.data.rd, "success").then(() =>
          this.backHandle()
        );
      } else {
        this.setState({ saveLoad: false });
        this.setState({ alertError: res.data.rd });
      }
    } else {
      this.setState({ saveLoad: false });
      dispatch(logout())
    }
  };

  checkboxHandler = (evt, idModul) => {
    if (!evt.target.checked) {
      const checkModul = this.state.addedNotAllowAccess.find(
        (access) => access.idModul == idModul
      );
      if (checkModul) {
        const filterModul = this.state.addedNotAllowAccess.filter(
          (access) => access.idModul == idModul
        );

        let checkAccessModul;
        if (filterModul[0].access === undefined) {
          checkAccessModul = undefined;
        } else {
          checkAccessModul = filterModul[0].access.find(
            (acc) => acc[evt.target.name] == evt.target.value
          );
        }

        if (checkAccessModul) {
          const filterAccessModul = filterModul[0].access.filter(
            (access) => access[evt.target.name] != evt.target.value
          );

          const newModul = this.state.addedNotAllowAccess.map((modul) => {
            if (modul.idModul == idModul) {
              return {
                ...modul,
                access: filterAccessModul,
              };
            } else {
              return {
                ...modul,
              };
            }
          });

          this.setState({ addedNotAllowAccess: newModul });
        } else {
          const newModul = this.state.addedNotAllowAccess.map((modul) => {
            if (modul.idModul == idModul) {
              return {
                ...modul,
                access: [
                  ...modul.access,
                  { [evt.target.name]: evt.target.value },
                ],
              };
            } else {
              return {
                ...modul,
              };
            }
          });
          this.setState({ addedNotAllowAccess: newModul });
        }
      } else {
        const newModul = {
          idModul: idModul,
          access: [
            {
              [evt.target.name]: evt.target.value,
            },
          ],
        };

        this.state.addedNotAllowAccess.push(newModul);
      }
    } else {
      const checkModul = this.state.deletedNotAllowAccess.find(
        (access) => access.idModul == idModul
      );
      if (checkModul) {
        const filterModul = this.state.deletedNotAllowAccess.filter(
          (access) => access.idModul == idModul
        );

        let checkAccessModul = undefined;
        if (filterModul[0].access === undefined) {
          checkAccessModul = undefined;
        } else {
          checkAccessModul = filterModul[0].access.find(
            (acc) => acc[evt.target.name] == evt.target.value
          );
        }

        if (checkAccessModul) {
          const filterAccessModul = filterModul[0].access.filter(
            (access) => access[evt.target.name] != evt.target.value
          );
          const newModul = this.state.deletedNotAllowAccess.map((modul) => {
            if (modul.idModul == idModul) {
              return {
                ...modul,
                access: filterAccessModul,
              };
            } else {
              return {
                ...modul,
              };
            }
          });
          this.setState({ deletedNotAllowAccess: newModul });
        } else {
          const newModul = this.state.deletedNotAllowAccess.map((modul) => {
            if (modul.idModul == idModul) {
              return {
                ...modul,
                access: [
                  ...modul.access,
                  { [evt.target.name]: evt.target.value },
                ],
              };
            } else {
              return {
                ...modul,
              };
            }
          });
          this.setState({ deletedNotAllowAccess: newModul });
        }
      } else {
        const newModul = {
          idModul: idModul,
          access: [
            {
              [evt.target.name]: evt.target.value,
            },
          ],
        };

        this.state.deletedNotAllowAccess.push(newModul);
      }
    }
  };

  modulAccessCheckbox = (moduls, selectedModulId) => {
    if (moduls instanceof Array) {
      const selectedModul = moduls.filter(
        (modul) => modul.id_modul == selectedModulId
      );

      return selectedModul.map((modul) => {
        return (
          <>
            {getModuleAccess(modul?.hak_akses).map((access, key) => {
              return (
                <Form.Check
                  inline
                  name={`access_${key}`}
                  label={access}
                  value={access}
                  type="checkbox"
                  defaultChecked={this.notAllowedAccess(modul.id_modul, access)}
                  onChange={(e) => this.checkboxHandler(e, modul.id_modul)}
                />
              );
            })}
          </>
        );
      });
    }

    return <></>;
  };

  backHandle = () => {
    this.setState({
      loading: false,
      modulAccess: [],
      alertError: "",
      defaultCheck: true,
      notAllowAccess: [],
      addedNotAllowAccess: [],
      deletedNotAllowAccess: [],
      saveLoad: false,
    })
    this.props.backPage("account-management")
  }

  render() {
    return (
      <>
        <AlertDialog
          show={!!this.state.alertError}
          onHide={this.closeError}
          onConfirm={this.closeError}
          title="Information"
          type="warning"
          messageTitle={this.state.alertError}
        />
        {this.state.loading ? (
          <Spinner
            size="xs"
            className="mr-1 mt-4 text-active"
            animation="border"
          />
        ) : (
          <>
            <div>
              <button
                type="button"
                className="btn btn-outline-primary back-button"
                onClick={this.backHandle}
              >
                <Image src={icons(iconName.backButton)} className="" />
              </button>
              &nbsp; {translateLang('access', this.props.language, buttonResource)}
            </div>
            <div className="w-100 my-4 d-flex flex-column container-management">
              <div className="ml-5 mb-3">
                <div className="d-flex align-items-center mb-3">
                  <span className="fontSize-16-SemiBold d-inline-block text-active" style={{ width: 150, marginRight: 15 }}>
                    <strong>{this.translate("home")}</strong>
                  </span>
                  <div>
                    {this.modulAccessCheckbox(this.state.modulAccess, 5)}
                  </div>
                </div>
                <div className="d-flex flex-column mb-3">
                  <div className="d-flex align-items-center">
                    <span style={{ width: 250, marginRight: 15 }}>
                      <strong>{this.translate("accountStatement")}</strong>
                    </span>
                    <div>
                      {this.modulAccessCheckbox(this.state.modulAccess, 7)}
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <span style={{ width: 250, marginRight: 15 }}>
                    <strong>{this.translate('transferAPI')}</strong>
                  </span>
                  <div>
                    {this.modulAccessCheckbox(this.state.modulAccess, 8)}
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <span style={{ width: 250, marginRight: 15 }}>
                    <strong>{this.translate('bulkTransferData')}</strong>
                  </span>
                  <div>
                    {this.modulAccessCheckbox(this.state.modulAccess, 10)}
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <span style={{ width: 250, marginRight: 15 }}>
                    <strong>{this.translate('virtalAccount')}</strong>
                  </span>
                  <div>
                    {this.modulAccessCheckbox(this.state.modulAccess, 9)}
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <span style={{ width: 250, marginRight: 15 }}>
                    <strong>{this.translate('requestPayment')}</strong>
                  </span>
                  <div>
                    {this.modulAccessCheckbox(this.state.modulAccess, 15)}
                  </div>
                </div>
                <hr />
                <div className="d-flex flex-column mb-3">
                  <span style={{ width: 250, marginRight: 15 }} className="fontSize-16-SemiBold d-inline-block text-active">
                    <strong>{this.translate("Report")}</strong>
                  </span>
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center">
                      <span style={{ width: 235, marginRight: 15 }}>
                        <strong>{this.translate('dailySuccessTransaction')}</strong>
                      </span>
                      <div>
                        {this.modulAccessCheckbox(this.state.modulAccess, 17)}
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <span style={{ width: 235, marginRight: 15 }}>
                        <strong>{this.translate('dailyOmzet')}</strong>
                      </span>
                      <div>
                        {this.modulAccessCheckbox(this.state.modulAccess, 18)}
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <span style={{ width: 235, marginRight: 15 }}>
                        <strong>{this.translate('dailyCallback')}</strong>
                      </span>
                      <div>
                        {this.modulAccessCheckbox(this.state.modulAccess, 19)}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="d-flex flex-column mb-3">
                  <span style={{ width: 235, marginRight: 15 }} className="fontSize-16-SemiBold d-inline-block text-active">
                    <strong>{this.translate("account")}</strong>
                  </span>
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center">
                      <span style={{ width: 235, marginRight: 15 }}>
                        <strong>{this.translate('myProfile')}</strong>
                      </span>
                      <div>
                        {this.modulAccessCheckbox(this.state.modulAccess, 20)}
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <span style={{ width: 235, marginRight: 15 }}>
                        <strong>{this.translate('changePassword')}</strong>
                      </span>
                      <div>
                        {this.modulAccessCheckbox(this.state.modulAccess, 21)}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="d-flex flex-column mb-3 ">
                  <span style={{ width: 235, marginRight: 15 }} className="fontSize-16-SemiBold d-inline-block text-active">
                    <strong>{this.translate("systemSetting")}</strong>
                  </span>
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center">
                      <span style={{ width: 235, marginRight: 15 }}>
                        <strong>{this.translate('callback')}</strong>
                      </span>
                      <div>
                        {this.modulAccessCheckbox(this.state.modulAccess, 24)}
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <span style={{ width: 235, marginRight: 15 }}>
                        <strong>{this.translate('credential')}</strong>
                      </span>
                      <div>
                        {this.modulAccessCheckbox(this.state.modulAccess, 25)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="button"
                className={this.state.loading ? 'btn btn-primary disabled button-custom-submit' : 'btn btn-primary button-custom-submit'}
                onClick={this.onSubmit}
              >
                {translateLang('save', this.props.language, buttonResource)}
              </button>
            </div>
          </>
        )}
      </>
    );
  }
}

EditUserAccess.defaultProps = {
  appUserGroup: "",
  appUserData: "",
  appIdUser: "",
  modulAccess: [],
};

EditUserAccess.propTypes = {
  appUserGroup: PropTypes.string,
  appUserData: PropTypes.string,
  modulAccess: PropTypes.array,
  appIdUser: PropTypes.string,
  backPage: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    appUserData: state.Auth[authConstant.USER_DATA],
    appUserAccess: state.RoleAccess[roleAccess.ACCESS],
    language: state.Global[global.LANGUAGE],
  }),
  (dispatch) => ({ dispatch })
)(EditUserAccess);
