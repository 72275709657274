import globalConstant from '../constant/global'

const initialState = {
  [globalConstant.IS_LOADING]: false,
  [globalConstant.IS_LOGIN]: false,
  [globalConstant.SIDEBAR_OPEN]: false,
  [globalConstant.LANGUAGE]: 'id',
  [globalConstant.ENVIRONMENT]: 'production',
  [globalConstant.MENU_CHOOSEN]: 'dashboard',
  [globalConstant.HEADER_TITLE]: '',
  [globalConstant.ANTI_CSRF_TOKEN]: '',
  [globalConstant.RULE_SHOW_BALANCE]: 1,
  [globalConstant.IS_TEST_USER]: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case globalConstant.IS_LOADING:
      return {
        ...state,
        [globalConstant.IS_LOADING]: action.payload,
      }
    case globalConstant.IS_LOGIN:
      return {
        ...state,
        [globalConstant.IS_LOGIN]: action.payload,
      }
    case globalConstant.SIDEBAR_OPEN:
      return {
        ...state,
        [globalConstant.SIDEBAR_OPEN]: action.payload,
      }
    case globalConstant.LANGUAGE:
      return {
        ...state,
        [globalConstant.LANGUAGE]: action.payload,
      }
    case globalConstant.ENVIRONMENT:
      return {
        ...state,
        [globalConstant.ENVIRONMENT]: action.payload,
      }
    case globalConstant.MENU_CHOOSEN:
      return {
        ...state,
        [globalConstant.MENU_CHOOSEN]: action.payload,
      }
    case globalConstant.HEADER_TITLE:
      return {
        ...state,
        [globalConstant.HEADER_TITLE]: action.payload,
      }
    case globalConstant.ANTI_CSRF_TOKEN:
      return {
        ...state,
        [globalConstant.ANTI_CSRF_TOKEN]: action.payload,
      }
    case globalConstant.RULE_SHOW_BALANCE:
      return {
        ...state,
        [globalConstant.RULE_SHOW_BALANCE]: action.payload,
      }
    case globalConstant.GLOBAL:
      return {
        ...state,
        ...action.payload,
      }
    case globalConstant.IS_TEST_USER:
      return { ...state, [globalConstant.IS_TEST_USER]: action.payload }
    default:
      return state
  }
}
