/* eslint-disable no-unused-vars */
import React from 'react'
import { Badge, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import translate from '../../../helper/translate'
import { columnResource, titleSentence } from '../../../helper/translatePage/languageResource'
import dataType from '../../../helper/constant/dataType'

const settingAccountFavourite = (actionFunction, languange) => ({
  key: 'id',
  columns: [
    {
      name: 'ID',
      selector: 'id_accounts',
      sortable: true,
      type: dataType.INTEGER,
      showFilter: true,
    },
    {
      name: translate('accountName', languange, columnResource),
      selector: 'accountname',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('accountNumber', languange, titleSentence),
      selector: 'accountnumber',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('bankName', languange, columnResource),
      selector: 'namabank',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: 'Status',
      selector: 'is_active',
      sortable: true,
      cell: (row) => {
        let word
        let color
        if (row.is_active) {
          word = translate('active', languange, columnResource)
          color = 'success'
        } else {
          word = translate('notActive', languange, columnResource)
          color = 'danger'
        }
        return <Badge variant={color}>{word}</Badge>
      },
      type: dataType.ENUM,
      showFilter: true,
      default_value: [
        {
          id: false,
          name: translate('notActive', languange, columnResource),
        },
        {
          id: true,
          name: translate('active', languange, columnResource),
        },
      ],
    },
    {
      name: translate('action', languange, columnResource),
      cell: (row) => {
        return (
          <div style={{ flexDirection: 'column' }}>
            <div>
              <Button
                size="sm"
                variant="outline-info"
                className="mr-1"
                data-action="edit-status"
                onClick={(e) => actionFunction(row, e)}
              >
                <FontAwesomeIcon fixedWidth icon={faPencilAlt} />
              </Button>
            </div>
          </div>
        )
      },
      showFilter: false,
    },
  ],
})

export default settingAccountFavourite
