import React from 'react'

const addBodyClass = (className) => document.body.classList.add(className)
const removeBodyClass = (className) => document.body.classList.remove(className)

export default class BaseScreen extends React.Component {
  constructor(props, className) {
    super(props)

    this.className = className
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      alertError: '',
      // eslint-disable-next-line react/no-unused-state
      alertConfirm: '',
    }
  }

  async componentDidMount() {
    if (this.className instanceof Array) {
      this.className.map(addBodyClass)
    } else if (this.className) {
      addBodyClass(this.className)
    }
    const language = localStorage.getItem('language')
    // eslint-disable-next-line react/no-unused-state
    this.setState({ language: language })
  }

  async componentWillUnmount() {
    if (this.className instanceof Array) {
      this.className.map(removeBodyClass)
    } else if (this.className) {
      removeBodyClass(this.className)
    }
  }

  handleLang = (language) => {
    // eslint-disable-next-line
    this.setState({ language })
  }

  showError = (alertError) => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ alertError })
  }

  closeError = () => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ alertError: '' })
  }

  showConfirm = (alertConfirm) => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ alertConfirm })
  }

  closeConfirm = () => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ alertConfirm: '' })
  }

  render() {
    return (
      <div>
        <h1>TEST MODIF</h1>
      </div>
    )
  }
}
