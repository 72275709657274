/* eslint-disable no-console */
import Var from './var'

const Logger = {
  IS_LOGGABLE: Var.toBoolean(process.env.REACT_APP_LOG_ENABLE),
  IS_DEEP_TRACE:
    Var.toBoolean(process.env.REACT_APP_LOG_ENABLE) &&
    Var.toBoolean(process.env.REACT_APP_LOG_DEEP),

  log: (t, any) => {
    let tag = t
    if (!tag) {
      tag = 'Yarsis Mart'
    }
    if (Logger.IS_LOGGABLE) {
      if (typeof any === 'object') {
        console.log(`${tag} => ${JSON.stringify(any)}`)
      } else if (any !== undefined) {
        console.log(`${tag} => ${any}`)
      } else {
        console.log(tag)
      }
    }
  },

  warn: (t, any) => {
    let tag = t
    if (!tag) {
      tag = 'Yarsis Mart'
    }
    if (Logger.IS_LOGGABLE) {
      const parsedError = Logger.parse(any)

      Logger.log(tag, parsedError.message)
      console.warn(parsedError.stack)
    }
  },

  error: (tag, any) => {
    if (Logger.IS_LOGGABLE) {
      const parsedError = Logger.parse(any)

      Logger.log(tag, parsedError.message)
      console.error(parsedError.stack)
    }
  },

  parse: (error) => {
    const parsedError = {
      message: 'n/a',
      status: 'n/a',
      stack: 'n/a',
    }

    if (typeof error === 'string') {
      parsedError.message = error
      parsedError.stack = error
    } else if (error) {
      // get best available error message
      if (error.message) {
        parsedError.message = error.message
      }
      // include HTTP status code
      if (error.status) {
        parsedError.status = error.status
      }
      // include stack trace
      if (error.stack) {
        parsedError.stack = error.stack
      }
    }

    return parsedError
  },
}

export default Logger
