/* eslint-disable camelcase */
import MessageSender from '../sender'
import BaseMessage from '../message'
import global from '../../store/constant/global'

export const sandbox = (password) => async (dispatch) => {
  const msg = new BaseMessage()

  msg.setPath('/control/change_environment_sandbox')
  msg.setItem('password', password)

  const response = await new MessageSender().doPost(msg)

  if (response.isOK()) {
    await dispatch({
      type: global.ENVIRONMENT,
      payload: 'sandbox',
    })
  } else {
    await dispatch({
      type: global.ENVIRONMENT,
      payload: 'production',
    })
  }

  return response
}

export const production = () => async (dispatch) => {
  const msg = new BaseMessage()

  msg.setPath('/control/change_environment_production')

  const response = await new MessageSender().doPost(msg)

  await dispatch({
    type: global.ENVIRONMENT,
    payload: 'production',
  })

  return response
}
