/* eslint-disable camelcase */
// eslint-disable-next-line import/no-extraneous-dependencies
// import { useCookies } from 'react-cookie'
import MessageSender from '../sender'
import BaseMessage from '../message'
import authConstant from '../../store/constant/auth'
import global from '../../store/constant/global'
import roleAccess from '../../store/constant/roleAccess'

export default (username, password, captcha, captchaRef) => async (dispatch) => {
  const msg = new BaseMessage()
  msg.setPath('auth/login')
  msg.setItem('username', username)
  msg.setItem('password', password)
  msg.setItem('captcha', captcha)

  const response = await new MessageSender().doPost(msg)
  if (response.isOK()) {
    if (process.env.REACT_APP_RECAPTCHA_TES !== captcha) {
      captchaRef.reset()
    }
    const {
      access_token: token,
      user,
      agent,
      user_type: type,
      // eslint-disable-next-line
      status_reg,
      role_data,
      access_data,
      is_user_parent,
      is_approved_kyc,
      rule_show_balance,
    } = response.getResultAsObject()
    let expired = new Date()
    expired = expired.setDate(expired.getDate() + 1)
    expired = new Date(expired)
    if (process.env.REACT_APP_ENV === 'development') {
      document.cookie = `token=${token}; Expires=${expired}; path=/; SameSite=Lax; Secure;`
    } else {
      document.cookie = `__Host-token=${token}; Expires=${expired}; path=/; SameSite=Lax; Secure;`
    }
    await dispatch({
      type: authConstant.USER,
      payload: {
        [authConstant.USER_ID]: user.username,
        [authConstant.USER_NAME]: user.nama,
        [authConstant.USER_EMAIL]: agent.email,
        [authConstant.USER_BALANCE]: agent.balance,
        [authConstant.USER_HOLDBALANCE]: agent.hold_balance,
        [authConstant.USER_PASS]: token,
        [authConstant.USER_GROUP]: user.sys_grup_user_id,
        [authConstant.AGENT_ID]: agent.id_agen,
        [authConstant.AGENT_NAME]: agent.nama_agen,
        [authConstant.AGENT_USER]: agent.username,
        [authConstant.AGENT_GROUP]: agent.id_grup,
        [authConstant.ENABLE_API]: agent.enable_api,
        [authConstant.USER_DATA]: user,
        [authConstant.USER_TYPE]: type,
        [authConstant.STATUS_REG]: status_reg,
        [authConstant.USER_PARENT]: is_user_parent,
        [authConstant.STATUS_KYC]: is_approved_kyc,
      },
    })
    await dispatch({
      type: roleAccess.SET_ROLE_ACCESS,
      payload: {
        [roleAccess.ROLE]: role_data,
        [roleAccess.ACCESS]: access_data,
      },
    })
    await dispatch({
      type: global.GLOBAL,
      payload: {
        [global.IS_LOGIN]: role_data,
        [global.RULE_SHOW_BALANCE]: rule_show_balance,
      },
    })
  }

  return response
}
