import { combineReducers } from 'redux'
import globalReducer from './reducer/global'
import authReducer from './reducer/auth'
import roleAccessReducer from './reducer/roleAccess'

const reducer = combineReducers({
  Global: globalReducer,
  Auth: authReducer,
  RoleAccess: roleAccessReducer,
})

export default reducer
