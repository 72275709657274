import MessageSender from '../sender'
import BaseMessage from '../message'

// eslint-disable-next-line no-unused-vars
export default (type) => async () => {
  const msg = new BaseMessage()
  msg.setPath('data/list_va')
  msg.setItem('type', type)

  const response = await new MessageSender().doGet(msg)

  if (response.isOK()) {
    return response.getResultAsObject()
  }

  return undefined
}
