/* eslint-disable no-unused-vars */
import React from 'react'
import { Badge, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dataType from '../../../helper/constant/dataType'
import DateTime from '../../../helper/datetime'

const CallbackColumn = (actionFunction, language) => ({
  key: 'id_callback',
  columns: [
    {
      name: 'Callback ID',
      selector: 'id_callback',
      sortable: false,
      type: dataType.INTEGER,
      showFilter: true,
    },
    {
      name: 'ID Trx',
      selector: 'id_transaksi',
      sortable: false,
      type: dataType.INTEGER,
      showFilter: true,
    },
    {
      name: 'Date Created',
      selector: 'date_created',
      sortable: false,
      cell: (row) => row.date_created,
      type: dataType.DATERANGE,
      default: true,
      showFilter: true,
    },
    {
      name: 'Date Sent',
      selector: 'date_sent',
      sortable: false,
      cell: (row) => row.date_sent,
      type: dataType.DATERANGE,
      showFilter: true,
    },
    {
      name: 'Status',
      selector: 'status_sent',
      sortable: false,
      cell: (row) => {
        let word
        let color
        switch (`${row.status_sent}`) {
          case '0':
            word = 'New'
            color = 'warning'
            break
          case '1':
            word = 'Sent'
            color = 'success'
            break
          case '2':
            word = 'Failed'
            color = 'Danger'
            break
          case '3':
            word = 'Process'
            color = 'info'
            break
          default:
            word = 'Other'
            color = 'secondary'
            break
        }
        return <Badge variant={color}>{word}</Badge>
      },
      showFilter: true,
      type: dataType.ENUM,
      default_value: [
        {
          id: '1',
          name: 'Success',
        },
        {
          id: '0',
          name: 'New',
        },
        {
          id: '2',
          name: 'Failed',
        },
        {
          id: '3',
          name: 'Process',
        },
      ],
    },
    {
      name: 'Callback Type',
      selector: 'type_callback',
      sortable: false,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: 'URL',
      selector: 'url_callback',
      sortable: false,
    },
    {
      name: 'Request',
      selector: 'request_raw',
      sortable: false,
      omit: true,
      preFormatted: true,
    },
    {
      name: 'Response',
      selector: 'response_raw',
      sortable: false,
      omit: true,
      preFormatted: true,
    },
    {
      name: 'Action',
      cell: (row) => {
        return (
          <div style={{ flexDirection: 'column' }}>
            <Button
              size="sm"
              variant="outline-danger"
              data-action="retry"
              onClick={(e) => actionFunction(row, e)}
            >
              <FontAwesomeIcon fixedWidth icon="redo" />
            </Button>
          </div>
        )
      },
    },
  ],
})

export default CallbackColumn
