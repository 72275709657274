import MessageSender from '../sender'
import BaseMessage from '../message'

export default () => async () => {
  const msg = new BaseMessage()
  msg.setPath('auth/datauser')
  const response = await new MessageSender().doPost(msg)
  if (response.isOK()) {
    return response.getResultAsObject()
  }
  return false
}
