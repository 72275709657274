import icBussiness from '../assets/imgs/icons/ic-bussiness.png'
import iconSaldo from '../assets/imgs/icons/icon_saldo.png'
import dataTransferInactive from '../assets/imgs/icons/icon_data_transfer_inactive.png'
import homeActive from '../assets/imgs/icons/icon_home_active_new.png'
import homeActiveFusindo from '../assets/imgs/icons/icon_home_fushindo_active.png'
import homeInactive from '../assets/imgs/icons/icon_home_inactive_new.png'
import laporanActive from '../assets/imgs/icons/icon_laporan_active_new.png'
import laporanActiveFusindo from '../assets/imgs/icons/icon_laporan_fusindo_active.png'
import laporanInactive from '../assets/imgs/icons/icon_laporan_inactive_new.png'
import danatalanganActive from '../assets/imgs/icons/icon_danatalangan_active.png'
import danatalanganInactive from '../assets/imgs/icons/icon_danatalangan_inactive.png'
import unduhActive from '../assets/imgs/icons/icon_unduh_active.png'
import unduhInactive from '../assets/imgs/icons/icon_unduh_inactive.png'
import settingActive from '../assets/imgs/icons/icon_setting_active.png'
import settingActiveFusindo from '../assets/imgs/icons/icon_setting_fusindo_active.png'
import settingInactive from '../assets/imgs/icons/icon_setting_inactive.png'
import settlementActive from '../assets/imgs/icons/icon_settlement_active_new.png'
import settlementActiveFusindo from '../assets/imgs/icons/icon_settlement_fusindo_active.png'
import settlementInactive from '../assets/imgs/icons/icon_settlement_inactive_new.png'
import terimauangActive from '../assets/imgs/icons/icon_terimauang_active_new.png'
import terimauangActiveFusindo from '../assets/imgs/icons/icon_terima_uang_fusindo_active.png'
import terimauangInactive from '../assets/imgs/icons/icon_terimauang_inactive_new.png'
import transactionActive from '../assets/imgs/icons/icon_transaction_active.png'
import transactionInactive from '../assets/imgs/icons/icon_transaction_inactive.png'
import transferActive from '../assets/imgs/icons/icon_transfer_active_new.png'
import transferActiveFusindo from '../assets/imgs/icons/icon_transfer_fusindo_active.png'
import transferInactive from '../assets/imgs/icons/icon_transfer_inactive_new.png'
import vaActive from '../assets/imgs/icons/icon_va_active_new.png'
import vaActiveFusindo from '../assets/imgs/icons/icon_va_active_fusindo.png'
import vaInactive from '../assets/imgs/icons/icon_va_inactive_new.png'
import fullArrowRightActive from '../assets/imgs/icons/icon_arrow_right_red.png'
import fullArrowRightActiveFusindo from '../assets/imgs/icons/icon_arrow_right_fusindo.png'
import arrowRight from '../assets/imgs/icons/icon_arrow_right.png'
import arrowRightNew from '../assets/imgs/icons/icon_arrow_right_new.png'
import arrowLeft from '../assets/imgs/icons/icon_arrow_left.png'
import arrowLeftNew from '../assets/imgs/icons/icon_arrow_left_new.png'
import profil from '../assets/imgs/icons/icon_profil.png'
import arrowDown from '../assets/imgs/icons/icon_arrow_down.png'
import arrowUpNew from '../assets/imgs/icons/icon_arrow_up.png'
import account from '../assets/imgs/icons/icon_akun.png'
import logout from '../assets/imgs/icons/icon_logout.png'
import logoutFusindo from '../assets/imgs/icons/icon_logout_fusindo.png'
import sendFeedback from '../assets/imgs/icons/icon_send_feedback.png'
import idCard from '../assets/imgs/icons/icon_la_id_card.png'
import fluentPassword from '../assets/imgs/icons/icon_fluent_password.png'
import checklist from '../assets/imgs/icons/icon_bi_card_checklist.png'
import pencil from '../assets/imgs/icons/icon_pencil.png'
import carbonPending from '../assets/imgs/icons/icon_carbon_pending.png'
import carbonPendingFusindo from '../assets/imgs/icons/icon_carbon_pending_fusindo.png'
import download from '../assets/imgs/icons/icon_download.png'
import check from '../assets/imgs/icons/icon_check.png'
import checkFusindo from '../assets/imgs/icons/icon_check_fusindo.png'
import save from '../assets/imgs/icons/icon_save.png'
import saveFusindo from '../assets/imgs/icons/icon_save_fusindo.png'
import timeRollback from '../assets/imgs/icons/icon_time_rollback.png'
import timeRollbackFusindo from '../assets/imgs/icons/icon_time_rollback_fusindo.png'
import history from '../assets/imgs/icons/icon_park_outline_transaction_new.png'
import historyFusindo from '../assets/imgs/icons/icon-park-outline_transaction_fusindo_active.png'
import historyInactive from '../assets/imgs/icons/icon_park_outline_transaction_inactive_new.png'
import cursorInput from '../assets/imgs/icons/lucide_text_cursor_input.png'
import cursorInputActive from '../assets/imgs/icons/lucide_text_cursor_input_red.png'
import cursorInputActiveFusindo from '../assets/imgs/icons/lucide_text_cursor_input_fusindo.png'
import bulkTransfer from '../assets/imgs/icons/icon_park_outline_excel.png'
import bulkTransferActive from '../assets/imgs/icons/icon_park_outline_excel_red.png'
import bulkTransferActiveFusindo from '../assets/imgs/icons/icon_park_outline_excel_fusindo.png'
import api from '../assets/imgs/icons/icon_api.png'
import apiFusindo from '../assets/imgs/icons/icon_api_fusindo.png'
import apiRed from '../assets/imgs/icons/icon_api_red.png'
import avatar from '../assets/imgs/icons/icon_avatar.png'
import avatarFusindo from '../assets/imgs/icons/icon_avatar_fusindo.png'
import copy from '../assets/imgs/icons/icons_copy.png'
import checksuccess from '../assets/imgs/icons/icon_check_success.png'
import iconSaveWhite from '../assets/imgs/icons/icon_save_white.png'
import plus from '../assets/imgs/icons/icon_plus.png'
import alert from '../assets/imgs/icons/icon_alert.png'
import backButton from '../assets/imgs/icons/icon_back.png'
import print from '../assets/imgs/icons/icon_print.png'
import merchantInactive from '../assets/imgs/icons/icon_merchant.png'
import merchantActive from '../assets/imgs/icons/icon_merchant_red.png'
import merchantActiveFusindo from '../assets/imgs/icons/icon_merchant_member_active.png'
import product from '../assets/imgs/icons/icon_product.png'
import agen from '../assets/imgs/icons/icon_agen.png'
import saldoRed from '../assets/imgs/icons/icon_saldo_red.png'
import saldoFusindo from '../assets/imgs/icons/icon_saldo_fusindo.png'
import withdraw from '../assets/imgs/icons/icon_withdraw.png'
import deposit from '../assets/imgs/icons/icon_deposit.png'
import checkSquare from '../assets/imgs/icons/icon_check_square.png'
import arrowRightActive from '../assets/imgs/icons/icon_arrow_red_right.png'
import arrowRightActiveFusindo from '../assets/imgs/icons/icon_arrow_fusindo_right.png'
import arrowWhite from '../assets/imgs/icons/icon_arrow_left_white.png'
import search from '../assets/imgs/icons/icon_search.png'
import requestPayment from '../assets/imgs/icons/icon_request_payment.png'
import clipPath from '../assets/imgs/icons/icon_clip_path.png'
import alertGrey from '../assets/imgs/icons/icon_alert_grey.png'
import bankCard from '../assets/imgs/icons/icon_bank_card.png'
import bankCardFusindo from '../assets/imgs/icons/icon_bank_card_fusindo.png'
import bankCardWhite from '../assets/imgs/icons/icon_bank_card_white.png'
import wallet from '../assets/imgs/icons/icon_wallet.png'
import walletFusindo from '../assets/imgs/icons/icon_wallet_fusindo.png'
import walletWhite from '../assets/imgs/icons/icon_wallet_white.png'
import store from '../assets/imgs/icons/icon_store.png'
import storeFusindo from '../assets/imgs/icons/icon_store_fusindo.png'
import storeWhite from '../assets/imgs/icons/icon_store_white.png'
import gubuk from '../assets/imgs/logo/Gubuk.png'
import arrowDownGrey from '../assets/imgs/icons/icon_arrow_down_grey.png'
import arrowUpGrey from '../assets/imgs/icons/icon_arrow_up_grey.png'
import voucher from '../assets/imgs/icons/icon_voucher.png'
import voucherFusindo from '../assets/imgs/icons/icon_voucher_fusindo.png'
import arrowRightBlack from '../assets/imgs/icons/icon_arrow_right_black.png'
import checkRounded from '../assets/imgs/icons/icon_check_circle_rounded.png'
import downloadFile from '../assets/imgs/icons/icon_download_file.png'
import downloadFileFusindo from '../assets/imgs/icons/icon_download_file_fusindo.png'
import balance from '../assets/imgs/icons/icon_laporan_rekening_menu.png'
import balanceFusindo from '../assets/imgs/icons/icon_laporan_rekening_fusindo.png'
import successDialog from '../assets/imgs/icons/icon_success_dialog.png'
import warningDialog from '../assets/imgs/icons/icon_warning_dialog.png'
import showPass from '../assets/imgs/icons/icon_show_password.png'
import hidePass from '../assets/imgs/icons/icon_hide_password.png'
import globe from '../assets/imgs/icons/icon_globe.png'
import support from '../assets/imgs/icons/icon_support.png'
import icPersonalFusindo from '../assets/imgs/icons/ic-personal-fusindo.png'
import icPersonal from '../assets/imgs/icons/ic-personal-linkqu.png'
import limitLogo from '../assets/imgs/limit.svg'
import limitLogoFusindo from '../assets/imgs/limit_fusindo.svg'
import empty from '../assets/imgs/empty.svg'
import emptyFusindo from '../assets/imgs/empty_fusindo.svg'
import accessDenied from '../assets/imgs/access_denied.svg'
import accessDeniedFusindo from '../assets/imgs/access_denied_fusindo.svg'
import iconName from './constant/iconName'

export default (slug, brand = '') => {
  let logo = ''
  let slg = slug
  if (process.env.REACT_APP_BRAND !== 'LINKQU') {
    slg = `${slug}${brand}`
  }
  switch (slg) {
    case iconName.accessDeniedFusindo:
      logo = accessDeniedFusindo
      break
    case iconName.accessDenied:
      logo = accessDenied
      break
    case iconName.empty:
      logo = empty
      break
    case iconName.emptyFusindo:
      logo = emptyFusindo
      break
    case iconName.limitLogo:
      logo = limitLogo
      break
    case iconName.limitLogoFusindo:
      logo = limitLogoFusindo
      break
    case iconName.saldoFusindo:
      logo = saldoFusindo
      break
    case iconName.icPersonal:
      logo = icPersonal
      break
    case iconName.icPersonalFusindo:
      logo = icPersonalFusindo
      break
    case iconName.api:
      logo = api
      break
    case iconName.apiFusindo:
      logo = apiFusindo
      break
    case iconName.globe:
      logo = globe
      break
    case iconName.support:
      logo = support
      break
    case iconName.hidePass:
      logo = hidePass
      break
    case iconName.showPass:
      logo = showPass
      break
    case iconName.successDialog:
      logo = successDialog
      break
    case iconName.warningDialog:
      logo = warningDialog
      break
    case iconName.balance:
      logo = balance
      break
    case iconName.balanceFusindo:
      logo = balanceFusindo
      break
    case iconName.downloadFile:
      logo = downloadFile
      break
    case iconName.downloadFileFusindo:
      logo = downloadFileFusindo
      break
    case iconName.checkRounded:
      logo = checkRounded
      break
    case iconName.arrowRightBlack:
      logo = arrowRightBlack
      break
    case iconName.voucher:
      logo = voucher
      break
    case iconName.voucherFusindo:
      logo = voucherFusindo
      break
    case iconName.arrowDownGrey:
      logo = arrowDownGrey
      break
    case iconName.arrowUpGrey:
      logo = arrowUpGrey
      break
    case iconName.arrowUpNew:
      logo = arrowUpNew
      break
    case iconName.gubuk:
      logo = gubuk
      break
    case iconName.walletWhite:
      logo = walletWhite
      break
    case iconName.bankCardWhite:
      logo = bankCardWhite
      break
    case iconName.storeWhite:
      logo = storeWhite
      break
    case iconName.bankCard:
      logo = bankCard
      break
    case iconName.bankCardFusindo:
      logo = bankCardFusindo
      break
    case iconName.wallet:
      logo = wallet
      break
    case iconName.walletFusindo:
      logo = walletFusindo
      break
    case iconName.store:
      logo = store
      break
    case iconName.storeFusindo:
      logo = storeFusindo
      break
    case iconName.alertGrey:
      logo = alertGrey
      break
    case iconName.clipPath:
      logo = clipPath
      break
    case iconName.requestPayment:
      logo = requestPayment
      break
    case iconName.search:
      logo = search
      break
    case iconName.checkSquare:
      logo = checkSquare
      break
    case iconName.arrowRightActive:
      logo = arrowRightActive
      break
    case iconName.arrowRightActiveFusindo:
      logo = arrowRightActiveFusindo
      break
    case iconName.arrowWhite:
      logo = arrowWhite
      break
    case iconName.deposit:
      logo = deposit
      break
    case iconName.withdraw:
      logo = withdraw
      break
    case iconName.saldoRed:
      logo = saldoRed
      break
    case iconName.product:
      logo = product
      break
    case iconName.agen:
      logo = agen
      break
    case iconName.merchantInactive:
      logo = merchantInactive
      break
    case iconName.merchantActive:
      logo = merchantActive
      break
    case iconName.merchantActiveFusindo:
      logo = merchantActiveFusindo
      break
    case iconName.print:
      logo = print
      break
    case iconName.checksuccess:
      logo = checksuccess
      break
    case iconName.backButton:
      logo = backButton
      break
    case iconName.alert:
      logo = alert
      break
    case iconName.plus:
      logo = plus
      break
    case iconName.iconSaveWhite:
      logo = iconSaveWhite
      break
    case iconName.copy:
      logo = copy
      break
    case iconName.avatar:
      logo = avatar
      break
    case iconName.avatarFusindo:
      logo = avatarFusindo
      break
    case iconName.bulkTransferActive:
      logo = bulkTransferActive
      break
    case iconName.bulkTransferActiveFusindo:
      logo = bulkTransferActiveFusindo
      break
    case iconName.cursorInputActive:
      logo = cursorInputActive
      break
    case iconName.cursorInputActiveFusindo:
      logo = cursorInputActiveFusindo
      break
    case iconName.apiActive:
      logo = apiRed
      break
    case iconName.history:
      logo = history
      break
    case iconName.historyFusindo:
      logo = historyFusindo
      break
    case iconName.historyInactive:
      logo = historyInactive
      break
    case iconName.bulkTransfer:
      logo = bulkTransfer
      break
    case iconName.cursorInput:
      logo = cursorInput
      break
    case iconName.download:
      logo = download
      break
    case iconName.timeRollback:
      logo = timeRollback
      break
    case iconName.timeRollbackFusindo:
      logo = timeRollbackFusindo
      break
    case iconName.save:
      logo = save
      break
    case iconName.saveFusindo:
      logo = saveFusindo
      break
    case iconName.check:
      logo = check
      break
    case iconName.checkFusindo:
      logo = checkFusindo
      break
    case iconName.pencil:
      logo = pencil
      break
    case iconName.fullArrowRightActive:
      logo = fullArrowRightActive
      break
    case iconName.fullArrowRightActiveFusindo:
      logo = fullArrowRightActiveFusindo
      break
    case iconName.carbonPending:
      logo = carbonPending
      break
    case iconName.carbonPendingFusindo:
      logo = carbonPendingFusindo
      break
    case iconName.fluentPassword:
      logo = fluentPassword
      break
    case iconName.checklist:
      logo = checklist
      break
    case iconName.account:
      logo = account
      break
    case iconName.idCard:
      logo = idCard
      break
    case iconName.sendFeedback:
      logo = sendFeedback
      break
    case iconName.logout:
      logo = logout
      break
    case iconName.logoutFusindo:
      logo = logoutFusindo
      break
    case iconName.arrowDown:
      logo = arrowDown
      break
    case iconName.profil:
      logo = profil
      break
    case iconName.arrowLeft:
      logo = arrowLeft
      break
    case iconName.arrowLeftNew:
      logo = arrowLeftNew
      break
    case iconName.arrowRight:
      logo = arrowRight
      break
    case iconName.arrowRightNew:
      logo = arrowRightNew
      break
    case iconName.saldo:
      logo = iconSaldo
      break
    case iconName.business:
      logo = icBussiness
      break
    case iconName.dataTransferInactive:
      logo = dataTransferInactive
      break
    case iconName.homeActive:
      logo = homeActive
      break
    case iconName.homeActiveFusindo:
      logo = homeActiveFusindo
      break
    case iconName.homeInactive:
      logo = homeInactive
      break
    case iconName.laporanActive:
      logo = laporanActive
      break
    case iconName.laporanActiveFusindo:
      logo = laporanActiveFusindo
      break
    case iconName.laporanInactive:
      logo = laporanInactive
      break
    case iconName.danatalanganActive:
      logo = danatalanganActive
      break
    case iconName.danatalanganInactive:
      logo = danatalanganInactive
      break
    case iconName.unduhActive:
      logo = unduhActive
      break
    case iconName.unduhInactive:
      logo = unduhInactive
      break
    case iconName.settingActive:
      logo = settingActive
      break
    case iconName.settingActiveFusindo:
      logo = settingActiveFusindo
      break
    case iconName.settingInactive:
      logo = settingInactive
      break
    case iconName.settlementActive:
      logo = settlementActive
      break
    case iconName.settlementActiveFusindo:
      logo = settlementActiveFusindo
      break
    case iconName.settlementInactive:
      logo = settlementInactive
      break
    case iconName.terimauangActive:
      logo = terimauangActive
      break
    case iconName.terimauangActiveFusindo:
      logo = terimauangActiveFusindo
      break
    case iconName.terimauangInactive:
      logo = terimauangInactive
      break
    case iconName.transactionActive:
      logo = transactionActive
      break
    case iconName.transactionInactive:
      logo = transactionInactive
      break
    case iconName.transferActive:
      logo = transferActive
      break
    case iconName.transferActiveFusindo:
      logo = transferActiveFusindo
      break
    case iconName.transferInactive:
      logo = transferInactive
      break
    case iconName.vaActive:
      logo = vaActive
      break
    case iconName.vaActiveFusindo:
      logo = vaActiveFusindo
      break
    case iconName.vaInactive:
      logo = vaInactive
      break
    default:
      logo = iconSaldo
  }
  return logo
}
