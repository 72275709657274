/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useRef, useState } from 'react'
import { Accordion, Image, Nav } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import global from '../../store/constant/global'
import icons from '../../helper/icons'
import iconName from '../../helper/constant/iconName'

function SubMenuComponent({ icon, title, active, menu, changeActive, items, isOpen, dispatch }) {
  const [initState, changedState] = useState({
    collapsed: active,
  })
  const accordionRef = useRef(null);  

  const toggleNavbar = () => {
    const { collapsed } = initState
    if(!isOpen) {
      dispatch({ type: global.SIDEBAR_OPEN, payload: !isOpen })
    }
    changedState({
      collapsed: !collapsed,
    })
  }

  const { collapsed } = initState

  const isAnySubItemActive = () => {
    return items.some((item) => menu === item.key);
  };

  const getIcon = () => {
    if (!collapsed) {
      return icons(iconName.arrowDownGrey);
    }
    return isAnySubItemActive() ? icons(iconName.arrowUpNew) : icons(iconName.arrowUpGrey);
  };

  return (
    <Nav.Item className={!collapsed ? " open mb-0" : "mb-0"}>
      <Accordion>
        <Accordion.Toggle ref={accordionRef} as={Nav.Link} variant="link" eventKey="0" onClick={toggleNavbar}>
          <Image
            className="d-inline-block icon"
            src={icons(icon)}
          />
          <div className={classNames('d-inline-block ml-3 fontSize-14-lexend-l title-menu', {
            'color-red': isAnySubItemActive(),
            'color-grey': !isAnySubItemActive(),
          })}>
            {title}
          </div>
          {isOpen && (
            <Image
              className="icon float-right mt-1 mr-2"
              style={{ width: '10px', height: '5px' }}
              src={getIcon()}
            />
          )}
        </Accordion.Toggle>

        <Accordion.Collapse eventKey="0" in={collapsed && isOpen}>
          <nav className="nav flex-column pb-3">
            {items.map((item) =>
              !item.hide ? (
                <></>
              ) : (
                <Nav.Link
                  as={Link}
                  active={menu === item.key}
                  className="pl-5"
                  to={item.path}
                  key={item.path}
                  eventKey={item.key}
                  onSelect={(selectedKey) => changeActive(selectedKey, true)}
                >
                  <div
                    className={menu === item.key ? "d-inline-block ml-3 fontSize-14-lexend-l color-red" : "d-inline-block ml-3 fontSize-14-lexend-l color-grey"}
                    style={{ paddingLeft:'25px' }}
                  >
                    {item.title}
                  </div>
                </Nav.Link>
              )
            )}
          </nav>
        </Accordion.Collapse>
      </Accordion>
    </Nav.Item>
  )
}

SubMenuComponent.defaultProps = {
  icon: '',
  title: '',
  active: false,
  items: [],
  isOpen: false,
}

SubMenuComponent.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  active: PropTypes.bool,
  menu: PropTypes.string.isRequired,
  changeActive: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.any),
  isOpen: PropTypes.bool,
  dispatch: PropTypes.func.isRequired
}

// export default SubMenuComponent

export default connect(
  (state) => ({}),
  (dispatch) => ({ dispatch })
)(SubMenuComponent)
