import MessageSender from '../sender'
import BaseMessage from '../message'

export const getBerita = (page, grup) => async () => {
  const msg = new BaseMessage()
  let id = 1588
  if (grup !== 1) {
    id = 1598
  }

  msg.setPath(`kategori/data?id=${id}&page=${page}`)

  const response = await new MessageSender().doGet(msg)

  return response
}

export const getKonten = (id) => async () => {
  const msg = new BaseMessage()
  msg.setPath(`kategori/konten?id=${id}`)
  const response = await new MessageSender().doGet(msg)
  return response.getResultAsList()
}
