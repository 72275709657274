/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import Dropzone from 'react-dropzone'
import { Button, Card } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import globalConstant from '../../store/constant/global'
import translate from '../../helper/translate'
import { titleSentence } from '../../helper/translatePage/languageResource'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eb6864',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}

const activeStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

class DropFileComponent extends React.PureComponent {
  render() {
    // eslint-disable-next-line react/prop-types
    const { onDrop, language } = this.props

    return (
      <Card className="dropzone-wrapper mt-3 mb-5">
        <Dropzone onDrop={onDrop} accept=".csv" maxSize={10000000}>
          {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => {
            return (
              <div
                {...getRootProps({
                  className: 'dropzone',
                  style: {
                    ...baseStyle,
                    ...(isDragActive ? activeStyle : {}),
                    ...(isDragAccept ? acceptStyle : {}),
                    ...(isDragReject ? rejectStyle : {}),
                  },
                })}
              >
                <input {...getInputProps()} />
                <p className="fontSize-20-Bold text-active">
                  {translate('bulkInfo6', language, titleSentence)}
                </p>
                <p className="fontSize-16 color-grey">{translate('or', language, titleSentence)}</p>
                <Button className="button-file-drop color-white  background-primary">
                  {translate('bulkInfo7', language, titleSentence)}
                </Button>
                <p className="fontSize-16 color-grey mt-3">
                  {translate('bulkInfo8', language, titleSentence)}
                </p>
              </div>
            )
          }}
        </Dropzone>
      </Card>
    )
  }
}

DropFileComponent.defaultProps = {
  onDrop: null,
}

DropFileComponent.propTypes = {
  onDrop: PropTypes.func,
}

// export default DropFileComponent
export default connect(
  (state) => ({
    language: state.Global[globalConstant.LANGUAGE],
  }),
  (dispatch) => ({ dispatch })
)(DropFileComponent)
