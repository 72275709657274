import { library } from '@fortawesome/fontawesome-svg-core'
import * as FA from '@fortawesome/free-solid-svg-icons'

library.add(
  FA.faAlignLeft,
  FA.faArrowLeft,
  FA.faArrowUp,
  FA.faArrowDown,
  FA.faArrowRight,
  FA.faBalanceScale,
  FA.faCalendarAlt,
  FA.faCaretDown,
  FA.faCaretRight,
  FA.faChartBar,
  FA.faChartLine,
  FA.faCogs,
  FA.faDownload,
  FA.faEdit,
  FA.faHome,
  FA.faInfoCircle,
  FA.faListUl,
  FA.faMoneyBillWaveAlt,
  FA.faMoneyCheckAlt,
  FA.faKey,
  FA.faPaperPlane,
  FA.faPrint,
  FA.faRedo,
  FA.faSave,
  FA.faSearch,
  FA.faScroll,
  FA.faTimes,
  FA.faTrashAlt,
  FA.faUser,
  FA.faPlus,
  FA.faBars
)
