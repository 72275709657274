/* eslint-disable react/forbid-prop-types */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Table } from 'react-bootstrap'

import AlertDialog from './alert'

class BankDialog extends React.PureComponent {
  render() {
    const { listBank } = this.props

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <AlertDialog {...this.props} title="Data Bank">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>No</th>
              <th>Bank Code</th>
              <th>Bank Name</th>
            </tr>
          </thead>
          <tbody>
            {listBank
              .sort((a, b) => (a.kodebank > b.kodebank ? 1 : -1))
              .map((bank, i) => (
                <tr key={bank.kodebank}>
                  <td>{i + 1}</td>
                  <td>{bank.kodebank}</td>
                  <td>{bank.namabank}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </AlertDialog>
    )
  }
}

BankDialog.defaultProps = {
  onHide: null,
  show: false,
  dispatch: null,
  listBank: [],
}

BankDialog.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  dispatch: PropTypes.func,
  listBank: PropTypes.arrayOf(PropTypes.object),
}

export default connect(null, (dispatch) => ({ dispatch }))(BankDialog)
