/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable eqeqeq */
import React from 'react'
import { Image, Navbar, NavDropdown } from 'react-bootstrap'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import swal from 'sweetalert'

import './header.scss'
import { getAppInfo } from '../../api/action/dataAccount'
// import { getAppInfo, getBalance, getHoldBalance } from '../../api/action/dataAccount'
import logout from '../../api/action/logout'
import authConstant from '../../store/constant/auth'
import global from '../../store/constant/global'
import Browser from '../../helper/browser'
import { production } from '../../api/action/changeEnv'
import icons from '../../helper/icons'
import iconName from '../../helper/constant/iconName'

class HeaderComponent extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      url: process.env.REACT_APP_URL_LINKQU_SUPPORT,
    }
    this.timeout = null
  }

  componentDidMount() {
    this.timeout = setInterval(this.getAppInfo, 10000)
  }

  componentWillUnmount() {
    // eslint-disable-next-line
    clearInterval(this.timeout)
  }

  toggleSidebar = () => {
    const { isOpen, dispatch } = this.props

    dispatch({ type: global.SIDEBAR_OPEN, payload: !isOpen })
  }

  getAppInfo = async () => {
    const { dispatch, isLogin } = this.props
    if (isLogin) {
      const response = await dispatch(getAppInfo())

      if (response.isOK()) {
        const data = response.getResultAsObject()

        // if (data.force_logout === '1') {
        //   this.doLogout()
        // }

        if (Browser.getCurrentPath().includes('auth/login')) {
          await dispatch({ type: authConstant.USER_BALANCE, payload: 'Rp. 0' })
          await dispatch({ type: authConstant.USER_HOLDBALANCE, payload: 'Rp. 0' })
        } else if (data.balance && data.hold_balance) {
          await dispatch({ type: authConstant.USER_BALANCE, payload: data.balance })
          await dispatch({ type: authConstant.USER_HOLDBALANCE, payload: data.hold_balance })
        } else {
          await dispatch({ type: authConstant.USER_BALANCE, payload: 'Rp. 0' })
          await dispatch({ type: authConstant.USER_HOLDBALANCE, payload: 'Rp. 0' })
        }
      }
    }
  }

  doLogout = async () => {
    const { dispatch } = this.props
    clearInterval(this.timeout)
    await dispatch(logout())
  }

  handleLang = (evt) => {
    const { dispatch } = this.props

    dispatch({ type: global.LANGUAGE, payload: evt.target.value })

    if (this.props.changeLang) {
      this.props.changeLang(evt.target.value)
    }
  }

  switchProduction = () => {
    const { dispatch } = this.props

    swal({
      icon: 'info',
      title: 'Information',
      text:
        'Anda berada dalam mode Sandbox, Kami akan mengganti mode ke Production ketika anda ingin logout. Klik tombol Yes jika ingin melanjutkan',
      buttons: {
        cancel: true,
        confirm: {
          text: 'Yes',
          value: 'confirmed',
          className: 'swal-red-btn',
          closeModal: false,
        },
      },
    })
      .then((onConfirm) => {
        if (onConfirm !== 'confirmed') {
          swal.close()
        }

        const response = dispatch(production())
        return response
      })
      .then((res) => {
        if (res.data.rc != '00' || res.data.rc == 500) {
          swal('Information', 'Something Wrong while switch to Production', 'info')
        }

        swal.close()
        this.doLogout()
      })
      .catch((err) => {
        swal('Warning', err.rd, 'warning')
      })
  }

  render() {
    const { language, environment, regStatus, isOpen } = this.props
    const { url } = this.state
    const profilePict = (
      <div className="profile">
        <Image src={icons(iconName.profil)} className="d-inline icon-profil" />
        <Image
          src={icons(iconName.arrowDown)}
          className="down d-inline color-black"
          fixedWidth
          icon="arrow-down"
        />
      </div>
    )
    return (
      <Navbar
        className={
          isOpen
            ? 'navbar shadow-sm heading open-sidebar'
            : 'navbar shadow-sm heading close-sidebar'
        }
        expand
      >
        {/* <Navbar.Brand className="ml-3 fontSize-26 color-black title-path">
          {headerTitle}
        </Navbar.Brand> */}
        <a href={url} target="_blank" rel="noopener noreferrer" className="support">
          <Image src={icons(iconName.support)} className="mr-3" />
        </a>
        {regStatus?.status && (
          <div className="lang-list button-change-server fontSize-17 color-black ">
            <Image src={icons(iconName.globe)} className="d-inline icon-lang" />
            <select
              className="form-control color-black text-uppercase"
              onChange={this.handleLang}
              value={language}
            >
              <option value="id" className="color-black text-uppercase">
                ID
              </option>
              <option value="en" className="color-black">
                EN
              </option>
            </select>
          </div>
        )}
        <NavDropdown title={profilePict} id="basic-nav-dropdown" className="profile-pict">
          <NavDropdown.Item eventKey="4.1" href="/account" className="fontSize-17 color-grey">
            Akun
            <Image
              src={icons(iconName.account)}
              className="item d-inline"
              fixedWidth
              icon="arrow-down"
            />
          </NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item
            onClick={() => (environment === 'sandbox' ? this.switchProduction() : this.doLogout())}
            className="fontSize-17 text-active"
          >
            Log out
            <Image
              src={icons(iconName.logout, process.env.REACT_APP_BRAND_NAME)}
              className="item d-inline"
              fixedWidth
              icon="arrow-down"
            />
          </NavDropdown.Item>
        </NavDropdown>
      </Navbar>
    )
  }
}

HeaderComponent.defaultProps = {
  changeLang: '',
  isOpen: false,
  language: '',
}

HeaderComponent.propTypes = {
  dispatch: PropTypes.func.isRequired,
  changeLang: PropTypes.string,
  isOpen: PropTypes.bool,
  language: PropTypes.string,
}

export default connect(
  (state) => ({
    appAgentGroup: state.Auth[authConstant.AGENT_GROUP],
    appUserID: state.Auth[authConstant.USER_ID],
    appUserEnabledApi: state.Auth[authConstant.ENABLE_API],
    appUserName: state.Auth[authConstant.USER_NAME],
    appUserType: state.Auth[authConstant.USER_TYPE],
    appUserData: state.Auth[authConstant.USER_DATA],
    isOpen: state.Global[global.SIDEBAR_OPEN],
    isLogin: state.Global[global.IS_LOGIN],
    language: state.Global[global.LANGUAGE],
    regStatus: state.Auth[authConstant.STATUS_REG],
    environment: state.Global[global.ENVIRONMENT],
    headerTitle: state.Global[global.HEADER_TITLE],
  }),
  (dispatch) => ({ dispatch })
)(HeaderComponent)
