const checkAccess = (accessData, moduleName, accessName) => {
  if (accessData === null || accessData === undefined) {
    return false
  }

  const selectedModule = accessData.filter((access) => access.nama_modul === moduleName)

  if (selectedModule.length === 0) {
    return false
  }

  const access = selectedModule[0].hak_akses

  return access.includes(accessName)
}

export default checkAccess
