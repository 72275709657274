import React from 'react'
import { connect } from 'react-redux'
// eslint-disable-next-line
import { Container, Row, Col, Button, Modal, Spinner, ProgressBar, Image } from 'react-bootstrap'
// import DataTable from 'react-data-table-component'
import PropTypes from 'prop-types'
// eslint-disable-next-line
import './topup.scss'
import BaseScreen from '../../base'
import globalConstant from '../../../store/constant/global'
import AlertDialog from '../../dialog/alert'
import auth from '../../../store/constant/auth'
import dataUserListVA from '../../../api/action/dataUserListVA'
import roleAccess from '../../../store/constant/roleAccess'
import checkAccess from '../../../helper/access'
import AccessDenied from '../../component/AccessDenied'
import translate from '../../../helper/translate'
import { buttonResource, headerPageResource } from '../../../helper/translatePage/languageResource'

class TopupVA extends BaseScreen {
  constructor(props) {
    super(props, ['page--topup'])
    this.state = {
      modalShow: false,
      alertError: '',
      loading: false,
      bankSlug: '',
      guideLink: '',
      data: [],
    }
  }

  closeError = () => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ alertError: '' })
  }

  componentDidMount() {
    super.componentDidMount()
    this.getData()
  }

  handleModal = () => {
    this.setState({ modalShow: !this.state.modalShow })
  }

  handleModalBody = () => {
    this.setState({ loading: true })

    setTimeout(() => {
      this.setState({ loading: false })
    }, 3000)
  }

  getData = async () => {
    const { dispatch } = this.props

    const response = await dispatch(dataUserListVA(this.props.appUserData.username_agen))

    if (response.isOK()) {
      this.setState({ data: response.data.data.data })
    } else {
      console.log('data user list va:', response)
    }
  }

  banks = [
    {
      slug: 'bca',
      name: 'Bank BCA',
      link: 'https://www.linkqu.id/cara-pembayaran-virtual-account-bca/',
    },
    {
      slug: 'bri',
      name: 'Bank BRI',
      link: 'https://www.linkqu.id/cara-pembayaran-virtual-account-bri/',
    },
    {
      slug: 'mandiri',
      name: 'Bank Mandiri',
      link: 'https://www.linkqu.id/cara-pembayaran-virtual-account-bank-mandiri/',
    },
    {
      slug: 'bni',
      name: 'Bank BNI',
      link: 'https://www.linkqu.id/cara-pembayaran-virtual-account-bni/',
    },
    {
      slug: 'permata',
      name: 'Bank Permata',
      link: 'https://www.linkqu.id/cara-pembayaran-virtual-account-permata-bank/',
    },
    {
      slug: 'cimb',
      name: 'CIMB NIAGA',
      link: 'https://www.linkqu.id/cara-pembayaran-virtual-account-cimb-niaga/',
    },
  ]

  showGuide = (slug) => {
    const bankGuideLink = this.banks.find((val) => val.slug === slug)
    if (bankGuideLink) {
      this.setState({ bankSlug: slug, guideLink: bankGuideLink.link, modalShow: true })
    } else {
      this.setState({
        bankSlug: slug,
        guideLink: 'https://www.linkqu.id/cara-pembayaran-virtual-account/',
        modalShow: true,
      })
    }
  }

  readAccess = () => {
    return checkAccess(this.props.appUserAccess, 'topup', 'READ')
  }

  render() {
    // eslint-disable-next-line
    const { loading, alertError, balanceFresh, modalShow, guide, guideLink } = this.state

    return (
      <>
        <AlertDialog
          show={!!alertError}
          onHide={this.closeError}
          onConfirm={this.closeError}
          title="Information"
        >
          {alertError}
        </AlertDialog>
        <Container fluid className="p-3 container-topup-virtual-account">
          <div className="fontSize-20 mb-5">
            {translate('virtualAccount', this.props.language, headerPageResource)}
          </div>
          {!this.readAccess() ? (
            <AccessDenied />
          ) : (
            <>
              {this.props.appIsLoading ? (
                <>
                  <ProgressBar
                    style={{ width: '100%' }}
                    className="mt-3 mb-3"
                    variant="info"
                    label="Please Wait..."
                    animated
                    now={100}
                  />
                </>
              ) : (
                <>
                  {this.state.data.map((item) => (
                    <Row key={item.bank}>
                      <Col>
                        <Image src={item.url_image} />
                      </Col>
                      <Col className="fontSize-17">{item.bank}</Col>
                      <Col className="fontSize-17">
                        {item.status_gangguan_va !== 'enable' ? 'Gangguan' : item.va_number}
                      </Col>
                      <Col className="fontSize-17">{item.nama_pemilik}</Col>
                      <Col>
                        <button
                          type="button"
                          className="btn btn-primary fontSize-14"
                          onClick={() => this.showGuide(item.bank)}
                        >
                          {translate('guide', this.props.language, buttonResource)}
                        </button>
                      </Col>
                    </Row>
                  ))}
                </>
              )}
            </>
          )}
        </Container>
        <Modal show={modalShow} onHide={this.handleModal}>
          <Modal.Header closeButton>
            <Modal.Title>{guide}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* eslint-disable-next-line */}
            {
                loading ? 
              (
              <Spinner size="xs" className="mr-1" animation="border" variant="primary" />
            ) : (
              // eslint-disable-next-line jsx-a11y/iframe-has-title
              <iframe src={guideLink} />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" className="d-none">
              {translate('close', this.props.language, buttonResource)}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

TopupVA.defaultProps = {
  appIsLoading: false,
}

TopupVA.propTypes = {
  appIsLoading: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
    appUserData: state.Auth[auth.USER_DATA],
    appUserAccess: state.RoleAccess[roleAccess.ACCESS],
    language: state.Global[globalConstant.LANGUAGE],
  }),
  (dispatch) => ({ dispatch })
)(TopupVA)
