/* eslint-disable radix */
import MessageSender from '../sender'
import BaseMessage from '../message'

export const getBank = () => async () => {
  const msg = new BaseMessage()
  msg.setPath(`transaction/list_bank`)

  const response = await new MessageSender().doGet(msg)

  return response.getResultAsList()
}

export const getCurrencyByCountry = () => async () => {
  const msg = new BaseMessage()
  msg.setPath(`transaction/list_currency_country`)

  const response = await new MessageSender().doGet(msg)

  return response.getResultAsList()
}

export const getCountry = () => async () => {
  const msg = new BaseMessage()
  msg.setPath(`transaction/list_country`)

  const response = await new MessageSender().doGet(msg)

  return response.getResultAsList()
}

export const getTransferPurposeListRemittance = () => async () => {
  const msg = new BaseMessage()
  msg.setPath(`data/transfer_purpose_list_remittance`)

  const response = await new MessageSender().doGet(msg)

  return response.getResultAsList()
}

export const getBankInternational = (countryCode) => async () => {
  const msg = new BaseMessage()
  msg.setPath(`transaction/list_bank_international`)
  msg.setItem('country_code', countryCode)

  const response = await new MessageSender().doGet(msg)

  return response.getResultAsList()
}

export const getTransactionDestionationStored = (email) => async () => {
  const msg = new BaseMessage()
  msg.setPath(`data/transaction_destination_stored`)
  msg.setItem('email', email)
  msg.setItem('offset', 0)
  msg.setItem('limit', 1000)

  const response = await new MessageSender().doGet(msg)

  return response.getResultAsList()
}

export const getChannelPayment = (idInquiry) => async () => {
  const msg = new BaseMessage()
  msg.setPath(`transaction/channel_payment`)
  msg.setItem('id_transaksi', idInquiry)

  const response = await new MessageSender().doGet(msg)

  if (response.isOK()) {
    return response.getResultAsObject()
  }

  return {}
}

export const postOrderCreate = (data) => async () => {
  const msg = new BaseMessage()
  msg.setPath(`transaction/order_create`)
  msg.setItem('data', data)

  const response = await new MessageSender().doPost(msg)
  return response
}

export const postChecksum = (password) => async () => {
  const msg = new BaseMessage()
  msg.setPath(`transaction/checksum`)
  msg.setItem('password', password)

  const response = await new MessageSender().doPost(msg)

  return response
}

export const postStoreBulkVA = (data) => async () => {
  const msg = new BaseMessage()
  msg.setPath(`transaction/store_va_bulk`)
  msg.setItem('data', data)

  const response = await new MessageSender().doPost(msg)

  return response
}

export const bulkWithdrawInquiry = (
  index,
  bankCode,
  bankNumber,
  nominal,
  token,
  uuid
) => async () => {
  const msg = new BaseMessage()
  msg.setPath(`transaction/cashout_inquiry`)
  msg.setItem('code', bankCode)
  msg.setItem('number', bankNumber)
  msg.setItem('nominal', nominal)
  msg.setItem('token', token)
  msg.setItem('uuid_user', uuid)
  msg.setItem('type_inq', 'bulk')

  const response = await new MessageSender().doPost(msg)
  response.setItem('index', index)

  return response
}

export const bulkWithdrawSave = (
  savedData,
  checksum,
  uuidUser,
  pin,
  groupName,
  groupDesc
) => async () => {
  const msg = new BaseMessage()
  msg.setPath(`transaction/cashout_save`)
  msg.setItem('saved_data', savedData)
  msg.setItem('pin', pin)
  msg.setItem('group_name', groupName)
  msg.setItem('group_desc', groupDesc)
  msg.setItem('token', checksum)
  msg.setItem('uuid_user', uuidUser)

  const response = await new MessageSender().doPost(msg)

  return response
}

export const withdrawInquiry = (
  bankCode,
  bankNumber,
  nominal,
  password,
  note,
  isVA,
  transferPurpose,
  senderName,
  recaptcha
) => async () => {
  const msg = new BaseMessage()
  msg.setPath(`transaction/cashout_inquiry`)
  msg.setItem('code', bankCode)
  msg.setItem('number', bankNumber)
  msg.setItem('nominal', nominal)
  msg.setItem('password', password)
  msg.setItem('note', note)
  msg.setItem('sendername', senderName)
  msg.setItem('purposetransaction', transferPurpose)
  msg.setItem('jenis_rekening', isVA ? 'VA' : '')
  msg.setItem('recaptcha', recaptcha)

  const response = await new MessageSender().doPost(msg)

  return response
}

export const withdrawInquiryRemittance = (
  countrydestination,
  nominal,
  nominaldestination,
  accountNumber,
  city,
  address,
  idnumberreceiver,
  swiftCode,
  anrekening,
  password,
  currency,
  bankCode,
  purposetransaction,
  phoneNumberReceiver,
  ifsCode,
  nationality
) => async () => {
  const msg = new BaseMessage()
  msg.setPath(`transaction/cashout_inquiry_remittance`)
  msg.setItem('country_destination', countrydestination)
  msg.setItem('nominal', nominal.replace(/[^0-9]/g, ''))
  msg.setItem('nominal_destination', nominaldestination)
  msg.setItem('account_number', accountNumber)
  msg.setItem('city', city)
  msg.setItem('address', address)
  msg.setItem('id_number_receiver', idnumberreceiver)
  msg.setItem('swift_code', swiftCode)
  msg.setItem('anrekening', anrekening)
  msg.setItem('password', password)
  msg.setItem('currency', currency)
  msg.setItem('kodebanktujuan', bankCode)
  msg.setItem('purposetransaction', purposetransaction)
  msg.setItem('phonenumberreceiver', phoneNumberReceiver)
  msg.setItem('ifscode', ifsCode)
  msg.setItem('nationality', nationality)

  const response = await new MessageSender().doPost(msg)

  return response
}

export const withdrawPayment = (
  bankNumber,
  nominal,
  idtransaksi,
  checksum,
  uuidUser,
  pin,
  bankCode
) => async () => {
  const msg = new BaseMessage()
  msg.setPath(`transaction/cashout_payment`)
  msg.setItem('checksum', checksum)
  msg.setItem('number', bankNumber)
  msg.setItem('pin', pin)
  msg.setItem('nominal', nominal)
  msg.setItem('reff_id', idtransaksi)
  msg.setItem('uuid_user', uuidUser)
  msg.setItem('bank_code', bankCode)

  const response = await new MessageSender().doPost(msg)

  return response
}

export const checkStatus = (uuidUser, checksum, idtransaksi) => async () => {
  const msg = new BaseMessage()
  msg.setPath(`transaction/status`)
  msg.setItem('checksum', checksum)
  msg.setItem('reff_id', idtransaksi)
  msg.setItem('uuid_user', uuidUser)

  const response = await new MessageSender().doGet(msg)

  return response
}

export const getDetail = (uuidUser, checksum, kodeproduk, reffid) => async () => {
  const msg = new BaseMessage()
  msg.setPath(`transaction/detail`)
  msg.setItem('checksum', checksum)
  msg.setItem('reff_id', reffid)
  msg.setItem('id_product', kodeproduk)
  msg.setItem('uuid_user', uuidUser)

  const response = await new MessageSender().doGet(msg)

  return response
}

export const topup = (username, email, nominal, kodeBank, pin, recaptcha) => async () => {
  const msg = new BaseMessage()
  msg.setPath('transaction/topup')
  msg.setItem('username', username)
  msg.setItem('email', email)
  msg.setItem('nominal', nominal)
  msg.setItem('kodeBank', kodeBank)
  msg.setItem('pin', pin)
  msg.setItem('brand', process.env.REACT_APP_BRAND_NAME.toLowerCase())
  msg.setItem('recaptcha', recaptcha)

  const response = await new MessageSender().doPost(msg)

  return response
}

export const createOTP = (pass, nominal) => async () => {
  const msg = new BaseMessage()

  msg.setPath('transaction/create_otp')
  msg.setItem('password', pass)
  msg.setItem('nominal_transfer', nominal)

  const response = await new MessageSender().doPost(msg)

  return response
}

export const ewalletInquiry = (
  produk,
  number,
  nominal,
  password,
  note,
  isVA,
  recaptcha
) => async () => {
  const msg = new BaseMessage()

  msg.setPath('transaction/ewallet_inquiry')
  msg.setItem('produk', produk)
  msg.setItem('number', number)
  msg.setItem('nominal', nominal)
  msg.setItem('password', password)
  msg.setItem('note', note)
  msg.setItem('jenis_rekening', isVA ? 'VA' : '')
  msg.setItem('recaptcha', recaptcha)

  const response = await new MessageSender().doPost(msg)

  return response
}

export const withdrawPaymentRemittance = (
  accountNumber,
  nominal,
  idtransaksi,
  checksum,
  uuidUser,
  pin
) => async () => {
  const msg = new BaseMessage()
  msg.setPath(`transaction/cashout_payment_remittance`)
  msg.setItem('checksum', checksum)
  msg.setItem('number', accountNumber)
  msg.setItem('pin', pin)
  msg.setItem('nominal', nominal.replace(/[^0-9]/g, ''))
  msg.setItem('reff_id', idtransaksi)
  msg.setItem('uuid_user', uuidUser)

  const response = await new MessageSender().doPost(msg)

  return response
}

export const ewalletPayment = (
  bankNumber,
  nominal,
  idtransaksi,
  checksum,
  uuidUser,
  pin,
  bankCode
) => async () => {
  const msg = new BaseMessage()
  msg.setPath(`transaction/ewallet_payment`)
  msg.setItem('checksum', checksum)
  msg.setItem('number', bankNumber)
  msg.setItem('pin', pin)
  msg.setItem('nominal', nominal)
  msg.setItem('reff_id', idtransaksi)
  msg.setItem('uuid_user', uuidUser)
  msg.setItem('bank_code', bankCode)

  const response = await new MessageSender().doPost(msg)

  return response
}

export const getEwalletList = () => async () => {
  const msg = new BaseMessage()

  msg.setPath('data/ewallet_list_web')

  const response = await new MessageSender().doGet(msg)

  return response.getResultAsList()
}

export const getKodeProduk = () => async () => {
  const msg = new BaseMessage()

  msg.setPath('data/kode_produk')

  const response = await new MessageSender().doGet(msg)

  return response.getResultAsList()
}

export const danaTalangan = (username, datefrom, dateto, kodeproduk, recaptcha) => async () => {
  const msg = new BaseMessage()
  msg.setPath('data/create_talangan')
  msg.setItem('username', username)
  msg.setItem('date_from', datefrom)
  msg.setItem('date_to', dateto)
  msg.setItem('kode_produk', kodeproduk)
  msg.setItem('recaptcha', recaptcha)

  const response = await new MessageSender().doPost(msg)

  return response
}

export const danaTalanganNext = (username, datefrom, dateto, kodeproduk, recaptcha) => async () => {
  const msg = new BaseMessage()
  msg.setPath('data/create_talangan_next')
  msg.setItem('username', username)
  msg.setItem('date_from', datefrom)
  msg.setItem('date_to', dateto)
  msg.setItem('kode_produk', kodeproduk)
  msg.setItem('recaptcha', recaptcha)

  const response = await new MessageSender().doPost(msg)

  return response
}

export const getTransactionStore = (email, offset, limit) => async () => {
  const msg = new BaseMessage()
  msg.setItem('email', email)
  msg.setItem('offset', offset)
  msg.setItem('limit', limit)

  msg.setPath('data/transaction_destination_stored_mobile')

  const response = await new MessageSender().doGet(msg)

  return response.getResultAsList()
}

export const getRetailList = () => async () => {
  const msg = new BaseMessage()

  msg.setPath('data/retail_list_web')

  const response = await new MessageSender().doGet(msg)

  return response.getResultAsList()
}

export const confirmOrder = (username, idOrder) => async () => {
  const msg = new BaseMessage()

  msg.setPath('transaction/confirm_order')
  msg.setItem('username', username)
  msg.setItem('id_order', idOrder.toString())

  const response = await new MessageSender().doPost(msg)

  return response
}

export const listVoucher = () => async () => {
  const msg = new BaseMessage()

  msg.setPath('transaction/list_voucher')

  const response = await new MessageSender().doGet(msg)

  return response
}
