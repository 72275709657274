import authConstant from '../constant/auth'

const initialState = {
  [authConstant.USER_ID]: '',
  [authConstant.USER_NAME]: '',
  [authConstant.USER_EMAIL]: '',
  [authConstant.USER_PASS]: '',
  [authConstant.USER_GROUP]: '',
  [authConstant.AGENT_ID]: '',
  [authConstant.AGENT_NAME]: '',
  [authConstant.AGENT_USER]: '',
  [authConstant.AGENT_GROUP]: '',
  [authConstant.ENABLE_API]: '',
  [authConstant.USER_BALANCE]: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case authConstant.USER_ID:
    case authConstant.USER_NAME:
    case authConstant.USER_EMAIL:
    case authConstant.USER_PASS:
    case authConstant.USER_GROUP:
    case authConstant.AGENT_ID:
    case authConstant.AGENT_NAME:
    case authConstant.AGENT_USER:
    case authConstant.AGENT_GROUP:
    case authConstant.USER_BALANCE:
      return {
        ...state,
        [action.type]: action.payload,
      }
    case authConstant.USER_HOLDBALANCE:
      return {
        ...state,
        [action.type]: action.payload,
      }
    case authConstant.ENABLE_API:
      return {
        ...state,
        [action.type]: action.payload,
      }
    case authConstant.USER:
      return {
        ...state,
        ...action.payload,
      }
    case authConstant.CLEAR:
      return initialState
    default:
      return state
  }
}
