/* eslint-disable react/prop-types */
import React from 'react'
import { Modal, Button, Image, Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import icons from '../../helper/icons'
import iconName from '../../helper/constant/iconName'
import './alert.scss'

class AlertDialog extends React.PureComponent {
  render() {
    const {
      onHide,
      onConfirm,
      type,
      show,
      showCancel,
      messageTitle,
      messageSubtitle,
      addedClass,
      children,
    } = this.props

    return (
      <Modal
        show={show}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
        centered
        contentClassName={addedClass}
      >
        <Modal.Body className="text-center modal-alert">
          {type && (
            <div className={`image-type ${type === 'success' ? `image-type-success` : ``}`}>
              {type === 'warning' ? (
                <Image src={icons(iconName.warningDialog)} className="image-alert-dialog-warning" />
              ) : (
                <></>
              )}
              {type === 'success' ? (
                <Image src={icons(iconName.successDialog)} className="image-alert-dialog-success" />
              ) : (
                <></>
              )}
            </div>
          )}
          <div className="message-dialog">
            <div className="message-dialog-title fontSize-21">{messageTitle}</div>
            <div className="message-dialog-subtitle fontSize-16">{messageSubtitle}</div>
          </div>
          {children}
          <Row className="mt-3">
            {showCancel && (
              <Col>
                <Button variant="secondary" onClick={onHide} className="fontSize-17 btn-custom">
                  Batal
                </Button>
              </Col>
            )}
            <Col className={`${!showCancel ? `text-center` : ``}`}>
              <Button
                variant="primary"
                onClick={onConfirm}
                className={`fontSize-17 ${
                  !showCancel
                    ? `clear-margin btn-custom btn-alert text-center`
                    : `btn-custom btn-alert`
                }`}
              >
                OK
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    )
  }
}

AlertDialog.defaultProps = {
  onHide: null,
  onConfirm: null,
  show: false,
  type: '',
  showCancel: false,
  children: null,
  messageTitle: '',
  messageSubtitle: '',
}

AlertDialog.propTypes = {
  onHide: PropTypes.func,
  onConfirm: PropTypes.func,
  show: PropTypes.bool,
  type: PropTypes.string,
  showCancel: PropTypes.bool,
  children: PropTypes.element,
  messageTitle: PropTypes.string,
  messageSubtitle: PropTypes.string,
}

export default AlertDialog
