import MessageSender from '../sender'
import BaseMessage from '../message'

export default (idTrx, date) => async () => {
  const msg = new BaseMessage()

  msg.setPath('/invoice/invoice_trx')
  msg.setItem('brand', process.env.REACT_APP_BRAND_NAME.toLowerCase())
  msg.setItem('id_transaksi', idTrx)
  msg.setItem('date', date)

  const response = await new MessageSender().doGet(msg)

  return response
}
