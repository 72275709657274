/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-undef */
import React from 'react'
import { connect } from 'react-redux'
import { Container, Row, Col, Spinner, Modal, Button, Form, Alert, Image } from 'react-bootstrap'
import PropTypes from 'prop-types'

import './account.scss'
import BaseScreen from '../base'
import globalConstant from '../../store/constant/global'
import authConstant from '../../store/constant/auth'
import { getAccounts, hapusAccount, postAccount, updateAccount } from '../../api/action/dataAccount'
import AlertDialog from '../dialog/alert'
import roleAccess from '../../store/constant/roleAccess'
import checkAccess from '../../helper/access'
import EditUserAccess from './EditUserAccess'
import getDataModulAccess from '../../api/action/getDataModulAccess'
import translate from '../../helper/translate'
import AccessDenied from '../component/AccessDenied'
import {
  buttonResource,
  headerPageResource,
  placeholderResource,
  titleSentence,
} from '../../helper/translatePage/languageResource'
import icons from '../../helper/icons'
import iconName from '../../helper/constant/iconName'
import getCsrf from '../../api/action/getCsrf'
import logout from '../../api/action/logout'

class AccountManagementScreen extends BaseScreen {
  constructor(props) {
    super(props, ['page--account'])
    this.state = {
      isOpen: false,
      userId: '',
      loading: false,
      userEmail: '',
      userName: '',
      userPassword: '',
      userPassword2: '',
      newPassword: '',
      newPassword2: '',
      passwordErr: false,
      sending: false,
      accounts: [],
      accountPassword: '',
      // eslint-disable-next-line react/no-unused-state
      alertError: '',
      // eslint-disable-next-line react/no-unused-state
      alertConfirm: '',
      balanceFresh: false,
      modalType: '',
      modalAdd: false,
      modalEdit: false,
      modalHapus: false,
      loadingData: false,
      accessEdit: false,
      pageTitle: '',
      modulAccess: [],
      idUser: '',
    }

    this.handleChange = this.handleChange.bind(this)
  }

  toggleSidebar = () => {
    const { isOpen } = this.state

    this.setState({ isOpen: !isOpen })
  }

  getData = async () => {
    this.setState({ loading: true })
    const { dispatch, appUserId } = this.props

    const response = await dispatch(getAccounts(appUserId))
    this.setState({ accounts: response, loading: false, balanceFresh: true })
  }

  getDataAccount = async (userId) => {
    this.setState({ loadingData: true })
    const { accounts } = this.state
    for (let i = 0; i < accounts.length; i += 1) {
      if (accounts[i].id === userId) {
        this.setState({
          userEmail: accounts[i].username,
          userName: accounts[i].nama,
          userId: accounts[i].id,
        })
      }
    }
    this.setState({ loadingData: false })
  }

  handleClose = () => {
    this.setState({ modalHapus: false })

  }

  handleModal = (type, idAccount = '') => {
    if (idAccount) {
      this.getDataAccount(idAccount)
    }

    this.setState({ [type]: !this.state[type] })
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value })
    if (event.target.name === 'userPassword2') {
      if (this.state.userPassword !== event.target.value) {
        this.setState({ passwordErr: true, sending: true })
      } else {
        this.setState({ passwordErr: false, sending: false })
      }
    }

    if (event.target.name === 'newPassword2') {
      if (this.state.newPassword !== event.target.value) {
        this.setState({ passwordErr: true, sending: true })
      } else {
        this.setState({ passwordErr: false, sending: false })
      }
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault()
    this.setState({ sending: true })
    const { userEmail, userName, userPassword, userPassword2, accountPassword } = this.state
    if (userPassword !== userPassword2) {
      this.setState({ passwordErr: true, sending: false })
    } else {
      const { dispatch, appUserData } = this.props
      let response = await dispatch(getCsrf())
      if (response.isOK()) {
        response = await dispatch(
          postAccount(appUserData.id, userEmail, userPassword, userName, accountPassword)
        )
        if (response.getRC() === '00') {
          this.setState({
            userName: '',
            userEmail: '',
            userPassword: '',
            userPassword2: '',
            accountPassword: '',
            sending: false,
            modalAdd: false,
          })
          this.getData()
          this.showError('Account management successfully registered')
        } else {
          this.setState({ sending: false })
          this.showError(response.getRD())
        }
      } else {
        dispatch(logout())
      }
    }
  }

  handleEdit = async (event) => {
    event.preventDefault()
    this.setState({ sending: true })
    const { userEmail, userName, userId, newPassword, newPassword2, accountPassword } = this.state
    if (newPassword !== newPassword2) {
      this.setState({ passwordErr: true, sending: false })
    } else {
      const { dispatch } = this.props
      let response = await dispatch(getCsrf())
      if (response.isOK()) {
        response = await dispatch(
          updateAccount(userId, userEmail, userName, newPassword, accountPassword)
        )

        if (response.getRC() === '00') {
          this.showError('Account management successfully updated')
          this.setState({
            userName: '',
            userEmail: '',
            userPassword: '',
            newPassword: '',
            newPassword2: '',
            accountPassword: '',
            sending: false,
            modalEdit: false,
            modalHapus: false,
            userId: '',
          })
          this.getData()
        } else {
          this.showError(response.getRD())
          this.setState({ sending: false })
        }
      } else {
        dispatch(logout())
      }
    }
  }

  handleHapus = async (event) => {
    event.preventDefault()
    this.setState({ sending: true })
    const {userId} = this.state
      const { dispatch } = this.props
      let response = await dispatch(getCsrf())
      if (response.isOK()) {
        response = await dispatch(
          hapusAccount(userId)
        )

        if (response.getRC() === '00') {
          this.showError('Account management successfully delete')
          this.setState({
            sending: false,
            modalHapus: false,
            userId: '',
          })
          this.getData()
        } else {
          this.showError(response.getRD())
          this.setState({ sending: false })
        }
      } else {
        dispatch(logout())
      }
    
  }

  showError = (alertError) => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ alertError })
  }

  closeError = () => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ alertError: '' })
  }

  getModulAccess = async (idUser) => {
    const { dispatch, appUserGroup } = this.props
    const res = await dispatch(getDataModulAccess(appUserGroup))

    if (res.data.rc != '00') {
      this.setState({ alertError: res.data.rd, accessEdit: false })
    } else {
      this.setState({ modulAccess: res.data.data, accessEdit: true, idUser: idUser })
    }
  }

  componentDidMount() {
    super.componentDidMount()

    this.getData()
  }

  readAccess = () => {
    return checkAccess(this.props.appUserAccess, 'account_management', 'READ')
  }

  createAccess = () => {
    return checkAccess(this.props.appUserAccess, 'account_management', 'ADD')
  }

  updateAccess = () => {
    return checkAccess(this.props.appUserAccess, 'account_management', 'UPDATE')
  }

  changeContent = (typeContent, idUser = '') => {
    switch (typeContent) {
      case 'account-management':
        this.setState({ accessEdit: false })
        break
      case 'edit-user-access':
        this.setState({ accessEdit: true, idUser: idUser })
        break

      default:
        this.setState({ accessEdit: false })
        break
    }
  }

  render() {
    const {
      loading,
      loadingData,
      userEmail,
      userPassword,
      userPassword2,
      userName,
      sending,
      accounts,
      alertError,
      modalAdd,
      modalEdit,
      modalHapus,
      passwordErr,
      accountPassword,
      newPassword,
      newPassword2,
    } = this.state
    const isParent = this.props.appUserData?.id_user_parent == null
    const isPersonal = this.props.appUserType === 'Personal END USER'
    return (
      <>
        {/* eslint-disable-next-line */}
        <AlertDialog
          show={!!alertError}
          onHide={this.closeError}
          onConfirm={this.closeError}
          title="Information"
        >
          {alertError}
        </AlertDialog>
        <Container fluid className={this.state.accessEdit ? 'p-3' : 'p-3 container-management'}>
          {!this.readAccess() ? (
            <AccessDenied />
          ) : isParent && !isPersonal ? (
            <>
              {loading ? (
                <>
                  <Spinner size="xs" className="mr-1" animation="border" variant="primary" />
                </>
              ) : this.state.accessEdit ? (
                <EditUserAccess
                  appUserGroup={this.props.appUserGroup}
                  backPage={this.changeContent}
                  appIdUser={this.state.idUser}
                />
              ) : (
                <>
                  {this.props.environment === 'sandbox' ? (
                    <Alert variant="warning" className="mb-3">
                      {translate('sandboxMode', this.props.language, titleSentence)}
                    </Alert>
                  ) : (
                    <Row>
                      <Col sm={12} className="mb-4">
                        {this.createAccess() && (
                          <div className="button-add" onClick={() => this.handleModal('modalAdd')}>
                            <Image src={icons(iconName.plus)} className="" />
                          </div>
                        )}
                        <Modal
                          className="custom-modal"
                          show={modalAdd}
                          onHide={() => this.handleModal('modalAdd')}
                        >
                          <Modal.Header closeButton className="header-custom align-items-center">
                            <Modal.Title className="fontSize-16-Bold">
                              {translate('addAcc', this.props.language, buttonResource)}
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <div className="alert-info-custom">
                              <Image src={icons(iconName.alert)} className="" />
                              <div className="color-pink-dark fontSize-16 d-inline">
                                {translate('neverShareMail', this.props.language, titleSentence)}
                              </div>
                            </div>
                            <Form onSubmit={this.handleSubmit} className="form-modal ">
                              <Form.Group controlId="formBasicEmail">
                                <Form.Label className="fontSize-16-Medium">
                                  {translate('accName', this.props.language, titleSentence)}
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder={translate(
                                    'inputName',
                                    this.props.language,
                                    placeholderResource
                                  )}
                                  name="userName"
                                  value={userName}
                                  onChange={this.handleChange}
                                  required
                                  className="custom-input fontSize-16"
                                />
                              </Form.Group>

                              <Form.Group controlId="formBasicEmail">
                                <Form.Label className="fontSize-16-Medium">Email</Form.Label>
                                <Form.Control
                                  type="email"
                                  placeholder={translate(
                                    'inputMail',
                                    this.props.language,
                                    placeholderResource
                                  )}
                                  name="userEmail"
                                  value={userEmail}
                                  onChange={this.handleChange}
                                  required
                                  className="custom-input fontSize-16"
                                />
                              </Form.Group>

                              <Form.Group controlId="formBasicPassword">
                                <Form.Label className="fontSize-16-Medium">
                                  {translate('accPass', this.props.language, titleSentence)}
                                </Form.Label>
                                <Form.Control
                                  type="password"
                                  placeholder={translate(
                                    'accountPass',
                                    this.props.language,
                                    placeholderResource
                                  )}
                                  name="userPassword"
                                  value={userPassword}
                                  onChange={this.handleChange}
                                  required
                                  className="custom-input fontSize-16"
                                />
                              </Form.Group>

                              <Form.Group controlId="formBasicPassword">
                                <Form.Label className="fontSize-16-Medium">
                                  {translate('confirmPass', this.props.language, titleSentence)}
                                </Form.Label>
                                <Form.Control
                                  type="password"
                                  placeholder={translate(
                                    'rewritePass',
                                    this.props.language,
                                    placeholderResource
                                  )}
                                  name="userPassword2"
                                  value={userPassword2}
                                  onChange={this.handleChange}
                                  required
                                  className="custom-input fontSize-16"
                                />
                                <Form.Text className={passwordErr ? 'text-danger' : 'text-muted'}>
                                  {userPassword2 && passwordErr && "confirm password doesn't match"}
                                </Form.Text>
                              </Form.Group>

                              <Form.Group controlId="formBasicPassword">
                                <Form.Label className="fontSize-16-Medium">
                                  {translate('yourPass', this.props.language, titleSentence)}
                                </Form.Label>
                                <Form.Control
                                  type="password"
                                  placeholder={translate(
                                    'inputPass',
                                    this.props.language,
                                    placeholderResource
                                  )}
                                  name="accountPassword"
                                  value={accountPassword}
                                  onChange={this.handleChange}
                                  required
                                  className="custom-input fontSize-16"
                                />
                              </Form.Group>
                              <Button
                                variant="primary"
                                type="submit"
                                className="button-custom-submit fontSize-17"
                                disabled={sending}
                              >
                                <Image src={icons(iconName.plus)} className="mr-3" />
                                {translate('addAcc', this.props.language, buttonResource)}
                              </Button>
                            </Form>
                          </Modal.Body>
                        </Modal>
                      </Col>
                      <Col sm={12}>
                        <div className="container-data-account">
                          {accounts.length > 0 &&
                            accounts.map((account) => (
                              <Row className="data-account">
                                <Col
                                  style={{ wordWrap: 'break-word' }}
                                  sm={12}
                                  md={4}
                                  className=" fontSize-16"
                                >
                                  {account.username}
                                </Col>
                                <Col
                                  style={{ wordWrap: 'break-word' }}
                                  sm={12}
                                  md={4}
                                  className=" fontSize-16"
                                >
                                  {account.nama}
                                </Col>
                                <Col sm={12} md={4} className="text-center">
                                  <div
                                    className="button-option fontSize-14"
                                    onClick={() => this.handleModal('modalEdit', account.id)}
                                  >
                                    {translate('edit', this.props.language, buttonResource)}
                                  </div>
                                  <div
                                    className="button-option fontSize-14"
                                    onClick={() =>
                                      this.changeContent('edit-user-access', account.id)
                                    }
                                  >
                                    {translate('setAccess', this.props.language, buttonResource)}
                                  </div>
                                  <div
                                    className="button-option fontSize-14"
                                    onClick={() => this.handleModal('modalHapus', account.id)}
                                  >
                                    {translate('setDelete', this.props.language, buttonResource)}
                                  </div>
                                </Col>
                              </Row>
                            ))}
                        </div>
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </>
          ) : (
            <AccessDenied />
          )}
        </Container>
        <Modal
          className="custom-modal"
          show={modalEdit}
          onHide={() => this.handleModal('modalEdit')}
        >
          <Modal.Header closeButton className="header-custom">
            <Modal.Title>
              {translate('editAcc', this.props.language, headerPageResource)}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {loadingData ? (
              <>
                <Spinner size="xs" className="mr-1 text-active" animation="border" />
              </>
            ) : (
              <>
                <div className="alert-info-custom">
                  <Image src={icons(iconName.alert)} className="" />
                  <div className="color-pink-dark fontSize-16 d-inline">
                    {translate('neverShareMail', this.props.language, titleSentence)}
                  </div>
                </div>
                <Form onSubmit={this.handleEdit} className="form-modal">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label className="fontSize-16-Medium">Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder={translate('inputMail', this.props.language, placeholderResource)}
                      name="userEmail"
                      value={userEmail}
                      disabled
                      required
                      className="custom-input fontSize-16"
                    />
                  </Form.Group>

                  <Form.Group controlId="formBasicEmail">
                    <Form.Label className="fontSize-16-Medium">
                      {translate('accName', this.props.language, titleSentence)}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={translate('inputName', this.props.language, placeholderResource)}
                      name="userName"
                      value={userName}
                      onChange={this.handleChange}
                      required
                      className="custom-input fontSize-16"
                    />
                  </Form.Group>

                  <Form.Group controlId="formBasicPassword">
                    <Form.Label className="fontSize-16-Medium">
                      {translate('newPass', this.props.language, titleSentence)}
                    </Form.Label>
                    <Form.Control
                      type="password"
                      placeholder={translate(
                        'inputNewPass',
                        this.props.language,
                        placeholderResource
                      )}
                      name="newPassword"
                      value={newPassword}
                      onChange={this.handleChange}
                      className="custom-input fontSize-16"
                    />
                    <Form.Text className={passwordErr ? 'text-danger' : 'text-muted'}>
                      {newPassword && passwordErr && "confirm password doesn't match"}
                    </Form.Text>
                  </Form.Group>

                  <Form.Group controlId="formBasicPassword">
                    <Form.Label className="fontSize-16-Medium">
                      {translate('confNewPass', this.props.language, titleSentence)}
                    </Form.Label>
                    <Form.Control
                      type="password"
                      placeholder={translate(
                        'rewriteNewPass',
                        this.props.language,
                        placeholderResource
                      )}
                      name="newPassword2"
                      value={newPassword2}
                      onChange={this.handleChange}
                      className="custom-input fontSize-16"
                    />
                    <Form.Text className={passwordErr ? 'text-danger' : 'text-muted'}>
                      {newPassword2 && passwordErr && "confirm password doesn't match"}
                    </Form.Text>
                  </Form.Group>

                  <Form.Group controlId="formBasicPassword">
                    <Form.Label className="fontSize-16-Medium">
                      {translate('yourPass', this.props.language, titleSentence)}
                    </Form.Label>
                    <Form.Control
                      type="password"
                      placeholder={translate('inputPass', this.props.language, placeholderResource)}
                      name="accountPassword"
                      value={accountPassword}
                      onChange={this.handleChange}
                      required
                      className="custom-input fontSize-16"
                    />
                  </Form.Group>

                  <Button
                    variant="primary"
                    type="submit"
                    disabled={sending}
                    className="button-custom-submit fontSize-17"
                  >
                    <Image src={icons(iconName.iconSaveWhite)} className="mr-3" />
                    {translate('save', this.props.language, buttonResource)}
                  </Button>
                </Form>
              </>
            )}
          </Modal.Body>
        </Modal>
        <Modal
          className="custom-modal"
          show={modalHapus}
          onHide={() => this.handleModal('modalHapus')}
        >
          
          <Modal.Body>
            <>
              <Form onSubmit={this.handleHapus} className="form-modal">
              <h1>{translate('hapusAcc', this.props.language, headerPageResource)}</h1>
              <p>{translate('hapusAccKonten', this.props.language, headerPageResource)}</p>
              <Button 
              variant="secondary" 
              onClick={this.handleClose} 
              className="button fontSize-17"
              >
                {translate('cancel', this.props.language, buttonResource)}
              </Button>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={sending}
                  className="button fontSize-17"
                >
                  {translate('setDelete', this.props.language, buttonResource)}
                </Button>
              </Form>
            </>
          </Modal.Body>
        </Modal>
      </>
    )
  }
}

AccountManagementScreen.defaultProps = {
  appIsLoading: false,
  appUserId: '',
  appUserName: '',
  appAgentName: '',
  appUserGroup: '',
  appUserData: '',
}

AccountManagementScreen.propTypes = {
  appIsLoading: PropTypes.bool,
  appUserId: PropTypes.string,
  appUserName: PropTypes.string,
  appAgentName: PropTypes.string,
  appUserGroup: PropTypes.string,
  appUserData: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
    appUserId: state.Auth[authConstant.USER_ID],
    appUserName: state.Auth[authConstant.USER_NAME],
    appAgentName: state.Auth[authConstant.AGENT_NAME],
    appUserGroup: state.Auth[authConstant.USER_GROUP],
    appUserData: state.Auth[authConstant.USER_DATA],
    appUserType: state.Auth[authConstant.USER_TYPE],
    appUserAccess: state.RoleAccess[roleAccess.ACCESS],
    environment: state.Global[globalConstant.ENVIRONMENT],
    language: state.Global[globalConstant.LANGUAGE],
  }),
  (dispatch) => ({ dispatch })
)(AccountManagementScreen)
