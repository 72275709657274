import { createStore, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import { composeWithDevTools } from 'redux-devtools-extension'
import Logger from '../helper/logger'
import reducers from './reducers'

const persistConfig = {
  key: 'root',
  storage,
  timeout: null,
}

const persistedReducer = persistReducer(persistConfig, reducers)

let xstore
if (Logger.IS_DEEP_TRACE) {
  // using redux-devtools-extension
  xstore = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk, logger)))
} else {
  xstore = createStore(persistedReducer, applyMiddleware(thunk))
}

const store = xstore

export const persistor = persistStore(store)

export default store
