import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import './dashboard.scss'
import globalConstant from '../store/constant/global'
import authConstant from '../store/constant/auth'
import Dashboard from './dashboard'
import LandingScreen from './landing'

function DashboardScreen({ appStatusReg, appUserGroup }) {
  const isUserRole = appStatusReg
  if (appUserGroup === '6') {
    return (
      <>{isUserRole.status ? <Dashboard /> : <LandingScreen token={isUserRole.auth_token} />}</>
    )
  }
  return (
    <>
      <Dashboard />
    </>
  )
}

DashboardScreen.defaultProps = {
  appStatusReg: '',
  appUserGroup: '',
}

DashboardScreen.propTypes = {
  appStatusReg: PropTypes.string,
  appUserGroup: PropTypes.string,
}

export default connect((state) => ({
  appIsLoading: state.Global[globalConstant.IS_LOADING],
  appStatusReg: state.Auth[authConstant.STATUS_REG],
  appUserGroup: state.Auth[authConstant.USER_GROUP],
}))(DashboardScreen)
