/* eslint-disable no-unused-vars */
import React from 'react'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import translate from '../../../helper/translate'
import Formatter from '../../../helper/formatter'
import { columnResource } from '../../../helper/translatePage/languageResource'
import dataType from '../../../helper/constant/dataType'

const DisbursementColumn = (actionFunction, languange) => ({
  key: 'id',
  columns: [
    {
      name: translate('groupName', languange, columnResource),
      selector: 'group_name',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('groupDesc', languange, columnResource),
      selector: 'group_desc',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('createDate', languange, columnResource),
      selector: 'created_date',
      sortable: true,
      type: dataType.DATERANGE,
      default: true,
      showFilter: true,
    },
    {
      name: translate('trxCount', languange, columnResource),
      selector: 'detail_count',
      sortable: true,
      cell: (row) => Formatter.price(row.detail_count),
      type: dataType.INTEGER,
      showFilter: true,
    },
    {
      name: translate('action', languange, columnResource),
      cell: (row) => {
        return (
          <div style={{ flexDirection: 'column' }}>
            <div className="mb-1">
              <Button
                size="sm"
                variant="outline-info"
                className="mr-1"
                data-action="disbursement_detail"
                onClick={(e) => actionFunction(row, e)}
              >
                <FontAwesomeIcon fixedWidth icon="list-ul" />
              </Button>
            </div>
          </div>
        )
      },
      showFilter: false,
    },
  ],
})

export default DisbursementColumn
