import MessageSender from '../sender'
import BaseMessage from '../message'

// eslint-disable-next-line no-unused-vars
export default (userId) => async () => {
  const msg = new BaseMessage()
  msg.setPath('data/user_list_va')
  msg.setItem('userId', userId)

  const response = await new MessageSender().doGet(msg)

  return response
}
