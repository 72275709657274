/* eslint-disable no-unused-vars */
import React from 'react'
import { Badge } from 'react-bootstrap'
import dataType from '../../../helper/constant/dataType'

import Formatter from '../../../helper/formatter'
import translate from '../../../helper/translate'
import { columnResource } from '../../../helper/translatePage/languageResource'

const VirtualAccountOpenColumn = (action, lang) => ({
  key: 'id',
  columns: [
    {
      name: 'Username',
      selector: 'customer_id',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('name', lang, columnResource),
      selector: 'nama_pemilik',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('vaNumber', lang, columnResource),
      selector: 'va_number',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('vaOwnerName', lang, columnResource),
      selector: 'nama_pemilik_va',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: 'Partner Reff',
      selector: 'partner_reff_id',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      cell: (row) => {
        let word
        let color
        switch (row.status) {
          case 0:
            word = translate('notActive', lang, columnResource)
            color = 'danger'
            break
          case 1:
            word = translate('active', lang, columnResource)
            color = 'success'
            break
          default:
            word = 'Unknown'
            color = 'secondary'
            break
        }
        return <Badge variant={color}>{word}</Badge>
      },
      type: dataType.ENUM,
      showFilter: true,
      default_value: [
        {
          id: 0,
          name: translate('notActive', lang, columnResource),
        },
        {
          id: 1,
          name: translate('active', lang, columnResource),
        },
      ],
    },
  ],
})

export default VirtualAccountOpenColumn
