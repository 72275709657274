const getModuleAccess = (accessStr) => {
  if (accessStr === undefined) {
    return ''
  }
  const accessArr = accessStr.split('|')

  const filterAccess = accessArr.filter((access) => access !== '')

  return filterAccess
}

export default getModuleAccess
