/* eslint-disable react/no-unescaped-entities */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/self-closing-comp */
/* eslint-disable prettier/prettier */
import React, { useEffect, useState, useRef } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { Button, Col, Form, Image, Modal, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import checkOTP from '../../api/action/checkOTP'
import icons from '../../helper/icons'
import iconName from '../../helper/constant/iconName'

function OtpModal({ show, onHide, parentFunc, dispatch, errMsg, email, idOtp, btnDisable, resetOtp, resetOtpFunc, type }) {
  const [initState, changeState] = useState({
    otpCode: '',
    emailDestination: '',
    showCode: false,
    char: false,
    required: false,
    captchaRequired: false,
    captchaRef: useRef().current,
    captcha: '',
  })

  const [checked, setChecked] = React.useState(false);

  const handleChangeCheckbox = () => {
    setChecked(!checked);
  };


  const handleChange = (e) => {
    const code = e.target.value
    changeState({
      ...initState,
      otpCode: code,
    })

    if (code.length < 6 || code.length > 6) {
      changeState({ ...initState, char: true, otpCode: code, required: false })
    } else {
      changeState({ ...initState, char: false, otpCode: code, required: false })
    }
  }

  const handleChangeEmail = (e) => {
    const emailDestination = e.target.value
    changeState({
      ...initState,
      emailDestination: emailDestination,
    })
  }

  const resetingOtp = () => {
    if (resetOtp) {
      changeState({...initState, otpCode: ''})
      resetOtpFunc()
      initState.captchaRef.reset()
    }
  }

  useEffect(() => {
    resetingOtp()
  }, [resetingOtp])
  
  const onConfirm = async () => {
    if (initState.otpCode.length === 0) {
      changeState({ ...initState, required: true, captchaRequired: false })
    } else if (initState.captcha == '') {
      changeState({ ...initState, required: false, captchaRequired: true })
    } else {
      changeState({ ...initState, required: false, captchaRequired: false })
      parentFunc(initState.otpCode, initState.captcha, initState.emailDestination)
      initState.captchaRef.reset()
    }
  }

  const handleCaptcha = (value) => {
    changeState({ ...initState, captcha: value })
  }

  return (
    <>
      <Modal show={show} onHide={onHide} backdrop="static" centered contentClassName="medium-height">
        <Modal.Body className="text-center modal-callback-otp">
          <div className="image-type">
            <Image src={icons(iconName.warningDialog)} className="image-alert-dialog-warning" />
          </div>
          <Form.Text id="otpHelpBlock" className="mb-4 text-center fontSize-16">
            Sebelum proses dapat dilanjutkan, silakan cek email <strong>"[LINKQU] OTP CODE"</strong> yang baru saja dikirim ke <strong>{email}</strong>, dan inputkan Kode OTP-nya. Jangan lupa untuk cek juga di folder Spam.
          </Form.Text>
          <Form.Group>
            <Form.Control
              type="number"
              placeholder="Masukkan Kode OTP"
              name="otpCode"
              value={initState.otpCode}
              onChange={(e) => handleChange(e)}
              minLength={6}
              required
              className="custom-input fontSize-16"
            />
            <Form.Text className={`${initState.char ? 'text-danger' : 'text-muted'} fontSize-16`}>
              {initState.otpCode && initState.char && 'OTP Code must be contain 6 characters'}
            </Form.Text>
            <Form.Text className={initState.required ? 'text-danger' : 'text-muted'}>
              {initState.required && 'Please input your OTP Code'}
            </Form.Text>
          </Form.Group>
          {!type ? (<></>) : (<>
            <Form.Group>
              <Form.Check
                inline
                name="group1"
                type="checkbox"
                id="check"
                onChange={handleChangeCheckbox}
                checked={checked}
              />
              <Form.Label htmlFor="check" className="fontSize-16">Ubah email tujuan</Form.Label>
            </Form.Group>
            {checked && (<Form.Group>
            <Form.Label className="fontSize-16">Masukkan Email Tujuan</Form.Label>
            <Form.Control
              placeholder="example:tes@gmail.com,tes1@gmail.com"
              name="email_destination"
              onChange={(e) => handleChangeEmail(e)}
              minLength={6}
              required={checked}
              className="custom-input fontSize-16"
            />
            <Form.Text className={initState.required ? 'text-danger' : 'text-muted'}>
              {initState.required && 'Please input your email destination'}
            </Form.Text>
          </Form.Group>)}
          </>)}
          <Form.Group>
            <ReCAPTCHA
              ref={(ref) => {
                initState.captchaRef = ref
              }}
              className="captcha mt-3 text-center centered"
              sitekey={process.env.REACT_APP_GRECAPTCHA_KEY}
              onChange={handleCaptcha}
            />
          </Form.Group>
          <Form.Text className={initState.captchaRequired ? 'text-danger' : 'text-muted'}>
            {initState.captchaRequired && 'Please input Recaptcha'}
          </Form.Text>
          <Row>
            <Col>
              <Button variant="secondary" onClick={onHide} disabled={btnDisable} className="fontSize-17 btn-custom clear-margin">
                Batal
              </Button>
            </Col>
            <Col>
              <Button
                variant="primary"
                onClick={onConfirm}
                className="fontSize-17 btn-custom btn-alert clear-margin"
                disabled={btnDisable}
              >
                OK
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default connect(
  (state) => ({ state }),
  (dispatch) => ({ dispatch })
)(OtpModal)
