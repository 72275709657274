/* eslint-disable camelcase */
import MessageSender from '../sender'
import BaseMessage from '../message'

export default (email, captcha) => async () => {
  const msg = new BaseMessage()

  msg.setPath('/auth/reset_password')
  msg.setItem('email', email)
  msg.setItem('captcha', captcha)

  const response = await new MessageSender().doPost(msg)

  return response
}
