import axios from 'axios'
import qs from 'qs'

import Logger from '../helper/logger'
import store from '../store'
import globalConstant from '../store/constant/global'
import authConstant from '../store/constant/auth'
import BaseMessage from './message'
import logout from './action/logout'
import Browser from '../helper/browser'

export default class MessageSender {
  constructor() {
    axios.defaults.validateStatus = () => {
      return true
    }
    this.tag = ''
    this.isShowLoading = true

    let baseURL
    if (window.API_BASE_URL) {
      baseURL = `${window.API_BASE_URL}api/`
    } else if (process.env.REACT_APP_API_URL) {
      baseURL = `${process.env.REACT_APP_API_URL}api/`
    } else {
      baseURL = `${Browser.baseURL()}api/`
    }

    this.instance = axios.create({
      baseURL,
      responseType: 'json',
      timeout: 60000,
    })
    this.instance.interceptors.request.use(this.onRequestSuccess, this.onRequestError)
    this.instance.interceptors.response.use(this.onResponseSuccess, this.onResponseError)
  }

  showLoading(show) {
    this.isShowLoading = show

    return this
  }

  onRequestSuccess = (config) => {
    Logger.log(`REQUEST ${config.method.toUpperCase()} TO`, config.url)
    if (Logger.IS_DEEP_TRACE) {
      Logger.log(`REQUEST HEADER ${this.tag}`, config.headers)
    }

    if (config.method === 'get') {
      Logger.log(
        `REQUEST DATA ${this.tag}`,
        qs.stringify(config.params, { arrayFormat: 'brackets' })
      )
    } else {
      Logger.log(`REQUEST DATA ${this.tag}`, config.data)
    }

    return config
  }

  onRequestError = (error) => {
    Logger.error(`REQUEST ERROR OCCURED ${this.tag}`, error)

    if (this.isShowLoading) {
      store.dispatch({
        type: globalConstant.IS_LOADING,
        payload: false,
      })
    }
    return Promise.reject(error)
  }

  onResponseSuccess = (response) => {
    if (Logger.IS_DEEP_TRACE) {
      Logger.log(`RESPONSE HEADER ${this.tag}`, response.headers)
    }
    Logger.log(`RESPONSE DATA ${this.tag}`, response.data)

    const msg = new BaseMessage(response.data)

    if (this.isShowLoading) {
      store.dispatch({
        type: globalConstant.IS_LOADING,
        payload: false,
      })
    }
    return msg
  }

  onResponseError = (error) => {
    Logger.error(`RESPONSE ERROR OCCURED ${this.tag}`, error)

    const msg = new BaseMessage()
    msg.setRC('GE')
    msg.setRD(error)

    if (this.isShowLoading) {
      store.dispatch({
        type: globalConstant.IS_LOADING,
        payload: false,
      })
    }
    return Promise.resolve(msg)
  }

  async doGet(gmes, loading = true) {
    if (this.isShowLoading && loading) {
      store.dispatch({
        type: globalConstant.IS_LOADING,
        payload: true,
      })
    }
    const msg = gmes

    this.tag = ` OF ${msg.getPath()}`

    const { Auth, Global } = await store.getState()
    const headers = msg.getHeader()
    headers['X-Requested-With'] = 'XMLHttpRequest'
    headers['X-LINKQU-ENV'] = Global[globalConstant.ENVIRONMENT]
    if (MessageSender.checkToken()) {
      headers['X-LINKQU-TOKEN'] = MessageSender.checkToken()
    } else {
      store.dispatch(logout())
    }
    if (Auth[authConstant.USER_PASS]) {
      headers['X-LINKQU-TOKEN'] = Auth[authConstant.USER_PASS]
    }
    const response = MessageSender.normalizeResponse(
      await this.instance.get(msg.getPath(), {
        params: msg.getData(),
        headers,
      })
    )

    if (response.getRC() === '401') {
      store.dispatch(logout())
    }

    return response
  }

  async doPost(msg) {
    if (this.isShowLoading) {
      store.dispatch({
        type: globalConstant.IS_LOADING,
        payload: true,
      })
    }

    this.tag = ` OF ${msg.getPath()}`

    const { Auth, Global } = await store.getState()
    const headers = {
      ...msg.getHeader(),
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-LINKQU-TOKENCSRF': Global[globalConstant.ANTI_CSRF_TOKEN],
    }
    headers['X-LINKQU-ENV'] = Global[globalConstant.ENVIRONMENT]
    if (Auth[authConstant.USER_PASS]) {
      headers['X-LINKQU-TOKEN'] = Auth[authConstant.USER_PASS]
    }

    if (MessageSender.checkToken()) {
      headers['X-LINKQU-TOKEN'] = MessageSender.checkToken()
    } else {
      store.dispatch(logout())
    }
    const data = msg.getData()
    const response = MessageSender.normalizeResponse(
      await this.instance.post(msg.getPath(), data, {
        headers,
      })
    )
    console.log('THE RESPONSE', response)
    if (response.getRC() === '401') {
      store.dispatch(logout())
    }

    return response
  }

  static checkToken = () => {
    let token = document.cookie.match(`(^|;)\\s*__Host-token\\s*=\\s*([^;]+)`)?.pop() || ''
    if (process.env.REACT_APP_ENV === 'development') {
      token = document.cookie.match(`(^|;)\\s*token\\s*=\\s*([^;]+)`)?.pop() || ''
    }

    if (token) {
      return token
    }

    return false
  }

  static normalizeResponse(response) {
    if (response instanceof BaseMessage) {
      return response
    }

    const msg = new BaseMessage()
    msg.setRC('GE')
    msg.setRD('GE')

    return msg
  }
}
