/* eslint-disable no-unused-vars */
import React from 'react'
import { Badge, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import translate from '../../../helper/translate'
import Formatter from '../../../helper/formatter'
import { columnResource } from '../../../helper/translatePage/languageResource'
import dataType from '../../../helper/constant/dataType'

const DanaTalangan = (actionFunction, languange) => ({
  key: 'id',
  columns: [
    {
      name: translate('tglTrx', languange, columnResource),
      selector: 'transaction_date',
      sortable: true,
      type: dataType.DATERANGE,
      showFilter: true,
    },
    {
      name: translate('createDate', languange, columnResource),
      selector: 'created_date',
      sortable: true,
      type: dataType.DATERANGE,
      showFilter: true,
      default: true,
    },
    {
      name: translate('ChargeNominal', languange, columnResource),
      selector: 'charge_nominal',
      sortable: true,
      type: dataType.INTEGER,
      showFilter: true,
    },
    {
      name: translate('ChargePercentage', languange, columnResource),
      selector: 'charge_percentage',
      sortable: true,
      type: dataType.INTEGER,
      showFilter: true,
    },
    {
      name: 'Kode Produk',
      selector: 'kode_produk',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: 'Status',
      selector: 'is_active',
      sortable: true,
      cell: (row) => {
        let word
        let color
        if (row.is_active) {
          word = translate('active', languange, columnResource)
          color = 'success'
        } else {
          word = translate('notActive', languange, columnResource)
          color = 'danger'
        }
        return <Badge variant={color}>{word}</Badge>
      },
      type: dataType.ENUM,
      showFilter: true,
      default_value: [
        {
          id: false,
          name: translate('notActive', languange, columnResource),
        },
        {
          id: true,
          name: translate('active', languange, columnResource),
        },
      ],
    },
    {
      name: translate('action', languange, columnResource),
      cell: (row) => {
        return (
          <div style={{ flexDirection: 'column' }}>
            <div>
              <Button
                size="sm"
                variant="outline-info"
                className="mr-1"
                data-action="hapus"
                onClick={(e) => actionFunction(row, e)}
              >
                <FontAwesomeIcon fixedWidth icon={faPencilAlt} />
              </Button>
            </div>
          </div>
        )
      },
      showFilter: false,
    },
  ],
})

export default DanaTalangan
