/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/react-in-jsx-scope */
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { useSelector, shallowEqual } from 'react-redux'
import global from '../store/constant/global'

export default function RouteWrapper({ component: Component, ...rest }) {
  const isLogin = useSelector((state) => state.Global[global.IS_LOGIN], shallowEqual)
  return (
    <Route
      {...rest}
      render={(props) => (isLogin ? <Component {...props} /> : <Redirect to="/auth/login" />)}
    />
  )
}
RouteWrapper.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.shape({
      type: PropTypes.func,
      WrappedComponent: PropTypes.func,
      displayName: PropTypes.string,
    }),
  ]).isRequired,
}
RouteWrapper.defaultProps = {}
