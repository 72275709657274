/* eslint-disable camelcase */
import MessageSender from '../sender'
import BaseMessage from '../message'

export default (idUser, idUserUpdated, addedNotAllowAccess, deleteNotAllowAccess) => async () => {
  const msg = new BaseMessage()

  msg.setPath('/data/modul_access_user')
  msg.setItem('id_user', idUser)
  msg.setItem('id_user_updated', idUserUpdated)
  msg.setItem('added_not_allow_access', addedNotAllowAccess)
  msg.setItem('delete_not_allow_access', deleteNotAllowAccess)

  const response = await new MessageSender().doPost(msg)

  return response
}
