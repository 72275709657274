import MessageSender from '../sender'
import BaseMessage from '../message'
import global from '../../store/constant/global'

export default () => async (dispatch) => {
  const msg = new BaseMessage()

  msg.setPath('/auth/csrf_token')

  const response = await new MessageSender().doGet(msg)
  if (response.isOK()) {
    await dispatch({
      type: global.ANTI_CSRF_TOKEN,
      payload: response.getCsrf(),
    })
  }
  return response
}
