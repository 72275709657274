/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { Container, Spinner } from 'react-bootstrap'
import './invoice.scss'

import BaseScreen from '../base'
import global from '../../store/constant/global'
import invoice from '../../api/action/invoice'
import AlertDialog from '../dialog/alert'

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />
}

class Invoice extends BaseScreen {
  constructor(props) {
    super(props, ['page--invoice'])

    const { idTrx, date } = props.match.params

    this.state = {
      idTrx,
      date,
      pdf: '',
      alertError: '',
    }
  }

  componentDidMount() {
    super.componentDidMount()

    this.getInvoice()
  }

  getInvoice = async () => {
    try {
      const callInvoice = await this.props.dispatch(invoice(this.state.idTrx, this.state.date))

      if (callInvoice.data.rc !== '00') {
        this.setState({ alertError: callInvoice.data.rd })
      } else {
        this.setState({ pdf: callInvoice.data.data })
      }
    } catch (error) {
      this.setState({ alertError: error })
    }
  }

  closeError = () => {
    this.setState({ alertError: '' })
  }

  loadingContent = () => {
    return (
      <>
        <div className="loading-state d-flex">
          <Spinner size="xl" className="mx-2" animation="grow" variant="primary" />
          <Spinner size="xl" className="mx-2" animation="grow" variant="primary" />
          <Spinner size="xl" className="mx-2" animation="grow" variant="primary" />
        </div>
      </>
    )
  }

  pdfContent = () => {
    return (
      <>
        <embed src={`data:application/pdf;base64,${this.state.pdf}`} width="100%" height="100%" />
      </>
    )
  }

  render() {
    const { alertError } = this.state
    const { appIsLoading } = this.props
    return (
      <>
        <div
          className="pdf-container d-flex justify-content-center align-items-center"
          style={{ width: '100vw', height: '100vh' }}
        >
          {appIsLoading && this.loadingContent()}
          {!appIsLoading && this.pdfContent()}
        </div>
        <AlertDialog
          show={!!alertError}
          onHide={this.closeError}
          onConfirm={this.closeError}
          title="Perhatian"
        >
          {alertError}
        </AlertDialog>
      </>
    )
  }
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[global.IS_LOADING],
  }),
  (dispatch) => ({ dispatch })
)(withParams(Invoice))
