/* eslint-disable no-unused-vars */
import React from 'react'
import { Badge, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Formatter from '../../../helper/formatter'
import translate from '../../../helper/translate'
import { columnResource } from '../../../helper/translatePage/languageResource'
import dataType from '../../../helper/constant/dataType'

const TransactionBulkVaColumn = (actionFunction, languange) => ({
  key: 'id',
  columns: [
    {
      name: 'ID',
      selector: 'id',
      sortable: true,
      type: dataType.INTEGER,
      showFilter: true,
    },
    {
      name: translate('createDate', languange, columnResource),
      selector: 'date_created',
      sortable: true,
      type: dataType.DATERANGE,
      showFilter: false,
      default: true,
    },
    {
      name: translate('finishDate', languange, columnResource),
      selector: 'date_complete',
      sortable: true,
      type: dataType.DATERANGE,
      showFilter: true,
    },
    {
      name: translate('amount', languange, columnResource),
      selector: 'amount',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('partnerReff', languange, columnResource),
      selector: 'partner_reff',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('customerId', languange, columnResource),
      selector: 'customer_id',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('customerName', languange, columnResource),
      selector: 'customer_name',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('customerPhone', languange, columnResource),
      selector: 'customer_phone',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('customerEmail', languange, columnResource),
      selector: 'customer_email',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('vaNumb', languange, columnResource),
      selector: 'nova',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('bankCode', languange, columnResource),
      selector: 'kodebank',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('expDate', languange, columnResource),
      selector: 'expired',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('username', languange, columnResource),
      selector: 'username',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('status', languange, columnResource),
      selector: 'status',
      sortable: true,
      cell: (row) => {
        let word
        let color
        switch (`${row.status}`) {
          case '1':
            word = 'COMPLETED'
            color = 'success'
            break
          case '2':
            word = 'FAILED'
            color = 'danger'
            break
          default:
            word = 'PROCESS'
            color = 'warning'
            break
        }
        return <Badge variant={color}>{word}</Badge>
      },
      type: dataType.ENUM,
      default_value: [
        {
          id: '0',
          name: 'Inq',
        },
        {
          id: '1',
          name: 'Pay',
        },
      ],
      showFilter: true,
    },
    {
      name: translate('type', languange, columnResource),
      selector: 'bill_type',
      sortable: true,
      cell: (row) => {
        let word
        let color
        switch (`${row.bill_type}`) {
          case 'open':
            word = 'OPEN'
            color = 'success'
            break
          case 'close':
            word = 'CLOSE'
            color = 'warning'
            break
          case 'close_recurring':
            word = 'CLOSE RECURRING'
            color = 'primary'
            break

          default:
            word = 'unknown'
            color = 'danger'
            break
        }
        return <Badge variant={color}>{word}</Badge>
      },
      type: dataType.ENUM,
      default_value: [
        {
          id: 'open',
          name: 'open',
        },
        {
          id: 'close',
          name: 'close',
        },
      ],
      showFilter: true,
    },
    {
      name: translate('response', languange, columnResource),
      selector: 'last_response',
      sortable: true,
      type: dataType.STRING,
      showFilter: false,
    },
  ],
})

export default TransactionBulkVaColumn
