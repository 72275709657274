/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Container, Image } from 'react-bootstrap'

// eslint-disable-next-line
import './topup.scss'
import BaseScreen from '../../base'
import globalConstant from '../../../store/constant/global'
import auth from '../../../store/constant/auth'
import TopupVA from './topup_va'
import TopupManual from './topup_manual_transfer'
import translate from '../../../helper/translate'
import { headerPageResource, titleSentence } from '../../../helper/translatePage/languageResource'
import AccessDenied from '../../component/AccessDenied'
import iconName from '../../../helper/constant/iconName'
import icons from '../../../helper/icons'
import checkAccess from '../../../helper/access'
import roleAccess from '../../../store/constant/roleAccess'

class TopUpScreen extends BaseScreen {
  constructor(props) {
    super(props, ['page--topup'])

    this.state = {
      guide: true,
      create: false,
      action: 'topup',
    }
  }

  changeContent = (e) => {
    const { name } = e.target

    if (name === 'guide') {
      this.setState({ guide: true, create: false })
    } else if (name === 'create') {
      this.setState({ guide: false, create: true })
    }
  }

  getAction = (action) => {
    this.setState({ action: action })
  }

  readAccess = () => {
    const pathname = window.location.pathname.split('/')
    return (
      checkAccess(this.props.appUserAccess, pathname[1] ? pathname[1] : '', 'READ') &&
      this.props.appEnableAPI
    )
  }

  render() {
    const { guide, create, action } = this.state
    return (
      <>
        {!this.readAccess() ? (
          <AccessDenied />
        ) : (
          <>
            <div className="mb-3 mt-3 ml-3" onClick={() => this.props.history.goBack()}>
              <button type="button" className="btn btn-outline-primary back-button">
                <Image src={icons(iconName.backButton)} className="" />
              </button>
              {action === 'topup' ? (
                <span className="ml-2 fontSize-20-Bold">
                  {translate('topup', this.props.language, headerPageResource)}
                </span>
              ) : (
                <span className="ml-2 fontSize-16 color-grey">
                  {translate('topupInfo4', this.props.language, titleSentence)}
                </span>
              )}
            </div>
            <Container fluid className="p-3">
              {action === 'success' ? (
                <></>
              ) : (
                <div className="container-text-topup">
                  <ul>
                    <li className="fontSize-16 color-grey">
                      {translate('depoVa', this.props.language, titleSentence)}
                    </li>
                    <li className="fontSize-16 color-grey">
                      {translate('depoTf1', this.props.language, titleSentence)}
                    </li>
                    <li className="fontSize-16 color-grey">
                      {translate('depoTf2', this.props.language, titleSentence)}
                    </li>
                    <li className="fontSize-16 color-grey">
                      {translate('depoTf3', this.props.language, titleSentence)}
                    </li>
                  </ul>
                </div>
              )}
              <div className="container-content mt-4">
                {action === 'success' ? (
                  <></>
                ) : (
                  <>
                    <div className="fontSize-16-SemiBold mb-4">
                      {translate('transferDestination', this.props.language, headerPageResource)}
                    </div>
                    <div className="content-tab">
                      <button
                        type="button"
                        className={`button-picker ${
                          guide ? 'button-picker-active color-white' : 'text-active'
                        } mr-3 fontSize-17`}
                        name="guide"
                        onClick={(e) => this.changeContent(e)}
                      >
                        {translate('virtualAccount', this.props.language, headerPageResource)}
                        <Image
                          src={
                            guide
                              ? icons(iconName.arrowWhite)
                              : icons(iconName.arrowRightActive, process.env.REACT_APP_BRAND_NAME)
                          }
                          className="ml-4"
                        />
                      </button>
                      <button
                        type="button"
                        className={`button-picker ${
                          create ? 'button-picker-active color-white' : 'text-active'
                        } mr-3 fontSize-17`}
                        name="create"
                        onClick={(e) => this.changeContent(e)}
                      >
                        {translate('manualTf', this.props.language, headerPageResource)}
                        <Image
                          src={
                            create
                              ? icons(iconName.arrowWhite)
                              : icons(iconName.arrowRightActive, process.env.REACT_APP_BRAND_NAME)
                          }
                          className="ml-4"
                        />
                      </button>
                    </div>
                  </>
                )}
                {guide && <TopupVA />}
                {create && <TopupManual onSuccess={this.getAction} />}
              </div>
            </Container>
          </>
        )}
      </>
    )
  }
}

TopUpScreen.defaultProps = {
  appIsLoading: false,
}

TopUpScreen.propTypes = {
  appIsLoading: PropTypes.bool,
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[globalConstant.IS_LOADING],
    language: state.Global[globalConstant.LANGUAGE],
    appUserData: state.Auth[auth.USER_DATA],
    token: state.Auth[auth.USER_PASS],
    appEnableAPI: state.Auth[auth.ENABLE_API],
    appUserAccess: state.RoleAccess[roleAccess.ACCESS],
  }),
  (dispatch) => ({ dispatch })
)(TopUpScreen)
