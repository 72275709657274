/* eslint-disable no-unused-vars */
import React from 'react'
import { Badge } from 'react-bootstrap'
import dataType from '../../../helper/constant/dataType'

import Formatter from '../../../helper/formatter'
import translate from '../../../helper/translate'
import { columnResource } from '../../../helper/translatePage/languageResource'

const VirtualAccountColumn = (action, lang) => ({
  key: 'id',
  columns: [
    {
      name: 'ID Trx',
      selector: 'id',
      sortable: true,
      type: dataType.INTEGER,
      showFilter: true,
    },
    {
      name: translate('vaNumber', lang, columnResource),
      selector: 'virtual_account_no',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: 'ID Outlet',
      selector: 'id_outlet',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('billType', lang, columnResource),
      selector: 'bill_type',
      sortable: true,
      cell: (row) => {
        let word
        let color
        switch (`${row.bill_type}`) {
          case 'o':
            word = 'Open'
            color = 'warning'
            break
          case 'c':
            word = 'Close'
            color = 'primary'
            break
          default:
            word = 'Other'
            color = 'secondary'
            break
        }
        return <Badge variant={color}>{word}</Badge>
      },
      type: dataType.ENUM,
      showFilter: true,
      default_value: [
        {
          id: 'o',
          name: 'OPEN',
        },
        {
          id: 'c',
          name: 'CLOSE',
        },
      ],
    },
    {
      name: 'Status',
      selector: 'transaction_type',
      sortable: true,
      cell: (row) => {
        let word
        let color
        switch (`${row.transaction_type}`) {
          case '0':
            word = 'New'
            color = 'warning'
            break
          case '1':
            word = 'Payment'
            color = 'primary'
            break
          case '2':
            word = 'Failed'
            color = 'danger'
            break
          default:
            word = 'Other'
            color = 'secondary'
            break
        }
        return <Badge variant={color}>{word}</Badge>
      },
      type: dataType.ENUM,
      showFilter: true,
      default_value: [
        {
          id: '0',
          name: 'NEW',
        },
        {
          id: '1',
          name: 'PAYMENT',
        },
        {
          id: '2',
          name: 'FAILED',
        },
      ],
    },
    {
      name: translate('createDate', lang, columnResource),
      selector: 'created_time',
      sortable: true,
      type: dataType.DATERANGE,
      showFilter: true,
      default: true,
    },
    {
      name: translate('expDate', lang, columnResource),
      selector: 'expired_time',
      sortable: true,
      type: dataType.DATERANGE,
      showFilter: true,
    },
    {
      name: translate('payTime', lang, columnResource),
      selector: 'payment_time',
      sortable: true,
      type: dataType.DATERANGE,
      showFilter: true,
    },
    {
      name: translate('custName', lang, columnResource),
      selector: 'customer_name',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: translate('amount', lang, columnResource),
      selector: 'amount',
      sortable: true,
      cell: (row) => Formatter.price(Formatter.format2(row.amount)),
      type: dataType.INTEGER,
      showFilter: true,
    },
    {
      name: translate('amountOri', lang, columnResource),
      selector: 'amount_origin',
      sortable: true,
      cell: (row) => Formatter.price(Formatter.format2(row.amount_origin)),
      type: dataType.INTEGER,
      showFilter: true,
    },
    {
      name: translate('adminFee', lang, columnResource),
      selector: 'admin',
      sortable: true,
      cell: (row) => Formatter.price(Formatter.format2(row.admin)),
      type: dataType.INTEGER,
      showFilter: true,
    },
    {
      name: 'Partner Reff',
      selector: 'partner_reff',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: 'Biller Reff',
      selector: 'biller_reff',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
    {
      name: 'URL Payment',
      selector: 'url_payment',
      sortable: true,
      type: dataType.STRING,
      showFilter: true,
    },
  ],
})

export default VirtualAccountColumn
