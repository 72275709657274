/* eslint-disable import/no-duplicates */
/* eslint-disable no-unused-vars */
/* eslint-disable no-lonely-if */
/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/react-in-jsx-scope */
import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import swal from 'sweetalert'

import './credential_server.scss'
import {
  Container,
  ProgressBar,
  Modal,
  Button,
  Form,
  Alert,
  Row,
  Col,
  Card,
  Image,
  InputGroup,
} from 'react-bootstrap'
import classNames from 'classnames'
import global from '../../store/constant/global'
import auth from '../../store/constant/auth'
import FabComponent from '../component/fab'
import BaseScreen from '../base'
import dataCredential from '../../api/action/dataCredential'
import credentialReset from '../../api/action/credentialReset'
import AlertDialog from '../dialog/alert'
import AlertDialogOTP from '../dialog/alert'
import generate from '../../api/action/generate'
import pinGenerate from '../../api/action/pinGenerate'
import OtpModal from './otp_modal'
import createOtp from '../../api/action/createOtp'
import icons from '../../helper/icons'
import iconName from '../../helper/constant/iconName'
import { getSettingCallback, saveSettingCallback } from '../../api/action/settingCallback'
import generateCallback from '../../api/action/generateCallback'
import translate from '../../helper/translate'
import {
  columnResource,
  sidebarLangResource,
  titleSentence,
} from '../../helper/translatePage/languageResource'
import checkAccess from '../../helper/access'
import AccessDenied from '../component/AccessDenied'
import roleAccess from '../../store/constant/roleAccess'
import createSignature from '../../api/action/createSignature'
import getCsrf from '../../api/action/getCsrf'
import logout from '../../api/action/logout'

class CredentialServerScreen extends BaseScreen {
  constructor(props) {
    super(props, ['page--credential-server'])
    this.state = {
      path: props.match.params.path,
      urls: [],
      success: '',
      idSaveInfo: null,
      credentialServerData: null,
      showModal: false,
      password: '',
      clientSecret: '',
      ipAddress: [],
      allowIp: '',
      showAllowIp: '',
      appName: '',
      showPass: false,
      process: false,
      showOtpModal: false,
      showOtpAlert: false,
      otpSuccessFunc: null,
      emailOtp: '',
      idOTP: '',
      captcha: '',
      otp_type: '',
      alert_otp_text: '',
      allow_ip_additional_1: '',
      show_allow_ip_additional_1: '',
      allow_ip_additional_2: '',
      show_allow_ip_additional_2: '',
      allow_ip_additional_3: '',
      show_allow_ip_additional_3: '',
      allow_ip_additional_4: '',
      show_allow_ip_additional_4: '',
      allow_ip_additional_5: '',
      show_allow_ip_additional_5: '',
      is_using_signature: '',
      resetOtp: false,
    }
    this.captchaRef = React.createRef().current
  }

  componentDidMount() {
    super.componentDidMount()

    this.getCredentialServerData()
    this.getCallback()
  }

  getCallback = async () => {
    const { dispatch } = this.props
    dispatch(getSettingCallback())
      .then((urls) => {
        this.setState({
          urls,
        })
      })
      .catch((err) => this.showError(err))
  }

  generateCallback = async (otpCode, captcha) => {
    const { dispatch, userAgen } = this.props
    this.setState({ proccess: true })
    const res = await dispatch(generateCallback(userAgen, this.state.idOTP, otpCode, captcha))

    if (res.data.rc === '00') {
      this.hideOtpModal()
      this.setState({ proccess: false })
      this.showError(res.data.rd)
      this.getCallback()
    } else {
      this.setState({ proccess: false })
      this.showError(res.getRD())
      this.getCallback()
    }
  }

  toggleEditable = (e) => {
    const { info } = e.currentTarget.dataset
    const isEditable = this.state[`iUrl${info}`]

    this.setState({ [`iUrl${info}`]: !isEditable })
  }

  saveInfoCallback = async (idInfo, otpCode, captcha) => {
    const { dispatch } = this.props
    const value = this.state[`vUrl${idInfo}`]

    let response = await dispatch(getCsrf())
    if (response.isOK()) {
      response = await dispatch(
        saveSettingCallback(idInfo, value, this.state.idOTP, otpCode, captcha)
      )
      if (response.isOK()) {
        this.hideOtpModal()
        this.setState({ [`iUrl${idInfo}`]: false })
      } else {
        this.showError(response.getRD())
      }
    } else {
      dispatch(logout())
    }
  }

  hideOtpModal = () => {
    this.setState({ showOtpModal: false, showOtpAlert: false })
    this.resetOtpCode()
  }

  showedOtpModal = () => {
    this.setState({ showOtpModal: true })
  }

  resetOtpCode = () => {
    this.setState({ resetOtp: true })
  }

  notResetOtpCode = () => {
    this.setState({ resetOtp: false })
  }

  getCredentialServerData = async () => {
    this.setState({ process: true })
    const response = await this.props.dispatch(dataCredential(this.props.appUserData.username_agen))

    if (response.isOK()) {
      this.setState({ process: false })
      if (response.data.data == null) {
        this.setState({
          credentialServerData: response.data.data,
          allowIp: '',
          appName: '',
        })
      } else {
        if (response.data.data.allow_ip_additional_0 == null) {
          this.setState({
            credentialServerData: response.data.data,
            allowIp: response.data.data.allow_ip == '0.0.0.0' ? '' : response.data.data.allow_ip,
            showAllowIp:
              response.data.data.allow_ip == '0.0.0.0' ? '' : response.data.data.allow_ip,
            appName: response.data.data.app_name,
          })
        } else {
          this.setState({
            credentialServerData: response.data.data,
            allowIp: response.data.data.allow_ip,
            showAllowIp: response.data.data.allow_ip,
            appName: response.data.data.app_name,
            allow_ip_additional_1: response.data.data.allow_ip_additional_1,
            show_allow_ip_additional_1: response.data.data.allow_ip_additional_1,
            allow_ip_additional_2: response.data.data.allow_ip_additional_2,
            show_allow_ip_additional_2: response.data.data.allow_ip_additional_2,
            allow_ip_additional_3: response.data.data.allow_ip_additional_3,
            show_allow_ip_additional_3: response.data.data.allow_ip_additional_3,
            allow_ip_additional_4: response.data.data.allow_ip_additional_4,
            show_allow_ip_additional_4: response.data.data.allow_ip_additional_4,
            allow_ip_additional_5: response.data.data.allow_ip_additional_5,
            show_allow_ip_additional_5: response.data.data.allow_ip_additional_5,
          })
        }
      }
    } else {
      this.setState({ process: false })
      this.showError(response.getRD())
    }
  }

  generateOTP = async () => {
    if (this.state.captcha === '') {
      this.showError('Please Fill Captcha')
    } else {
      const res = await this.props.dispatch(createOtp(this.state.captcha))

      if (res.data.rc == '00') {
        this.setState({
          emailOtp: res.data.data.email,
          idOTP: res.data.data.idOtp,
          showOtpAlert: false,
          showModal: false,
          captcha: '',
        })
        switch (this.state.otp_type) {
          case 'generate_new_credential':
            this.generateCredentialOtp()
            break
          case 'generate_pin':
            this.generatePINOtp()
            break
          case 'activate_signature':
            this.createSignatureOTP()
            break
          case 'edit_credential':
            this.editCredentialOtp()
            break
          case 'save_callback':
            this.saveInfoOtp(this.state.idSaveInfo)
            break
          case 'generate_callback':
            this.generateCallbackOtp(this.state.idSaveInfo)
            break

          default:
            this.showError('INVALID OTP TYPE')
            break
        }
      } else {
        this.showError(res.data.rd)
      }
    }
  }

  generateCallbackOtp = () => {
    this.showedOtpModal()
    this.setState({ otpSuccessFunc: (otpCode, captcha) => this.generateCallback(otpCode, captcha) })
  }

  saveInfoOtp = (idInfo) => {
    this.showedOtpModal()
    this.setState({
      otpSuccessFunc: (otpCode, captcha) => this.saveInfoCallback(idInfo, otpCode, captcha),
    })
  }

  alertUrlCallback = (idInfo) => {
    const textAlert =
      'Jika Anda mengubah URL Callback ini dengan yang baru, maka URL Callback yang lama akan langsung tidak bisa digunakan lagi.'
    this.setState({
      alert_otp_text: textAlert,
      showOtpAlert: true,
      idSaveInfo: idInfo,
      otp_type: 'save_callback',
    })
  }

  generateCredential = async (otpCode, captcha, emailDestination) => {
    const response = await this.props.dispatch(getCsrf())
    if (response.isOK()) {
      const res = await this.props.dispatch(
        generate(this.state.idOTP, otpCode, captcha, emailDestination)
      )
      if (res.data.rc != '00') {
        this.setState({ alertError: res.data.rd })
        this.showError(res.data.rd)
      } else {
        this.hideOtpModal()
        this.showError(res.data.rd)
        this.getCredentialServerData()
      }
    } else {
      this.props.dispatch(logout())
    }
  }

  readAccess = () => {
    const pathname = window.location.pathname.split('/')
    return (
      checkAccess(this.props.appUserAccess, pathname[2] ? pathname[2] : '', 'READ') &&
      this.props.appEnableAPI
    )
  }

  generateCredentialOtp = () => {
    this.showedOtpModal()
    this.setState({
      otpSuccessFunc: this.generateCredential,
    })
  }

  generatePIN = async (otpCode, captcha, emailDestination) => {
    const response = await this.props.dispatch(getCsrf())
    if (response.isOK()) {
      const res = await this.props.dispatch(
        pinGenerate(this.state.idOTP, otpCode, captcha, emailDestination)
      )

      if (res.data.rc != '00') {
        this.showError(res.data.rd)
      } else {
        this.hideOtpModal()
        this.showError(res.data.rd)
      }
    } else {
      this.props.dispatch(logout())
    }
  }

  createSignature = async (otpCode, captcha, emailDestination) => {
    const { credentialServerData } = this.state
    let kode = ''
    if (credentialServerData.is_using_signature) {
      kode = 'RESET'
    } else {
      kode = 'ACTIVATED'
    }
    const res = await this.props.dispatch(
      createSignature(this.state.idOTP, otpCode, captcha, emailDestination, kode)
    )

    if (res.data.rc != '00') {
      this.showError(res.data.rd)
    } else {
      this.hideOtpModal()
      this.showError(res.data.rd)
      this.getCredentialServerData()
    }
  }

  generatePINOtp = () => {
    this.showedOtpModal()
    this.setState({ otpSuccessFunc: this.generatePIN })
  }

  createSignatureOTP = () => {
    this.showedOtpModal()
    this.setState({ otpSuccessFunc: this.createSignature })
  }

  handleShowPass = (type) => {
    switch (type) {
      case 'show':
        this.setState({ showPass: true })
        break
      case 'hidden':
        this.setState({ showPass: false })
        break

      default:
        this.setState({ showPass: false })
        break
    }
  }

  openModal = (otp_type = 'edit_credential') => {
    this.setState({ showModal: true, otp_type: otp_type })
  }

  closeModal = () => {
    this.setState({ showModal: false, password: '', captcha: '', otp_type: '' })
    this.captchaRef.reset()
  }

  onConfirm = async (otpCode, captcha) => {
    const { dispatch } = this.props

    const ipArray = []

    if (this.state.allowIp !== '') {
      ipArray.push(this.state.allowIp)
    }

    if (this.state.allow_ip_additional_1 !== '') {
      ipArray.push(this.state.allow_ip_additional_1)
    }

    if (this.state.allow_ip_additional_2 !== '') {
      ipArray.push(this.state.allow_ip_additional_2)
    }

    if (this.state.allow_ip_additional_3 !== '') {
      ipArray.push(this.state.allow_ip_additional_3)
    }

    if (this.state.allow_ip_additional_4 !== '') {
      ipArray.push(this.state.allow_ip_additional_4)
    }

    if (this.state.allow_ip_additional_5 !== '') {
      ipArray.push(this.state.allow_ip_additional_5)
    }

    const ipAddress = ipArray.join(',')

    let resetResponse = await this.props.dispatch(getCsrf())
    if (resetResponse.isOK()) {
      resetResponse = await dispatch(
        credentialReset(ipAddress, this.state.appName, this.state.idOTP, otpCode, captcha)
      )

      if (resetResponse.isOK()) {
        this.hideOtpModal()
        this.showError(resetResponse.getRD())
        this.getCredentialServerData()
      } else {
        this.showError(resetResponse.getRD())
      }
    } else {
      this.props.dispatch(logout())
    }
  }

  editCredentialOtp = () => {
    this.closeModal()
    this.showedOtpModal()
    this.setState({ otpSuccessFunc: this.onConfirm })
  }

  formHandleChange = (e) => {
    const { name, value } = e.target

    this.setState({ [name]: value })
  }

  handleIpChange = (e, key) => {
    // eslint-disable-next-line prefer-const
    let { ipAddress } = this.state
    ipAddress[key] = e.target.value
    this.setState({ ipAddress })
  }

  addIpAddress = () => {
    if (this.state.ipAddress.length < 10) {
      const newIp = '0.0.0.0'
      this.setState({ ipAddress: [...this.state.ipAddress, newIp] })
    } else {
      this.showError('IP Address tidak boleh melebihi 10')
    }
  }

  removeIpAddress = (key) => {
    if (this.state.ipAddress.length === 1) {
      this.showError('IP Address tidak boleh kurang dari 1')
    } else {
      const newIpFiltered = this.state.ipAddress.splice(key, 1)

      this.setState({ ipAddress: newIpFiltered })
    }
  }

  EmptyCredentialServer = () => {
    return (
      <div className="empty-wrapper w-100 d-flex flex-column align-items-center justify-content-center">
        <div style={{ width: '60%' }}>
          <img
            src={icons(iconName.empty, process.env.REACT_APP_BRAND_NAME)}
            alt="Empty"
            width="100%"
          />
        </div>
        <div className="w-50 mt-2" style={{ textAlign: 'center' }}>
          <span style={{ textAlign: 'center', fontWeight: 'bolder' }}>
            Saat ini Anda belum memiliki Credential Server, silakan tekan tombol 'Generate' untuk
            membuat Credential Server Anda.
          </span>
        </div>
        <button
          type="button"
          className="btn text-white button-custom-submit w-50 mt-3 mb-5"
          disabled={this.props.appIsLoading}
          onClick={this.alertGenerateClientId}
        >
          Generate
        </button>
      </div>
    )
  }

  errorHandler = () => {
    this.setState({ alertError: '' })
    if (this.captchaRef !== null) {
      this.captchaRef.reset()
    }
  }

  alertGenerateClientId = () => {
    const textAlert =
      'Jika Anda meminta nilai Client ID dan Client Secret yang baru, nilai Client ID dan Client Secret yang lama akan langsung tidak bisa digunakan lagi.'

    this.setState({
      showOtpAlert: true,
      alert_otp_text: textAlert,
      otp_type: 'generate_new_credential',
    })
  }

  alertGeneratePin = () => {
    const textAlert =
      'Jika Anda meminta nilai PIN Transaksi yang baru, nilai PIN Transaksi yang lama akan langsung tidak bisa digunakan lagi.'

    this.setState({ showOtpAlert: true, alert_otp_text: textAlert, otp_type: 'generate_pin' })
  }

  alertCreateSignature = () => {
    const textAlert = 'Anda meminta untuk mengaktifkan signature.'

    this.setState({ showOtpAlert: true, alert_otp_text: textAlert, otp_type: 'activate_signature' })
  }

  alertResetSignature = () => {
    const textAlert = 'Anda meminta untuk reset signature.'

    this.setState({ showOtpAlert: true, alert_otp_text: textAlert, otp_type: 'activate_signature' })
  }

  handleCaptcha = (value) => {
    this.setState({ captcha: value })
  }

  resetCaptcha = () => {
    this.setState({
      captcha: '',
      alert_otp_text: '',
      showOtpAlert: false,
      otp_type: '',
      idSaveInfo: null,
    })
    this.captchaRef.reset()
  }

  content = (tipe) => {
    if (tipe == 'CALLBACK') {
      return (
        <>
          <span className="mb-3 text-center fontSize-16">
            Jika Anda mengubah URL Callback ini dengan yang baru, maka URL Callback yang lama akan
            langsung tidak bisa digunakan lagi.
          </span>
          <ReCAPTCHA
            ref={(ref) => {
              this.captchaRef = ref
            }}
            className="captcha mb-3 w-100 centered fontSize-16"
            sitekey={process.env.REACT_APP_GRECAPTCHA_KEY}
            onChange={this.handleCaptcha}
          />
        </>
      )
    }

    return (
      <>
        <span className="mb-3 text-center fontSize-16">{this.state.alert_otp_text}</span>
        <ReCAPTCHA
          ref={(ref) => {
            this.captchaRef = ref
          }}
          className="captcha mt-3 w-100 centered"
          sitekey={process.env.REACT_APP_GRECAPTCHA_KEY}
          onChange={this.handleCaptcha}
        />
      </>
    )
  }

  render() {
    this.props.dispatch({
      type: global.HEADER_TITLE,
      payload: translate('setting', this.props.language, sidebarLangResource),
    })

    const { appIsLoading, appAgenGroup, environment } = this.props
    // eslint-disable-next-line camelcase
    const { alertError, credentialServerData, showModal, urls, otp_type } = this.state
    const group = appAgenGroup
    const env = 'production'
    const url = urls

    // eslint-disable-next-line camelcase
    const otpType = otp_type
    return (
      <>
        {!this.readAccess() ? (
          <AccessDenied />
        ) : (
          <>
            <Container fluid className=" mt-4">
              {this.state.process ? (
                <ProgressBar
                  style={{ width: '100%' }}
                  className="mt-3 mb-3"
                  variant="info"
                  label="Please Wait..."
                  animated
                  now={100}
                />
              ) : credentialServerData === null ? (
                group == 3 ? (
                  <Alert variant="warning" className="mb-3 mt-3 fontSize-21">
                    {translate('pleaseActivateBusiness', this.props.language, titleSentence)}
                  </Alert>
                ) : (
                  <>
                    {env === 'sandbox' && (
                      <Alert variant="warning" className="mb-3 mt-3 fontSize-21">
                        {translate('warningModeSandbox', this.props.language, titleSentence)}
                      </Alert>
                    )}
                    {env === 'production' && this.EmptyCredentialServer()}
                    {(env === undefined || env == null) && (
                      <Alert variant="warning" className="mb-3 mt-3 fontSize-21">
                        {translate('warningModeSandbox', this.props.language, titleSentence)}
                      </Alert>
                    )}
                  </>
                )
              ) : (
                <>
                  {env === 'sandbox' && (
                    <Alert variant="warning" className="mb-3 mt-3 fontSize-21">
                      {translate('warningModeSandbox', this.props.language, titleSentence)}
                    </Alert>
                  )}
                  {group == 3 && (
                    <Alert variant="warning" className="mb-3 mt-3 fontSize-21">
                      {translate('pleaseActivateBusiness', this.props.language, titleSentence)}
                    </Alert>
                  )}
                  {env === 'production' && group != 3 && (
                    <Row>
                      <Col xs={12} md={6}>
                        <div className="fontSize-21 color-black title-card">URL Callback</div>
                        <Card style={{ borderRadius: '18px' }}>
                          <Card.Body>
                            {url.length === 0 ? (
                              <div className="w-100 d-flex justify-content-center">
                                <button
                                  type="button"
                                  className="btn btn-primary btn-custom fontSize-17"
                                  onClick={() => this.openModal('generate_callback')}
                                  disabled={this.state.proccess}
                                >
                                  Generate URL Callback Setting
                                </button>
                              </div>
                            ) : (
                              <>
                                {this.props.environment === 'sandbox' && (
                                  <Alert variant="warning" className="mb-3 fontSize-21">
                                    {translate('sandboxMode', this.props.language, titleSentence)}
                                  </Alert>
                                )}
                                {this.props.appAgenGroup == 3 && (
                                  <Alert variant="warning" className="mb-3 mt-3 fontSize-21">
                                    {translate(
                                      'pleaseActivateBusiness',
                                      this.props.language,
                                      titleSentence
                                    )}
                                  </Alert>
                                )}
                                {this.props.environment === 'production' &&
                                  this.props.appAgenGroup != 3 && (
                                    <>
                                      <h6 className="mb-5 mt-3 fontSize-16 color-grey">
                                        {translate(
                                          'callbackInfo',
                                          this.props.language,
                                          titleSentence
                                        )}
                                      </h6>
                                      <Form>
                                        {url.map((data) => {
                                          const name = `vUrl${data.idinfo}`
                                          const isEditable = `iUrl${data.idinfo}`
                                          return (
                                            <Form.Group key={data.idinfo}>
                                              <Form.Label className="fontSize-16-Medium">
                                                {data.type_info.toUpperCase().replaceAll('_', ' ')}
                                              </Form.Label>
                                              <InputGroup className="mb-3">
                                                <Form.Control
                                                  type="url"
                                                  name={name}
                                                  value={this.state[name] || data.type_value}
                                                  onChange={this.formHandleChange}
                                                  placeholder="http://test.com or https://test.com"
                                                  className="custom-input"
                                                />
                                                <InputGroup.Append>
                                                  {!this.state[isEditable] && (
                                                    <Button
                                                      variant="outline-info"
                                                      data-info={data.idinfo}
                                                      onClick={this.toggleEditable}
                                                    >
                                                      <FontAwesomeIcon fixedWidth icon="edit" />
                                                    </Button>
                                                  )}
                                                  {this.state[isEditable] && (
                                                    <Button
                                                      variant="outline-danger"
                                                      data-info={data.idinfo}
                                                      className={classNames({
                                                        hidden: !this.state[isEditable],
                                                      })}
                                                      onClick={this.toggleEditable}
                                                    >
                                                      <FontAwesomeIcon fixedWidth icon="times" />
                                                    </Button>
                                                  )}
                                                  {this.state[isEditable] && (
                                                    <Button
                                                      variant="outline-success"
                                                      data-info={data.idinfo}
                                                      className={classNames({
                                                        hidden: !this.state[isEditable],
                                                      })}
                                                      onClick={() =>
                                                        this.alertUrlCallback(data.idinfo)
                                                      }
                                                    >
                                                      <FontAwesomeIcon fixedWidth icon="save" />
                                                    </Button>
                                                  )}
                                                </InputGroup.Append>
                                              </InputGroup>
                                            </Form.Group>
                                          )
                                        })}
                                      </Form>
                                    </>
                                  )}
                              </>
                            )}
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col xs={12} md={6}>
                        <div className="fontSize-21 color-black title-card">Credential Server</div>
                        <Card style={{ borderRadius: '18px' }} className="mb-5">
                          <Card.Body>
                            <div className="d-flex w-100 flex-column mt-3">
                              <p className="mb-3 pr-3 fontSize-16 color-grey">
                                {translate('infoCredential1', this.props.language, titleSentence)}
                              </p>
                              <div className="content-wrapper d-flex justify-content-between">
                                <div className="right-content d-flex flex-column pr-3">
                                  <div className="client-id my-3">
                                    <p className="fontSize-16-Medium">
                                      {translate(
                                        'infoCredential2',
                                        this.props.language,
                                        titleSentence
                                      )}
                                    </p>
                                    <p className="fontSize-16 color-grey">
                                      {translate(
                                        'infoCredential3',
                                        this.props.language,
                                        titleSentence
                                      )}
                                    </p>
                                    <button
                                      type="button"
                                      className="mb-3 btn btn-primary btn-custom fontSize-17"
                                      onClick={this.alertGenerateClientId}
                                      disabled={appIsLoading}
                                    >
                                      {translate(
                                        'sendCredential',
                                        this.props.language,
                                        titleSentence
                                      )}
                                      <Image
                                        src={icons(iconName.fluentPassword)}
                                        className="icon-button"
                                      />
                                    </button>
                                  </div>
                                  <div className="client-id mb-3">
                                    <p className="fontSize-16-Medium">
                                      {translate(
                                        'infoCredential4',
                                        this.props.language,
                                        titleSentence
                                      )}
                                    </p>
                                    <p className="fontSize-16-Medium color-grey">
                                      {translate(
                                        'infoCredential5',
                                        this.props.language,
                                        titleSentence
                                      )}
                                    </p>
                                    <p className="fontSize-16-SemiBold color-grey">
                                      {translate(
                                        'infoCredential6',
                                        this.props.language,
                                        titleSentence
                                      )}
                                    </p>
                                    <button
                                      type="button"
                                      className="mb-3 btn btn-primary btn-custom fontSize-17"
                                      onClick={this.alertGeneratePin}
                                      disabled={appIsLoading}
                                    >
                                      {translate('sendPin', this.props.language, titleSentence)}
                                      <Image
                                        src={icons(iconName.fluentPassword)}
                                        className="icon-button"
                                      />
                                    </button>
                                  </div>
                                  <div className="ip-whitelist my-3">
                                    <p className="fontSize-16-Medium">IP whitelist</p>
                                    <p className=" fontSize-16 color-grey">
                                      {translate(
                                        'infoCredential7',
                                        this.props.language,
                                        titleSentence
                                      )}
                                    </p>
                                    <p className="fontSize-16-SemiBold color-grey">
                                      {translate(
                                        'infoCredential8',
                                        this.props.language,
                                        titleSentence
                                      )}
                                    </p>
                                    <ul className="fontSize-16-SemiBold color-grey">
                                      <li>
                                        {this.state.showAllowIp
                                          ? this.state.showAllowIp
                                          : translate(
                                              'infoCredential9',
                                              this.props.language,
                                              titleSentence
                                            )}
                                      </li>
                                      {this.state.show_allow_ip_additional_1 && (
                                        <li>{this.state.show_allow_ip_additional_1}</li>
                                      )}
                                      {this.state.show_allow_ip_additional_2 && (
                                        <li>{this.state.show_allow_ip_additional_2}</li>
                                      )}
                                      {this.state.show_allow_ip_additional_3 && (
                                        <li>{this.state.show_allow_ip_additional_3}</li>
                                      )}
                                      {this.state.show_allow_ip_additional_4 && (
                                        <li>{this.state.show_allow_ip_additional_4}</li>
                                      )}
                                      {this.state.show_allow_ip_additional_5 && (
                                        <li>{this.state.show_allow_ip_additional_5}</li>
                                      )}
                                    </ul>
                                    <button
                                      type="button"
                                      className="btn btn-primary btn-custom fontSize-17"
                                      onClick={() => this.openModal()}
                                      disabled={appIsLoading}
                                    >
                                      Edit IP Whitelist
                                      <Image
                                        src={icons(iconName.checklist)}
                                        className="icon-button"
                                      />
                                    </button>
                                  </div>
                                  <div className="client-id mb-3">
                                    <p className="fontSize-16-Medium">
                                      {translate(
                                        'infoCredential20',
                                        this.props.language,
                                        titleSentence
                                      )}
                                    </p>
                                    <p className="fontSize-16-SemiBold color-grey">
                                      {translate(
                                        'infoCredential21',
                                        this.props.language,
                                        titleSentence
                                      )}
                                      <p
                                        className={`fontSize-16-SemiBold d-inline ${
                                          credentialServerData.is_using_signature
                                            ? 'color-green'
                                            : 'color-red'
                                        }`}
                                      >
                                        {credentialServerData.is_using_signature
                                          ? translate('active', this.props.language, columnResource)
                                          : translate(
                                              'notActive',
                                              this.props.language,
                                              columnResource
                                            )}
                                      </p>
                                    </p>
                                    <button
                                      type="button"
                                      className="mb-3 btn btn-primary btn-custom fontSize-17"
                                      onClick={
                                        credentialServerData.is_using_signature
                                          ? this.alertResetSignature
                                          : this.alertCreateSignature
                                      }
                                      disabled={appIsLoading}
                                    >
                                      {translate(
                                        credentialServerData.is_using_signature
                                          ? 'resetSignature'
                                          : 'activateSignature',
                                        this.props.language,
                                        titleSentence
                                      )}
                                      <Image
                                        src={icons(iconName.fluentPassword)}
                                        className="icon-button"
                                      />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </Container>
            <Modal
              show={showModal}
              onHide={this.closeModal}
              backdrop="static"
              keyboard={false}
              centered
              className={`${otpType === 'generate_callback' ? `custom-modal` : ``}`}
            >
              {otpType === 'generate_callback' ? (
                <>
                  <Modal.Body className="text-center modal-callback-otp">
                    <div className="image-type">
                      <Image
                        src={icons(iconName.warningDialog)}
                        className="image-alert-dialog-warning"
                      />
                    </div>
                    <div className="message-dialog">
                      <div className="message-dialog-title fontSize-21">Kirim Permintaan OTP</div>
                      <ReCAPTCHA
                        ref={(ref) => {
                          this.captchaRef = ref
                        }}
                        className="captcha my-3 text-center centered"
                        sitekey={process.env.REACT_APP_GRECAPTCHA_KEY}
                        onChange={this.handleCaptcha}
                      />
                    </div>
                    <Row>
                      <Col>
                        <Button
                          variant="secondary"
                          onClick={this.closeModal}
                          disabled={appIsLoading}
                          className="fontSize-17 btn-custom clear-margin"
                        >
                          Batal
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          className="fontSize-17 btn-custom btn-alert clear-margin"
                          variant="primary"
                          onClick={() => this.generateOTP('generate_callback')}
                          disabled={appIsLoading}
                        >
                          Lanjutkan
                        </Button>
                      </Col>
                    </Row>
                  </Modal.Body>
                </>
              ) : (
                <>
                  <Modal.Header closeButton className="header-custom align-items-center">
                    <Modal.Title className="fontSize-21-Semibold">Edit IP Whitelist</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="container-modal-edit-ip">
                    <Form.Text
                      id="otpHelpBlock"
                      className="mb-4 fontSize-16 color-grey"
                      style={{ fontSize: 14, fontWeight: 'bold' }}
                    >
                      Anda bisa memasukkan minimal 1 IP Address dan maksimal 6 IP Address.
                    </Form.Text>
                    <Form.Group>
                      <Form.Label className="fontSize-16-SemiBold">IP Address 1</Form.Label>
                      <Form.Control
                        placeholder="Masukkan IP Address 1"
                        value={this.state.allowIp}
                        onChange={this.formHandleChange}
                        name="allowIp"
                        className="fontSize-16 custom-input"
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label className="fontSize-16-SemiBold">IP Address 2</Form.Label>
                      <Form.Control
                        placeholder="Masukkan IP Address 2"
                        value={this.state.allow_ip_additional_1}
                        onChange={this.formHandleChange}
                        name="allow_ip_additional_1"
                        className="fontSize-16 custom-input"
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label className="fontSize-16-SemiBold">IP Address 3</Form.Label>
                      <Form.Control
                        placeholder="Masukkan IP Address 3"
                        value={this.state.allow_ip_additional_2}
                        onChange={this.formHandleChange}
                        name="allow_ip_additional_2"
                        className="fontSize-16 custom-input"
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label className="fontSize-16-SemiBold">IP Address 4</Form.Label>
                      <Form.Control
                        placeholder="Masukkan IP Address 4"
                        value={this.state.allow_ip_additional_3}
                        onChange={this.formHandleChange}
                        name="allow_ip_additional_3"
                        className="fontSize-16 custom-input"
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label className="fontSize-16-SemiBold">IP Address 5</Form.Label>
                      <Form.Control
                        placeholder="Masukkan IP Address 5"
                        value={this.state.allow_ip_additional_4}
                        onChange={this.formHandleChange}
                        name="allow_ip_additional_4"
                        className="fontSize-16 custom-input"
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label className="fontSize-16-SemiBold">IP Address 6</Form.Label>
                      <Form.Control
                        placeholder="Masukkan IP Address 6"
                        value={this.state.allow_ip_additional_5}
                        onChange={this.formHandleChange}
                        name="allow_ip_additional_5"
                        className="fontSize-16 custom-input"
                      />
                    </Form.Group>
                    <ReCAPTCHA
                      ref={(ref) => {
                        this.captchaRef = ref
                      }}
                      className="captcha my-3 text-center centered"
                      sitekey={process.env.REACT_APP_GRECAPTCHA_KEY}
                      onChange={this.handleCaptcha}
                    />
                    <Row>
                      <Col className="clear-padding">
                        <Button
                          variant="secondary"
                          onClick={this.closeModal}
                          disabled={appIsLoading}
                          className="fontSize-17 btn-custom clear-margin  w-100"
                        >
                          Batal
                        </Button>
                      </Col>
                      <Col className="clear-padding text-right">
                        <Button
                          className="fontSize-17 btn-custom btn-alert w-100 clear-margin"
                          variant="primary"
                          onClick={() => this.generateOTP('edit_credential')}
                          disabled={appIsLoading}
                        >
                          Lanjutkan
                        </Button>
                      </Col>
                    </Row>
                  </Modal.Body>
                </>
              )}
            </Modal>
            <AlertDialog
              show={!!alertError}
              onHide={this.errorHandler}
              onConfirm={this.errorHandler}
              type="warning"
              messageTitle={alertError}
              addedClass="mini-height"
            />
            <AlertDialogOTP
              show={this.state.showOtpAlert}
              onHide={this.resetCaptcha}
              onConfirm={this.generateOTP}
              title="Perhatian"
              addedClass="mini-height"
              type="warning"
              showCancel
            >
              {this.content()}
            </AlertDialogOTP>
            <OtpModal
              show={this.state.showOtpModal}
              onHide={this.hideOtpModal}
              parentFunc={this.state.otpSuccessFunc}
              errMsg={this.showError}
              email={this.state.emailOtp}
              idOtp={this.state.idOTP}
              btnDisable={appIsLoading}
              resetOtp={this.state.resetOtp}
              resetOtpFunc={this.notResetOtpCode}
              type={
                this.state.otp_type == 'generate_new_credential' ||
                this.state.otp_type == 'generate_pin' ||
                this.state.otp_type == 'activate_signature'
              }
            />
          </>
        )}
      </>
    )
  }
}

CredentialServerScreen.defaultProps = {
  appIsLoading: false,
}

CredentialServerScreen.propTypes = {
  appIsLoading: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    appIsLoading: state.Global[global.IS_LOADING],
    appUserData: state.Auth[auth.USER_DATA],
    appAgenGroup: state.Auth[auth.AGENT_GROUP],
    environment: state.Global[global.ENVIRONMENT],
    language: state.Global[global.LANGUAGE],
    appEnableAPI: state.Auth[auth.ENABLE_API],
    appUserAccess: state.RoleAccess[roleAccess.ACCESS],
  }),
  (dispatch) => ({ dispatch })
)(CredentialServerScreen)
