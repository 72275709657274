/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/prop-types */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Button, Modal, Image } from 'react-bootstrap'

import './manualInput.scss'
import { connect } from 'react-redux'
import Formatter from '../../../../helper/formatter'
import icons from '../../../../helper/icons'
import iconName from '../../../../helper/constant/iconName'
import globalConstant from '../../../../store/constant/global'
import {
  buttonResource,
  placeholderResource,
  titleSentence,
} from '../../../../helper/translatePage/languageResource'
import translate from '../../../../helper/translate'

class InquiryResult extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
    }
  }

  handleNext = () => {
    this.props.btnNext(true)
  }

  handleBack = () => {
    this.props.onBack('inquiry')
  }

  labelCheckbox = () => {
    return (
      <>
        <div className="fontSize-16">
          {translate('manualInputInquiryResultInfo1', this.props.language, titleSentence)}
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => this.setState({ showModal: true })}
            className="text-active"
          >
            FAQ
          </span>
        </div>
      </>
    )
  }

  closeModal = () => {
    this.setState({ showModal: false })
  }

  render() {
    const { tableData, nextBtn, language } = this.props
    const { showModal } = this.state
    return (
      <>
        <div className="mb-3 mt-3 ml-3 d-flex align-items-center" onClick={this.handleBack}>
          <button type="button" className="btn btn-outline-primary back-button">
            <Image src={icons(iconName.backButton)} className="" />
          </button>
          <span className="ml-3">
            <div className="fontSize-16">
              {translate('manualInputInfoback1', language, titleSentence)}
            </div>
            <div className="fontSize-16">
              {translate('manualInputInfoback1Add', language, titleSentence)}
            </div>
          </span>
        </div>
        <div className="container-inquiry-result mt-5">
          <div className="container-inquiry-info mb-5">
            <div className="image-logo">
              <Image
                src={tableData.url_image != '' ? tableData.url_image : icons(iconName.gubuk)}
                className=""
              />
            </div>
            <Row>
              <Col sm="6" xs="12">
                <div className="fontSize-16-Bold text-active">
                  {translate('destinationAccount', language, titleSentence)}
                </div>
                <div className="fontSize-16-Bold">
                  {tableData.no_pelanggan}
                  <br />
                  {tableData.nama_pelanggan}
                </div>
              </Col>
              <Col sm="6" xs="12">
                <div className="fontSize-16-Bold text-active">
                  {translate('nominalTransfer', language, placeholderResource)}
                </div>
                <div className="fontSize-16-Bold">Rp {Formatter.price(tableData.nominal)}</div>
              </Col>
              <Col sm="6" xs="12">
                <div className="fontSize-16-Bold text-active mt-3">
                  {translate('news', language, titleSentence)}
                </div>
                <div className="fontSize-16-Bold">
                  {tableData.catatan ? tableData.catatan : '-'}
                </div>
              </Col>
              <Col sm="6" xs="12">
                <div className="fontSize-16-Bold text-active mt-3">
                  {translate('adminFee', language, titleSentence)}
                </div>
                <div className="fontSize-16-Bold">
                  Rp {Formatter.price(tableData.nominal_admin)}
                </div>
              </Col>
            </Row>
          </div>
          <Row>
            <Col className="d-flex align-items-center">{this.labelCheckbox()}</Col>
            <Col>
              {nextBtn && (
                <Button
                  type="submit"
                  size="md"
                  variant="primary"
                  block
                  onClick={this.handleNext}
                  className="btn-custom btn-custom-reset-password fontSize-17 clear-margin"
                >
                  {translate('withdrawButton', language, buttonResource)}
                  <Image src={icons(iconName.arrowWhite)} className="ml-3" />
                </Button>
              )}
            </Col>
          </Row>
          <Modal show={showModal} onHide={this.closeModal}>
            <Modal.Header closeButton>
              <Modal.Title>FAQ</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <iframe title="FAQ" src="https://www.linkqu.id/faq-produk-dan-layanan/" />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" className="d-none">
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    )
  }
}

InquiryResult.defaultProps = {
  tableData: '',
  btnNext: '',
  onBack: '',
  nextBtn: true,
}

InquiryResult.propTypes = {
  tableData: PropTypes.string,
  btnNext: PropTypes.string,
  onBack: PropTypes.string,
  nextBtn: PropTypes.bool,
}

export default connect(
  (state) => ({
    language: state.Global[globalConstant.LANGUAGE],
  }),
  (dispatch) => ({ dispatch })
)(InquiryResult)
